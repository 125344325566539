import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface IPropsBorderStatus {
  backgroundColor: string;
}

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  padding: 37px 10px 68px 10px;
  margin: 0 auto;
  position: relative;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 40px 24px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: 100%;
    max-width: 1170px;
  }
`;

export const Separator = styled.div`
  max-width: 870px;
  border: 1px solid #e9e9e9;
  margin: 0 auto;
`;

export const ContainerBorderStatus = styled.div`
  width: 100%;
  display: flex;
`;

export const BorderStatus = styled.div<IPropsBorderStatus>`
  width: 100%;
  height: 8px;
  box-shadow: inset 0px 2.76838px 17.9945px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  background: ${({ backgroundColor }) => backgroundColor};
`;

export const Header = styled.div`
  background: ${Color.blueIntense};
  border-radius: 23px 23px 0 0;
  height: 40px;
`;

export const Container = styled.div`
  width: calc(100% - 300px);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.19);
  border-radius: 16px;
  margin-right: 296px;

  @media screen and (max-width: 1340px) {
    width: 100%;
    margin-right: 0px;
  }
`;

export const BoxContent = styled.div`
  width: calc(100 - 48px);
  max-width: 870px;
  margin: 0 auto;
  padding: 24px 24px 0 24px;

  h1 {
    max-width: 670px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    color: ${Color.blueIntense};
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: 100%;
    padding: 32px 0 0 0;

    h1 {
      font-size: 28px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 22px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    margin-top: 34px;
    flex-direction: row;
  }
`;

export const Divisor = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;

  h2 {
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }

  a {
    color: ${Color.blueSeconde};
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-left: auto;
    position: absolute;
    top: -30px;
    right: 0;
  }

  button {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
  }

  :last-of-type {
    justify-content: flex-end;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    top: 0;
  }
`;

export const ContainerDate = styled.div`
  width: 100%;
  display: flex;
  margin: 76px 0 24px 0;
  gap: 8px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    p:first-of-type {
      font-size: 14px;
    }
    p:last-of-type {
      font-weight: 500;
      font-size: 18px;
      margin-top: 6px;
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    margin: 0;
  }
`;

export const SeparatorDate = styled.div`
  border: 1px solid #e9e9e9;
`;

export const ContainerInfo = styled.div`
  width: 100%;
  height: 128px;
  background: ${Color.grayLightSeconde};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  color: '#000000';
  margin-bottom: 80px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    margin-bottom: 0;
  }
`;

export const ContainerProtocol = styled.div`
  width: 100%;

  p {
    font-weight: 300;
    font-size: 14px;
    color: '#000000';
    text-align: center;

    b {
      font-weight: 500;
      font-size: 18px;
    }

    button {
      background: none !important;
      border: none;
      margin-left: 7px;
      box-shadow: none;
      cursor: pointer;
      svg {
        width: 13px;
        height: 15px;
      }
    }
  }
`;

export const CopyText = styled.p`
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: ${Color.blueSeconde};
`;

export const ContentProtocol = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentProtocolButtons = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    height: 48px;
    max-width: 300px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1180px;
  margin: 0 auto 22px auto;
  display: none;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    display: inherit;
  }
`;

export const DateSeparator = styled.span`
  border: 1px solid #e9e9e9;
`;
