import ReactGA from 'react-ga4';
import { Dispatch } from 'redux';
import { postPaymentService, getPaymentReceiptService } from '~/services/payment';
import Navigation from '~/utils/Navigation';
import {
  IPaymentVoucher,
  IRequestIPaymentVoucher,
  IRequestPayment,
  IResponsePayment,
  RepositoriesTypesPayment,
} from './types';
import { IEnumCacheConfig } from '~/store/cache/enum';
import { apiCache } from '~/store/cache/apiCache';

const postPaymentRequest = (data: IRequestPayment) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesPayment.POST_PAYMENT_METHOD_REQUEST,
    });

    const response = (await postPaymentService(data)) as IResponsePayment;

    if (data?.isAuthenticated) {
      dispatch(
        apiCache.util.invalidateTags([
          IEnumCacheConfig.ENROLLMENTS_INVOICES_INVOICES_LIST,
          IEnumCacheConfig.ENROLLMENTS_INVOICES_INVOICE_DETAIL,
          IEnumCacheConfig.NEGOTATION_DEBTS_LIST,
        ]),
      );
    } else {
      dispatch(
        apiCache.util.invalidateTags([
          IEnumCacheConfig.OUTSIDES_ENROLLMENTS_INVOICES_LIST,
          IEnumCacheConfig.OUTSIDES_DEBTS_CHARGES_LIST,
        ]),
      );
    }

    if (!data.isNegotiation) {
      const responseReceipt = (await getPaymentReceiptService({
        isAuthenticated: data?.isAuthenticated || false,
        queryParam: {
          groupId: response?.groupId,
        },
      })) as IPaymentVoucher;

      if (data?.dataSucessEventGA) {
        const { category, action } = data.dataSucessEventGA;

        ReactGA.event({
          category,
          action,
        });
      }

      Navigation.navigate(data?.routeInvoiceReceipt);

      return dispatch({
        type: RepositoriesTypesPayment.POST_PAYMENT_METHOD_SUCCESS,
        payload: {
          data: {
            ...responseReceipt,
          },
        },
      });
    }

    return dispatch({
      type: RepositoriesTypesPayment.POST_PAYMENT_METHOD_NEGOTIATION_SUCCESS,
    });
  } catch (error: any) {
    return dispatch({
      type: RepositoriesTypesPayment.POST_PAYMENT_METHOD_FAILURE,
      payload: {
        error: {
          status: error?.response?.status,
          state: true,
          message: error?.response?.data?.type,
        },
      },
    });
  }
};

const postPaymentCloseError = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesPayment.POST_PAYMENT_METHOD_FAILURE_CLOSE,
  });
};

const getPaymentReceiptRequest =
  (data: IRequestIPaymentVoucher) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesPayment.GET_PAYMENT_VOUCHER_METHOD_REQUEST,
      });

      const response = (await getPaymentReceiptService(data)) as IPaymentVoucher;

      Navigation.navigate(data?.routeInvoiceReceipt);

      return dispatch({
        type: RepositoriesTypesPayment.GET_PAYMENT_VOUCHER_METHOD_SUCCESS,
        payload: {
          data: {
            ...response,
          },
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesPayment.GET_PAYMENT_VOUCHER_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
            message: error?.response?.data?.type,
          },
        },
      });
    }
  };

const getPaymentReceiptCloseError = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesPayment.POST_PAYMENT_METHOD_FAILURE_CLOSE,
  });
};

export {
  postPaymentRequest,
  postPaymentCloseError,
  getPaymentReceiptRequest,
  getPaymentReceiptCloseError,
};
