import { EndpointBuilder, BaseQueryFn } from '@reduxjs/toolkit/query';
import { IEnumCacheConfig } from '../enum';
import {
  IResponseGetRenovationSocialTariff,
  IResponseGetSocialTariff,
} from '~/store/modules/socialTariff/types';

export const socialTariffEndpoints = (
  builder: EndpointBuilder<BaseQueryFn, IEnumCacheConfig, 'cache'>,
) => ({
  fetchSocialTariffCheck: builder.query<
    IResponseGetSocialTariff,
    { registration: string }
  >({
    query: ({ registration }) => ({
      url: `v3/social-tariff/enrollment/${registration}`,
      method: 'GET',
    }),
    providesTags: [IEnumCacheConfig.ORDER_SERVICES_LIST],
  }),
  fetchSocialTariffRenovationCheck: builder.query<
    IResponseGetRenovationSocialTariff,
    { registration: string }
  >({
    query: ({ registration }) => ({
      url: `v3/social-tariff/renovation/enrollment/${registration}`,
      method: 'GET',
    }),
    providesTags: [IEnumCacheConfig.SOCIAL_TARIFF_RENOVATION_CHECK],
  }),
});
