import styled, { css, keyframes } from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const ContainerInvoicePixCode = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  margin-top: 24px;
  align-items: flex-end;
  flex-direction: column;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
  }
`;

export const ContainerInvoiceCodeBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 72%;
  }
`;

export const LabelBox = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${Color.realBlack};
  margin-top: 20px;
  margin-bottom: 4px;
`;

export const ContainerBox = styled.div`
  min-height: 36px;
  display: flex;
  align-items: center;
  border: 1px solid #999999;
  border-radius: 10px;
  padding: 0 20px 0 10px;
`;

export const TextBox = styled.p`
  height: 15px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
`;

const rotating = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

interface IPropsContainerCodeBarLabelInvoice {
  copied: boolean;
  clicked: boolean;
  maxWidth: boolean;
}

export const ButtonCodeInvoice = styled.button<IPropsContainerCodeBarLabelInvoice>`
  width: 100%;
  margin-top: 16px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${({ copied }) => (copied ? Color.green : '#e9ecef')};
  border-radius: 8px;
  padding: 0 16px;
  cursor: pointer;
  transition: all 0.25s ease;

  ${({ maxWidth }) => maxWidth && `width: 100%;`}

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #000;
  }

  svg {
    width: 14px;
    height: 16px;
    cursor: pointer;
  }

  & + & {
    margin: 8px 16px 0 0;
  }

  ${({ clicked }) =>
    clicked &&
    css`
      width: 32px;
      height: 32px;
      margin: 8px auto 0 auto !important;
      padding: 0;
      border: 1px solid ${Color.grayDark};
      border-color: ${Color.grayDark};
      border-radius: 50px;
      border-width: 3px;
      font-size: 0;
      border-left-color: ${Color.green};
      animation: ${rotating} 2s 0.25s linear infinite;
    `}

  ${({ copied }) =>
    copied &&
    css`
      background: ${Color.green};
    `}

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: 36px;
    max-width: 251px;

    ${({ clicked }) =>
      clicked &&
      css`
        height: 32px;
      `}
  }
`;
