import React, { FC, useEffect, useState } from 'react';
import {
  Main,
  Card,
  ContainerBreadCrumb,
  Title,
  Content,
  ContainerStepper,
} from './styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import StepOne from './StepOne';
import { Stepper } from './Stepper';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import Modal from '~/components/Modal';
import ModalTerm from './ModalTerm';
import {
  IResponseDataDebtsNegotiationInstallmentsInstallment,
  IResponseDataDebtsNegotiationPaymentCondition,
} from '~/store/modules/negotiation/types';
import LoadingComponent from '~/components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import ModalError from '~/components/ModalError';
import {
  getErrorCloseDebtsInstallmentsNegotiation,
  getErrorCloseDebtsNegotiationTerms,
  postErrorCloseDebtsNegotiation,
} from '~/store/modules/negotiation/actions';
import { INegotationProps } from '../types';
import formatter from '~/utils/formatter';
import { AppDispatch } from '~/store';

const steps = [
  {
    id: 0,
    label: 'Entrada',
  },
  {
    id: 1,
    label: 'Parcelas',
  },
  {
    id: 2,
    label: 'Cofirmação',
  },
];

export interface IDebtNegotiationSimulationHistory {
  unitRegistrationNumber: string;
  campaignPaymentConditions: IResponseDataDebtsNegotiationPaymentCondition;
  maximumDownPayment: number;
  campaignPaymentConditionId: number;
}

const NegotiationSimulation: FC<INegotationProps> = ({ links }) => {
  const dispatch = useDispatch<AppDispatch>();

  const loadingDebtsNegotiationInstallments = useSelector(
    (item: IState) => item.negotiation.loadingDebtsNegotiationInstallments,
  );
  const loadingDebtsNegotiationTerms = useSelector(
    (item: IState) => item.negotiation.loadingDebtsNegotiationTerms,
  );
  const loadingDebtsNegotiationCreate = useSelector(
    (item: IState) => item.negotiation.loadingDebtsNegotiationCreate,
  );
  const errorDebtsNegotiationInstallments = useSelector(
    (item: IState) => item.negotiation.errorDebtsNegotiationInstallments,
  );
  const errorDebtsNegotiationTerms = useSelector(
    (item: IState) => item.negotiation.errorDebtsNegotiationTerms,
  );
  const errorDebtsNegotiationCreate = useSelector(
    (item: IState) => item.negotiation.errorDebtsNegotiationCreate,
  );

  const [step, setStep] = useState<number>(0);
  const [openTerm, setOpenTerm] = useState<boolean>(false);
  const [entryValue, setEntryValue] = useState<number>(0);
  const [installment, setInstallment] = useState<string>('');
  const [installmentSelected, setInstallmentSelected] =
    useState<IResponseDataDebtsNegotiationInstallmentsInstallment>();

  const modalError =
    !!errorDebtsNegotiationInstallments ||
    !!errorDebtsNegotiationTerms ||
    !!errorDebtsNegotiationCreate;

  const handleCloseErrors = () => {
    dispatch(getErrorCloseDebtsInstallmentsNegotiation());
    dispatch(getErrorCloseDebtsNegotiationTerms());
    dispatch(postErrorCloseDebtsNegotiation());
  };

  useEffect(() => {
    handleCloseErrors();
  }, []);

  useEffect(() => {
    document.getElementById('root')?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [step]);

  const getStep = () => {
    switch (step) {
      case 0:
        return {
          step: <StepOne setStep={setStep} setEntryValue={setEntryValue} />,
        };
      case 1:
        return {
          step: (
            <StepTwo
              setStep={setStep}
              entryValue={entryValue}
              installment={installment}
              setInstallment={setInstallment}
              setInstallmentSelected={setInstallmentSelected}
            />
          ),
        };

      default:
        return {
          step: (
            <StepThree
              setStep={setStep}
              setOpenTerm={setOpenTerm}
              entryValue={entryValue}
              installment={`${installmentSelected?.installmentCount} X ${formatter.formatCurrency(
                installmentSelected?.installmentAmount,
              )}`}
            />
          ),
        };
    }
  };

  return (
    <Main>
      {(loadingDebtsNegotiationInstallments ||
        loadingDebtsNegotiationTerms ||
        loadingDebtsNegotiationCreate) && <LoadingComponent />}
      <Modal open={modalError} type="error" title="Ops!" v3>
        <ModalError onClose={handleCloseErrors} />
      </Modal>
      {openTerm && (
        <Modal open type="info" v3 hidden maxWidth={670} maxHeight={505}>
          <ModalTerm
            onClose={() => setOpenTerm(false)}
            installmentSelected={installmentSelected}
            entryValue={entryValue}
          />
        </Modal>
      )}
      <ContainerBreadCrumb>
        <BreadCrumb links={links} />
      </ContainerBreadCrumb>
      <Card>
        <Content>
          <Title>Simule uma negociação</Title>
          <ContainerStepper>
            <Stepper steps={steps} activeStep={step} />
          </ContainerStepper>
        </Content>
        <Content>{getStep()?.step}</Content>
      </Card>
    </Main>
  );
};

export default NegotiationSimulation;
