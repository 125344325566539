import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';

import Navigation from '~/utils/Navigation';

import { ContactUsText, Main } from './styles';
import { RouteName } from '~/routes/Routes.name';
import { IState } from '~/store/modules/types';
import { postEnrollmentsUsersSendRecoveryRequest } from '~/store/modules/enrollmentsUsers/actions';
import { history } from '~/store';
import { CardForm } from '~/design_system/components/CardForm';
import { Button } from '~/design_system/components/Button';
import { IEnumButtonVariant } from '~/design_system/components/Button/enum';
import Modal from '~/design_system/components/Modal';
import { IEnumModalType } from '~/design_system/components/Modal/enum';
import { MethodSendCode } from '~/design_system/components/MethodSendCode';
import { AppDispatch } from '~/store';

const ForgotPasswordMethod: React.FC = () => {
  const dataHistory = history.location.state as any;

  const dispatch = useDispatch<AppDispatch>();

  const dataObfuscated = useSelector(
    (item: IState) => item.enrollmentsUsers.dataObfuscated,
  );

  const [open, setOpen] = useState<boolean>(false);

  const emptyPhone = !dataObfuscated?.phone;
  const emptyEmail = !dataObfuscated?.email;

  useEffect(() => {
    !dataObfuscated?.phone && !dataObfuscated?.email && setOpen(true);
  }, []);

  const handleSendToken = (shippingChannel: 'phone' | 'email') => {
    ReactGA.event({
      category: 'Expiração de senha',
      action:
        shippingChannel === 'phone'
          ? '[Expiração de Senha][WEB][Código de Verificação] Receber por SMS'
          : '[Expiração de Senha][WEB][Código de Verificação] Receber por E-mail',
    });

    dispatch(
      postEnrollmentsUsersSendRecoveryRequest({
        cnp: dataHistory?.cnp || '',
        shippingChannel,
      }),
    );
    Navigation.navigate(RouteName.FORGOTPASSWORDCODE, {
      cnp: dataHistory?.cnp,
      shippingChannel,
    });
  };

  return (
    <Main>
      {open && (
        <Modal
          type={IEnumModalType.DEFAULT}
          description={
            <>
              Seu cadastro está incompleto.
              <br /> Acesse o <ContactUsText>Fale Conosco</ContactUsText> para
              recuperar a sua senha.
            </>
          }
          textButton="Voltar"
          onClick={() => setOpen(false)}
        />
      )}
      <CardForm
        title="Quase lá!"
        description="Escolha por onde receber o código de verificação:"
        maxWidth={472}
        doubleGap
      >
        <MethodSendCode
          onClickSms={() => dataObfuscated?.phone && handleSendToken('phone')}
          onClickEmail={() => dataObfuscated?.email && handleSendToken('email')}
          textPhone={dataObfuscated?.phone}
          textEmail={dataObfuscated?.email}
          disabledSms={emptyPhone}
          disabledEmail={emptyEmail}
        />
        <Button
          variant={IEnumButtonVariant.TEXT}
          onClick={() => Navigation.goBack()}
        >
          Voltar
        </Button>
      </CardForm>
    </Main>
  );
};

export default ForgotPasswordMethod;
