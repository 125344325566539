import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
  }
`;

export const ContainerGroupSkeleton = styled.div`
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 16px 16px;
`;
