import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import LoadingComponent from '~/components/Loading';
import { IState } from '~/store/modules/types';

import { Margin } from '~/components/Margin';
import {
  Main,
  ContainerBreadCrumb,
  Card,
  ContainerBorder,
  Border,
  Container,
  ContainerHeader,
  ContainerColumn,
  HeaderText,
} from './styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import ContainerNegotiation from './ContainerNegotiation';
import Pagination from './ContainerNegotiation/Pagination';
import EmptyMessage from '~/components/EmptyMessage';
import { BannerApp } from '~/components/BannerApp';
import {
  getDebtsNegotiationListRequest,
  getErrorCloseDebtsListListDetailNegotiation,
  getErrorCloseDebtsListNegotiation,
} from '~/store/modules/negotiation/actions';
import Modal from '~/components/Modal';
import ModalError from '~/components/ModalError';
import { AppDispatch } from '~/store';

const Negotiations: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const dataDebtsNegotiationList = useSelector(
    (item: IState) => item?.negotiation?.dataDebtsNegotiationList,
  );
  const pagination = useSelector(
    (item: IState) => item?.negotiation?.dataDebtsNegotiationList?.pagination,
  );
  const loading = useSelector(
    (item: IState) => item.negotiation.loadingDebtsNegotiationList,
  );
  const loadingDebtsNegotiationListDetail = useSelector(
    (item: IState) => item.negotiation.loadingDebtsNegotiationListDetail,
  );
  const errorDebtsNegotiationListDetail = useSelector(
    (item: IState) => item.negotiation.errorDebtsNegotiationListDetail,
  );

  useEffect(() => {
    dispatch(getErrorCloseDebtsListNegotiation());
    dispatch(getDebtsNegotiationListRequest({ page: 1, size: 6 }));
  }, []);

  return (
    <Main>
      {(loading || loadingDebtsNegotiationListDetail) && (
        <LoadingComponent labelWait="Carregando..." />
      )}
      <Modal open={!!errorDebtsNegotiationListDetail} type="error" title="Ops!" v3>
        <ModalError
          onClose={() => dispatch(getErrorCloseDebtsListListDetailNegotiation())}
        />
      </Modal>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Minhas negociações',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Container>
        <BannerApp />
        <Card>
          <h1>Minhas negociações</h1>
          <ContainerBorder>
            <Border />
          </ContainerBorder>
          <ContainerHeader>
            <ContainerColumn flexBasis={20}>
              <HeaderText>ID</HeaderText>
            </ContainerColumn>
            <ContainerColumn flexBasis={25}>
              <HeaderText>Tipo</HeaderText>
            </ContainerColumn>
            <ContainerColumn flexBasis={15}>
              <HeaderText>Valor</HeaderText>
            </ContainerColumn>
            <ContainerColumn flexBasis={40}>
              <HeaderText>Status</HeaderText>
            </ContainerColumn>
          </ContainerHeader>
          {dataDebtsNegotiationList &&
          dataDebtsNegotiationList?.negotiations?.length > 0 ? (
            dataDebtsNegotiationList?.negotiations?.map((negotiation) => (
              <ContainerNegotiation key={negotiation.id} data={negotiation} />
            ))
          ) : (
            <>
              <Margin height={27} />
              <EmptyMessage textMessage="Você ainda não possui negociações!" />
            </>
          )}
          {pagination && pagination?.maxPages > 1 && <Pagination />}
        </Card>
      </Container>
    </Main>
  );
};

export default Negotiations;
