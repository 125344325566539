import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosRequestConfig, AxiosError } from 'axios';
import { api } from '.';

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: Record<string, string>;
      timeout?: number;
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers, timeout }) => {
    try {
      const result = await api({
        url,
        method,
        data,
        params,
        headers,
        timeout: timeout ?? 60000,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default axiosBaseQuery;
