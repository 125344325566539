import React from 'react';
import { createRoot } from 'react-dom/client';
import moment from 'moment';
import 'moment/locale/pt-br';

import App from './app';

moment.locale('pt-br');

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);
