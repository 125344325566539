import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteName } from '~/routes/Routes.name';
import Navigation from '~/utils/Navigation';
import {
  getErrorCloseDebtsInstallmentsNegotiation,
  getErrorCloseDebtsNegotiationTerms,
  postErrorCloseDebtsNegotiation,
} from '~/store/modules/negotiation/actions';
import NegotiationSimulation from '~/components/Negotation/NegotiationSimulation';
import { IState } from '~/store/modules/types';
import { AppDispatch } from '~/store';

const DebtNegotiationSimulation: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const token = useSelector((item: IState) => item.enrollmentsAuth.token);

  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(token ? RouteName.HOME : RouteName.LANDING),
    },
    {
      label: 'Negociação de dívidas',
      active: false,
      onClick: () => Navigation.navigate(RouteName.DISCHARGEDEBTS),
    },
    {
      label: 'Dívidas em aberto',
      active: false,
      onClick: () => Navigation.navigate(RouteName.DEBTNEGOTIATION),
    },
    {
      label: 'Tipo de pagamento',
      active: false,
      onClick: () => Navigation.goBack(),
    },
    {
      label: 'Simulação',
      active: true,
    },
  ];

  const handleCloseErrors = () => {
    dispatch(getErrorCloseDebtsInstallmentsNegotiation());
    dispatch(getErrorCloseDebtsNegotiationTerms());
    dispatch(postErrorCloseDebtsNegotiation());
  };

  useEffect(() => {
    handleCloseErrors();
  }, []);

  if (token) delete links[1];

  return <NegotiationSimulation links={links} />;
};

export default DebtNegotiationSimulation;
