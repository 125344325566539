import React, { FC, useEffect, useState } from 'react';

import {
  Main,
  Label,
  Row,
  SenFiles,
  ContainerTitle,
  SeparatorTitle,
  Title,
  Content,
} from './styles';

import DropZone from '../DropZone';
import {
  postCreateFileCodesCustomerObligationRequest,
  patchUploadFileCustomerObligationRequest,
  getFileCodesCustomerObligationRequest,
  patchAttachFilesCustomerObligationRequest,
  patchAttachRevisionFilesDocumentsAndProjectsOCRequest,
} from '~/store/modules/customerObligation/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import StatusInfo from '../../../components/StatusInfo';
import { Margin } from '~/components/Margin';
import { IDocuments as IDocumentsDocumentationApproval } from '../Form';
import { IDocuments as IDocumentsGenerateCode } from '../FormGenerateCode';
import convertFileToBase64 from '~/utils/convertBase64';
import {
  BoxGeneratedCode,
  ButtonInfo,
  ContainerGenerateCode,
} from '../FormGenerateCode/styles';
import ArrowUpSVG from '~/assets/Icons/arrowUp';
import { IResponseAttachment } from '~/store/modules/customerObligation/types';
import sourceDocuments from '../documents.json';
import sourceDocumentsGenerateCode from '../FormGenerateCode/documents.json';
import { ContainerButton } from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/styles';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { AppDispatch } from '~/store';

const DocumentationApprovalRevision: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const documents = sourceDocuments as IDocumentsDocumentationApproval[];
  const documentsGenerateCode =
    sourceDocumentsGenerateCode as IDocumentsGenerateCode[];

  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);

  const idCreate = useSelector((item: IState) => item.customerObligation.idCreate);
  const fileCodes = useSelector((item: IState) => item.customerObligation.fileCodes);

  const [disabled, setDisabled] = useState(true);
  const [documentsFilter, setDocumentsFilter] = useState<
    Array<IDocumentsDocumentationApproval>
  >([]);
  const [documentsGenerateCodeFilter, setDocumentsGenerateCodeFilter] = useState<
    Array<IDocumentsGenerateCode>
  >([]);

  const loadingUploadFile = useSelector(
    (item: IState) => item.customerObligation.loadingUploadUpdateFile,
  );
  const loadingAttachFiles = useSelector(
    (item: IState) => item.customerObligation.loadingAttachFiles,
  );
  const loadingDocumentAndProject = useSelector(
    (item: IState) => item.customerObligation.loadingDarmCode,
  );
  const isRevisionDocuments = caseOC?.attachments.find(
    (attachment: IResponseAttachment) =>
      attachment.review === true && attachment.step === '2A',
  );
  const isRevisionProject = caseOC?.attachments.find(
    (attachment: IResponseAttachment) =>
      attachment.review === true && attachment.step === '2B',
  );

  const verifyDocuments = async () => {
    if (isRevisionDocuments && isRevisionProject) {
      if (
        documentsFilter.filter((document) => !document.file).length > 0 ||
        documentsGenerateCodeFilter.filter((document) => !document.file).length >
          0 ||
        loadingUploadFile
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }

    if (isRevisionDocuments && !isRevisionProject) {
      if (
        documentsFilter.filter((document) => !document.file).length > 0 ||
        loadingUploadFile
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }

    if (isRevisionProject && !isRevisionDocuments) {
      if (
        documentsGenerateCodeFilter.filter((document) => !document.file).length >
          0 ||
        loadingUploadFile
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  };

  const sendDocsRevision = async () => {
    const isRevisionDocuments = caseOC?.attachments.find(
      (attachment: IResponseAttachment) =>
        attachment.review === true && attachment.step === '2A',
    );
    const isRevisionProject = caseOC?.attachments.find(
      (attachment: IResponseAttachment) =>
        attachment.review === true && attachment.step === '2B',
    );

    const calls = {
      document: patchAttachFilesCustomerObligationRequest({
        case: idCreate || caseOC?.id || '',
        step: '2A',
      }),
      project: patchAttachFilesCustomerObligationRequest({
        case: idCreate || caseOC?.id || '',
        step: '2B',
      }),
      documentAndProject: patchAttachRevisionFilesDocumentsAndProjectsOCRequest({
        document: {
          case: idCreate || caseOC?.id || '',
          step: '2A',
        },
        project: {
          case: idCreate || caseOC?.id || '',
          step: '2B',
        },
      }),
    };

    const resolveCall = () => {
      if (!!isRevisionDocuments && !!isRevisionProject) return 'documentAndProject';
      if (isRevisionProject) return 'project';

      return 'document';
    };

    const call = resolveCall();

    const caller = calls[call];

    dispatch(caller);
  };

  useEffect(() => {
    verifyDocuments();
  }, [
    isRevisionDocuments,
    isRevisionProject,
    documentsFilter,
    documentsGenerateCodeFilter,
    loadingUploadFile,
  ]);

  const filterRevisionDocuments: Array<any> = [];
  const filterRevisionProjects: Array<any> = [];

  const getFileRevisionData = async () => {
    caseOC?.attachments.forEach((attachment: IResponseAttachment) => {
      if (attachment.review === true) {
        documents.map((e) => {
          if (e.key === attachment.type) {
            return filterRevisionDocuments.push({
              ...e,
              id: attachment.id,
              description: attachment.review_description,
              attachKey: attachment.key,
              error: '',
            });
          }
          return null;
        });
        documentsGenerateCode.map((e) => {
          if (e.key === attachment.type) {
            return filterRevisionProjects.push({
              ...e,
              id: attachment.id,
              description: attachment.review_description,
              isActive: true,
              attachKey: attachment.key,
              error: '',
            });
          }
          return null;
        });
      }
    });
    setDocumentsGenerateCodeFilter(filterRevisionProjects);
    setDocumentsFilter(filterRevisionDocuments);
    return null;
  };

  useEffect(() => {
    getFileRevisionData();
  }, []);

  useEffect(() => {
    documentsGenerateCode.map((document) => {
      return dispatch(
        getFileCodesCustomerObligationRequest({
          case: idCreate || caseOC?.id || '',
          documentType: document.key,
          type: caseOC?.type || 'oca',
        }),
      );
    });
  }, []);

  const pushCodeButton = async () => {
    let executed_work_descriptive_memorial_codes: Array<string> = [];
    let executed_work_calculation_memorial_codes: Array<string> = [];
    let executed_work_executive_project_codes: Array<string> = [];

    fileCodes.executed_work_descriptive_memorial.map(
      (executed_work_descriptive_memorial) => {
        return executed_work_descriptive_memorial_codes.push(
          executed_work_descriptive_memorial.code,
        );
      },
    );

    fileCodes.executed_work_calculation_memorial.map(
      (executed_work_calculation_memorial) => {
        return executed_work_calculation_memorial_codes.push(
          executed_work_calculation_memorial.code,
        );
      },
    );

    fileCodes.executed_work_executive_project.map(
      (executed_work_executive_project) => {
        return executed_work_executive_project_codes.push(
          executed_work_executive_project.code,
        );
      },
    );

    let array = [...documentsGenerateCodeFilter];

    const codesDocument = array.map((document) => {
      if (document.key === 'executed_work_descriptive_memorial') {
        return {
          ...document,
          codes: executed_work_descriptive_memorial_codes,
        };
      }

      if (document.key === 'executed_work_calculation_memorial') {
        return {
          ...document,
          codes: executed_work_calculation_memorial_codes,
        };
      }

      if (document.key === 'executed_work_executive_project') {
        return {
          ...document,
          codes: executed_work_executive_project_codes,
        };
      }

      return document;
    });

    setDocumentsGenerateCodeFilter(codesDocument);
  };

  useEffect(() => {
    if (documentsGenerateCodeFilter?.length > 0) pushCodeButton();
  }, [fileCodes]);

  return (
    <>
      <Main>
        <>
          <Content>
            <StatusInfo status="warning">
              Os documentos abaixo precisam ser revisados. Favor, leia o comentário e
              anexe um novo arquivo.
            </StatusInfo>
          </Content>
          <Margin height={60} />
          {isRevisionDocuments && (
            <>
              <ContainerTitle>
                <SeparatorTitle />
                <Title>Revisão de Documentos</Title>
              </ContainerTitle>
              <Margin height={74} />
              <Content>
                {documentsFilter?.map((document, index: number) => (
                  <Row key={document.key}>
                    <SenFiles>
                      <DropZone
                        disabled={!!document.file}
                        infoFileName={document.label}
                        name={document.label}
                        content={document.file || null}
                        onFileUploaded={async (file: File | null) => {
                          const array = [...documentsFilter];
                          if (file && file.size < 100) {
                            array[index].error =
                              'Por favor, verifique o seu arquivo.';
                            return setDocumentsFilter(array);
                          }
                          array[index].file = file;
                          if (file === null) {
                            array[index].error = '';
                            return setDocumentsFilter(array);
                          }
                          if (file) {
                            convertFileToBase64(file, (base64: string) => {
                              dispatch(
                                patchUploadFileCustomerObligationRequest({
                                  idFile: document?.id || '',
                                  caseIdSales: idCreate || caseOC?.id || '',
                                  attachmentKey: document?.attachKey || '',
                                  type: document.key,
                                  step: '2A',
                                  filename: file?.name || '',
                                  filedata: base64,
                                }),
                              );
                            });
                          }

                          return setDocumentsFilter(array);
                        }}
                        isRevision
                        type={document.type}
                        error={!document.file || !!document.error}
                        errorText={document.error}
                      />
                    </SenFiles>
                    <Label>{document.description}</Label>
                  </Row>
                ))}
              </Content>
              <Margin height={50} />
            </>
          )}
          {isRevisionProject && (
            <>
              <ContainerTitle>
                <SeparatorTitle />
                <Title>Revisão de Projetos</Title>
              </ContainerTitle>
              <Margin height={74} />
              <Content>
                {documentsGenerateCodeFilter.map((docButton, index: number) => {
                  return (
                    <div key={docButton.key}>
                      <ButtonInfo
                        key={index && index}
                        isActive={docButton.isActive}
                        onClick={async () => {
                          const array = documentsGenerateCodeFilter.map(
                            (item: any, indexItem) => {
                              if (index === indexItem)
                                item.isActive = !item.isActive;
                              return item;
                            },
                          );
                          setDocumentsGenerateCodeFilter(array);
                        }}
                        isRevision={!docButton.file}
                      >
                        {docButton.label}
                        <ArrowUpSVG />
                      </ButtonInfo>
                      {!docButton.isActive && <Margin height={40} />}
                      {docButton.isActive && (
                        <>
                          <Margin height={12} />
                          <ContainerGenerateCode isRevision={!docButton.file}>
                            <DropZone
                              isRevision
                              disabled={
                                !!docButton.file || docButton.codes.length < 1
                              }
                              infoFileName="Anexar arquivo"
                              content={docButton.file}
                              name={`file-${index}`}
                              onFileUploaded={async (file: File | null) => {
                                const array = [...documentsGenerateCodeFilter];

                                if (file && file.size < 100) {
                                  array[index].error =
                                    'Por favor, verifique o seu arquivo.';
                                  return setDocumentsGenerateCodeFilter(array);
                                }
                                array[index].file = file;
                                if (file === null) {
                                  array[index].error = '';
                                  return setDocumentsGenerateCodeFilter(array);
                                }

                                if (file) {
                                  convertFileToBase64(file, (base64: string) => {
                                    dispatch(
                                      patchUploadFileCustomerObligationRequest({
                                        idFile: docButton.id || '',
                                        caseIdSales: idCreate || caseOC?.id || '',
                                        attachmentKey: docButton.attachKey || '',
                                        type: docButton.key,
                                        step: '2B',
                                        filename: file?.name || '',
                                        filedata: base64,
                                      }),
                                    );
                                  });
                                }

                                return setDocumentsGenerateCodeFilter(array);
                              }}
                              error={!docButton.file || !!docButton.error}
                              errorText={docButton.error}
                            />
                            <BoxGeneratedCode>
                              <button
                                type="button"
                                onClick={() => {
                                  dispatch(
                                    postCreateFileCodesCustomerObligationRequest({
                                      caseIdSales: idCreate || caseOC?.id || '',
                                      type:
                                        caseOC?.type?.toLocaleLowerCase() || 'oca',
                                      documentType: docButton.key,
                                      processNumber: caseOC?.caseNumber || ``,
                                    }),
                                  );
                                }}
                              >
                                Gerar código
                              </button>
                              <div>
                                <p>Seus códigos para preenchimento:</p>
                                {docButton.codes.length > 0 ? (
                                  docButton.codes.map((code, index: number) => (
                                    <p key={index && index} className="code">
                                      {code}
                                    </p>
                                  ))
                                ) : (
                                  <p>Você ainda não gerou nenhum código.</p>
                                )}
                              </div>
                            </BoxGeneratedCode>
                            <BoxGeneratedCode>
                              <div
                                style={{
                                  padding: '0',
                                  margin: '0',
                                  alignItems: 'flex-start',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <p>Revisão</p>
                                <p style={{ color: '#872D2D' }} className="code">
                                  {docButton.description}
                                </p>
                              </div>
                            </BoxGeneratedCode>
                          </ContainerGenerateCode>
                        </>
                      )}
                    </div>
                  );
                })}
              </Content>
            </>
          )}
        </>
      </Main>
      <Margin height={40} />
      <ContainerButton isRevision>
        <Button
          backIcon
          backgroundColor={Color.green}
          onClick={() => {
            Navigation.navigate(RouteName.ENTERPRISE_VIABILITY);
          }}
        >
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          style={{ opacity: 1 }}
          onClick={() => sendDocsRevision()}
          loading={
            loadingAttachFiles || loadingUploadFile || loadingDocumentAndProject
          }
          disabled={disabled}
        >
          Continuar
        </Button>
      </ContainerButton>
    </>
  );
};

export default DocumentationApprovalRevision;
