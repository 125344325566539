import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';
import { Text } from '~/components/TextInfo';

interface IPropsCard {
  isBorder?: boolean;
  height?: number;
  hiddenPadding?: boolean;
}

interface IBtn {
  bgColor: 'green' | 'blue' | 'red' | 'redIntense' | 'blueIntense' | 'yellow';
  withMargin?: boolean;
}

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 37px 10px 68px 10px;
  position: relative;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 37px 24px 207px 24px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: 100%;
    max-width: 1170px;
    padding: 64px 0 296px 0;
  }
`;

export const Card = styled.section<IPropsCard>`
  width: ${({ isBorder, hiddenPadding }) =>
    isBorder || hiddenPadding ? 'calc(100% - 16px)' : 'calc(100% - 67px)'};
  height: ${({ height }) => height && `${height}px`};
  background: #ffffff;
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.09);
  padding: ${({ isBorder, hiddenPadding }) =>
    isBorder || hiddenPadding ? '32px 8px 40px 8px' : '36px 27px 49px 40px'};
  margin: 0 auto;
  border-top: ${({ isBorder }) =>
    isBorder ? `30px solid ${Color.blueIntense}` : '0'};
  border-radius: ${({ isBorder }) => (isBorder ? '24px 24px 0px 0px' : '0')};

  h1 {
    font-weight: 500;
    font-size: 24px;
    height: 32px;
    text-align: center;
    color: ${Color.blueIntense};
    white-space: nowrap;
  }

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: ${({ isBorder }) =>
      isBorder ? 'calc(100% - 80px)' : 'calc(100% - 77px)'};
    padding: ${({ isBorder }) =>
      isBorder ? '32px 40px 0 40px' : '32px 33px 35px 44px'};

    h1 {
      text-align: left;
    }
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: ${({ isBorder }) =>
      isBorder ? 'calc(100% - 80px)' : 'calc(100% - 62px)'};
    max-width: calc(870px - 80px);
    padding: ${({ isBorder }) =>
      isBorder ? '32px 40px 40px 40px' : '32px 32px 50px 40px'};
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    max-width: calc(900px - 86px);
    padding: 32px 32px 33px 24px;
    margin: 0;
  }
`;

export const RowServices = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 23px;
  justify-content: space-between;
  max-width: 250px;
  margin: 0 auto;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    justify-content: flex-start;
    max-width: 400px;
    margin: 0 auto 0 0;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    gap: 0;
    justify-content: inherit;
    max-width: inherit;
    margin: 0;
    gap: 23px 0;
  }
`;

export const ButtonService = styled.button<IBtn>`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  width: 103px;
  height: 103px;
  border-radius: 9px;
  border: none;
  background-color: ${({ bgColor }) =>
    bgColor === 'green'
      ? Color.green
      : bgColor === 'blue'
        ? Color.blueSeconde
        : bgColor === 'red'
          ? Color.redSecond
          : bgColor === 'redIntense'
            ? Color.red
            : bgColor === 'yellow'
              ? Color.yellow
              : Color.blueIntense};
  box-shadow: 0px 2.76838px 17.9945px rgba(0, 0, 0, 0.19);
  margin-right: 0;

  svg {
    height: 28px;
    margin-top: 20px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    margin-right: ${({ withMargin }) => (withMargin ? '23px' : '0')};
  }
`;

export const TextService = styled(Text)`
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: ${Color.white};
  line-height: 15px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    text-align: center;
  }
`;
