import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import LoadingComponent from '~/components/Loading';
import { IState } from '~/store/modules/types';

import { Margin } from '~/components/Margin';
import {
  getEnrollmentsInvoicesGroupRequest,
  getEnrollmentsInvoicesMaturityDaysAlternateRequest,
  getEnrollmentsInvoicesMaturityDaysRequest,
  getEnrollmentsInvoicesRequest,
  getEnrollmentsTypesInvoicesRequest,
  putSuccessCloseEnrollmentsTypesInvoices,
  putSuccessCloseMaturityDaysAlternate,
} from '~/store/modules/enrollmentsInvoices/actions';
import {
  Main,
  ContainerBreadCrumb,
  Card,
  ContainerBorder,
  Border,
  ContainerCards,
  Container,
  ContainerSinglePayment,
  PaymentButton,
  ContainerHeader,
} from './styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { Link } from 'react-router-dom';
import { CalendarIcon, DeliveryTypeIcon, PaymentIcon } from './icons';
import { checkToggle } from '~/utils/toggles';
import ContainerInvoice from './ContainerInvoice';
import Pagination from './ContainerInvoice/Pagination';
import EmptyMessage from '~/components/EmptyMessage';
import { IInvoices } from '~/store/modules/enrollmentsInvoices/types';
import WarningBox from '~/components/WarningBox';
import { AppDispatch } from '~/store';
import InvoiceSkeletons from './InvoiceSkeletons';

const InvoicesPage: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const data = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.data?.invoices,
  );
  const pagination = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.data?.pagination,
  );
  const infoGroup = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.data?.info,
  );
  const maturityDay = useSelector(
    (item: IState) => item.enrollmentsInvoices.dataMaturityDays?.day,
  );
  const type_invoice = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.type_invoice?.label,
  );
  const loading = useSelector((item: IState) => item.enrollmentsInvoices.loading);
  const loadingVoucher = useSelector((item: IState) => item.payment.loadingVoucher);
  const next_reading_prediction = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.data?.next_reading_prediction,
  );
  const toggles = useSelector((item: IState) => item.togglesMe.data);

  const [groupPayment, setGroupPayment] = useState<boolean>(false);

  const verifyToggles = async () => {
    setGroupPayment(await checkToggle(toggles, 'PAGAMENTO_AGRUPADO'));
  };

  useEffect(() => {
    verifyToggles();
  }, [toggles]);

  useEffect(() => {
    dispatch(getEnrollmentsInvoicesRequest({ page: 1, size: 6 }));
    dispatch(getEnrollmentsTypesInvoicesRequest());
    dispatch(putSuccessCloseMaturityDaysAlternate());
    dispatch(putSuccessCloseEnrollmentsTypesInvoices());
    dispatch(getEnrollmentsInvoicesMaturityDaysRequest());
    dispatch(getEnrollmentsInvoicesMaturityDaysAlternateRequest());
  }, [dispatch]);

  const convertDeliveryType = () => {
    switch (type_invoice) {
      case 'FÍSICO':
        return 'Física';
      case 'E-MAIL/FÍSICO':
        return 'E-mail/Física';
      default:
        return 'E-mail';
    }
  };

  return (
    <Main>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Faturas',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      {loadingVoucher && <LoadingComponent labelWait="Aguarde..." />}
      <Container>
        <Card>
          <h1>Faturas</h1>
          <ContainerBorder>
            <Border />
          </ContainerBorder>
          {infoGroup && infoGroup?.opened > 1 && !loading && groupPayment && (
            <ContainerSinglePayment>
              <div>
                <p>{infoGroup?.opened} faturas em aberto!</p>
                <p>Parcele suas faturas em aberto em até 12X sem juros!</p>
              </div>
              <PaymentButton
                type="button"
                onClick={() => {
                  ReactGA.event({
                    category: 'Pagamento Único',
                    action: '[Pag. Único][WEB] Faturas - Pagamento Único',
                  });
                  dispatch(getEnrollmentsInvoicesGroupRequest());
                }}
              >
                Pagamento único
                <PaymentIcon />
              </PaymentButton>
            </ContainerSinglePayment>
          )}
          <ContainerHeader>
            <div>
              <span />
            </div>
            <p>Mês</p>
            <p className="small">Valor</p>
            <p>Vencimento</p>
          </ContainerHeader>
          {loading ? (
            <InvoiceSkeletons />
          ) : (
            <>
              {data && data.length > 0 ? (
                <>
                  {data?.map((invoice: IInvoices) => (
                    <ContainerInvoice key={invoice.invoice_id} invoice={invoice} />
                  ))}
                  <WarningBox label="Ao realizar o pagamento de faturas vencidas, os juros e multa serão calculados e cobrados na sua próxima fatura." />
                </>
              ) : (
                <>
                  <Margin height={27} />
                  <EmptyMessage textMessage="Você ainda não possui faturas!" />
                </>
              )}
            </>
          )}
          {pagination && pagination?.maxPages > 1 && <Pagination />}
        </Card>
        <ContainerCards>
          <Card miniCard>
            <div className="mini-card-top">
              <h2>
                Previsão da próxima <br /> leitura{' '}
              </h2>
              <p>{moment(next_reading_prediction).format('DD/MM/YY')}</p>
              <Margin height={6} />
              <Border />
              <Margin height={14} />
              <button
                type="button"
                onClick={() =>
                  Navigation.navigate(RouteName.INVOICESCHANGEEXPIRATION)
                }
              >
                Alterar vencimento
              </button>
              <Margin height={14} />
              <p className="line-icon">
                <CalendarIcon /> <span>{maturityDay}</span>
              </p>
              <Margin height={14} />
              <button
                type="button"
                onClick={() =>
                  Navigation.navigate(RouteName.INVOICESCHANGEDELIVERYTYPE)
                }
              >
                Alterar tipo de entrega
              </button>
              <Margin height={14} />
              <p className="line-icon">
                <DeliveryTypeIcon /> <span>{convertDeliveryType()}</span>
              </p>
            </div>
          </Card>
          <Card miniCard hiddenBorder>
            <h3>Mais facilidades para você</h3>
            <div>
              <Link to={RouteName.AUTOMATICDEBIT}>Débito automático</Link>
            </div>
            <div>
              <Link to={RouteName.DETAILEDHISTORY}>Histórico detalhado</Link>
            </div>
            <div>
              <a
                href="https://igua.com.br/informacoes-para-voce"
                target="_blank"
                rel="noreferrer"
              >
                Entenda sua conta
              </a>
            </div>
            <div>
              <a
                href="https://igua.com.br/informacoes-para-voce"
                target="_blank"
                rel="noreferrer"
              >
                Como identificar vazamento
              </a>
            </div>
          </Card>
        </ContainerCards>
      </Container>
    </Main>
  );
};

export default InvoicesPage;
