import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import { Margin } from '~/components/Margin';

import {
  Main,
  Card,
  ContainerBreadCrumb,
  ContainerButtons,
  Border,
  ContainerBorder,
  RowDocument,
  SenFiles,
  Label,
  ContainerDocuments,
  ContainerTerms,
  LabelRadio,
} from './styles';
import { ButtonText } from '~/components/ButtonText';
import ModalError from '~/components/ModalError';
import { Color } from '~/styles';
import formatter from '~/utils/formatter';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { BreadCrumb } from './BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { IRequestRegisterEnrollmentNotClient } from '~/store/modules/enrollmentsUsersNotClient/types';
import { getCepViaCep } from '~/services/cep';
import { history } from '~/store';
import DropZone from './DropZone';
import convertFileToBase64 from '~/utils/convertBase64';
import { InputChecked } from '~/common/InputChecked';
import {
  patchEnrollmentsUsersNotClientRegister,
  patchEnrollmentsUsersNotClientRegisterServiceErrorClose,
  patchEnrollmentsUsersNotClientRegisterServiceSuccessClose,
} from '~/store/modules/enrollmentsUsersNotClient/actions';
import Modal from '~/components/Modal';
import ModalTerm from './ModalTerm';
import ModalSuccess from './ModalSuccess';
import { validateBase64FileData } from '~/utils/validation';
import FormImmobile from './FormImmobile';
import FormClient from './FormClient';
import { AppDispatch } from '~/store';

export interface IDocuments {
  key: string;
  label: string;
  file: null | File;
}

const LinkEnrollmentForm: FC = () => {
  const enrollmentValidate = history.location.state as any;

  const dispatch = useDispatch<AppDispatch>();

  const userData = useSelector((item: IState) => item.enrollmentsUsers.data);

  const loading = useSelector(
    (item: IState) => item.enrollmentsUsersNotClient.loadingRegister,
  );
  const success = useSelector(
    (item: IState) => item.enrollmentsUsersNotClient.success,
  );
  const error = useSelector(
    (item: IState) => item.enrollmentsUsersNotClient.errorRegister,
  );
  const operationId = useSelector(
    (item: IState) => item.enrollmentsUsersNotClient.operationId,
  );

  const [dataForm, setDataForm] = useState<IRequestRegisterEnrollmentNotClient>({
    cnp:
      userData?.cnp?.length === 11
        ? formatter.cpfMask(userData?.cnp)
        : formatter.cnpjMask(userData?.cnp || ''),
    enrollment: enrollmentValidate.enrollment,
    nomeCompleto: userData?.cnp?.length === 11 ? userData?.fullName : '',
    rg: '',
    celular: formatter.formatarPhone(userData?.mobile.primary) || '',
    telefone: '',
    email: userData?.email || '',
    dataNascimento: '',
    nomeMae: '',
    vinculoImovel: '',
    tipoReservatorio: '',
    rua: '',
    numero: '',
    bairro: '',
    complemento: '',
    pontoReferencia: '',
    numeroResidenciaLado: '',
    cidade: '',
    estado: '',
    cep: '',
    deAcordo: 'true',
    files: [
      {
        filename: '',
        filedata: '',
      },
      {
        filename: '',
        filedata: '',
      },
      {
        filename: '',
        filedata: '',
      },
    ],
  });
  const [dataFormErrors, setDataFormErrors] = useState({
    nomeCompleto: '',
    rg: '',
    dataNascimento: '',
    nomeMae: '',
    celular: '',
    telefone: '',
    vinculoImovel: '',
    tipoReservatorio: '',
    cep: '',
    estado: '',
    cidade: '',
    bairro: '',
    rua: '',
    numero: '',
    numeroResidenciaLado: '',
    files: [
      {
        error: '',
      },
      {
        error: '',
      },
      {
        error: '',
      },
    ],
  });
  const [documentType, setDocumentType] = useState<'' | 'rgCpf' | 'cnh'>('');
  const [documents, setDocuments] = useState<IDocuments[]>([
    {
      key: 'rg',
      label: 'RG*',
      file: null,
    },
    {
      key: 'cpf',
      label: 'CPF*',
      file: null,
    },
    {
      key: 'proof_of_address',
      label: 'Comprovante de residência*',
      file: null,
    },
  ]);
  const [documentsCNH, setDocumentsCNH] = useState<IDocuments[]>([
    {
      key: 'cnh',
      label: 'CNH*',
      file: null,
    },
    {
      key: 'proof_of_address',
      label: 'Comprovante de residência*',
      file: null,
    },
  ]);
  const [termRegistration, setTermRegistration] = useState<boolean>(false);
  const [openTerm, setOpenTerm] = useState<boolean>(false);

  useEffect(() => {
    dispatch(patchEnrollmentsUsersNotClientRegisterServiceSuccessClose());
    dispatch(patchEnrollmentsUsersNotClientRegisterServiceErrorClose());
    ReactGA.event({
      category: 'Recadastramento',
      action: `[Cliente Não Saneamento] – Vincular Matricula – Recadastramento`,
    });
  }, []);

  const getCep = async (cep: string) => {
    const dataCep: any = await getCepViaCep(cep);

    if (dataCep?.erro) {
      setDataForm({
        ...dataForm,
        bairro: '',
        complemento: '',
        cidade: '',
        rua: '',
        estado: '',
      });
    } else {
      setDataForm({
        ...dataForm,
        bairro: dataCep.bairro,
        complemento: dataCep.complemento,
        cidade: dataCep.localidade,
        rua: dataCep.logradouro,
        estado: dataCep.uf,
      });
      setDataFormErrors({
        ...dataFormErrors,
        bairro: dataCep.bairro ? '' : dataFormErrors.bairro,
        cidade: dataCep.localidade ? '' : dataFormErrors.cidade,
        rua: dataCep.logradouro ? '' : dataFormErrors.rua,
        estado: dataCep.uf ? '' : dataFormErrors.estado,
      });
    }
  };

  useEffect(() => {
    if (dataForm.cep.length > 8) getCep(dataForm.cep);
  }, [dataForm.cep]);

  const verifyForm = async () => {
    let inputsEmpty: any = {};

    for (let key in dataForm) {
      const typedKey = key as keyof IRequestRegisterEnrollmentNotClient;

      if (
        dataForm[typedKey] === '' &&
        typedKey !== 'telefone' &&
        typedKey !== 'complemento' &&
        typedKey !== 'pontoReferencia'
      ) {
        inputsEmpty = { ...inputsEmpty, [typedKey]: 'Campo obrigatório' };
      }

      if (typedKey === 'files') {
        let inputsEmptyFiles: any = [...dataForm[typedKey]];
        for (let index = 0; index < dataForm[typedKey].length; index++) {
          if (dataForm[typedKey][index].filename === '') {
            inputsEmptyFiles[index].error = 'empty';
          }
        }
        inputsEmpty = { ...inputsEmpty, files: inputsEmptyFiles };
      }
    }

    documentType === 'cnh' && delete inputsEmpty.files[2];

    const filesEmpty = inputsEmpty.files;

    delete inputsEmpty.files;

    if (userData && userData?.cnp?.length > 11) {
      delete inputsEmpty.nomeMae;
      delete inputsEmpty.rg;
    }

    if (
      (inputsEmpty && Object.keys(inputsEmpty).length > 0) ||
      filesEmpty[0]?.error ||
      filesEmpty[1]?.error ||
      filesEmpty[2]?.error
    ) {
      setDataFormErrors({
        ...dataFormErrors,
        ...inputsEmpty,
        files: filesEmpty,
      });
      console.log({
        ...dataFormErrors,
        ...inputsEmpty,
        files: filesEmpty,
      });
    } else {
      const filesCNH = [...dataForm.files];
      documentType === 'cnh' && filesCNH.splice(-1);

      const data = {
        ...dataForm,
        operationId,
        files: [...filesCNH],
      };
      dispatch(patchEnrollmentsUsersNotClientRegister(data));
    }
  };

  useEffect(() => {
    if (error && error.state) {
      ReactGA.event({
        category: 'Recadastramento',
        action: `[Cliente Não Saneamento] – Erro na Solicitação de Recadastramento`,
      });
    }
  }, [error]);

  const resetFiles = (typeDocument: 'rgCpf' | 'cnh') => {
    setDataForm({
      ...dataForm,
      files: [
        {
          filename: '',
          filedata: '',
        },
        {
          filename: '',
          filedata: '',
        },
        {
          filename: '',
          filedata: '',
        },
      ],
    });

    setDataFormErrors({
      ...dataFormErrors,
      files: [
        {
          error: '',
        },
        {
          error: '',
        },
        {
          error: '',
        },
      ],
    });

    setDocuments([
      {
        key: 'rg',
        label: 'RG*',
        file: null,
      },
      {
        key: 'cpf',
        label: 'CPF*',
        file: null,
      },
      {
        key: 'proof_of_address',
        label: 'Comprovante de residência*',
        file: null,
      },
    ]);
    setDocumentsCNH([
      {
        key: 'cnh',
        label: 'CNH*',
        file: null,
      },
      {
        key: 'proof_of_address',
        label: 'Comprovante de residência*',
        file: null,
      },
    ]);
    setDocumentType(typeDocument);
  };

  return (
    <Main>
      <Modal open={!!(error && error.state)} type="error" title="Ops!" v3>
        <ModalError
          onClose={() =>
            dispatch(patchEnrollmentsUsersNotClientRegisterServiceErrorClose())
          }
        />
      </Modal>
      <Modal open={openTerm} type="info" v3 hidden maxWidth={670}>
        <ModalTerm onClose={() => setOpenTerm(false)} />
      </Modal>
      <Modal open={success} type="info" v3 hidden maxWidth={621}>
        <ModalSuccess
          onClose={() => {
            dispatch(patchEnrollmentsUsersNotClientRegisterServiceSuccessClose());
            Navigation.navigate(RouteName.HOME);
          }}
        />
      </Modal>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Recadastramento',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Card>
        <h1>Recadastramento</h1>
        <Margin height={50} />
        <ContainerBorder>
          <Border />
        </ContainerBorder>
        <p>
          Verificamos que sua matrícula precisa de recadastramento. Com o cadastro
          atualizado, você terá mais agilidade ao buscar o atendimento da Iguá e
          receberá informações personalizadas sobre as condições de abastecimento e
          manutenções em sua região.
          <br /> <br />A solicitação de recadastramento é feita a partir do
          formulário a seguir e o progresso da mesma poderá ser acompanhado em Fale
          conosco.
        </p>
        <ContainerBorder>
          <Border />
          <h2>
            {userData?.cnp?.length === 11 ? 'Dados do titular' : 'Dados da empresa'}
          </h2>
          <Border />
        </ContainerBorder>
        <FormClient
          dataForm={dataForm}
          setDataForm={setDataForm}
          dataFormErrors={dataFormErrors}
          setDataFormErrors={setDataFormErrors}
        />
        <ContainerBorder>
          <Border />
          <h2>Dados do imóvel</h2>
          <Border />
        </ContainerBorder>
        <FormImmobile
          dataForm={dataForm}
          setDataForm={setDataForm}
          dataFormErrors={dataFormErrors}
          setDataFormErrors={setDataFormErrors}
        />
        <Margin height={50} />
        <ContainerBorder>
          <Border />
          <h2>Anexos</h2>
          <Border />
        </ContainerBorder>
        <span className="label">
          Selecione o tipo de documento de identificação que deseja anexar:
        </span>
        <LabelRadio>
          <input
            name="origin"
            type="radio"
            value="google"
            checked={documentType === 'rgCpf'}
            onChange={() => resetFiles('rgCpf')}
          />{' '}
          <div>
            <p>RG e CPF</p>
            {documentType === 'rgCpf' && (
              <>
                <Margin height={8} />
                <span className="label">Anexe os seguintes documentos:</span>
              </>
            )}
          </div>
        </LabelRadio>
        <Margin height={40} />
        {documentType === 'rgCpf' && (
          <ContainerDocuments>
            {documents.map((document, index: number) => (
              <RowDocument key={document.key}>
                <SenFiles>
                  <DropZone
                    disabled={!!document.file}
                    infoFileName={document.label}
                    name={document.label}
                    content={document.file || null}
                    error={dataFormErrors.files[index].error}
                    onFileUploaded={(file: File | null) => {
                      const array = [...documents];
                      let arrayDocuments = [...dataForm.files];
                      let arrayDocumentsErrors = [...dataFormErrors.files];
                      array[index].file = file;

                      const validateData = {
                        filedata: file,
                        filesize: file?.size,
                      };

                      if (file) {
                        if (validateBase64FileData(validateData)) {
                          convertFileToBase64(file, (base64: string) => {
                            arrayDocuments[index].filename = file.name;
                            arrayDocuments[index].filedata = base64;
                          });
                          arrayDocumentsErrors[index].error = '';
                        } else {
                          arrayDocuments[index].filename = '';
                          arrayDocuments[index].filedata = '';
                          arrayDocumentsErrors[index].error = 'invalid';
                        }
                      } else {
                        arrayDocuments[index].filename = '';
                        arrayDocuments[index].filedata = '';
                        arrayDocumentsErrors[index].error = 'empty';
                      }

                      setDataForm({ ...dataForm, files: arrayDocuments });
                      setDataFormErrors({
                        ...dataFormErrors,
                        files: arrayDocumentsErrors,
                      });
                      setDocuments(array);
                    }}
                  />
                </SenFiles>
                <Label>
                  Documentos devem ser anexados em formato PDF, JPEG ou PNG .
                </Label>
              </RowDocument>
            ))}
          </ContainerDocuments>
        )}
        {documentType === 'rgCpf' && <Margin height={40} />}
        <LabelRadio>
          <input
            name="origin"
            type="radio"
            value="google"
            checked={documentType === 'cnh'}
            onChange={() => resetFiles('cnh')}
          />{' '}
          <div>
            <p>CNH</p>
            {documentType === 'cnh' && (
              <>
                <Margin height={8} />
                <span className="label">Anexe os seguintes documentos:</span>
              </>
            )}
          </div>
        </LabelRadio>
        <Margin height={40} />
        {documentType === 'cnh' && (
          <ContainerDocuments>
            {documentsCNH.map((document, index: number) => (
              <RowDocument key={document.key}>
                <SenFiles>
                  <DropZone
                    disabled={!!document.file}
                    infoFileName={document.label}
                    name={document.label}
                    content={document.file || null}
                    error={dataFormErrors.files[index].error}
                    onFileUploaded={(file: File | null) => {
                      const array = [...documentsCNH];
                      let arrayDocuments = [...dataForm.files];
                      let arrayDocumentsErrors = [...dataFormErrors.files];
                      array[index].file = file;

                      const validateData = {
                        filedata: file,
                        filesize: file?.size,
                      };

                      if (file) {
                        if (validateBase64FileData(validateData)) {
                          convertFileToBase64(file, (base64: string) => {
                            arrayDocuments[index].filename = file.name;
                            arrayDocuments[index].filedata = base64;
                          });
                          arrayDocumentsErrors[index].error = '';
                        } else {
                          arrayDocuments[index].filename = '';
                          arrayDocuments[index].filedata = '';
                          arrayDocumentsErrors[index].error = 'invalid';
                        }
                      } else {
                        arrayDocuments[index].filename = '';
                        arrayDocuments[index].filedata = '';
                        arrayDocumentsErrors[index].error = 'empty';
                      }

                      setDataForm({ ...dataForm, files: arrayDocuments });
                      setDataFormErrors({
                        ...dataFormErrors,
                        files: arrayDocumentsErrors,
                      });
                      setDocumentsCNH(array);
                    }}
                  />
                </SenFiles>
                <Label>
                  Documentos devem ser anexados em formato PDF, JPEG ou PNG .
                </Label>
              </RowDocument>
            ))}
          </ContainerDocuments>
        )}
        <Margin height={40} />
        <ContainerBorder style={{ width: '100%' }}>
          <Border />
        </ContainerBorder>
        <Margin height={10} />
        <ContainerTerms>
          <InputChecked
            checked={termRegistration}
            onChange={(e) => setTermRegistration(e.target.checked)}
          >
            <p>
              <span className="label">Li e aceito o </span>
              <button type="button" onClick={() => setOpenTerm(true)}>
                termo de cadastro de unidade imobiliária
              </button>
            </p>
          </InputChecked>
        </ContainerTerms>
        <Margin height={112} />
        <ContainerButtons>
          <ButtonText
            text="Voltar"
            typeBtn="content"
            Color={Color.white}
            backgroundColor={Color.green}
            onClick={() => Navigation.goBack()}
            backIcon
          />
          <ButtonText
            typeBtn="content"
            backgroundColor={!termRegistration ? Color.gray : Color.blueSeconde}
            onClick={() => {
              if (!termRegistration || loading) return;
              verifyForm();
            }}
            nextIcon
            loading={loading}
          >
            Solicitar <br /> recadastramento
          </ButtonText>
        </ContainerButtons>
      </Card>
    </Main>
  );
};

export default LinkEnrollmentForm;
