import React from 'react';
import { useDispatch } from 'react-redux';

import { Main } from './styles';

import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import { InfoSVG } from '~/assets/Icons';
import { putCloseErrorUsersUpdatePassword } from '~/store/modules/enrollmentsUsers/actions';
import { AppDispatch } from '~/store';

interface IProps {
  setOpen: (openStatus: boolean) => void;
}

const ModalInfoPassword: React.FC<IProps> = ({ setOpen }) => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Main>
      <InfoSVG />
      <h1>Atenção</h1>
      <p>A nova senha não pode ser igual à senhas anteriores</p>
      <ButtonText
        backgroundColor={Color.blueSeconde}
        typeBtn="content"
        text="Ok"
        onClick={() => {
          setOpen(false);
          dispatch(putCloseErrorUsersUpdatePassword());
        }}
      />
    </Main>
  );
};

export default ModalInfoPassword;
