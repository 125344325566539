import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../store/modules/types';
import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';

import '../styles/utilities.css';
import '../styles/globalStyle.css';
import { history } from '../store';
import { TitleRoutes } from './Routes.name';
import {
  clearModalDataUpdate,
  clearModalSalesforceSatisfaction,
} from '~/store/modules/outsides/actions';
import UnavailableServicePage from '~/views/UnavailableServicePage';
import { setUnavailableService } from '~/store/modules/unavailableService/actions';
import AlertCookies from '~/components/AlertCookies';
import { saveCookies, deleteCookies } from '~/store/modules/cookies/actions';
import { AppDispatch } from '~/store';

ReactGA.initialize(process.env.REACT_APP_GA_KEY || '');

const Routes = () => {
  const dispatch = useDispatch<AppDispatch>();

  const token = useSelector((state: IState) => state.enrollmentsAuth.token);
  const showUnavailabilityScreen = useSelector(
    (state: IState) => state.unavailableService.isUnavailableService,
  );
  const cookiesAccepted = useSelector((state: IState) => state.cookies.accepted);
  const { pathname } = useLocation();

  const [showModalConsent, setShowModalConsent] = useState<boolean>(false);

  useEffect(() => {
    dispatch(clearModalSalesforceSatisfaction());
    dispatch(clearModalDataUpdate());
    setShowModalConsent(!cookiesAccepted);
  }, []);

  const handleAccept = () => {
    dispatch(saveCookies());
    setShowModalConsent(false);
  };

  const handleDecline = () => {
    dispatch(deleteCookies());
    setShowModalConsent(false);
  };

  useEffect(() => {
    document.getElementById('root')?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  useEffect(() => {
    const handleUnload = () => {
      dispatch(setUnavailableService(false));
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  if (showUnavailabilityScreen) {
    return <UnavailableServicePage />;
  }

  history.listen(() => {
    document.title = TitleRoutes[window.location.pathname];

    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  });

  return (
    <>
      {showModalConsent && (
        <AlertCookies onAccept={handleAccept} onDecline={handleDecline} />
      )}
      {token ? <AuthRoutes /> : <AppRoutes />}
    </>
  );
};

export default Routes;
