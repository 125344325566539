import { IRequestCreateAutomaticDebits } from '~/store/modules/automaticDebits/types';
import { api } from './api';

function getAutomaticDebitsBanksService(enrollmentId: string) {
  return new Promise((resolve, reject) => {
    try {
      const getAutomaticDebitsBanks: any = async () => {
        const response = await api.get(
          `v3/enrollments/${enrollmentId}/direct-debit/banks`,
        );

        return response.data;
      };

      resolve(getAutomaticDebitsBanks());
    } catch (error) {
      reject(error);
    }
  });
}

function getAutomaticDebitsService(enrollmentId: string) {
  return new Promise((resolve, reject) => {
    try {
      const getAutomaticDebits: any = async () => {
        const response = await api.get(
          `v3/enrollments/${enrollmentId}/direct-debit`,
        );

        return response.data;
      };

      resolve(getAutomaticDebits());
    } catch (error) {
      reject(error);
    }
  });
}

function postAutomaticDebitsService(
  enrollmentId: string,
  data: IRequestCreateAutomaticDebits,
) {
  return new Promise((resolve, reject) => {
    try {
      const postAutomaticDebits: any = async () => {
        const response = await api.post(
          `v3/enrollments/${enrollmentId}/direct-debit`,
          {
            ...data,
          },
        );

        return response.data;
      };

      resolve(postAutomaticDebits());
    } catch (error) {
      reject(error);
    }
  });
}

function deleteAutomaticDebitsService(enrollmentId: string) {
  return new Promise((resolve, reject) => {
    try {
      const deleteAutomaticDebits: any = async () => {
        const response = await api.delete(
          `v3/enrollments/${enrollmentId}/direct-debit`,
        );

        return response.data;
      };

      resolve(deleteAutomaticDebits());
    } catch (error) {
      reject(error);
    }
  });
}

export {
  getAutomaticDebitsBanksService,
  getAutomaticDebitsService,
  postAutomaticDebitsService,
  deleteAutomaticDebitsService,
};
