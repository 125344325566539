import React, { FC, useEffect, useState } from 'react';

import { Main, Label, Row, SenFiles } from './styles';

import DropZone from '../../../components/DropZone';
import { Input } from '~/components/Input';
import { Margin } from '~/components/Margin';
import {
  ContainerButton,
  Subtitle,
} from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/styles';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import convertFileToBase64 from '~/utils/convertBase64';
import formatter from '~/utils/formatter';
import {
  patchAttachmentPropertyRegistrationRequest,
  patchProtocolPropertyRegistratioRequest,
  patchStepLocalPropertyRegistratioRequest,
  postUploadFilePropertyRegistrationRequest,
} from '~/store/modules/propertyRegistration/actions';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import StatusInfo from '../../../components/StatusInfo';
import { AppDispatch } from '~/store';

const Form: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const caseFCP = useSelector((item: IState) => item.propertyRegistration.dataItem);
  const loadingUploadFile = useSelector(
    (item: IState) => item.propertyRegistration.loadingUploadFile,
  );
  const loadingAttachFiles = useSelector(
    (item: IState) => item.propertyRegistration.loadingItem,
  );
  const loadingPatch = useSelector(
    (item: IState) => item.propertyRegistration.loadingPatch,
  );
  const loadingItem = useSelector(
    (item: IState) => item.propertyRegistration.loadingItem,
  );

  const [file, setFile] = useState<File | null>(null);
  const [startRequestDate, setStartRequestDate] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [disabledDate, setDisabledDate] = useState(true);
  const [isRevision, setIsRevision] = useState<boolean>(false);
  const [revisionDescription, setRevisionDescription] = useState<string>('');
  const [shouldAttachDocument, setShouldAttachDocument] = useState<boolean>(true);
  const [alreadyUpdate, setAlreadyUpdate] = useState(false);

  useEffect(() => {
    if (!isRevision) return setShouldAttachDocument(true);

    const workMaterialInvoice = caseFCP?.attachments?.find(
      (attachment) => attachment.type === 'WORK_MATERIAL_INVOICE',
    );

    if (
      !workMaterialInvoice ||
      (workMaterialInvoice && workMaterialInvoice.review)
    ) {
      setRevisionDescription(workMaterialInvoice?.review_description || '');
      return setShouldAttachDocument(true);
    }

    return setShouldAttachDocument(false);
  }, [isRevision, caseFCP]);

  const handleClick = () => {
    if (shouldAttachDocument)
      dispatch(
        patchAttachmentPropertyRegistrationRequest({
          caseId: caseFCP?.id || '',
          step: '4',
        }),
      );

    dispatch(
      patchProtocolPropertyRegistratioRequest({
        caseId: caseFCP?.id || '',
        caseFcp: caseFCP?.details?.fcp?.id || '',
        materialInspectionDate: startRequestDate,
      }),
    );
  };

  useEffect(() => {
    if (caseFCP) {
      setIsRevision(caseFCP?.step?.status === stepStatusEnum.REVIEW);

      const date = caseFCP?.details?.materialInspectionDates?.chosen as string;

      if (
        caseFCP?.details?.materialInspectionDates?.initial &&
        caseFCP?.details?.materialInspectionDates?.final
      ) {
        const dateInitial = new Date(
          caseFCP?.details?.materialInspectionDates?.initial,
        );
        const dateFinal = new Date(caseFCP?.details?.materialInspectionDates?.final);
        if (new Date(date) < dateInitial || new Date(date) > dateFinal) {
          setStartRequestDate(date);
          return setDisabledDate(true);
        }

        setStartRequestDate(date);
        return setDisabledDate(false);
      }

      setStartRequestDate(date);
      return setDisabledDate(false);
    }

    return undefined;
  }, [caseFCP]);

  useEffect(() => {
    if (
      (shouldAttachDocument &&
        file &&
        !loadingUploadFile &&
        startRequestDate?.length === 10 &&
        !disabledDate) ||
      (!shouldAttachDocument && startRequestDate.length === 10 && !disabledDate)
    )
      return setDisabled(false);

    return setDisabled(true);
  }, [
    file,
    startRequestDate,
    disabled,
    disabledDate,
    loadingUploadFile,
    shouldAttachDocument,
  ]);

  return (
    <Main>
      {isRevision && (
        <>
          <StatusInfo style={{ width: 'calc(100% - 50px)' }} status="warning">
            As informações precisam ser revisadas. Por favor, preencha novamente.
          </StatusInfo>
          <Margin height={40} />

          {caseFCP?.reviewDescription && (
            <>
              <Label
                style={{
                  width: '100%',
                  color: Color.red,
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                {caseFCP?.reviewDescription}
              </Label>
              <Margin height={40} />
            </>
          )}
        </>
      )}
      <Subtitle>
        Nesta etapa é necessário o anexo de todos os documentos e informações
        solicitados a seguir para a solicitação de início de obra.
      </Subtitle>
      <Margin height={64} />
      {shouldAttachDocument && (
        <>
          <Row>
            <SenFiles>
              <DropZone
                disabled={!!file}
                infoFileName="Nota fiscal do material da obra*"
                name="Nota fiscal do material da obra*"
                content={file || null}
                isRevision={isRevision}
                onFileUploaded={(file: File | null) => {
                  setFile(file);

                  if (file) {
                    convertFileToBase64(file, (base64: string) => {
                      dispatch(
                        postUploadFilePropertyRegistrationRequest({
                          caseIdSales: caseFCP?.id || '',
                          type: 'WORK_MATERIAL_INVOICE',
                          step: '4',
                          filename: file?.name || '',
                          filedata: base64,
                        }),
                      );
                    });
                  }
                }}
                type="default"
              />
            </SenFiles>
            <Label isRevision={isRevision}>
              {isRevision
                ? revisionDescription
                : 'Arquivos devem ser anexados em formato PDF.'}
            </Label>
          </Row>
          <Margin height={40} />
        </>
      )}
      <Input
        isRevision={isRevision && !alreadyUpdate}
        styleInput={{ width: `35%` }}
        label="Data para fiscalização do material"
        type="date"
        value={startRequestDate}
        min={caseFCP?.details?.materialInspectionDates?.initial}
        max={caseFCP?.details?.materialInspectionDates?.final}
        onChange={(ev) => {
          const date = formatter.formatDate(ev.target.value);
          if (
            caseFCP?.details?.materialInspectionDates?.initial &&
            caseFCP?.details?.materialInspectionDates?.final
          ) {
            const dateInitial = new Date(
              caseFCP?.details?.materialInspectionDates?.initial,
            );
            const dateFinal = new Date(
              caseFCP?.details?.materialInspectionDates?.final,
            );
            if (new Date(date) < dateInitial || new Date(date) > dateFinal) {
              setStartRequestDate(date);
              setAlreadyUpdate(true);
              return setDisabledDate(true);
            }

            setStartRequestDate(date);
            setAlreadyUpdate(true);
            return setDisabledDate(false);
          }

          setStartRequestDate(date);
          setAlreadyUpdate(true);
          return setDisabledDate(false);
        }}
        maxLength={6}
      />
      <Margin height={40} />
      <ContainerButton>
        <Button
          backIcon
          backgroundColor={Color.green}
          onClick={() =>
            dispatch(
              patchStepLocalPropertyRegistratioRequest({
                name: '',
                number: 3,
                substep: 'B',
                status: stepStatusEnum.SUCCESS,
              }),
            )
          }
        >
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          style={{ opacity: 1 }}
          onClick={handleClick}
          disabled={disabled}
          loading={
            !!loadingUploadFile ||
            !!loadingAttachFiles ||
            !!loadingPatch ||
            !!loadingItem
          }
        >
          Continuar
        </Button>
      </ContainerButton>
    </Main>
  );
};

export default Form;
