import React, { useState } from 'react';
import { StyledCheckbox } from './styles';
import { CSSProperties } from 'styled-components';

interface IProps {
  checked: boolean;
  onChange: (ev: any) => void;
  style?: CSSProperties;
}

const Checkbox = ({ checked, onChange, style }: IProps) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };
  return (
    <StyledCheckbox style={style} checked={checked} onClick={handleChange}>
      <>
        <input type="checkbox" />
        <label htmlFor="checkbox">
          <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.3647 12.8235C24.6381 13.0969 24.7917 13.4678 24.7917 13.8545C24.7917 14.2412 24.6381 14.6121 24.3647 14.8855L17.073 22.1772C16.7995 22.4506 16.4287 22.6042 16.042 22.6042C15.6553 22.6042 15.2844 22.4506 15.0109 22.1772L10.6359 17.8022C10.3703 17.5272 10.2233 17.1588 10.2266 16.7764C10.2299 16.3941 10.3833 16.0283 10.6537 15.7579C10.9241 15.4875 11.2899 15.3341 11.6722 15.3308C12.0546 15.3275 12.423 15.4745 12.698 15.7401L16.042 19.0841L22.3026 12.8235C22.5761 12.5501 22.9469 12.3965 23.3336 12.3965C23.7203 12.3965 24.0912 12.5501 24.3647 12.8235Z"
              fill="white"
            />
            {/* <rect
            x="5.83203"
            y="5.83398"
            width="23.3333"
            height="23.3333"
            rx="5.83333"
            fill="#37B482"
          /> */}
          </svg>
        </label>
      </>
    </StyledCheckbox>
  );
};

export default Checkbox;
