import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '~/components/ButtonV3';
import { Margin } from '~/components/Margin';
import { RouteName } from '~/routes/Routes.name';
import { getDataDownloadFileCustomerObligationRequest } from '~/store/modules/customerObligation/actions';
import { IState } from '~/store/modules/types';
import { Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import StatusInfo from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/components/StatusInfo';
import { ContainerButton } from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/styles';
import { Container } from './styles';
import { AppDispatch } from '~/store';

export const NetworkAcceptance: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);
  const loadingDownloadFile = useSelector(
    (item: IState) => item.customerObligation.loadingDownloadFile,
  );
  const loadingAttachFiles = useSelector(
    (item: IState) => item.customerObligation.loadingAttachFiles,
  );
  const isRevision = caseOC?.attachments.find(
    (attachment) => attachment.review === true,
  );

  const [hasDownloaded, setHasDownloaded] = useState<boolean>(false);

  const handleClick = () => {
    () => Navigation.navigate(RouteName.HOME);
  };

  return (
    <Container>
      <StatusInfo
        status="success"
        onClick={() => {
          if (loadingDownloadFile) return;
          dispatch(
            getDataDownloadFileCustomerObligationRequest({
              idFile: caseOC?.approvedDocuments?.OCA || '',
            }),
          );
          setHasDownloaded(true);
        }}
        labelButton="Baixar Aceite de Rede"
      >
        Os documentos foram gerados. Faça o download <br />
        da documentação antes de seguir com sua solicitação.
      </StatusInfo>

      <Margin height={50} />

      {caseOC?.type?.toLowerCase() === 'oca' ? (
        <StatusInfo status="warning">
          Sua OCA foi concluída!
          <br />
          Para solicitar sua ligação de água você deve abrir uma FCP.
        </StatusInfo>
      ) : (
        <StatusInfo status="warning">
          Sua OCE foi concluída!
          <br />
          Para solicitar sua ligação de esgoto você deve abrir uma FCP.
        </StatusInfo>
      )}

      <Margin height={50} />

      <ContainerButton isRevision={!!isRevision}>
        <Button
          backIcon
          backgroundColor={Color.green}
          onClick={() => Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)}
        >
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={
            !hasDownloaded ? Color.grayLightSeconde : Color.blueSeconde
          }
          style={{ opacity: 1 }}
          onClick={handleClick}
          disabled={!hasDownloaded}
          loading={loadingAttachFiles}
        >
          Home
        </Button>
      </ContainerButton>
    </Container>
  );
};
