import React, { FC, useEffect, useState } from 'react';
import DropZone from '../../../../components/DropZone';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import convertFileToBase64 from '~/utils/convertBase64';
import { Margin } from '~/components/Margin';
import { Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import ButtonBar from '../../../../components/ButtonBar';
import { Main, Label, Row, SenFiles, DescriptionTitle } from './styles';
import {
  patchAttachmentPropertyRegistrationRequest,
  postUploadFilePropertyRegistrationRequest,
} from '~/store/modules/propertyRegistration/actions';
import { AppDispatch } from '~/store';

const Water: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const caseId = useSelector(
    (item: IState) => item.propertyRegistration.dataItem?.id,
  );

  const loadingUploadFile = useSelector(
    (item: IState) => item.propertyRegistration.loadingUploadFile,
  );
  const loadingItem = useSelector(
    (item: IState) => item.propertyRegistration.loadingItem,
  );
  const loadingPatch = useSelector(
    (item: IState) => item.propertyRegistration.loadingPatch,
  );

  const [documents, setDocuments] = useState<any[]>([
    {
      key: 'OWNER_IDENTIFICATION_DOCUMENT',
      label: 'Documento de identificação do Proprietário*',
      file: null,
      type: 'RG, CNH ou Passaporte. Documentos devem ser anexados em formato PDF.',
    },
    {
      key: 'TECHNICAL_RESPONSIBLE',
      label: 'ART - Responsável técnico*',
      file: null,
      type: 'ART paga descrevendo projeto de esgoto e execução das instalações sanitárias de esgotos (Responsável Técnico) em formato PDF.',
    },
    {
      key: 'RESPONSIBLE_FOR_EXECUTION',
      label: 'ART - Responsável pela execução*',
      file: null,
      type: 'ART paga descrevendo projeto de esgoto e execução das instalações sanitárias de esgotos (Responsável pela Execução) em formato PDF.',
    },
    {
      key: 'TECHNICIANS_FUNCTIONAL_IDENTITY_CARD',
      label: 'Carteira de identidade funcional do responsável técnico*',
      file: null,
      type: 'Documentos devem ser anexados em formato PDF.',
    },
    {
      key: 'EXECUTION_RESPONSIBLE_PERSON_FUNCTIONAL_IDENTITY_IDENTITY',
      label: 'Carteira de identidade funcional do responsável pela execução*',
      file: null,
      type: 'Documentos devem ser anexados em formato PDF.',
    },
    {
      key: 'PROPERTY_GENERAL_REGISTRATION',
      label: 'RGI*',
      file: null,
      type: 'Registro geral do imóvel. Documentos devem ser anexados em formato PDF.',
    },
    {
      key: 'CHARGE_TECHNICIAN_CREA',
      label:
        'Certidão de Registro de Pessoa Jurídica do responsável técnico (CREA)*',
      file: null,
      type: 'Anuidade do CREA em formato PDF',
    },
    {
      key: 'EXECUTION_RESPONSIBLE_CREA',
      label:
        'Certidão de Registro de Pessoa Jurídica do responsável pela execução (CREA)*',
      file: null,
      type: 'Anuidade do CREA em formato PDF',
    },
    {
      key: 'FLOOR_PLAN',
      label: 'Planta de situação*',
      file: null,
      type: 'A planta deve conter a situação do imóvel, marcação dos imóveis, vizinhos, o perímetro do terreno da construção e quadro de áreas. Deve estar aprovada ou processo de aprovação pela prefeitura. Arquivos devem ser anexados em formato PDF.',
    },
    {
      key: 'SOCIAL_CONTRACT',
      label: 'Contrato social*',
      file: null,
      type: 'Em caso de empresa, deve ser enviado cópia do contrato social. Documentos devem ser anexados em formato PDF.',
    },
    {
      key: 'SUPPLY_POSSIBILITY_DECLARATION',
      label: 'DPA*',
      file: null,
      type: 'Declaração de possibilidade de abastecimento. Documentos devem ser anexados em formato PDF.',
    },
  ]);

  const [disabled, setDisabled] = useState(true);
  const disabledDocuments = false;

  const handleClick = () => {
    return dispatch(
      patchAttachmentPropertyRegistrationRequest({
        caseId: caseId || '',
        step: '3A',
      }),
    );
  };

  const verifyDocuments = async () => {
    if (
      (await documents.filter((document) => !document.file)).length > 0 ||
      loadingUploadFile
    ) {
      return setDisabled(true);
    }
    return setDisabled(false);
  };

  useEffect(() => {
    verifyDocuments();
  }, [documents]);

  return (
    <>
      <Main>
        <DescriptionTitle>
          Nesta etapa é necessário o anexo de todos os documentos solicitados a
          seguir conforme a orientação dada à direita de cada anexo.
        </DescriptionTitle>
      </Main>
      <Margin height={64} />
      <Main>
        {documents.map((document, index: number) => (
          <Row key={document.key}>
            <SenFiles>
              <DropZone
                disabled={!!document.file || disabledDocuments}
                disabledDeleteFile={disabledDocuments}
                infoFileName={document.label}
                name={document.label}
                content={document.file || null}
                onFileUploaded={(file: File | null) => {
                  const array = [...documents];
                  array[index].file = file;

                  if (file) {
                    convertFileToBase64(file, (base64: string) => {
                      dispatch(
                        postUploadFilePropertyRegistrationRequest({
                          caseIdSales: caseId!,
                          type: document.key,
                          step: '3A',
                          filename: file?.name || '',
                          filedata: base64,
                        }),
                      );
                    });
                  }
                  setDocuments(array);
                }}
                type="default"
              />
            </SenFiles>
            <Label>{document.type}</Label>
          </Row>
        ))}

        <Margin height={40} />
        <ButtonBar
          nextBackground={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          nextDisabled={disabled}
          nextLoading={!!loadingUploadFile || !!loadingItem || !!loadingPatch}
          backOnClick={() => Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)}
          nextOnClick={handleClick}
        />
        <Margin height={50} />
      </Main>
    </>
  );
};

export default Water;
