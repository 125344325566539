import styled from 'styled-components';

export const LayoutContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;
export const Main = styled.main`
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: 1fr;
  position: relative;
`;
