import React from 'react';
import { Field } from 'formik';

import { Container, InputText, Label, InputSelect } from './styles';
import { ErrorsText } from '../InitialStep/styles';
import formatter from '~/utils/formatter';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { CpaeNotRequiredInputNames } from '~/enums/cpaeEnum';

interface IPRops {
  label?: string;
  name?: string;
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  character?: boolean;
  selectForm?: boolean;
  values?: any;
  disabled?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  type?: any;
}

const handleOptionalChange = async (
  form: any,
  valueForm: string,
  nameForm: string,
) => {
  let setValue = valueForm;

  if (nameForm === 'cpf_cnpj') {
    setValue = formatter.cpfCnpjMask(setValue);
  }

  if (nameForm === 'number') {
    setValue = formatter.formatarNumber(setValue) || '';
  }

  if (nameForm === 'cep') {
    setValue = formatter.cepMask(setValue);
  }

  if (nameForm === 'phone') {
    setValue = formatter.formatarPhone(setValue);
  }

  form.setFieldValue(nameForm, setValue);
};

const FieldInput: React.FC<IPRops> = ({
  name = '',
  label,
  value,
  character,
  selectForm,
  values,
  disabled,
  readOnly,
  maxLength,
  onChange,
  ...props
}) => {
  const caseDetail = useSelector((item: IState) => item.dpadpe.caseDetail);
  const alreadyTriedSend = useSelector(
    (item: IState) => item.dpadpeDataMenager.alreadyTriedSend,
  );

  return (
    <Field name={name} as="select" component="select">
      {({ field, form, form: { touched, errors } }: any) => (
        <>
          <Container character={character}>
            <Label>{label}</Label>
            {selectForm ? (
              <InputSelect
                {...field}
                {...props}
                value={value}
                disabled={disabled}
                onChange={(ev) => {
                  handleOptionalChange(form, ev.target.value, field.name);
                  onChange ? onChange(ev.target.value) : null;
                }}
              >
                {values.map((item: any) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </InputSelect>
            ) : (
              <InputText
                {...field}
                {...props}
                value={caseDetail?.selectedCase ? value : form.values[name]}
                disabled={disabled}
                readOnly={readOnly}
                onChange={(ev) => {
                  handleOptionalChange(form, ev.target.value, field.name);
                  onChange ? onChange(ev.target.value) : null;
                }}
                maxLength={maxLength}
              />
            )}
          </Container>
          {errors[name] && touched[name] && <ErrorsText>{errors[name]}</ErrorsText>}
          {alreadyTriedSend &&
            !errors[name] &&
            !form.values[name] &&
            !CpaeNotRequiredInputNames.includes(name) && (
              <ErrorsText>Campo obrigatório</ErrorsText>
            )}
        </>
      )}
    </Field>
  );
};

export default FieldInput;
