import React from 'react';
import formatter from '~/utils/formatter';

import { Container, InputText, Label } from './styles';

interface IPRops {
  label?: string;
  name?: string;
  value?: string;
  onChangeValue: (value: string) => void;
  onBlur?: () => void;
  character?: boolean;
  selectForm?: boolean;
  values?: any;
  disabled?: boolean;
  maxLength?: number;
  type?: string;
  index?: number;
}

const InputSelect: React.FC<IPRops> = ({
  name,
  label,
  value,
  onChangeValue,
  onBlur,
  character,
  selectForm,
  values,
  disabled,
  maxLength,
  type,
  index,
}) => {
  return (
    <Container
      character={character}
      style={index && index > 3 ? { marginTop: '24px' } : { marginTop: '0px' }}
    >
      <Label>{label}</Label>
      {selectForm ? (
        <InputText
          character={character}
          selectForm
          as="select"
          onBlur={() => onBlur && onBlur()}
          name={name}
          value={value}
          disabled={disabled}
          onChange={(e: any) => {
            if (onChangeValue) onChangeValue(e.target.value);
          }}
        >
          {values.map((item: any) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </InputText>
      ) : (
        <InputText
          name={name}
          onBlur={() => onBlur && onBlur()}
          value={value}
          disabled={disabled}
          onChange={(e) => {
            const formatters: any = {
              number: formatter.formatarNumber,
              float: formatter.formatarDecimalNumber,
              default: (value?: string) => {
                return value || '';
              },
            };

            const formatterChoiced = type ? formatters[type] : formatters.default;

            if (onChangeValue) {
              onChangeValue(formatterChoiced(e.target.value));
            }
          }}
          maxLength={maxLength}
        />
      )}
    </Container>
  );
};

export default InputSelect;
