import React from 'react';
import { useDispatch } from 'react-redux';
import { IResponseListCases } from '~/store/modules/dpadpe/types';
import EmptyMessage from '~/components/EmptyMessage';
import { Margin } from '~/components/Margin';
import { verifyStatus } from '../validator';
import {
  TableContainer,
  TbodyContainer,
  TheadContainer,
  BorderCase,
  AlertIconContainer,
  AlertText,
} from './styles';
import { getConsultationCaseRequest } from '~/store/modules/dpadpe/actions';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import AlertLineSVG from '~/assets/Icons/AlertLine';
import ArrowRightLineSVG from '~/assets/Icons/arrowRightLine';
import { EmptyContainer } from '../styles';
import { statusCustomerObligation } from '~/utils/customerObligation';
import { getItemCustomerObligationRequest } from '~/store/modules/customerObligation/actions';
import { Color } from '~/styles';
import { statusPropertyRegistration } from '~/utils/propertyRegistration';
import { getItemPropertyRegistrations } from '~/store/modules/propertyRegistration/actions';
import { AppDispatch } from '~/store';

const Table = ({ cases }: { cases: IResponseListCases[] }) => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <TableContainer>
      <TheadContainer>
        <tr>
          <th style={{ width: '19%' }}>Protocolo</th>
          <th style={{ width: '30%' }}>Status</th>
          <th style={{ width: '40%' }}>Observações</th>
        </tr>
      </TheadContainer>
      <TbodyContainer>
        <Margin height={15} />
        {cases?.length > 0 ? (
          cases.map((caseItem: any) => (
            <>
              {caseItem.caseType === 'cpae' && (
                <tr key={caseItem.cpae.id}>
                  <td
                    style={{
                      width: '20%',
                      fontWeight: 300,
                      fontSize: '14px',
                      lineHeight: '17px',
                      color: '#000',
                    }}
                  >
                    <BorderCase color={verifyStatus(caseItem.cpae.status).color} />
                    {caseItem.cpae.caseNumber}
                  </td>
                  <td
                    style={{
                      width: '30%',
                      fontWeight: 700,
                      color: verifyStatus(caseItem.cpae.status).color,
                      textAlign: 'left',
                      fontSize: '14px',
                    }}
                  >
                    {caseItem.cpae.status}
                  </td>
                  {/* {caseItem.cpae.status === 'Boleto vencido' && (
                    <td style={{ width: '24%' }}>
                      <AlertIconContainer>
                        <AttachmentSVG color="#212529" />
                      </AlertIconContainer>
                      <AlertText>Gerar Boleto</AlertText>
                    </td>
                  )}
                  {caseItem?.cpae.status === 'Aguardando pagamento' && (
                    <td style={{ width: '24%' }}>
                      <AlertIconContainer>
                        <AttachmentSVG color="#212529" />
                      </AlertIconContainer>
                      <AlertText>Baixar Boleto</AlertText>
                    </td>
                  )} */}
                  {(caseItem?.cpae.alerts.oca || caseItem?.cpae.alerts.oce) && (
                    <td style={{ width: '20%' }}>
                      <AlertIconContainer>
                        <AlertLineSVG color="#212529" />
                      </AlertIconContainer>
                      <AlertText>Necessidade de OC</AlertText>
                    </td>
                  )}
                  {(caseItem?.cpae.alerts.fcpa || caseItem?.cpae.alerts.fcpe) && (
                    <td style={{ width: '20%' }}>
                      <AlertIconContainer>
                        <AlertLineSVG color="#212529" />
                      </AlertIconContainer>
                      <AlertText>Necessidade de FCP</AlertText>
                    </td>
                  )}

                  {/* {caseItem.cpae.status === 'Aguardando pagamento' && (
                    <td style={{ width: '24%' }}>
                      <AlertIconContainer>
                        <AttachmentSVG color="#212529" />
                      </AlertIconContainer>
                      <AlertText>Baixar Boleto</AlertText>
                    </td>
                  )} */}
                  {caseItem.cpae.status === 'Declarações emitidas' && (
                    <>
                      <td style={{ width: '17%' }}>
                        <AlertIconContainer>
                          <AlertLineSVG color="#212529" />
                        </AlertIconContainer>
                        <AlertText>Necessidade de OCA</AlertText>
                      </td>
                      <td style={{ width: '17%' }}>
                        <AlertIconContainer>
                          <AlertLineSVG color="#212529" />
                        </AlertIconContainer>
                        <AlertText>Necessidade de OCE</AlertText>
                      </td>
                    </>
                  )}

                  <button
                    type="button"
                    onClick={() => dispatch(getConsultationCaseRequest(caseItem.id))}
                  >
                    <AlertIconContainer>
                      <ArrowRightLineSVG color="#212529" />
                    </AlertIconContainer>
                  </button>
                </tr>
              )}
              {caseItem.caseType === 'oc' && (
                <tr
                  key={caseItem.id}
                  style={{
                    borderColor: statusCustomerObligation(caseItem?.oc.status || '')
                      .color,
                  }}
                >
                  <td
                    style={{
                      width: '20%',
                      fontWeight: 300,
                      fontSize: '14px',
                      lineHeight: '17px',
                      color: '#000',
                    }}
                  >
                    <BorderCase
                      color={
                        statusCustomerObligation(caseItem?.oc.status || '').color
                      }
                    />
                    {caseItem.caseNumber}
                  </td>
                  <td
                    style={{
                      width: '30%',
                      fontWeight: 700,
                      fontSize: '14px',
                      color: statusCustomerObligation(caseItem?.oc.status || '')
                        .color,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: '10px',
                        color: Color.gray,
                        lineHeight: '12px',
                        textTransform: 'uppercase',
                      }}
                    >
                      Etapa {caseItem.oc.step.number}
                    </p>
                    {caseItem.oc.status ||
                      statusCustomerObligation(caseItem.oc?.status || '').label}
                  </td>
                  <td
                    style={{
                      width: '10%',
                      fontWeight: 300,
                      fontSize: '14px',
                      lineHeight: '17px',
                      color: '#000',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: '10px',
                        color: '#999',
                      }}
                    >
                      TIPO
                    </p>
                    <p>{caseItem.oc.type || '-'}</p>
                  </td>
                  <td
                    style={{
                      width: '30%',
                      fontWeight: 300,
                      fontSize: '14px',
                      lineHeight: '17px',
                      color: '#000',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: '10px',
                        color: '#999',
                      }}
                    >
                      CPAE
                    </p>
                    <p>{caseItem.oc.caseCpae.caseNumber}</p>
                  </td>

                  <button
                    type="button"
                    onClick={() => {
                      dispatch(
                        getItemCustomerObligationRequest({ caseID: caseItem.id }),
                      );
                      Navigation.navigate(RouteName.CUSTOMEROBLIGATION);
                    }}
                  >
                    <AlertIconContainer>
                      <ArrowRightLineSVG color="#212529" />
                    </AlertIconContainer>
                  </button>
                </tr>
              )}
              {caseItem.caseType === 'fcp' && (
                <>
                  <tr
                    key={caseItem.id}
                    style={{
                      borderColor: statusPropertyRegistration(caseItem?.fcp.status)
                        .color,
                    }}
                  >
                    <td
                      style={{
                        width: '20%',
                        fontWeight: 300,
                        fontSize: '14px',
                        lineHeight: '17px',
                        color: '#000',
                      }}
                    >
                      <BorderCase
                        color={
                          statusPropertyRegistration(caseItem.fcp?.status).color
                        }
                      />
                      {caseItem.caseNumber}
                    </td>
                    <td
                      style={{
                        width: '30%',
                        fontWeight: 700,
                        fontSize: '14px',
                        color: statusPropertyRegistration(caseItem.fcp?.status)
                          .color,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: '10px',
                          color: Color.gray,
                          lineHeight: '12px',
                          textTransform: 'uppercase',
                        }}
                      >
                        Etapa {caseItem.fcp.step.number}
                      </p>
                      {caseItem.fcp.status ||
                        statusPropertyRegistration(caseItem.fcp?.status).label}
                    </td>
                    <td
                      style={{
                        width: '10%',
                        fontWeight: 300,
                        fontSize: '14px',
                        lineHeight: '17px',
                        color: '#000',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: '10px',
                          color: '#999',
                        }}
                      >
                        TIPO
                      </p>
                      <p>{caseItem.fcp.type || '-'}</p>
                    </td>
                    <td
                      style={{
                        width: '30%',
                        fontWeight: 300,
                        fontSize: '14px',
                        lineHeight: '17px',
                        color: '#000',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: '10px',
                          color: '#999',
                        }}
                      >
                        CPAE
                      </p>
                      <p>{caseItem.fcp.caseCpae.caseNumber}</p>
                    </td>

                    <button
                      type="button"
                      onClick={() => {
                        dispatch(
                          getItemPropertyRegistrations({ case: caseItem.id }),
                        );
                        Navigation.navigate(RouteName.PROPERTYREGISTRATION);
                      }}
                    >
                      <AlertIconContainer>
                        <ArrowRightLineSVG color="#212529" />
                      </AlertIconContainer>
                    </button>
                  </tr>
                  <Margin height={12} />
                </>
              )}

              <Margin height={12} />
            </>
          ))
        ) : (
          <EmptyContainer colSpan={3}>
            <EmptyMessage textMessage="Você ainda não possui processos!" />
          </EmptyContainer>
        )}
      </TbodyContainer>
    </TableContainer>
  );
};

export default Table;
