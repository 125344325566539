import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setValuesObject } from '~/store/modules/dpadpeDataMenager/actions';
import { IFieldAgrgregatort } from '~/store/modules/dpadpeDataMenager/types';
import { Button, Container, ContainerButton, ContainerTitle } from './styles';
import { Margin } from '~/components/Margin';
import { IPropsForm } from '../typesForm';
import { IState } from '~/store/modules/types';
import FieldInput from '../FieldInput';
import { Color } from '~/styles';
import PlusSVG from '~/assets/Icons/Plus';
import { AppDispatch } from '~/store';

interface IProps extends IPropsForm {
  fields: IFieldAgrgregatort[];
}

const Aggregator: React.FC<IProps> = ({ fields }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [fieldsTotalValues, setFieldsTotal] = useState<IFieldAgrgregatort[]>([]);

  const [fieldsArray, setFieldsArray] = useState<IFieldAgrgregatort[]>(fields);

  const caseDetail = useSelector((item: IState) => item.dpadpe.caseDetail);

  const [indexField, setIndexField] = useState(0);

  useEffect(() => {
    setFieldsArray(fields);
    setFieldsTotal(fields);
  }, []);

  return (
    <>
      {fieldsArray ? (
        <>
          {fieldsTotalValues.map((itemField, indexItemField) => {
            return (
              <div key={indexItemField.toString()}>
                {itemField.fields.map((item, indexItem) => {
                  return (
                    <>
                      <Container key={indexItem.toString()}>
                        <>
                          <ContainerTitle>{itemField.label}</ContainerTitle>
                          {item.map((fields, indexField) => (
                            <div
                              style={{
                                width: '48%',
                                marginTop: indexField > 1 ? '24px' : '0px',
                              }}
                              key={indexField.toString()}
                            >
                              <FieldInput
                                value={fields.value}
                                selectForm={fields.type === 'select' || false}
                                values={
                                  fields.type === 'select' ? fields.values : []
                                }
                                onChange={(t) => {
                                  const send = JSON.parse(
                                    JSON.stringify(fieldsTotalValues),
                                  );

                                  send[indexItemField].fields[indexItem][
                                    indexField
                                  ].value = t;

                                  dispatch(setValuesObject(send));
                                  setFieldsTotal(send);
                                }}
                                label={`${fields.label}*`}
                                name={`${itemField.key}-${fields.key}`}
                                maxLength={
                                  fields?.maxLength ? fields?.maxLength : undefined
                                }
                                // type={fields.type}
                                character
                              />
                            </div>
                          ))}
                          {/* <Margin height={16} /> */}
                          {/* <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                              marginTop: '26px',
                            }}
                          >
                            {indexItem > 0 && itemField.fields.length < 30 && (
                              <Margin width={15} />
                            )}
                          </div> */}
                        </>
                      </Container>
                      <ContainerButton>
                        {indexItem > 0 && (
                          <Button
                            style={{
                              background: Color.red,
                              width: '30%',
                              fontSize: '12px',
                              color: Color.white,
                              marginRight: 'auto',
                            }}
                            border={Color.white}
                            onClick={async () => {
                              const filter = await itemField.fields.filter(
                                (item, index) => indexItem !== index,
                              );

                              const send = JSON.parse(JSON.stringify(fieldsArray));

                              send[indexItemField].fields = filter;

                              setFieldsArray(send);
                              setFieldsTotal(send);
                              dispatch(setValuesObject(send));
                            }}
                          >
                            - Excluir campo
                          </Button>
                        )}
                        {itemField.fields.length < 30 && (
                          <Button
                            style={{
                              background: Color.blueSeconde,
                              width: '30%',
                              fontSize: '12px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            border={Color.white}
                            onClick={async () => {
                              const resetField = await item.map((field) => {
                                return {
                                  ...field,
                                  key: `${field.key}${indexField}`,
                                  value: '',
                                };
                              });

                              setIndexField((prevState) => prevState + 1);

                              const newArray = JSON.parse(
                                JSON.stringify(fieldsTotalValues),
                              );

                              await newArray[indexItemField].fields.push(resetField);
                              setFieldsTotal(newArray);
                              setFieldsArray(newArray);
                              dispatch(setValuesObject(newArray));
                            }}
                            disabled={!!caseDetail?.selectedCase}
                          >
                            <PlusSVG />
                            <Margin width={12} />
                            Adicionar campo
                          </Button>
                        )}
                      </ContainerButton>
                    </>
                  );
                })}
              </div>
            );
          })}
        </>
      ) : (
        <div />
      )}
    </>
  );
};

export default Aggregator;
