import { EndpointBuilder, BaseQueryFn } from '@reduxjs/toolkit/query';
import { IEnumCacheConfig } from '../enum';
import {
  IOperations,
  IResponseListCases,
  IResponseListSlips,
  IResponseListStatuses,
} from '~/store/modules/dpadpe/types';
import { IRequestListCases, IRequestListSlip } from '~/store/modules/dpadpe/types';

export const dpadpeEndpoints = (
  builder: EndpointBuilder<BaseQueryFn, IEnumCacheConfig, 'cache'>,
) => ({
  fetchDpadpeListStatuses: builder.query<IResponseListStatuses[], void>({
    query: () => ({
      url: `v3/supply-depletion-possibility-declaration/statuses`,
      method: 'GET',
    }),
    providesTags: [IEnumCacheConfig.DPA_DPE_STATUSES],
  }),
  fetchDpadpeListCases: builder.query<IResponseListCases[], IRequestListCases>({
    query: (params) => ({
      url: `v3/supply-depletion-possibility-declaration/cases`,
      method: 'GET',
      params: { ...params },
    }),
    providesTags: [IEnumCacheConfig.DPA_DPE_LIST_CASES],
  }),
  fetchDpadpeListOperations: builder.query<IOperations[], void>({
    query: () => ({
      url: `v3/supply-depletion-possibility-declaration/operations`,
      method: 'GET',
    }),
    providesTags: [IEnumCacheConfig.DPA_DPE_LIST_OPERATIONS],
  }),
  fetchDpadpeListSlips: builder.query<IResponseListSlips[], IRequestListSlip>({
    query: (params) => ({
      url: `v3/supply-depletion-possibility-declaration/slips`,
      method: 'GET',
      params: { ...params },
    }),
    providesTags: [IEnumCacheConfig.DPA_DPE_LIST_SLIPS],
  }),
});
