import { IEnumErrorResponse } from '~/enums/errorsEnum';

export enum RepositoriesTypesPayment {
  ENROLLMENTS_GET_SITUATION_PIX_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_SITUATION_PIX_METHOD_REQUEST',
  ENROLLMENTS_GET_SITUATION_PIX_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_SITUATION_PIX_METHOD_SUCCESS',
  ENROLLMENTS_GET_SITUATION_PIX_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_SITUATION_PIX_METHOD_FAILURE',

  ENROLLMENTS_POST_PAYMENT_METHOD_REQUEST = '@repositories/ENROLLMENTS_POST_PAYMENT_METHOD_REQUEST',
  ENROLLMENTS_POST_PAYMENT_METHOD_SUCCESS = '@repositories/ENROLLMENTS_POST_PAYMENT_METHOD_SUCCESS',
  ENROLLMENTS_POST_PAYMENT_METHOD_FAILURE = '@repositories/ENROLLMENTS_POST_PAYMENT_METHOD_FAILURE',
  ENROLLMENTS_POST_PAYMENT_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_POST_PAYMENT_METHOD_FAILURE_CLOSE',

  ENROLLMENTS_GET_PAYMENT_VOUCHER_METHOD_REQUEST = '@repositories/ENROLLMENTS_GET_PAYMENT_VOUCHER_METHOD_REQUEST',
  ENROLLMENTS_GET_PAYMENT_VOUCHER_METHOD_SUCCESS = '@repositories/ENROLLMENTS_GET_PAYMENT_VOUCHER_METHOD_SUCCESS',
  ENROLLMENTS_GET_PAYMENT_VOUCHER_METHOD_FAILURE = '@repositories/ENROLLMENTS_GET_PAYMENT_VOUCHER_METHOD_FAILURE',
  ENROLLMENTS_GET_PAYMENT_VOUCHER_METHOD_FAILURE_CLOSE = '@repositories/ENROLLMENTS_GET_PAYMENT_VOUCHER_METHOD_FAILURE_CLOSE',
}

export interface IRequestSituationPix {
  enrollmentId: string;
  barCodeNumber: string;
}

export interface IResponseSituationPix {
  isPaid: boolean;
}

export interface IRequestPayment {
  enrollmentId: string;
  invoiceId: string;
  paymentCardNumber: string;
  paymentCardHolder: string;
  paymentExpirationDate: string;
  paymentSecurityCode: string;
  paymentBrand: string;
  installments: number;
}

export interface IRequestIPaymentVoucher {
  enrollmentId: string;
  invoiceId: number;
}

export interface IPaymentVoucher {
  reference: string;
  invoice_id: number;
  amount: number;
  issue_date_hour: string;
  billed_consumption: string;
  maturity_date: string;
  read_date_hour: string;
  consumption_days: string;
  meter_reader: string;
  colected_read: string;
  average_consumption: string;
  read_type: string;
  rate_type: string;
  reader: string;
  invoice_type: string;
  has_payment_integrator: false;
  status: {
    id: number;
    label: string;
  };
  qr_code: string;
  qr_code_key: string;
  pix_key: string;
  bar_code_number: string;
  consumer: {
    name: string;
    address: string;
  };
  items:
    | [
        {
          description: string;
          amount: number;
        },
      ]
    | [];
  authorization_code: string;
  payment_id: string;
  received_date: string;
  brand: string;
  installments: number;
}

export interface IRepositoriesStateEnrollmentsPayment {
  readonly pixChecked: boolean;
  readonly paymentVoucher: IPaymentVoucher | null;
  readonly error: IEnumErrorResponse | null;
  readonly loading: boolean;
  readonly errorVoucher: {
    message: string;
    status: number;
    state: boolean;
  } | null;
  readonly loadingVoucher: boolean;
}
