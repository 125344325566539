import React, { FC, useEffect, useState } from 'react';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  ComposedChart,
} from 'recharts';
import styled from 'styled-components';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';

import { Breakpoints } from '~/styles';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { checkToggle } from '~/utils/toggles';
import { sortConsumptionHistoryData } from '~/utils/sort';

const CustomTooltip = ({ active, payload }: any) => {
  if (active) {
    return (
      <div
        style={{
          background: '#FFFFFF',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',

          padding: '10px 10px',
        }}
      >
        <p style={{ fontWeight: 700 }}>{`${format(
          new Date(payload[0]?.payload.read_date_reference),
          'dd',
        )} de ${format(new Date(payload[0]?.payload.read_date_reference), 'MMM', {
          locale: ptBR,
        })}. de ${format(new Date(payload[0]?.payload.read_date_reference), 'yyyy')}`}</p>
        <p>
          Consumo:{' '}
          {`${
            payload[0].payload.formatedConsumption
              ? payload[0].payload.formatedConsumption
              : 0
          }`}
          m³
        </p>
        <p>
          Méd. do período:{' '}
          {payload[0].payload.average_consumption
            .toFixed(2)
            .toString()
            .replace('.', ',')}
          m³
        </p>
      </div>
    );
  }

  return null;
};

const Chart: FC<any> = ({ dataChart, average }) => {
  const alert = useSelector((item: IState) => item?.enrollmentsAlerts?.data);

  const [existSmartAlert, setExistSmartAlert] = useState<boolean>(false);

  const toggles = useSelector((item: IState) => item.togglesMe.data);

  const sortedData = [...dataChart];

  const verifyToggles = async () => {
    setExistSmartAlert(await checkToggle(toggles, 'MVP_SMART_METER'));
  };

  useEffect(() => {
    verifyToggles();
  }, [toggles]);

  return (
    <Container>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={sortConsumptionHistoryData(sortedData)}>
          <CartesianGrid strokeDasharray="2 1" />
          <XAxis
            dataKey="formatedReference"
            name="Consumo Real"
            fontSize={12}
            stroke="#000"
            fontWeight={400}
          />
          <YAxis fontSize={12} />
          <Tooltip
            content={
              <CustomTooltip
                active={undefined}
                payload={undefined}
                label={undefined}
                average={average}
              />
            }
          />

          <Bar name="Consumo Real" dataKey="formatedConsumption" fill="#AAD7E1" />
          <Line
            type="monotone"
            activeDot={{ r: 8 }}
            dataKey="average_consumption"
            stroke="#004B55"
            strokeWidth={2}
          />
          {alert && existSmartAlert && (
            <Line
              type="monotone"
              dataKey="amt"
              stroke="#37B482"
              fill="#37B482"
              strokeDasharray="3 3"
              strokeWidth={2}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 260px;

  @media (max-width: ${Breakpoints.tablet.min}px) {
    height: 180px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    margin-left: -50px;
  }
`;

export default Chart;
