import React, { FC, useEffect, useState } from 'react';

import { Container } from '~/common';
import {
  Card,
  ContainerBreadCrumb,
  Content,
  ContentButton,
  ContentRow,
  Line,
  Main,
  TitleFilter,
  Wrapper,
} from './styles';
import { RouteName } from '~/routes/Routes.name';
import Navigation from '~/utils/Navigation';
import { BreadCrumb } from '~/components/BreadCrumb';
import { Margin } from '~/components/Margin';
import Table from './Table';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { getListSlipsRequest } from '~/store/modules/dpadpe/actions';
import ButtonGeneric from './components/ButtonGeneric';
import FilterSVG from '~/assets/Icons/FilterSVG';
import LoadingComponent from '~/components/Loading';
import Pagination from '~/components/Pagination';
import { AppDispatch } from '~/store';

const SupplyAndDepletionPossibilityDeclarationPanel: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((item: IState) => item.dpadpe.loading);
  const pagination = useSelector((item: IState) => item.dpadpe.paginationSlips);
  const cases = useSelector((item: IState) => item.dpadpe.dataSlips);
  const [protocol] = useState('');
  const [page, setPage] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  const [isCancelledActive, setIsCancelledActive] = useState(false);
  const [isPendingActive, setIsPendingActive] = useState(false);
  const [isPaidActive, setPaidActive] = useState(false);

  useEffect(() => {
    dispatch(
      getListSlipsRequest({
        cancelled: isCancelledActive,
        pending: isPendingActive,
        paid: isPaidActive,
        page,
        limit: 6,
        protocol,
      }),
    );
  }, [isCancelledActive, isPaidActive, isPendingActive, protocol, page]);

  return (
    <>
      {loading && <LoadingComponent />}
      <Main>
        <Container size="newLarge">
          <Wrapper>
            <Margin height={18} />
            <ContainerBreadCrumb>
              <BreadCrumb
                links={[
                  {
                    label: 'Home',
                    active: false,
                    onClick: () => Navigation.navigate(RouteName.HOME),
                  },
                  {
                    label: 'Acessar Boletos',
                    active: true,
                  },
                ]}
              />
            </ContainerBreadCrumb>

            <Margin height={24} />

            <Card>
              <Margin height={30} />
              <Content>
                <h1>Boletos</h1>
              </Content>
              <Margin height={24} />
              <Line />
              <Margin height={24} />

              <Content>
                <ContentRow>
                  <ButtonGeneric
                    select={isFilter}
                    type="buttons"
                    onClick={() => {
                      setIsFilter(!isFilter);
                      setIsCancelledActive(false);
                      setIsPendingActive(false);
                      setPaidActive(false);
                    }}
                  >
                    <>
                      Filtro
                      <Margin width={10} />
                      <FilterSVG />
                    </>
                  </ButtonGeneric>
                  <ContentButton />
                </ContentRow>
                {isFilter && (
                  <>
                    <TitleFilter>Selecione os filtros de sua busca:</TitleFilter>
                    <ContentRow>
                      <ButtonGeneric
                        type="filters-paid"
                        onClick={() => setPaidActive(!isPaidActive)}
                        select={isPaidActive}
                      >
                        Quitados
                      </ButtonGeneric>
                      <ButtonGeneric
                        type="filters-pending"
                        onClick={() => setIsPendingActive(!isPendingActive)}
                        select={isPendingActive}
                      >
                        Pendentes
                      </ButtonGeneric>
                      <ButtonGeneric
                        type="filters-cancel"
                        onClick={() => setIsCancelledActive(!isCancelledActive)}
                        select={isCancelledActive}
                      >
                        Cancelados
                      </ButtonGeneric>
                    </ContentRow>
                  </>
                )}
                <Margin height={30} />
                <Table cases={cases} />
                <Pagination setPage={setPage} pagination={pagination} />
              </Content>
            </Card>
          </Wrapper>
        </Container>
      </Main>
    </>
  );
};

export default SupplyAndDepletionPossibilityDeclarationPanel;
