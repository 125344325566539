import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import ChangeAccountSVG from '~/assets/Icons/changeAccount';
import ManagerUser from '~/assets/Icons/ManagerUser';
import { Margin } from '~/components/Margin';
import { activateModalAuth } from '~/store/modules/enrollmentsAuth/actions';

import { Container, Content, TextTitle, Text, Column, Button } from './styles';
import { IState } from '~/store/modules/types';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
// import { getEnrollmentsInvoicesDetailRequest } from '~/store/modules/enrollmentsInvoices/actions';
import { checkToggle } from '~/utils/toggles';
import { activateModalNotClient } from '~/store/modules/enrollmentsUsersNotClient/actions';
import configsAppJsonImport from '~/configs/app.config';
import { activateRouteReportLackWater } from '~/store/modules/outsides/actions';
import { getOrderServicesValidateLackWaterRequest } from '~/store/modules/orderServices/actions';
import { AppDispatch } from '~/store';

const configsAppJson = configsAppJsonImport as any;

interface IProps {
  active: boolean;
  setDropDownActive: (status: boolean) => void;
}

interface IActiveModalSession {
  route: string;
}

const Dropdown: FC<IProps> = ({ active, setDropDownActive }) => {
  const dispatch = useDispatch<AppDispatch>();

  const token = useSelector((state: IState) => state.enrollmentsAuth.token);
  // const invoices = useSelector(
  //   (item: IState) => item?.enrollmentsInvoices?.data?.invoices,
  // );
  const toggles = useSelector((item: IState) => item.toggles.data);
  const togglesMe = useSelector((item: IState) => item.togglesMe.data);
  const isEntrepreneur = useSelector(
    (state: IState) => state.enrollmentsAuth.isEntrepreneur,
  );
  const isManager = useSelector((state: IState) => state.enrollmentsAuth.isManager);
  const isClient = useSelector((state: IState) => state.enrollmentsAuth.isClient);
  const registrations = useSelector(
    (state: IState) => state.enrollmentsAuth.registrations,
  );
  const eletronic_exists = useSelector(
    (state: IState) => state.enrollmentsAuth.registrationCheck?.eletronic_exists,
  );

  const enrollment = useSelector(
    (state: IState) => state.enrollmentsAuth.registrationCheck?.enrollment,
  );

  const needRenovationSocialTariff = useSelector(
    (item: IState) => item.socialTariff.needRenovation,
  );

  const [directDebit, setDirectDebit] = useState<boolean>(false);
  const [supportCenter, setSupportCenter] = useState<boolean>(false);
  const [ownership, setOwnership] = useState<boolean>(false);
  const [socialTariffView, setSocialTariffView] = useState<boolean>(false);
  const [existIotView, setExistIotView] = useState<boolean>(false);
  const [reportLackWater, setReportLackWater] = useState<boolean>(false);

  const actionLabel = {
    change_type_delivery: {
      category: 'Faturas',
      action: '[Menu][Área Logada][Seção Minha Fatura] – Alterar tipo de entrega',
    },
    change_maturity: {
      category: 'Faturas',
      action: '[Menu][Área Logada][Seção Minha Fatura] – Alterar vencimento',
    },
    negative_certificate: {
      category: 'Certidão negativa',
      action: '[Menu][Área Logada][Seção Minha Fatura] – Certidão negativa',
    },
    invoice_consultation: {
      category: 'Faturas',
      action: '[Menu][Área Logada][Seção Minha Fatura] – Consulta de faturas',
    },
    automatic_debit: {
      category: 'Débito automático',
      action: '[Menu][Área Logada][Seção Minha Fatura] – Débito automático',
    },
    consumption_history: {
      category: 'Histórico detalhado',
      action: '[Menu][Área Logada][Seção Minha Fatura] – Histórico detalhado',
    },
    repair_center: {
      category: 'Minhas solicitações',
      action: '[Menu][Área Logada][Seção Solicitações] – Minhas solicitações',
    },
    dpa_dpe: {
      category: 'DPA/DPE',
      action: '[Menu][Área Logada][Seção Solicitações] – DPA/DPE',
    },
    change_registration_data: {
      category: 'Alterar dados cadastrais',
      action: '[Menu][Área Logada][Seção Minha Conta] – Alterar dados cadastrais',
    },
    smart_measurement: {
      category: 'Medição inteligente',
      action: '[Menu][Área Logada][Seção Minha Conta] – Medição inteligente',
    },
    privacy_policy: {
      category: 'Política de privacidade',
      action: '[Menu][Área Logada][Seção Minha Conta] – Política de privacidade',
    },
    contact_us: {
      category: 'Fale conosco',
      action: '[Menu][Área Logada][Seção Suporte] – Fale conosco',
    },
    change_registration: {
      category: 'Trocar matrícula',
      action: '[Menu][Área Logada][Seção Suporte] – Trocar matrícula',
    },
    lack_water: {
      category: 'Falta d’água',
      action: token
        ? '[WEB][AL][MenuLateral][Falta d’água]'
        : '[WEB][NL][MenuLateral][Falta d’água]',
    },
  };

  const verifyToggles = async () => {
    setReportLackWater(await checkToggle(toggles, 'REPORT_LACK_WATER'));
  };

  useEffect(() => {
    if (toggles && toggles?.length > 0) verifyToggles();
  }, [toggles]);

  const verifytogglesMe = async () => {
    setDirectDebit(await checkToggle(togglesMe, 'DIRECT_DEBIT'));
    setSocialTariffView(await checkToggle(togglesMe, 'TARIFA_SOCIAL'));
    setExistIotView(await checkToggle(togglesMe, 'MVP_MESSAGE_SMART_METER'));
  };

  useEffect(() => {
    if (togglesMe && togglesMe.length > 0) verifytogglesMe();
  }, [togglesMe]);

  const verifyTogglesMe = async () => {
    setSupportCenter(await checkToggle(togglesMe, 'SUPPORT_CENTER_NEW'));
    setOwnership(await checkToggle(togglesMe, 'OWNERSHIP_NEW'));
  };

  useEffect(() => {
    if (togglesMe && togglesMe.length > 0) verifyTogglesMe();
  }, [togglesMe]);

  const activeModalSession = (route: string) => {
    setDropDownActive(false);
    if (token) {
      if (isClient && !enrollment) return;
      Navigation.navigate(route);
    } else {
      dispatch(activateModalAuth(route));
    }
  };

  // const getDetailInvoice = (stateRoute?: boolean) => {
  //   setDropDownActive(false);
  //   if (!isClient && !isEntrepreneur && !isManager) {
  //     Navigation.navigate(RouteName.SIMPLIFIEDINVOICE, {
  //       titleNotClient: stateRoute ? 'PAGAMENTOS PIX E CARTÃO DE CRÉDITO' : '',
  //     });
  //     return;
  //   }

  //   if (invoices && invoices.length > 0) {
  //     dispatch(getEnrollmentsInvoicesDetailRequest(invoices[0]?.invoice_id));
  //   } else {
  //     Navigation.navigate(RouteName.INVOICES);
  //   }
  // };

  const handleGaEvent = (
    type:
      | 'change_type_delivery'
      | 'change_maturity'
      | 'negative_certificate'
      | 'invoice_consultation'
      | 'automatic_debit'
      | 'consumption_history'
      | 'repair_center'
      | 'dpa_dpe'
      | 'change_registration_data'
      | 'smart_measurement'
      | 'privacy_policy'
      | 'contact_us'
      | 'change_registration'
      | 'lack_water',
  ) => {
    ReactGA.event({
      category: actionLabel[type].category,
      action: actionLabel[type].action,
    });
  };

  return (
    <Container active={active} className={active ? 'menu-dropdown' : undefined}>
      <Content className={active ? 'menu-dropdown' : undefined}>
        <Column>
          <TextTitle>Minha fatura</TextTitle>
          <Text
            onClick={() => {
              activeModalSession(RouteName.INVOICESCHANGEDELIVERYTYPE);
              handleGaEvent('change_type_delivery');
            }}
            type="button"
          >
            Alterar tipo de entrega de fatura
          </Text>
          <Text
            onClick={() => {
              activeModalSession(RouteName.INVOICESCHANGEEXPIRATION);
              handleGaEvent('change_maturity');
            }}
            type="button"
          >
            Alterar vencimento
          </Text>
          <Text
            onClick={() => {
              activeModalSession(RouteName.NEGATIVECERTIFICATE);
              handleGaEvent('negative_certificate');
            }}
            type="button"
          >
            Certidão negativa
          </Text>
          <Text
            onClick={() => {
              activeModalSession(RouteName.INVOICES);
              handleGaEvent('invoice_consultation');
            }}
            type="button"
          >
            Consulta de faturas
          </Text>
          {(directDebit || !token) && (
            <Text
              onClick={() => {
                activeModalSession(RouteName.AUTOMATICDEBIT);
                handleGaEvent('automatic_debit');
              }}
              type="button"
            >
              Débito automático
            </Text>
          )}
          <Text
            onClick={() => {
              activeModalSession(RouteName.DETAILEDHISTORY);
              handleGaEvent('consumption_history');
            }}
            type="button"
          >
            Histórico detalhado
          </Text>
          <Text
            onClick={() => {
              activeModalSession(RouteName.DEBTNEGOTIATION);
            }}
            type="button"
          >
            Minhas pendências
          </Text>
          {/* <Text
            onClick={() => {
              if (token) getDetailInvoice();
              else activeModalSession();
            }}
            type="button"
          >
            Emissão de segunda via
          </Text>
          <Text
            onClick={() => {
              if (token) getDetailInvoice(true);
              else activeModalSession();
            }}
            type="button"
          >
            Pagamentos PIX e cartão de <br /> crédito
          </Text> */}
        </Column>
        <Column>
          <TextTitle>Solicitações</TextTitle>

          <Text
            onClick={() => {
              if (token) {
                activeModalSession(RouteName.ENTERPRISE_VIABILITY);
                handleGaEvent('dpa_dpe');
              } else {
                Navigation.navigate(
                  RouteName.SUPPLYDEPLETIONPOSSIBILITYDECLARATIONLANDING,
                );
              }
            }}
            type="button"
          >
            DPA/DPE
          </Text>
          {/* <Text
            onClick={() => {
              if (token && !isClient && !isEntrepreneur && !isManager) {
                dispatch(activateModalNotClient());
              } else {
                activeModalSession(RouteName.NEGOTIATIONS);
              }
            }}
            type="button"
          >
            Minhas negociações
          </Text> */}
          {((supportCenter && isClient) || !token) && (
            <Text
              onClick={() => {
                if (token && !isClient && !isEntrepreneur && !isManager) {
                  dispatch(activateModalNotClient());
                } else {
                  activeModalSession(RouteName.SUPPORTCENTER);
                  handleGaEvent('repair_center');
                }
              }}
              type="button"
            >
              Minhas solicitações
            </Text>
          )}
          {reportLackWater && (
            <Text
              onClick={() => {
                handleGaEvent('lack_water');
                if (token) {
                  if (registrations?.length > 0) {
                    dispatch(
                      getOrderServicesValidateLackWaterRequest(
                        RouteName.REPORTLACKWATER,
                      ),
                    );
                  } else {
                    dispatch(activateRouteReportLackWater());
                  }
                } else {
                  dispatch(activateRouteReportLackWater());
                  Navigation.navigate(RouteName.LOGIN);
                }
              }}
              type="button"
              id={
                token
                  ? 'AL-menu-lateral-falta-d’água'
                  : 'NL-menu-lateral-falta-d’água'
              }
            >
              Reportar falta d’água
            </Text>
          )}
          {(socialTariffView || !token) && (
            <Text
              onClick={() => {
                if (token) {
                  activeModalSession(
                    needRenovationSocialTariff
                      ? RouteName.SOCIALTARIFFSOLICITATION
                      : RouteName.SOCIALTARIFF,
                  );
                } else {
                  ReactGA.event({
                    category: 'Tarifa Social',
                    action: `[Ñ Logado][Menu Acesso Rápido] – Tarifa Social`,
                  });
                  Navigation.navigate(RouteName.SOCIALTARIFFOUTSIDE);
                }
              }}
              type="button"
            >
              Tarifa social
            </Text>
          )}
        </Column>
        <Column>
          <TextTitle>Minha conta</TextTitle>
          <Text
            onClick={() => {
              activeModalSession(RouteName.ACCOUNTCONFIG);
              handleGaEvent('change_registration_data');
            }}
            type="button"
          >
            Alterar dados cadastrais
          </Text>
          {(eletronic_exists || configsAppJson?.NODE_ENV === 'HMG') &&
            existIotView && (
              <Text
                onClick={() => {
                  activeModalSession(RouteName.SMARTMETER);
                  handleGaEvent('smart_measurement');
                }}
                type="button"
              >
                Medição inteligente
              </Text>
            )}
          <Text
            onClick={() => {
              handleGaEvent('privacy_policy');
              window.open(
                'https://igua.com.br/politica-de-privacidade',
                '_blank',
                'noreferrer',
              );
            }}
            type="button"
          >
            Política de privacidade
          </Text>
        </Column>
        <Column>
          {isClient && (
            <>
              <Button
                onClick={() => {
                  handleGaEvent('change_registration');
                  activeModalSession(RouteName.REGISTRATIONLIST);
                }}
                type="button"
              >
                Trocar matrícula <ChangeAccountSVG />
              </Button>
              <Margin height={8} />
            </>
          )}
          <Button style={{ visibility: 'hidden' }}>
            Alterar para Perfil gestor <ManagerUser />
          </Button>
        </Column>
      </Content>
    </Container>
  );
};

export default Dropdown;
