import { EndpointBuilder, BaseQueryFn } from '@reduxjs/toolkit/query';
import { IEnumCacheConfig } from '../enum';
import { IResponseInvoicesDebts } from '~/store/modules/outsides/types';

export const negotiationEndpoints = (
  builder: EndpointBuilder<BaseQueryFn, IEnumCacheConfig, 'cache'>,
) => ({
  fetchNegotiationDebtsList: builder.query<IResponseInvoicesDebts, void>({
    query: () => ({
      url: `v3/invoices/debts`,
      method: 'GET',
      params: {
        page: 1,
        size: 1000,
      },
      timeout: 90000,
    }),
    providesTags: [IEnumCacheConfig.NEGOTATION_DEBTS_LIST],
  }),
});
