import React, { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import {
  activateModalDataUpdate,
  activateModalSalesforceSatisfaction,
} from '~/store/modules/outsides/actions';
import PaymentReceipt from '~/components/PaymentReceipt';
import { AppDispatch } from '~/store';

const DischargeDebtsSinglePaymentReceipt: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const dataRegistrationUpdate = useSelector(
    (item: IState) => item.outsides.dataRegistrationUpdate,
  );
  const dataInvoiceGroupVoucher = useSelector(
    (item: IState) => item?.outsides?.dataInvoiceVoucher,
  );

  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(RouteName.LANDING),
    },
    {
      label: 'Comprovante de pagamento',
      active: true,
    },
  ];

  const getPrintGA = () => {
    if (dataInvoiceGroupVoucher && dataInvoiceGroupVoucher?.detail) {
      return {
        category: 'Pagamento',
        action: '[QD][C4][WEB] – Baixar Comprovante - única dívida',
      };
    }

    return {
      category: 'Pagamento Único',
      action: '[QD][C4][WEB] – Pg. Único - Baixar Comprovante',
    };
  };

  useEffect(() => {
    if (dataInvoiceGroupVoucher && dataInvoiceGroupVoucher?.detail) {
      ReactGA.event({
        category: 'Pagamento',
        action:
          '[QD][C4][WEB] – Cartão – Pg. de uma única dívida - Sucesso no Pagamento',
      });
    } else {
      ReactGA.event({
        category: 'Pagamento Único',
        action: '[QD][C4][WEB] – Cartão – PG. Único - Sucesso no Pagamento',
      });
    }

    dataRegistrationUpdate?.isUpdatable
      ? dispatch(activateModalDataUpdate())
      : dispatch(activateModalSalesforceSatisfaction());
  }, []);

  return (
    <PaymentReceipt
      links={links}
      printGA={getPrintGA()}
      loading={false}
      labelWait="Baixando..."
      navigateHomeRoute={RouteName.LANDING}
    />
  );
};

export default DischargeDebtsSinglePaymentReceipt;
