import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Main,
  MainCard,
  TitleText,
  DescriptionText,
  LinkRegistrationButton,
  ContainerTitle,
  ContainerInfo,
  ContainerMainBackgroundIcon,
  ContainerCards,
  ContentCards,
  Separador,
  DescriptionTextUlContainer,
  DescriptionTextLine,
} from './styles';
import { MainBackgroundIcon } from './icons';
import { BannerApp } from '~/design_system/components/BannerApp';
import { IState } from '~/store/modules/types';
import { Breakpoints } from '~/styles';
import { useMediaQuery } from 'react-responsive';
import { clearCutoverClient } from '~/store/modules/enrollmentsAuth/actions';
import LoadingComponent from '~/components/Loading';
import { AppDispatch } from '~/store';

const HomeCutoverClient: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const isDesktop = useMediaQuery({ minWidth: Breakpoints.desktopLarge.min });

  const loading = useSelector((state: IState) => state.enrollmentsAuth.loading);
  const isClient = useSelector((state: IState) => state.enrollmentsAuth.isClient);
  const enrollmentsUserInfo = useSelector(
    (item: IState) => item.enrollmentsUsers.data,
  );
  const firstName = enrollmentsUserInfo?.firstName?.toLocaleLowerCase();

  return (
    <>
      <Separador />
      <Main>
        {loading && <LoadingComponent />}
        <ContainerCards>
          <ContentCards>
            <MainCard>
              <ContainerTitle>
                <TitleText>
                  Olá
                  {firstName &&
                    `, ${enrollmentsUserInfo?.firstName?.toLocaleLowerCase()}`}
                  !
                </TitleText>
              </ContainerTitle>
              <ContainerInfo>
                <ContainerMainBackgroundIcon>
                  <MainBackgroundIcon />
                </ContainerMainBackgroundIcon>
                <DescriptionText hasMaxWidth>
                  Informamos que a partir de abril 2025 não estaremos mais atuando na
                  sua cidade.
                  <br />
                  Logo, o acesso aos serviços por meio deste aplicativo foi
                  descontinuado.
                  <br />
                  <br />
                  Para mais informações ou dúvidas procure a sua concessionária.
                </DescriptionText>
                {isClient && (
                  <>
                    <DescriptionText hasMaxWidth>
                      Operações em que não estamos mais atuando:
                    </DescriptionText>
                    <DescriptionTextUlContainer>
                      <DescriptionTextLine>Águas Palestina</DescriptionTextLine>
                      <DescriptionTextLine>Águas Piquete</DescriptionTextLine>
                      <DescriptionTextLine>Águas Alta Floresta</DescriptionTextLine>
                      <DescriptionTextLine>Águas Canarana</DescriptionTextLine>
                      <DescriptionTextLine>Águas Colíder</DescriptionTextLine>
                      <DescriptionTextLine>Águas Comodoro</DescriptionTextLine>
                      <DescriptionTextLine>
                        Águas Pontes e Lacerda
                      </DescriptionTextLine>
                      <DescriptionTextLine>Itapoá Saneamento</DescriptionTextLine>
                    </DescriptionTextUlContainer>
                  </>
                )}
                <DescriptionText>
                  Para assumir titularidade de nova matrícula dê seguimento ao
                  processo na próxima tela.
                </DescriptionText>
                <LinkRegistrationButton
                  type="button"
                  onClick={() => dispatch(clearCutoverClient())}
                >
                  Entendi
                </LinkRegistrationButton>
              </ContainerInfo>
            </MainCard>
          </ContentCards>
          {isDesktop && <BannerApp />}
        </ContainerCards>
      </Main>
    </>
  );
};

export default HomeCutoverClient;
