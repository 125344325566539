import { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { Breakpoints, Color } from '~/styles';
import { COLORS } from '~/utils/constants';

export interface IPropStyled extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  borderColor?: string;
  disabledInput?: boolean;
}

export const ContainerAutocompleteStyled = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
`;

export const LabelRow = styled.p`
  color: ${Color.blueIntense};
  font-weight: 500;
  font-size: 14px;
  margin-block-end: 4px;
`;

export const ContainerSvg = styled.p<{ error: boolean }>`
  height: max-content;
  position: absolute;
  top: ${({ error }) => (error ? '-5px' : '15px')};
  bottom: 0;
  right: 15px;
  margin: auto 0;
  pointer-events: none;
`;

export const AutocompleteStyled = styled.input<IPropStyled>`
  font-size: 16px;
  height: 36px;
  font-weight: 400;
  color: ${({ error }) => (error ? Color.red : Color.black)};
  border: 1px solid
    ${({ error, borderColor }) =>
      error ? Color.red : borderColor || Color.grayLightSeconde};
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 32px;

  :focus-visible {
    outline: none;
  }

  ::-webkit-input-placeholder {
    font-weight: 300;
    font-size: 16px;
  }

  :-ms-input-placeholder {
    font-weight: 300;
    font-size: 16px;
  }

  :disabled {
    background: ${Color.white};
  }

  ::placeholder {
    font-weight: 300;
    font-size: 16px;
  }

  :read-only {
    background: ${COLORS.gray200};
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 16px;
    height: 36px;
  }

  ${({ disabledInput }) =>
    disabledInput &&
    css`
      line-height: 150%;
      color: #999999;
    `}
`;

export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  background: white;
  border: 1px solid #b4b4b4;
  border-radius: 8px;
  top: 62px;
  width: 100%;
  overflow-y: auto;
  max-height: 120px;

  button {
    padding: 6px 12px;
    cursor: pointer;
    background: none;
    font-size: 14px;
    font-weight: normal;
    color: #000000;
    text-align: left;
    text-transform: capitalize;

    &:hover {
      background-color: #e5e5e5;
    }
  }
`;

export const LabelError = styled.div`
  margin-block-start: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.3;
  color: ${Color.red};
`;
