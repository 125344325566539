import { combineReducers } from 'redux';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import dpadpeDataMenager from './dpadpeDataMenager';
// import dpadpe from './dpadpe';
import dpadpe from './dpadpe/reducer';
import enrollmentsAuth from './enrollmentsAuth/reducer';
import enrollmentsConsumptionHistory from './enrollmentsConsumptionHistory/reducer';
import enrollmentsNegativeCertificates from './enrollmentsNegativeCertificates/reducer';
import enrollmentsInvoices from './enrollmentsInvoices/reducer';
import enrollmentsUsers from './enrollmentsUsers/reducer';
import enrollmentsPayment from './enrollmentsPayment/reducer';
import enrollmentsLoginCredentials from './enrollmentsLoginCredentials/reducer';
import enrollmentsCards from './enrollmentsCards/reducer';
import subscription from './subscription/reducer';
import payment from './payment/reducer';
import plans from './plans/reducer';
import enrollmentsAlerts from './enrollmentsAlerts/reducer';
import automaticDebits from './automaticDebits/reducer';
import outsides from './outsides/reducer';
import outsidesCredentials from './outsidesCredentials/reducer';
import toggles from './toggles/reducer';
import togglesMe from './togglesMe/reducer';
import orderServices from './orderServices/reducer';
import socialTariff from './socialTariff/reducer';
import customerObligation from './customerObligation/reducer';
import enrollmentsUsersNotClient from './enrollmentsUsersNotClient/reducer';
import propertyRegistration from './propertyRegistration/reducer';
import enrollmentsSubscribes from './enrollmentsSubscribes/reducer';
import notifications from './notifications/reducer';
import unavailableService from './unavailableService/reducer';
import cookies from './cookies/reducer';
import negotiation from './negotiation/reducer';
import { apiCache } from '../cache/apiCache';

export default () =>
  combineReducers({
    dpadpeDataMenager,
    dpadpe,
    enrollmentsAuth,
    enrollmentsConsumptionHistory,
    enrollmentsNegativeCertificates,
    enrollmentsInvoices,
    enrollmentsUsers,
    enrollmentsPayment,
    enrollmentsLoginCredentials: persistReducer(
      {
        key: 'enrollmentsLoginCredentials',
        storage,
      },
      enrollmentsLoginCredentials,
    ),
    enrollmentsCards,
    subscription,
    payment,
    plans,
    enrollmentsAlerts,
    automaticDebits,
    outsides,
    outsidesCredentials: persistReducer(
      {
        key: 'outsidesCredentials',
        storage,
      },
      outsidesCredentials,
    ),
    toggles,
    togglesMe,
    orderServices,
    socialTariff,
    customerObligation,
    enrollmentsUsersNotClient,
    propertyRegistration,
    enrollmentsSubscribes,
    notifications,
    unavailableService,
    cookies: persistReducer(
      {
        key: 'cookies',
        storage,
      },
      cookies,
    ),
    negotiation,
    [apiCache.reducerPath]: apiCache.reducer,
  });
