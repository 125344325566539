import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Main, Card, ContainerBreadCrumb, ContainerMaturityDay } from './styles';
import { Margin } from '~/components/Margin';
import { InputSelect } from '~/components/InputSelect';
import { ButtonText } from '~/components/ButtonText';
import Modal from '~/components/Modal';
import ModalError from '~/components/ModalError';
import { Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { IState } from '~/store/modules/types';
import { BreadCrumb } from '~/components/BreadCrumb';
import {
  getEnrollmentsInvoicesMaturityDaysAlternateRequest,
  getEnrollmentsInvoicesMaturityDaysRequest,
  putEnrollmentsInvoicesMaturityDaysAlternateRequest,
  putErrorCloseMaturityDaysAlternate,
} from '~/store/modules/enrollmentsInvoices/actions';
import { InvoiceChangeDueDateInfo } from './InvoiceChangeDueDateInfo';
import { checkToggle } from '~/utils/toggles';
import LoadingComponent from '~/components/Loading';
import { AppDispatch } from '~/store';

interface IExpirationDaysAvailable {
  label?: string;
  value?: number;
}

const InvoiceChangeDueDate: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const expirationDaysAvailable = useSelector(
    (item: IState) => item.enrollmentsInvoices.dataMaturityDaysAlternate,
  );
  const maturityDay = useSelector(
    (item: IState) => item.enrollmentsInvoices.dataMaturityDays?.day,
  );
  const loading = useSelector(
    (item: IState) => item.enrollmentsInvoices.loadingPutMaturityDaysAlternate,
  );
  const loadingMaturity = useSelector(
    (item: IState) => item.enrollmentsInvoices.loadingMaturityDays,
  );
  const loadingMaturityDaysAlternate = useSelector(
    (item: IState) => item.enrollmentsInvoices.loadingMaturityDaysAlternate,
  );
  const error = useSelector(
    (item: IState) => item.enrollmentsInvoices.errorPutMaturityDaysAlternate,
  );
  const toggles = useSelector((item: IState) => item.togglesMe.data);

  const [expirationDays, setExpirationDays] = useState<IExpirationDaysAvailable[]>(
    [],
  );
  const [maturityDayState, setMaturityDayState] = useState<string>('');
  const [changeDatePaymentRio, setChangeDatePaymenRio] = useState<boolean>(false);
  const [changeDatePaymentPaty, setChangeDatePaymenPaty] = useState<boolean>(false);
  const [changeDatePaymentMiguel, setChangeDatePaymenMiguel] =
    useState<boolean>(false);

  const verifyToggles = async () => {
    setChangeDatePaymenRio(
      await checkToggle(toggles, 'ALTERACAO_DATA_PAGAMENTO_RIO'),
    );
    setChangeDatePaymenPaty(
      await checkToggle(toggles, 'ALTERACAO_DATA_PAGAMENTO_PATY'),
    );
    setChangeDatePaymenMiguel(
      await checkToggle(toggles, 'ALTERACAO_DATA_PAGAMENTO_MIGUEL'),
    );
  };

  useEffect(() => {
    verifyToggles();
  }, [toggles]);

  useEffect(() => {
    const array: IExpirationDaysAvailable[] = [
      {
        label: maturityDay?.toString(),
        value: maturityDay,
      },
    ];

    if (expirationDaysAvailable) {
      for (let index = 0; index < expirationDaysAvailable?.length; index++) {
        if (expirationDaysAvailable[index] !== maturityDay) {
          array.push({
            label: expirationDaysAvailable[index].toString(),
            value: expirationDaysAvailable[index],
          });
        }
      }
    }
    setExpirationDays(array || []);
  }, [loadingMaturity, loadingMaturityDaysAlternate]);

  useEffect(() => {
    maturityDay && setMaturityDayState(maturityDay.toString());
  }, [maturityDay]);

  useEffect(() => {
    dispatch(getEnrollmentsInvoicesMaturityDaysRequest());
    dispatch(getEnrollmentsInvoicesMaturityDaysAlternateRequest());
  }, []);

  return (
    <Main>
      {(loadingMaturity || loadingMaturityDaysAlternate) && (
        <LoadingComponent labelWait="Carregando..." />
      )}
      <Modal open={!!(error && error.state)} type="error" title="Ops!" v3>
        <ModalError onClose={() => dispatch(putErrorCloseMaturityDaysAlternate())} />
      </Modal>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Faturas',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Card>
        <h1>Alterar vencimento</h1>
        <ContainerMaturityDay>
          <p>O dia {maturityDay} é o vencimento atual</p>
        </ContainerMaturityDay>
        <p>Escolha um novo dia de vencimento para suas próximas faturas.</p>
        <Margin height={24} />
        {(changeDatePaymentRio ||
          changeDatePaymentPaty ||
          changeDatePaymentMiguel) && (
          <>
            <InvoiceChangeDueDateInfo
              phone={
                changeDatePaymentRio
                  ? '0800 400 0509'
                  : changeDatePaymentPaty
                    ? '0800 400 0510'
                    : '0800 400 0511'
              }
            />
            <Margin height={24} />
          </>
        )}
        <div className="container-input">
          <InputSelect
            label="Vencimento"
            name="maturity"
            values={expirationDays}
            onChange={(ev) => setMaturityDayState(ev.target.value)}
            errorPosition="bottom"
          />
        </div>
        <Margin height={24} />
        <ButtonText
          text="Salvar"
          typeBtn="content"
          Color={Color.white}
          backgroundColor={
            Number(maturityDay) !== Number(maturityDayState)
              ? Color.green
              : Color.grayLightSeconde
          }
          onClick={() => {
            if (Number(maturityDay) !== Number(maturityDayState) && !loading) {
              dispatch(
                putEnrollmentsInvoicesMaturityDaysAlternateRequest(
                  Number(maturityDayState),
                ),
              );
            }
          }}
          loading={loading}
        />
        <Margin height={24} />
        <ButtonText
          text="Voltar"
          typeBtn="content"
          backgroundColor={Color.green}
          onClick={() => Navigation.goBack()}
        />
      </Card>
    </Main>
  );
};

export default InvoiceChangeDueDate;
