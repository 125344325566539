import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import TitleStep from '../TitleStep';
import { SenFiles, Container, Row, Label, ContainerFile } from './styles';
import { Margin } from '~/components/Margin';
import DropZone from '~/components/DropZone';
import { IState } from '~/store/modules/types';
import { Link } from 'react-router-dom';
import CarimboCPAE from '../../carimbos/carimbo_CPAE.dwg';
import { IPropsForm } from '../typesForm';
import { ErrorsText } from '../InitialStep/styles';
import { CpaeLabelsEnum } from '~/enums/cpaeEnum';
import {
  DpaDpeLocationsEnum,
  DpaDpeLocationsLabelEnum,
  DpaDpeLocationsLabelMap,
} from '~/enums/dpadpeEnum';

interface Props extends IPropsForm {
  setFieldValue?: any;
  submit?: boolean;
}

const Anexos: FC<Props> = ({ setFieldValue, errors, submit }) => {
  const caseDetail = useSelector((item: IState) => item.dpadpe.caseDetail);
  const dpadpeLocationData = useSelector(
    (item: IState) => item.dpadpe.dpadpeLocation,
  );

  const dpadpeLocation = caseDetail?.selectedCase
    ? DpaDpeLocationsLabelMap[caseDetail?.enterprise?.city] ||
      DpaDpeLocationsLabelMap[DpaDpeLocationsLabelEnum.RIO]
    : dpadpeLocationData;

  const handleUpload = (key: string, file: File | null) => {
    if (!file) {
      return setFieldValue(key, null);
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64String = String(reader.result)
        .replace('data:', '')
        .replace(/^.+,/, '');

      setFieldValue(key, {
        filename: file.name,
        filesize: file.size,
        filedata: base64String,
      });
    };
    return null;
  };

  return (
    <>
      {caseDetail?.selectedCase && <Margin height={41} />}
      <div
        style={{
          display:
            (caseDetail?.attachments?.length > 0 &&
              caseDetail?.attachments[0]?.key) ||
            (!!caseDetail?.selectedCase &&
              caseDetail.status === `Revisar documentos`) ||
            !caseDetail?.selectedCase
              ? 'inherit'
              : 'none',
        }}
      >
        <TitleStep title="Anexos" />
        <Container style={{ padding: '41px 80px' }}>
          <div style={{ width: '100%' }}>
            {caseDetail?.selectedCase &&
            caseDetail?.status !== `Revisar documentos` ? (
              caseDetail?.attachments?.map(
                (attachments: any) =>
                  attachments?.url && (
                    <>
                      <Row key={attachments?.key}>
                        <SenFiles>
                          <ContainerFile
                            href={attachments?.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <p>
                              {`${CpaeLabelsEnum[attachments?.label]}${
                                attachments?.label ===
                                'location_and_activity_analysis'
                                  ? ''
                                  : '*'
                              }` || ''}
                            </p>
                          </ContainerFile>
                        </SenFiles>
                      </Row>
                      <Margin height={14} />
                    </>
                  ),
              )
            ) : (
              <>
                <Row>
                  <SenFiles>
                    <DropZone
                      v3
                      trashIcon
                      disabled={
                        !!caseDetail?.selectedCase &&
                        caseDetail?.status !== `Revisar documentos`
                      }
                      infoFileName="Documento de identificação*"
                      name="identificationDocument"
                      onFileUploaded={(file: File | null) =>
                        handleUpload('identificationDocument', file)
                      }
                    />
                  </SenFiles>

                  <Label>
                    RG, CNH ou Passaporte. Documentos devem ser anexados em formato
                    PDF.
                  </Label>
                </Row>
                {errors.identificationDocument && submit && (
                  <ErrorsText>{errors.identificationDocument}</ErrorsText>
                )}

                <Margin height={14} />

                <Row>
                  <SenFiles>
                    <DropZone
                      v3
                      trashIcon
                      disabled={
                        !!caseDetail?.selectedCase &&
                        caseDetail?.status !== `Revisar documentos`
                      }
                      infoFileName="Planta de localização*"
                      name="locationPlan"
                      onFileUploaded={(file: File | null) =>
                        handleUpload('locationPlan', file)
                      }
                    />
                  </SenFiles>
                  <Label>
                    Arquivos devem ser anexados em formato PDF e com Carimbo{' '}
                    <Link to={CarimboCPAE} target="_blank" download>
                      padrão Iguá.
                    </Link>{' '}
                    (clique para o download)
                  </Label>
                </Row>
                {errors.locationPlan && submit && (
                  <ErrorsText>{errors.locationPlan}</ErrorsText>
                )}

                <Margin height={14} />

                <Row>
                  <SenFiles>
                    <DropZone
                      v3
                      trashIcon
                      disabled={
                        !!caseDetail?.selectedCase &&
                        caseDetail?.status !== `Revisar documentos`
                      }
                      infoFileName="Planta de situação*"
                      name="situationPlan"
                      onFileUploaded={(file: File | null) =>
                        handleUpload('situationPlan', file)
                      }
                    />
                  </SenFiles>
                  <Label>
                    Arquivos devem ser anexados em formato PDF e com Carimbo{' '}
                    <Link to={CarimboCPAE} target="_blank" download>
                      padrão Iguá.
                    </Link>{' '}
                    (clique para o download)
                  </Label>
                </Row>
                {errors.situationPlan && submit && (
                  <ErrorsText>{errors.situationPlan}</ErrorsText>
                )}

                {dpadpeLocation === DpaDpeLocationsEnum.CUIABA && (
                  <>
                    <Margin height={14} />
                    <Row>
                      <SenFiles>
                        <DropZone
                          v3
                          trashIcon
                          disabled={
                            !!caseDetail?.selectedCase &&
                            caseDetail?.status !== `Revisar documentos`
                          }
                          infoFileName="Análise de localização e atividade"
                          name="locationAndActivityAnalysis"
                          onFileUploaded={(file: File | null) =>
                            handleUpload('locationAndActivityAnalysis', file)
                          }
                        />
                      </SenFiles>
                      <Label>
                        <div>
                          Obrigatório para empreendimentos acima de 99 economias.
                          Documentos devem ser anexados em formato PDF.
                        </div>
                      </Label>
                    </Row>
                  </>
                )}
                {dpadpeLocation === DpaDpeLocationsEnum.CUIABA &&
                  errors.locationAndActivityAnalysis &&
                  submit && (
                    <ErrorsText>{errors.locationAndActivityAnalysis}</ErrorsText>
                  )}
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Anexos;
