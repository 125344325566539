import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0;
  margin: 0 auto;
  padding: 0;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 48px);
    padding: 24px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: calc(100% - 64px);
    max-width: 1170px;
    padding: 32px;
  }
`;

export const ContentCards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    gap: 24px;
  }
`;

export const MainCard = styled.div`
  width: 100%;
  border: 1px solid #ebeced;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const ContainerTitle = styled.section`
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 12px;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0 24px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) and (max-width: ${Breakpoints
      .laptop.max}px) {
    height: 64px;
  }
`;

export const TitleText = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #202022;
`;

export const ContainerInfo = styled.section`
  background: #f5fcfd;
  border-top: 1px solid #ebeced;
  padding: 40px 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  z-index: 1;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    padding: 24px;
  }
`;

export const ContainerMainBackgroundIcon = styled.div`
  position: absolute;
  right: 0;
  z-index: -1;
  display: none;

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    display: inherit;
  }
`;

export const DescriptionText = styled.p`
  max-width: 550px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #202022;
`;

export const InfoEnrollmentButton = styled.button`
  width: fit-content;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #32a2b4;
  background: none;
  border: none;
  cursor: pointer;
  margin-bottom: 24px;
`;

export const LinkRegistrationButton = styled.button`
  width: 163px;
  height: 40px;
  background: #32a2b4;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #ffffff;
`;

export const ContainerServices = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${Breakpoints.laptop.min}px) and (max-width: ${Breakpoints
      .laptop.max}px) {
    gap: 0;
  }

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    flex-direction: row;
    gap: 16px;
  }
`;

export const RowContainerServices = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${Breakpoints.laptop.min}px) and (max-width: ${Breakpoints
      .laptop.max}px) {
    gap: 0;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    flex-direction: row;
    gap: 16px;
  }
`;

export const ServiceBox = styled.span`
  width: calc(100px - 32px);
  height: 96px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #5fc3d3;
  padding: 0 16px;

  @media (min-width: ${Breakpoints.laptop.min}px) and (max-width: ${Breakpoints
      .laptop.max}px) {
    width: 100%;
    max-width: 175px;
    height: 48px;
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 0;
    padding: 0 16px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    width: calc(104px - 32px);
  }
`;

export const ContainerCards = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    gap: 24px;
  }
`;

export const Card = styled.div`
  min-height: calc(236px - 80px);
  border: 1px solid #ebeced;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px 12px;
  position: relative;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    min-height: calc(270px - 48px);
    padding: 24px;
    border-radius: 8px;
  }
`;

export const SubTitleText = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #202022;
`;

export const ContainerCardBackgroundIcon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
`;

export const DescriptionButton = styled.button`
  width: fit-content;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #32a2b4;
  background: none;
  border: none;
  cursor: pointer;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    margin-bottom: 24px;
  }
`;
