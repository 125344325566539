import React, { FC, useEffect, useState } from 'react';
import creditCardType from 'credit-card-type';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import {
  Main,
  Card,
  ContainerBreadCrumb,
  ContainerButtons,
  Border,
  ContainerBorder,
  ContainerAmount,
  ContainerPayment,
  BoxDivider,
  ContainerCardTemplate,
  LabelPaymentMethods,
  ContainerPix,
  ContentSinglePayment,
  AlertFraudBox,
  PaymentButton,
  ContainerSinglePayment,
  ContainerQrCodePix,
  ContainerWarning,
} from './styles';
import CreditCardTemplate from './CardTemplate';

import { ButtonText } from '~/components/ButtonText';
import { BreadCrumb } from '~/components/BreadCrumb';
import { Input } from '~/components/Input';
import { InputSelect } from '~/components/InputSelect';
import { Margin } from '~/components/Margin';
import Modal from '~/components/Modal';
import { Breakpoints, Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import formatter from '~/utils/formatter';

import { IState } from '~/store/modules/types';
import { CopyCode } from './CopyCode';
import { DescriptionPayment } from './DescriptionPayment';
import LoadingComponent from '~/components/Loading';
import { PaymentMethods } from './PaymentMethods';
import { QrCode } from './QrCode';
import ModalError from './ModalError';
import { checkToggle } from '~/utils/toggles';
import AlertPixFraud from '../AlertPixFraud';
import WarningBox from '~/components/WarningBox';
import { PaymentIcon } from './icons';
import { Dialog } from '../Dialog';
import ModalPaymentUnavailable, {
  ModalPaymentUnavailableTypeInfo,
} from './ModalPaymentUnavailable';
import { IErrors, IInstallment, IPaymentProps } from './types';
import {
  getPaymentReceiptCloseError,
  postPaymentCloseError,
  postPaymentRequest,
} from '~/store/modules/payment/actions';
import ModalNegotiationSuccess from './ModalNegotiationSuccess';
import { BarCode } from './BarCode';
import ModalDoublePayment from './ModalDoublePayment';
import ModalNegotiationCopy from './ModalNegotiationCopy';
import { RouteName } from '~/routes/Routes.name';
import { AppDispatch } from '~/store';

const Payment: FC<IPaymentProps> = ({
  links,
  loading,
  loadingLabel,
  isAuthenticated,
  paymentUnavailable,
  typePayment,
  paymentData,
  handlePaymentGroup,
  handleDownloadBillet,
  handleBackNavigation,
  operation,
  buttonBilletDisabled,
  showDuplicateModal,
  isNegotiation,
  handleOnClickMetric,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });
  const isMobileTablet = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const paymentLoading = useSelector((item: IState) => item.payment.loading);
  const successNegotation = useSelector(
    (item: IState) => item.payment.successNegotation,
  );
  const error = useSelector((item: IState) => item.payment.error);
  const toggles = useSelector((item: IState) => item.togglesMe.data);
  const infoGroup = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.data?.info,
  );
  const token = useSelector((state: IState) => state.enrollmentsAuth.token);

  const [openPaymentUnavailable, setOpenPaymentUnavailable] =
    useState<boolean>(false);
  const [paymentUnavailableTypeInfo, setPaymentUnavailableTypeInfo] =
    useState<ModalPaymentUnavailableTypeInfo>('credit_card');
  const [installments, setInstallments] = useState<IInstallment[]>([]);
  const [dataPayment, setDataPayment] = useState<any>({
    installment: '',
    cardNumber: '',
    cardHolder: '',
    validity: '',
    securityCode: '',
  });
  const [brandCard, setBrandCard] = useState<string>('');
  const [errors, setErrors] = useState<IErrors>({
    number: {
      error: false,
      message: 'Bandeira não aceita',
    },
    validity: {
      error: false,
      message: 'Validade do cartão inválida',
    },
  });
  const [disabled, setDisabled] = useState<boolean>(true);
  const [codeVisible, setCodeVisible] = useState<boolean>(false);
  const [typePaymentSelected, setTypePaymentSelected] = useState<
    'credit_card' | 'pix' | 'billet'
  >(paymentUnavailable?.pix && paymentUnavailable?.billet ? 'credit_card' : 'pix');
  const [groupPayment, setGroupPayment] = useState<boolean>(true);
  const [openDoublePayment, setOpenDoublePayment] = useState<boolean>(false);
  const [openCopy, setOpenCopy] = useState<boolean>(false);

  const hasAlert = !!(operation?.company_name && operation?.cnp);
  const showInfoGroup = typePayment === 'simplified_invoice';

  const openModalError = error && error?.state;

  useEffect(() => {
    dispatch(postPaymentCloseError());
    dispatch(getPaymentReceiptCloseError());

    const array: IInstallment[] = [
      {
        label: 'Selecione',
        value: undefined,
      },
    ];

    for (
      let index = 0;
      index < paymentData?.possible_quantity_installments;
      index++
    ) {
      array.push({
        label: `${index + 1} x sem juros`,
        value: index + 1,
      });
    }
    setInstallments(array || []);
  }, []);

  const verifyToggles = async () => {
    setGroupPayment(await checkToggle(toggles, 'PAGAMENTO_AGRUPADO'));
  };

  useEffect(() => {
    verifyToggles();
  }, [toggles]);

  const ALLOWED_CARDS = [
    {
      brand: null,
      issuer: 'default',
    },
    {
      brand: 'Master',
      issuer: 'mastercard',
    },
    {
      brand: 'Visa',
      issuer: 'visa',
    },
    {
      brand: 'Hiper',
      issuer: 'hipercard',
    },
    {
      brand: 'Elo',
      issuer: 'elo',
    },
  ];

  const findBrand = () => {
    let brandType = creditCardType(
      (dataPayment?.cardNumber || '').replace(/\D/g, ''),
    ) as any;

    if (brandType?.length > 1) brandType = [{ type: 'undefined' }];
    else if (brandType?.length >= 0) brandType = brandType[0];

    const brand = ALLOWED_CARDS.find(
      (itemBrand) => itemBrand?.issuer === brandType?.type,
    );

    if (brand?.brand) setBrandCard(brand?.issuer);
    else setBrandCard('');
  };

  useEffect(() => {
    findBrand();
  }, [dataPayment?.cardNumber]);

  useEffect(() => {
    if (
      dataPayment?.installment &&
      dataPayment?.cardNumber &&
      dataPayment?.cardHolder &&
      dataPayment?.validity &&
      dataPayment?.securityCode &&
      !errors?.number?.error &&
      !errors?.validity?.error
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [dataPayment, errors]);

  const validateCardNumber = () => {
    if (
      (dataPayment?.cardNumber && dataPayment?.cardNumber?.length < 19) ||
      (dataPayment?.cardNumber && dataPayment?.cardNumber?.length > 0 && !brandCard)
    ) {
      setErrors(({ number, validity }: any) => ({
        number: { ...number, error: true },
        validity,
      }));

      return false;
    }
    setErrors(({ number, validity }: any) => ({
      number: { ...number, error: false },
      validity,
    }));

    return true;
  };

  const validateCardValidity = () => {
    if (dataPayment?.validity && dataPayment?.validity?.length < 7) {
      setErrors(({ number, validity }: any) => ({
        number,
        validity: { ...validity, error: true },
      }));

      return false;
    }
    setErrors(({ number, validity }: any) => ({
      number,
      validity: { ...validity, error: false },
    }));

    return true;
  };

  const backNavigation = () =>
    handleBackNavigation ? handleBackNavigation() : Navigation.goBack();

  const errorTitle = () => {
    switch (error?.message) {
      case 'INVALID_DATA':
        return 'Dados inválidos';
      case 'UNAUTHORIZED_TRANSACTION':
        return 'Transação não autorizada';
      case 'UNAUTHORIZED_TRANSACTION_TRY_AGAIN_LATER':
        return 'Transação não concluída';
      case 'UNAUTHORIZED_TRANSACTION_CONTACT_THE_OPERATOR':
        return 'Transação não concluída';
      default:
        return 'Transação não concluída';
    }
  };
  const closeModalPaymentUnavailable = () =>
    setOpenPaymentUnavailable(!openPaymentUnavailable);

  const handlePayment = () => {
    if (disabled || loading) return;

    dispatch(
      postPaymentRequest({
        invoices: paymentData?.invoices,
        charges: paymentData?.charges,
        debtNegotiation: paymentData?.debtNegotiation,
        payment: {
          paymentCardNumber: formatter.deleteSpaces(dataPayment?.cardNumber),
          paymentCardHolder: dataPayment?.cardHolder,
          paymentExpirationDate: dataPayment?.validity,
          paymentSecurityCode: dataPayment?.securityCode,
          paymentBrand: brandCard,
          installments: dataPayment?.installment,
        },
        routeInvoiceReceipt: paymentData?.routeInvoiceReceipt,
        isAuthenticated: isAuthenticated || false,
        isNegotiation: isNegotiation || false,
      }),
    );
  };

  const handleOnClose = () => {
    Navigation.navigate(token ? RouteName.HOME : RouteName.LANDING);
    dispatch(postPaymentCloseError());
  };

  const handleOnCloseDoublePayment = () => setOpenDoublePayment(false);

  const handleOnCloseCopy = () => setOpenCopy(false);

  return (
    <Main>
      {(paymentLoading || loading) && (
        <LoadingComponent labelWait={loadingLabel || 'Processando...'} />
      )}
      {openModalError && (
        <Modal open={openModalError} type="error" title={errorTitle()}>
          <ModalError />
        </Modal>
      )}
      <Dialog
        open={openDoublePayment}
        onClose={handleOnCloseDoublePayment}
        fullScreen
      >
        <ModalDoublePayment
          onClose={handleOnCloseDoublePayment}
          onConfirmPayment={handlePayment}
          showDuplicateModal={showDuplicateModal}
          installment={dataPayment?.installment}
        />
      </Dialog>
      <Dialog
        open={(openCopy && isNegotiation) || false}
        onClose={handleOnCloseCopy}
        fullScreen
      >
        <ModalNegotiationCopy onClose={handleOnCloseCopy} />
      </Dialog>
      <Dialog open={successNegotation} onClose={handleOnClose} fullScreen>
        <ModalNegotiationSuccess onClose={handleOnClose} />
      </Dialog>
      <Dialog open={openPaymentUnavailable} onClose={closeModalPaymentUnavailable}>
        <ModalPaymentUnavailable
          onClose={closeModalPaymentUnavailable}
          typeInfo={paymentUnavailableTypeInfo}
        />
      </Dialog>
      <ContainerBreadCrumb>
        <BreadCrumb links={links} />
      </ContainerBreadCrumb>
      <Card>
        <h1>Pagamento de fatura</h1>
        <Margin height={24} />
        <ContainerBorder>
          <Border />
          <h2>Dados de pagamento</h2>
          <Border />
        </ContainerBorder>
        <Margin height={24} />
        <LabelPaymentMethods>Selecione a forma de pagamento:</LabelPaymentMethods>
        <PaymentMethods
          type={typePaymentSelected}
          setType={setTypePaymentSelected}
          setPaymentUnavailableTypeInfo={setPaymentUnavailableTypeInfo}
          paymentUnavailable={paymentUnavailable}
          setOpenPaymentUnavailable={setOpenPaymentUnavailable}
        />
        <ContainerAmount>
          <p>Valor total: {formatter.formatCurrency(paymentData?.amount)}</p>
        </ContainerAmount>
        <Margin height={24} />
        {typePaymentSelected === 'credit_card' && (
          <>
            {infoGroup && infoGroup?.opened > 1 && groupPayment && showInfoGroup && (
              <ContentSinglePayment>
                <ContainerSinglePayment>
                  <div>
                    <p>Não perca essa chance!</p>
                    <p>
                      Você ainda pode quitar todos seus débitos em apenas uma
                      transação e <br /> até 12X sem juros no cartão de crédito.
                    </p>
                  </div>
                  <PaymentButton type="button" onClick={handlePaymentGroup}>
                    Pagamento único
                    <PaymentIcon />
                  </PaymentButton>
                </ContainerSinglePayment>
              </ContentSinglePayment>
            )}
            <ContainerPayment>
              <BoxDivider>
                <ContainerCardTemplate>
                  <CreditCardTemplate
                    holder={dataPayment?.cardHolder}
                    cardNumber={dataPayment?.cardNumber}
                    securityCode={dataPayment?.securityCode}
                    validity={dataPayment?.validity}
                    issuer={brandCard}
                  />
                </ContainerCardTemplate>
              </BoxDivider>
              <BoxDivider>
                <div className="container-inputs">
                  <InputSelect
                    label="Parcelas"
                    name="installment"
                    values={installments}
                    onChange={(ev) => {
                      setDataPayment({
                        ...dataPayment,
                        installment: ev.target.value,
                      });
                    }}
                    errorPosition="bottom"
                  />
                </div>
                <div className="container-inputs">
                  <Input
                    label="Número do cartão"
                    name="cardNumber"
                    value={dataPayment?.cardNumber}
                    onChange={(ev) =>
                      setDataPayment({
                        ...dataPayment,
                        cardNumber: formatter.creditCard(ev.target.value) || '',
                      })
                    }
                    onBlur={validateCardNumber}
                    errorText={errors?.number?.message}
                    error={errors?.number?.error}
                    errorPosition="bottom"
                    maxLength={19}
                  />
                </div>
                <div className="container-inputs">
                  <Input
                    label="Nome do titular"
                    name="cardHolder"
                    value={dataPayment?.cardHolder}
                    onChange={(ev) =>
                      setDataPayment({
                        ...dataPayment,
                        cardHolder: ev.target.value,
                      })
                    }
                  />
                </div>
                <div className="container-inputs container-inputs-box">
                  <Input
                    label="Validade"
                    name="validity"
                    value={dataPayment?.validity}
                    onChange={(ev) =>
                      setDataPayment({
                        ...dataPayment,
                        validity: formatter.creditCardValidity(ev.target.value),
                      })
                    }
                    onBlur={validateCardValidity}
                    errorText={errors?.validity?.message}
                    error={errors?.validity?.error}
                    errorPosition="bottom"
                    placeholder="mm/aaaa"
                  />
                  <Input
                    label={isMobile ? 'Cód. de segurança' : 'Código de segurança'}
                    name="securityCode"
                    value={dataPayment?.securityCode}
                    onChange={(ev) =>
                      setDataPayment({
                        ...dataPayment,
                        securityCode: formatter.formatarNumber(ev.target.value),
                      })
                    }
                    errorPosition="bottom"
                    maxLength={3}
                    placeholder="000"
                    type={!codeVisible ? 'password' : 'text'}
                    iconPassword
                    passwordVisible={codeVisible}
                    setPasswordVisible={setCodeVisible}
                  />
                </div>
              </BoxDivider>
            </ContainerPayment>
          </>
        )}
        {typePaymentSelected === 'pix' && (
          <>
            <ContainerPix>
              <ContainerQrCodePix>
                <QrCode qr_code_key={paymentData?.qr_code_key || ''} />
                {!isMobileTablet && hasAlert && (
                  <AlertFraudBox>
                    <AlertPixFraud
                      company={operation?.company_name}
                      cnpj={operation?.cnp}
                    />
                  </AlertFraudBox>
                )}
              </ContainerQrCodePix>
              {isMobileTablet && hasAlert && (
                <AlertFraudBox>
                  <AlertPixFraud
                    company={operation?.company_name}
                    cnpj={operation?.cnp}
                  />
                </AlertFraudBox>
              )}
              <DescriptionPayment type={typePaymentSelected} />
            </ContainerPix>
            <CopyCode
              code_copy={paymentData?.qr_code_key || ''}
              type={typePaymentSelected}
              setOpenCopy={setOpenCopy}
              handleOnClickMetric={handleOnClickMetric}
            />
          </>
        )}
        {typePaymentSelected === 'billet' && (
          <>
            <DescriptionPayment type={typePaymentSelected} />
            <CopyCode
              code_copy={paymentData?.bar_code_number || ''}
              type={typePaymentSelected}
              setOpenCopy={setOpenCopy}
              handleOnClickMetric={handleOnClickMetric}
            />
            {paymentData?.bar_code_base64 && (
              <BarCode barcodeImageBase64={paymentData?.bar_code_base64} />
            )}
          </>
        )}
        <Margin height={24} />
        <ContainerWarning>
          <WarningBox label="Ao realizar o pagamento de faturas vencidas, os juros e multa serão calculados e cobrados na sua próxima fatura." />
        </ContainerWarning>
        <ContainerButtons>
          <ButtonText text="Voltar" typeBtn="content" onClick={backNavigation} />
          {typePaymentSelected === 'credit_card' ? (
            <ButtonText
              typeBtn="content"
              backgroundColor={disabled ? Color.gray : Color.blueSeconde}
              onClick={() => {
                if (showDuplicateModal) {
                  setOpenDoublePayment(true);
                  return;
                }

                handlePayment();
              }}
              loading={loading}
              nextIcon
            >
              Confirmar pagamento
            </ButtonText>
          ) : (
            <ButtonText
              typeBtn="content"
              backgroundColor={buttonBilletDisabled ? Color.gray : Color.blueSeconde}
              onClick={() => {
                if (buttonBilletDisabled) return;
                handleDownloadBillet && handleDownloadBillet(typePaymentSelected);
              }}
              loading={loading}
            >
              Baixar boleto de pagamento
            </ButtonText>
          )}
        </ContainerButtons>
      </Card>
    </Main>
  );
};

export default Payment;
