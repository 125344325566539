import { EndpointBuilder, BaseQueryFn } from '@reduxjs/toolkit/query';
import { IEnumCacheConfig } from '../enum';
import { IResponseEasyInvoices, IResponseDebts } from '../../modules/outsides/types';
import {
  IFetchOutsidesInvoicesList,
  IFetchOutsidesDebtsChargesList,
} from '../types';
import formatter from '~/utils/formatter';

export const outsidesEndpoints = (
  builder: EndpointBuilder<BaseQueryFn, IEnumCacheConfig, 'cache'>,
) => ({
  fetchOutsidesInvoicesList: builder.query<
    IResponseEasyInvoices,
    IFetchOutsidesInvoicesList
  >({
    query: ({ enrollmentId, cnp, params }) => ({
      url: `v3/outsides/v2/enrollments/${formatter.formatarNumber(enrollmentId)}/invoices`,
      method: 'GET',
      headers: {
        'x-cnp': formatter.formatarNumber(cnp),
      },
      params: {
        ...params,
      },
    }),
    providesTags: [IEnumCacheConfig.OUTSIDES_ENROLLMENTS_INVOICES_LIST],
  }),
  fetchOutsidesDebtsChargesList: builder.query<
    IResponseDebts,
    IFetchOutsidesDebtsChargesList
  >({
    query: ({ cnp, params }) => ({
      url: `v3/outsides/debts/charges`,
      method: 'GET',
      headers: {
        'x-cnp': formatter.formatarNumber(cnp),
      },
      params: {
        ...params,
      },
    }),
    providesTags: [IEnumCacheConfig.OUTSIDES_DEBTS_CHARGES_LIST],
  }),
});
