import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import configsAppJsonImport from '~/configs/app.config';
import { ButtonText } from '~/components/ButtonText';
import { Input } from '~/components/Input';
import { Margin } from '~/components/Margin';
import { RouteName } from '~/routes/Routes.name';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';
import { Color } from '~/styles';
import { Breakpoints } from '../../styles/breakpoints';
import {
  Main,
  DescriptionTextStyled,
  TitleTextStyled,
  CardForm,
  SubTitle,
  InputRadio,
  LabelRadio,
  ContainerInputRadio,
  TitleInputRadio,
  ContainerTermsStyled,
  TextTerm,
} from './styles';
import {
  getCloseErrorEnrollmentsUsersValidate,
  postEnrollmentsUsersSendSmsRegisterRequest,
} from '~/store/modules/enrollmentsUsers/actions';
import Navigation from '~/utils/Navigation';
import { checkCNPJ, checkCPF, checkCnp, validateEmail } from '~/utils/validation';
import { history } from '~/store';
import { ButtonLink } from '~/common';
import Checkbox from '~/components/CheckboxIcon';
import ReCAPTCHA from 'react-google-recaptcha';
import { AppDispatch } from '~/store';

const configsAppJson = configsAppJsonImport as any;

const RegisterNotClient: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dataRegister = history.location.state as any;

  const dispatch = useDispatch<AppDispatch>();

  const loading = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingValidateRegister,
  );
  const error = useSelector(
    (item: IState) => item.enrollmentsUsers.errorValidateRegister,
  );
  const [originalTypeClient] = useState<string>(
    dataRegister && formatter.formatarNumber(dataRegister?.cnp || '')?.length < 14
      ? 'PF'
      : 'PJ',
  );
  const [typeClient, setTypeClient] = useState<string>(
    dataRegister && formatter.formatarNumber(dataRegister?.cnp || '')?.length < 14
      ? 'PF'
      : 'PJ',
  );
  const [razaoSocial, setRazaoSocial] = useState<string>(
    dataRegister?.nameResponsible || '',
  );
  const [cpf, setCpf] = useState<string>(
    dataRegister &&
      dataRegister?.cnp &&
      formatter.formatarNumber(dataRegister?.cnp)?.length < 14
      ? dataRegister?.cnp
      : dataRegister?.documentNumberResponsible || '',
  );
  const [cnpj, setCnpj] = useState<string>(
    formatter.formatarNumber(dataRegister?.cnp)?.length >= 14
      ? dataRegister?.cnp
      : '',
  );
  const [name, setName] = useState<string>(dataRegister?.name || '');
  const [email, setEmail] = useState<string>(dataRegister?.email || '');
  const [phone, setPhone] = useState<string>(dataRegister?.phone || '');
  const [telephone, setTelephone] = useState<string>(dataRegister?.telephone || '');
  const [systemTerms, setSystemTerms] = useState<boolean>(
    dataRegister?.systemTerms || false,
  );
  const [productTerms, setProductTerms] = useState<boolean>(
    dataRegister?.productTerms || false,
  );
  const [enableSubmit, setEnableSubmit] = useState<boolean>(
    configsAppJson?.NODE_ENV !== 'PRD',
  );
  const [disabled, setDisabled] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [cpfError, setCpfError] = useState<boolean>(false);
  const [cnpjError, setCnpjError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<string>('');

  const validatePhone = () => {
    console.log('validatePhone');
    if (!telephone) return setPhoneError('');

    if (telephone.length < 14) {
      return setPhoneError('Telefone inválido');
    }
    return setPhoneError('');
  };

  const clearError = () => {
    dispatch(getCloseErrorEnrollmentsUsersValidate());
  };

  useEffect(() => {
    return () => clearError();
  }, []);

  useEffect(() => {
    if (error && error.state) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [error]);

  useEffect(() => {
    if (!enableSubmit) return setDisabled(true);

    const firstName = name.split(' ')[0];
    const lastName = name.substring(firstName.length + 1);

    if (typeClient === 'PF') {
      if (telephone && telephone.length < 14) return setDisabled(true);
      if (checkCnp(cpf) && name && firstName && lastName) return setDisabled(false);

      setDisabled(true);
    }

    if (telephone && telephone.length < 14) return setDisabled(true);

    if (
      checkCnp(cpf) &&
      checkCnp(cnpj) &&
      cpf &&
      name &&
      firstName &&
      lastName &&
      razaoSocial
    ) {
      return setDisabled(false);
    }

    return setDisabled(true);
  }, [
    cpf,
    cnpj,
    name,
    email,
    phone,
    telephone,
    razaoSocial,
    systemTerms,
    productTerms,
    enableSubmit,
    typeClient,
  ]);

  const handleContinue = async () => {
    if (disabled) return false;
    const firstName = name.split(' ')[0];
    const lastName = name.substring(firstName.length + 1);

    const data_pf = {
      clientType: dataRegister?.clientType,
      cnp: cpf.replace(/[\D]+/g, ''),
      name,
      documentType: 'CPF',
      phone,
      telephone,
      email,
      systemTerms: true,
      productTerms: true,
    };

    const data_cnpj = {
      clientType: dataRegister?.clientType,
      cnp: cnpj.replace(/[\D]+/g, ''),
      name,
      documentType: 'CNPJ',
      phone,
      telephone,
      email,
      systemTerms: true,
      productTerms: true,
      documentNumberResponsible: cpf,
      nameResponsible: razaoSocial,
      firstNameResponsible: firstName,
      lastNameResponsible: lastName,
    };

    const data = typeClient === 'PF' ? data_pf : data_cnpj;

    if (!email || !phone) {
      return dispatch(
        postEnrollmentsUsersSendSmsRegisterRequest(
          {
            cnp: dataRegister?.cnp || '',
            email: dataRegister?.email,
            phone: dataRegister?.phone,
            isEntrepreneur: false,
            isManager: false,
          },
          {
            ...data,
            method: dataRegister?.email ? 'email' : 'sms',
          },
        ),
      );
    }

    return Navigation.navigate(RouteName.REGISTERVERIFICATIONMETHOD, { ...data });
  };

  return (
    <Main>
      <CardForm>
        <Margin height={isMobile ? 38 : 59} />
        <TitleTextStyled>BEM VINDO!</TitleTextStyled>
        <DescriptionTextStyled>
          Para começar, preencha corretamente os <br /> dados a seguir:
        </DescriptionTextStyled>
        <Margin height={isMobile ? 12 : 28} />
        <TitleInputRadio>Quero me cadastrar como:</TitleInputRadio>
        <Margin height={isMobile ? 12 : 28} />

        <ContainerInputRadio>
          <div>
            <InputRadio
              name="inputRadio"
              checked={typeClient === 'PF'}
              onChange={(e) => setTypeClient(e.target.value)}
              value="PF"
            />
            <LabelRadio>Pessoa física</LabelRadio>
          </div>
          <div>
            <InputRadio
              name="inputRadio"
              checked={typeClient === 'PJ'}
              onChange={(e) => setTypeClient(e.target.value)}
              value="PJ"
            />
            <LabelRadio>Pessoa jurídica</LabelRadio>
          </div>
        </ContainerInputRadio>
        <Margin height={isMobile ? 12 : 28} />
        {typeClient === 'PJ' && (
          <>
            <SubTitle>
              <p>Dados da empresa </p>
              <hr />
            </SubTitle>
            <Margin height={isMobile ? 12 : 28} />
            <Input
              styleLabel={{ fontSize: '19px', fontWeight: 500 }}
              value={formatter.cnpjMask(cnpj)}
              onChange={(ev) => {
                setCnpj(formatter.cnpjMask(ev.target.value));

                if (!ev.target.value) return setCnpjError(false);

                const cnpIsvalid = checkCNPJ(ev.target.value.replace(/[^\d]+/g, ''));

                if (!cnpIsvalid) return setCnpjError(true);

                return setCnpjError(false);
              }}
              label="CNPJ*"
              error={cnpjError}
              errorText="CNPJ inválido"
              errorPosition="bottom"
              disabled={originalTypeClient === 'PJ'}
            />
            <Margin height={isMobile ? 12 : 24} />
            <Input
              styleLabel={{ fontSize: '19px', fontWeight: 500 }}
              value={razaoSocial}
              onChange={(ev) =>
                setRazaoSocial(ev.target.value.replace(/[^\D]/g, ''))
              }
              label="Razão social*"
              maxLength={50}
            />
            <Margin height={isMobile ? 12 : 34} />
          </>
        )}
        <SubTitle>
          <p>Dados do representante</p>
          <hr />
        </SubTitle>
        <Margin height={isMobile ? 12 : 28} />
        {typeClient === 'PJ' ? (
          <>
            <Input
              styleLabel={{ fontSize: '19px', fontWeight: 500 }}
              value={name}
              onChange={(ev) => setName(ev.target.value.replace(/[^\D]/g, ''))}
              label="Nome completo*"
            />
            <Margin height={isMobile ? 12 : 24} />
            <Input
              styleLabel={{ fontSize: '19px', fontWeight: 500 }}
              value={formatter.cpfMask(cpf)}
              onChange={(ev) => {
                setCpf(formatter.cpfMask(ev.target.value));

                if (!ev.target.value) return setCpfError(false);

                const cnpIsvalid = checkCPF(ev.target.value.replace(/[^\d]+/g, ''));

                if (!cnpIsvalid) return setCpfError(true);

                return setCpfError(false);
              }}
              label="CPF*"
              error={cpfError}
              errorText="CPF inválido."
              errorPosition="bottom"
              disabled={originalTypeClient === 'PF'}
            />
            <Margin height={isMobile ? 12 : 24} />{' '}
          </>
        ) : (
          <>
            <Input
              styleLabel={{ fontSize: '19px', fontWeight: 500 }}
              value={formatter.cpfMask(cpf)}
              onChange={(ev) => setCpf(formatter.cpfMask(ev.target.value))}
              label="CPF*"
              disabled={originalTypeClient === 'PF'}
            />
            <Margin height={isMobile ? 12 : 24} />
            <Input
              styleLabel={{ fontSize: '19px', fontWeight: 500 }}
              value={name}
              onChange={(ev) => setName(ev.target.value.replace(/[^\D]/g, ''))}
              label="Nome completo*"
            />
            <Margin height={isMobile ? 12 : 24} />
          </>
        )}

        <Input
          styleLabel={{ fontSize: '19px', fontWeight: 500 }}
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
          label="Email"
          disabled
        />
        <Margin height={isMobile ? 12 : 24} />
        <Input
          styleLabel={{ fontSize: '19px', fontWeight: 500 }}
          value={formatter.formatarPhone(phone)}
          onChange={(ev) => setPhone(formatter.formatarPhone(ev.target.value))}
          label="Celular"
          disabled
        />
        <Margin height={isMobile ? 12 : 24} />
        <Input
          styleLabel={{ fontSize: '19px', fontWeight: 500 }}
          onBlur={validatePhone}
          error={!!phoneError}
          errorText={phoneError}
          errorPosition="bottom"
          value={formatter.formatarTel(telephone)}
          onChange={(ev) => setTelephone(formatter.formatarTel(ev.target.value))}
          label="Telefone"
        />

        {configsAppJson?.NODE_ENV === 'PRD' && (
          <>
            <Margin height={24} />
            <div
              style={{
                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                margin: 'auto',
                width: 'min-content',
              }}
            >
              <ReCAPTCHA
                sitekey={configsAppJson?.GOOGLE_RECAPTCHA[configsAppJson?.NODE_ENV]}
                onErrored={() => setEnableSubmit(false)}
                onExpired={() => setEnableSubmit(false)}
                onChange={(j) => setEnableSubmit(!!j)}
                size="normal"
              />
            </div>
          </>
        )}
        <Margin height={isMobile ? 12 : 24} />

        <ContainerTermsStyled>
          <Checkbox
            checked={systemTerms}
            onChange={(e) => {
              setSystemTerms(e);
            }}
            style={{ marginTop: '2px' }}
          />
          <Margin width={1} />
          <TextTerm>
            Li o{' '}
            <a href="/trm1.pdf" target="_blank" rel="noreferrer">
              Termo de Adesão
            </a>{' '}
            para utilização dos sistemas do Grupo Iguá
          </TextTerm>
        </ContainerTermsStyled>

        <Margin height={10} />

        <ContainerTermsStyled>
          <Checkbox
            checked={productTerms}
            onChange={(e) => {
              setProductTerms(e);
            }}
            style={{ marginTop: '2px' }}
          />
          <Margin width={1} />
          <TextTerm>
            Li o{' '}
            <a href="/trm2.pdf" target="_blank" rel="noreferrer">
              Termo de Adesão
            </a>{' '}
            para oferecimento de produtos e serviços
          </TextTerm>
        </ContainerTermsStyled>

        <Margin height={24} />
        <ButtonText
          text="Continuar"
          typeBtn="content"
          Color={Color.white}
          backgroundColor={disabled ? Color.gray : Color.green}
          onClick={() => handleContinue()}
          loading={loading}
        />
        <Margin height={24} />
        <ButtonLink
          type="button"
          color="gray"
          onClick={() =>
            Navigation.navigate(RouteName.REGISTER, { ...dataRegister })
          }
          style={{ height: '48px' }}
        >
          Voltar
        </ButtonLink>
        <Margin height={isMobile ? 15 : 32} />
      </CardForm>
    </Main>
  );
};

export default RegisterNotClient;
