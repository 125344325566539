import React, { FC, useState } from 'react';

import { Margin } from '~/components/Margin';

import {
  Main,
  Card,
  ContainerBorder,
  Border,
  Content,
  ContainerBreadCrumb,
  ContainerDocuments,
  RowDocument,
  SenFiles,
  Label,
  ContainerButtons,
} from './styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import Modal from '~/components/Modal';
import ModalError from '~/components/ModalError';
import DropZone from './DropZone';
import convertFileToBase64 from '~/utils/convertBase64';
import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import {
  postErrorCloseSocialTariffAttachmentsRequest,
  postSocialTariffAttachmentsRequest,
} from '~/store/modules/socialTariff/actions';
import { BannerApp } from '~/components/BannerApp';
import { AppDispatch } from '~/store';

export interface IFile {
  label: string;
  note: string;
  file: File | null;
  name: string;
  base64: string;
}

const SocialTariffSolicitationCuiaba: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const enrollment = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.enrollment,
  );
  const loading = useSelector(
    (item: IState) => item.socialTariff.loadingAttachments,
  );
  const error = useSelector((item: IState) => item.socialTariff.errorAttachments);
  const [disabled, setDisabled] = useState<boolean>(true);

  const [files, setFiles] = useState<Array<IFile>>([
    {
      label: 'Documento de identificação*',
      note: 'Ex.: CPF; RG; CNH. Documentos devem ser anexados em formato PDF ou JPG.',
      file: null,
      name: 'identification_document',
      base64: '',
    },
    {
      label: 'CadÚnico atualizado*',
      note: 'Documentos devem ser anexados em formato PDF ou JPG.',
      file: null,
      name: 'cadunico_updated',
      base64: '',
    },
    {
      label: 'Conta de água*',
      note: 'A conta de água deve ser atualizada (últimos 90 dias). Documentos devem ser anexados em formato PDF ou JPG.',
      file: null,
      name: 'water_bill',
      base64: '',
    },
    {
      label: 'Conta de energia*',
      note: 'A conta de energia deve ser atualizada (últimos 90 dias). A conta de energia deve ser do mesmo titular da matricula iguá Documentos devem ser anexados em formato PDF ou JPG.',
      file: null,
      name: 'energy_bill',
      base64: '',
    },
  ]);

  return (
    <Main>
      <Modal open={!!(error && error.state)} type="error" title="Ops!" v3>
        <ModalError
          onClose={() => dispatch(postErrorCloseSocialTariffAttachmentsRequest())}
        />
      </Modal>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Tarifa social',
              active: false,
              onClick: () => Navigation.navigate(RouteName.SOCIALTARIFF),
            },
            {
              label: 'Solicitar tarifa social',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <div>
        <BannerApp app />
      </div>
      <Card>
        <Content>
          <h1>Solicitação de tarifa social</h1>
          <Margin height={44} />
        </Content>
        <ContainerBorder>
          <Border />
          <h2>Documentos necessários</h2>
          <Border />
        </ContainerBorder>
        <Margin height={14} />
        <Content>
          <p className="info">
            O responsável pela família deve apresentar os seguintes documentos, de
            acordo com as instruções ao lado de cada campo:
          </p>
          <Margin height={24} />
          <ContainerDocuments>
            {files.map((document, index: number) => (
              <RowDocument key={document.name}>
                <SenFiles>
                  <DropZone
                    disabled={!!document.file}
                    infoFileName={document.label}
                    name={document.label}
                    content={document.file || null}
                    // error={dataFormErrors.files[index].error}
                    onFileUploaded={async (file: File | null) => {
                      const array = [...files];

                      if (file) {
                        await convertFileToBase64(file, (base64: string) => {
                          array[index].file = file;
                          array[index].base64 = base64;
                        });
                      } else {
                        array[index].file = null;
                        array[index].base64 = '';
                      }

                      setTimeout(() => {
                        const emptyFiles = array.filter(
                          (document) => document.file === null,
                        );
                        if (emptyFiles.length > 0) {
                          setDisabled(true);
                        } else {
                          setDisabled(false);
                        }
                        setFiles(array);
                      }, 100);
                    }}
                  />
                </SenFiles>
                <Label
                  style={{
                    whiteSpace:
                      document.name === 'energy_bill' ? 'nowrap' : undefined,
                  }}
                >
                  {document.name === 'energy_bill' ? (
                    <>
                      A conta de energia deve ser atualizada (últimos 90 dias).
                      <br />A conta de energia deve ser do mesmo titular da matrícula
                      iguá
                      <br />
                      Documentos devem ser anexados em formato PDF ou JPG.
                    </>
                  ) : (
                    document.note
                  )}
                </Label>
              </RowDocument>
            ))}
          </ContainerDocuments>
          <ContainerButtons>
            <ButtonText
              text="Enviar solicitação"
              typeBtn="content"
              nextIcon
              backgroundColor={disabled ? Color.gray : Color.blueSeconde}
              loading={loading}
              onClick={() => {
                if (disabled || loading) return;
                if (files && enrollment) {
                  let fileObj: any = {};

                  files.map((file) => {
                    if (file.file) {
                      fileObj[file.name] = {
                        filename: file.file.name,
                        filedata: file.base64,
                      };
                    }
                  });

                  dispatch(
                    postSocialTariffAttachmentsRequest(
                      {
                        enrollment,
                        description: 'Solicitação de tarifa social Cuiabá',
                        files: fileObj,
                      },
                      true,
                    ),
                  );
                }
              }}
            />
          </ContainerButtons>
        </Content>
      </Card>
    </Main>
  );
};

export default SocialTariffSolicitationCuiaba;
