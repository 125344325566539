import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  Container,
  Border,
  ContainerSkeleton,
  Content,
  RowSkeleton,
} from './styles';
import { Breakpoints } from '~/styles';
import SkeletonCustom from '~/design_system/components/Skeleton';

const InvoiceSkeletons: FC = () => {
  const isLaptop = useMediaQuery({ minWidth: Breakpoints.laptop.min });

  const countSkeletons = 5;

  return Array.from({ length: countSkeletons }).map((_, index) => (
    <Container key={index}>
      <Border />
      <Content>
        {isLaptop ? (
          <>
            <ContainerSkeleton>
              <SkeletonCustom width={96} />
            </ContainerSkeleton>
            <ContainerSkeleton>
              <SkeletonCustom width={58} />
            </ContainerSkeleton>
            <ContainerSkeleton>
              <SkeletonCustom width={75} />
            </ContainerSkeleton>
            <ContainerSkeleton>
              <SkeletonCustom width={105} />
            </ContainerSkeleton>
            <ContainerSkeleton>
              <SkeletonCustom width={89} />
            </ContainerSkeleton>
          </>
        ) : (
          <>
            <ContainerSkeleton>
              <SkeletonCustom width={128} />
            </ContainerSkeleton>
            <ContainerSkeleton>
              <SkeletonCustom width={68} />
            </ContainerSkeleton>
            <ContainerSkeleton>
              <SkeletonCustom width={171} />
            </ContainerSkeleton>
            <RowSkeleton>
              <SkeletonCustom width={89} height={32} />
            </RowSkeleton>
          </>
        )}
      </Content>
    </Container>
  ));
};

export default InvoiceSkeletons;
