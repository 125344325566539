import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Margin } from '~/components/Margin';

import {
  Main,
  Card,
  ContainerBanner,
  ContainerIcons,
  ContainerCancelSubscribe,
  Content,
  ContentPlan,
  ContainerBreadCrumb,
  ContentSubscribe,
} from './styles';
import bannerSmartMeterImg from '~/assets/Images/banner-medicao-inteligente.png';
import { DigitalClientIcon, DailyHistoryIcon, ConsumptionAlertsIcon } from './icons';
import ContainerIcon, { IPropsIcon } from './ContainerIcons';
import { RouteName } from '~/routes/Routes.name';
import ContainerPlan from './ContainerPlan';
import { IState } from '~/store/modules/types';
import {
  deleteEnrollmentsSubscribesMeRequest,
  deleteErrorCloseEnrollmentsSubscribesMe,
} from '~/store/modules/enrollmentsSubscribes/actions';
import LoadingComponent from '~/components/Loading';
import Modal from '~/components/Modal';
import ModalError from '~/components/ModalError';
import ContainerSubscribe from './ContainerSubscribe';
import { ModalCancel } from './ModalCancel';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';
import bannerSmartMeterisMobileImg from './assets/banner-320.png';
import bannerSmartMeterisTabletImg from './assets/banner-480.png';
import bannerSmartMeterisLaptopImg from './assets/banner-769.png';
import bannerSmartMeterisDesktopLargeImg from './assets/banner-1200.png';
import { BreadCrumb } from '~/components/BreadCrumb';
import Navigation from '~/utils/Navigation';
import { SubscribesInfo } from './SubscribesInfo';
import { AppDispatch } from '~/store';

interface IGetDimensionType {
  isMobile?: boolean;
  isTablet?: boolean;
  isLaptop?: boolean;
  isDesktopLarge?: boolean;
}

const getImgDimensionType = (dimensionData: IGetDimensionType) => {
  if (dimensionData.isMobile) {
    return bannerSmartMeterisMobileImg;
  }
  if (dimensionData.isTablet) {
    return bannerSmartMeterisTabletImg;
  }
  if (dimensionData.isLaptop) {
    return bannerSmartMeterisLaptopImg;
  }

  return bannerSmartMeterisDesktopLargeImg;
};

const SmartMeterNew: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });
  const isTablet = useMediaQuery({ maxWidth: Breakpoints.tablet.max });
  const isLaptop = useMediaQuery({ maxWidth: Breakpoints.laptop.max });
  const isDesktopLarge = useMediaQuery({ maxWidth: Breakpoints.desktopLarge.max });

  const dispatch = useDispatch<AppDispatch>();

  const loading = useSelector((item: IState) => item?.enrollmentsSubscribes.loading);
  const loadingPlans = useSelector(
    (item: IState) => item?.enrollmentsSubscribes.loadingPlans,
  );
  const loadingDelete = useSelector(
    (item: IState) => item?.enrollmentsSubscribes.loadingDelete,
  );
  const loadingEnrollment = useSelector(
    (item: IState) => item?.enrollmentsSubscribes.loadingEnrollment,
  );
  const data = useSelector((item: IState) => item?.enrollmentsSubscribes.data);
  const dataEnrollment = useSelector(
    (item: IState) => item?.enrollmentsSubscribes.dataEnrollment,
  );
  const enrollment = useSelector(
    (item: IState) => item?.enrollmentsAuth.registrationCheck?.enrollment,
  );
  const enrollment_id = useSelector(
    (item: IState) => item?.enrollmentsAuth.registrationCheck?.enrollment,
  );
  const error = useSelector(
    (item: IState) => item?.enrollmentsSubscribes?.errorDelete,
  );
  const [openCancel, setOpenCancel] = useState<boolean>(false);

  const infoIcons: IPropsIcon[] = [
    {
      icon: <DigitalClientIcon />,
      title: 'Cliente digital',
      children: (
        <>
          {' '}
          Venha ser um cliente digital! aproveite todas as vantagens de um consumo
          consciente.{' '}
        </>
      ),
    },
    {
      icon: <DailyHistoryIcon />,
      title: 'Histórico diário',
      children: (
        <>
          Você poderá acompanhar seu{' '}
          <Link to={RouteName.DETAILEDHISTORY}>histórico detalhado</Link> diário nas
          plataformas WEB e App.
        </>
      ),
    },
    {
      icon: <ConsumptionAlertsIcon />,
      title: 'Alertas de consumo',
      children: (
        <>
          Ajuste seus <Link to={RouteName.DETAILEDHISTORY}>alertas de consumo</Link>{' '}
          e informaremos quando os gastos ultrapassarem o desejado!
        </>
      ),
    },
  ];

  // @deprecated
  // useEffect(() => {
  //   dispatch(getEnrollmentsSubscribesMeRequest());
  //   dispatch(getEnrollmentsSubscribesMeEnrollmentRequest(enrollment || ''));
  //   dispatch(getEnrollmentsSubscribesAllPlansRequest());
  //   dispatch(postErrorCloseEnrollmentsSubscribesMe());
  // }, []);

  return (
    <Main>
      {(loading || loadingPlans || loadingDelete || loadingEnrollment) && (
        <LoadingComponent
          labelWait={loadingDelete ? 'Cancelando...' : 'Carregando...'}
        />
      )}
      <Modal open={openCancel} type="info" title="Atenção!" v3>
        <ModalCancel
          onClose={() => setOpenCancel(false)}
          onSubmit={() => {
            ReactGA.event({
              category: 'Medição digital',
              action: 'Cancelar assinatura',
            });
            setOpenCancel(false);
            dispatch(
              deleteEnrollmentsSubscribesMeRequest(
                {
                  plan_id: data[0].plan.id,
                  enrollment_id: enrollment_id || '',
                },
                enrollment || '',
              ),
            );
          }}
        />
      </Modal>
      <Modal open={!!(error && error.state)} type="error" title="Ops!" v3>
        <ModalError
          onClose={() => {
            dispatch(deleteErrorCloseEnrollmentsSubscribesMe());
          }}
        />
      </Modal>
      <ContainerBanner>
        <img src={bannerSmartMeterImg} alt="medição inteligente" />
      </ContainerBanner>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Medição Digital',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Card>
        <img
          src={getImgDimensionType({
            isMobile,
            isTablet,
            isLaptop,
            isDesktopLarge,
          })}
          alt="medição inteligente"
        />
        <Content>
          <h1>Medição digital</h1>
          <Margin height={24} />
          <ContainerIcons>
            {infoIcons.map((item) => (
              <ContainerIcon key={item.title} icon={item.icon} title={item.title}>
                {item.children}
              </ContainerIcon>
            ))}
          </ContainerIcons>
          {data && data[0] ? (
            <ContentSubscribe>
              <ContainerSubscribe />
              <ContainerCancelSubscribe>
                <button type="button" onClick={() => setOpenCancel(true)}>
                  Solicitar o cancelamento da assinatura
                </button>
              </ContainerCancelSubscribe>
            </ContentSubscribe>
          ) : (
            <ContentPlan>
              {dataEnrollment && dataEnrollment?.deletedAt !== null ? (
                <>
                  <SubscribesInfo />
                  <ContainerPlan />
                </>
              ) : (
                <>
                  <ContainerSubscribe />
                  <ContainerPlan />
                </>
              )}
            </ContentPlan>
          )}
        </Content>
      </Card>
      <Margin height={16} />
    </Main>
  );
};

export default SmartMeterNew;
