import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';

import { Main } from './styles';

import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { SuccessSVG } from '~/assets/Icons';
import { putCloseSuccessUsersUpdatePasswordRecovery } from '~/store/modules/enrollmentsUsers/actions';
import { AppDispatch } from '~/store';

const ModalSuccess: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    ReactGA.event({
      category: 'Expiração de senha',
      action: '[Expiração de Senha][WEB] Senha cadastrada com sucesso',
    });
  }, []);

  return (
    <Main>
      <SuccessSVG />
      <h1>Senha alterada</h1>
      <p>Volte para o login para acessar o Digi Iguá!</p>
      <ButtonText
        backgroundColor={Color.blueSeconde}
        typeBtn="content"
        text="Ok"
        onClick={() => {
          dispatch(putCloseSuccessUsersUpdatePasswordRecovery());
          Navigation.navigate(RouteName.LOGIN);
        }}
      />
    </Main>
  );
};

export default ModalSuccess;
