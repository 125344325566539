import React, { FC, useEffect, useState } from 'react';
import DropZone from '../../../../components/DropZone';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import convertFileToBase64 from '~/utils/convertBase64';
import { Margin } from '~/components/Margin';
import { Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import ButtonBar from '../../../../components/ButtonBar';
import {
  Main,
  DescriptionTitle,
  ButtonInfo,
  ContainerGenerateCode,
  BoxGeneratedCode,
  ContainerTitle,
  SeparatorTitle,
  Title,
  ContentGenerateCode,
  Row,
  SenFiles,
  Label,
} from './styles';
import {
  getFileCodesPropertyRegistrationRequest,
  patchAttachmentPropertyRegistrationRequest,
  postCreateFileCodesPropertyRegistrationRequest,
  postUploadFilePropertyRegistrationRequest,
} from '~/store/modules/propertyRegistration/actions';
import ArrowUpSVG from '~/assets/Icons/arrowUp';
import StatusInfo from '../../../../components/StatusInfo';
import { AppDispatch } from '~/store';

const Sewage: FC = () => {
  const fileCodes = useSelector(
    (item: IState) => item.propertyRegistration.fileCodes,
  );
  const loadingUploadFile = useSelector(
    (item: IState) => item.propertyRegistration.loadingUploadFile,
  );

  const loadingItem = useSelector(
    (item: IState) => item.propertyRegistration.loadingItem,
  );

  const caseFCP = useSelector((item: IState) => item.propertyRegistration.dataItem);
  const caseId = useSelector(
    (item: IState) => item.propertyRegistration.dataItem?.id,
  );

  const [isArrayFilter, setIsArrayFilter] = useState(false);
  const [documentsProject, setDocumentsProject] = useState<any[]>([
    {
      key: 'CARRIED_OUT_DEPLETION_WORK_DESCRIPTIVE_MEMORIAL',
      label: 'Memorial descritivo da obra de esgotamento a ser executada*',
      isActive: false,
      file: null,
    },
    {
      key: 'CARRIED_OUT_DEPLETION_WORK_CALCULATION_MEMORIAL',
      label: 'Memorial de cálculo da obra de esgotamento a ser executada*',
      isActive: false,
      file: null,
    },
    {
      key: 'DEFINITIVE_INTERNAL_EXHAUSTION_PROJECT',
      label: 'Projeto de esgotamento interno definitivo*',
      isActive: false,
      file: null,
    },
  ]);

  const [documents, setDocuments] = useState<any[]>([
    {
      key: 'OWNER_IDENTIFICATION_DOCUMENT',
      label: 'Documento de identificação do Proprietário*',
      file: null,
      type: 'RG, CNH ou Passaporte. Documentos devem ser anexados em formato PDF.',
    },
    {
      key: 'TECHNICAL_RESPONSIBLE',
      label: 'ART - Responsável técnico*',
      file: null,
      type: 'ART paga descrevendo projeto de esgoto e execução das instalações sanitárias de esgotos (Responsável Técnico) em formato PDF.',
    },
    {
      key: 'RESPONSIBLE_FOR_EXECUTION',
      label: 'ART - Responsável pela execução*',
      file: null,
      type: 'ART paga descrevendo projeto de esgoto e execução das instalações sanitárias de esgotos (Responsável pela Execução) em formato PDF.',
    },
    {
      key: 'TECHNICIANS_FUNCTIONAL_IDENTITY_CARD',
      label: 'Carteira de identidade funcional do responsável técnico*',
      file: null,
      type: 'Documentos devem ser anexados em formato PDF.',
    },
    {
      key: 'EXECUTION_RESPONSIBLE_PERSON_FUNCTIONAL_IDENTITY_IDENTITY',
      label: 'Carteira de identidade funcional do responsável pela execução*',
      file: null,
      type: 'Documentos devem ser anexados em formato PDF.',
    },
    {
      key: 'PROPERTY_GENERAL_REGISTRATION',
      label: 'RGI*',
      file: null,
      type: 'Registro geral do imóvel. Documentos devem ser anexados em formato PDF.',
    },
    {
      key: 'CHARGE_TECHNICIAN_CREA',
      label:
        'Certidão de Registro de Pessoa Jurídica do responsável técnico (CREA)*',
      file: null,
      type: 'Anuidade do CREA em formato PDF',
    },
    {
      key: 'EXECUTION_RESPONSIBLE_CREA',
      label:
        'Certidão de Registro de Pessoa Jurídica do responsável pela execução (CREA)*',
      file: null,
      type: 'Anuidade do CREA em formato PDF',
    },
    {
      key: 'FLOOR_PLAN',
      label: 'Planta de situação*',
      file: null,
      type: 'A planta deve conter a situação do imóvel, marcação dos imóveis, vizinhos, o perímetro do terreno da construção e quadro de áreas. Deve estar aprovada ou processo de aprovação pela prefeitura. Arquivos devem ser anexados em formato PDF.',
    },
    {
      key: 'SOCIAL_CONTRACT',
      label: 'Contrato social*',
      file: null,
      type: 'Em caso de empresa, deve ser enviado cópia do contrato social. Documentos devem ser anexados em formato PDF.',
    },
    {
      key: 'EXHAUSTION_POSSIBILITY_DECLARATION',
      label: 'DPE*',
      file: null,
      type: 'Declaração de possibilidade de esgotamento. Documentos devem ser anexados em formato PDF.',
    },
  ]);

  const keysDocumentsProject = [
    'CARRIED_OUT_DEPLETION_WORK_DESCRIPTIVE_MEMORIAL',
    'CARRIED_OUT_DEPLETION_WORK_CALCULATION_MEMORIAL',
    'DEFINITIVE_INTERNAL_EXHAUSTION_PROJECT',
  ];

  useEffect(() => {
    const documentsProjectForReview: string[] = [];
    const documentsForReview: string[] = [];
    let errorDocumentsProjectForReview: any = {};
    let errorDocumentsForReview: any = {};

    caseFCP?.attachments?.forEach((document) => {
      if (document.review && keysDocumentsProject.includes(document.type)) {
        errorDocumentsProjectForReview = {
          ...errorDocumentsProjectForReview,
          [document.type]: { error: document.review_description },
        };
        return documentsProjectForReview.push(document.type);
      }
      if (document.review) {
        errorDocumentsForReview = {
          ...errorDocumentsForReview,
          [document.type]: { error: document.review_description },
        };

        return documentsForReview.push(document.type);
      }
      return null;
    });

    setDocuments((prevState) =>
      prevState
        .map((document) => {
          if (documentsForReview.includes(document.key)) {
            return {
              ...document,
              type: errorDocumentsForReview[document.key].error,
            };
          }
          return undefined;
        })
        .filter((document) => document !== undefined),
    );
    setDocumentsProject((prevState) =>
      prevState
        .map((document) => {
          if (documentsProjectForReview.includes(document.key)) {
            return {
              ...document,
              reviewDescription: errorDocumentsProjectForReview[document.key].error,
            };
          }
          return undefined;
        })
        .filter((document) => document !== undefined),
    );
    setIsArrayFilter(true);
  }, []);

  const [disabled, setDisabled] = useState(true);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    console.log('documents', documents);
    console.log('documentsProject', documentsProject);
  }, [documents, documentsProject]);

  const handleClick = () => {
    if (documentsProject?.length)
      dispatch(
        patchAttachmentPropertyRegistrationRequest({
          caseId: caseFCP?.id || '',
          step: '3B',
        }),
      );

    if (documents?.length)
      dispatch(
        patchAttachmentPropertyRegistrationRequest({
          caseId: caseFCP?.id || '',
          step: '3A',
        }),
      );
  };

  const verifyDocuments = async () => {
    if (
      (documents &&
        documents?.length &&
        documents.filter((document) => !document.file).length > 0) ||
      (documentsProject &&
        documentsProject.length &&
        documentsProject.filter((document) => !document.file).length > 0) ||
      loadingUploadFile
    )
      return setDisabled(true);

    return setDisabled(false);
  };

  useEffect(() => {
    verifyDocuments();
  }, [documents, documentsProject, loadingUploadFile]);

  useEffect(() => {
    documentsProject.map((document) => {
      return dispatch(
        getFileCodesPropertyRegistrationRequest({
          case: caseFCP?.id || '',
          documentType: document.key,
          type: caseFCP?.type || 'FCPE',
        }),
      );
    });
  }, []);

  const pushCodeButton = async () => {
    const CARRIED_OUT_DEPLETION_WORK_DESCRIPTIVE_MEMORIAL_CODES: Array<string> = [];
    const CARRIED_OUT_DEPLETION_WORK_CALCULATION_MEMORIAL_CODES: Array<string> = [];
    const DEFINITIVE_INTERNAL_EXHAUSTION_PROJECT_CODES: Array<string> = [];

    await fileCodes.CARRIED_OUT_DEPLETION_WORK_DESCRIPTIVE_MEMORIAL.map(
      (CARRIED_OUT_DEPLETION_WORK_DESCRIPTIVE_MEMORIAL) => {
        return CARRIED_OUT_DEPLETION_WORK_DESCRIPTIVE_MEMORIAL_CODES.push(
          CARRIED_OUT_DEPLETION_WORK_DESCRIPTIVE_MEMORIAL.code,
        );
      },
    );

    await fileCodes.CARRIED_OUT_DEPLETION_WORK_CALCULATION_MEMORIAL.map(
      (CARRIED_OUT_DEPLETION_WORK_CALCULATION_MEMORIAL) => {
        return CARRIED_OUT_DEPLETION_WORK_CALCULATION_MEMORIAL_CODES.push(
          CARRIED_OUT_DEPLETION_WORK_CALCULATION_MEMORIAL.code,
        );
      },
    );

    await fileCodes.DEFINITIVE_INTERNAL_EXHAUSTION_PROJECT.map(
      (DEFINITIVE_INTERNAL_EXHAUSTION_PROJECT) => {
        return DEFINITIVE_INTERNAL_EXHAUSTION_PROJECT_CODES.push(
          DEFINITIVE_INTERNAL_EXHAUSTION_PROJECT.code,
        );
      },
    );

    const array = [...documentsProject];

    const codesDocument = await array.map((document) => {
      if (document.key === 'CARRIED_OUT_DEPLETION_WORK_DESCRIPTIVE_MEMORIAL') {
        return {
          ...document,
          codes: CARRIED_OUT_DEPLETION_WORK_DESCRIPTIVE_MEMORIAL_CODES,
        };
      }

      if (document.key === 'CARRIED_OUT_DEPLETION_WORK_CALCULATION_MEMORIAL') {
        return {
          ...document,
          codes: CARRIED_OUT_DEPLETION_WORK_CALCULATION_MEMORIAL_CODES,
        };
      }

      if (document.key === 'DEFINITIVE_INTERNAL_EXHAUSTION_PROJECT') {
        return {
          ...document,
          codes: DEFINITIVE_INTERNAL_EXHAUSTION_PROJECT_CODES,
        };
      }

      return document;
    });

    setDocumentsProject(codesDocument);
  };

  useEffect(() => {
    if (isArrayFilter) {
      pushCodeButton();
    }
  }, [fileCodes, isArrayFilter]);

  return (
    <>
      <Main>
        <DescriptionTitle>
          Nesta etapa é necessário o anexo de todos os documentos solicitados a
          seguir conforme a orientação.
        </DescriptionTitle>
      </Main>
      <Margin height={64} />
      <Main>
        <>
          <StatusInfo style={{ width: 'calc(100% - 50px)' }} status="warning">
            Os documentos abaixo precisam ser revisados. Favor, leia o comentário e
            anexe um novo arquivo.
          </StatusInfo>

          {!!documents?.length && (
            <>
              <Margin height={40} />
              <ContainerTitle>
                <SeparatorTitle />
                <Title>Revisão de Documentos</Title>
              </ContainerTitle>
              <Margin height={40} />
            </>
          )}

          {documents.map((document, index: number) => (
            <>
              <Row key={document.key}>
                <SenFiles>
                  <DropZone
                    isRevision
                    infoFileName={document.label}
                    name={document.label}
                    content={document.file || null}
                    onFileUploaded={(file: File | null) => {
                      const array = [...documents];
                      array[index].file = file;

                      if (file) {
                        convertFileToBase64(file, (base64: string) => {
                          dispatch(
                            postUploadFilePropertyRegistrationRequest({
                              caseIdSales: caseId!,
                              type: document.key,
                              step: '3A',
                              filename: file?.name || '',
                              filedata: base64,
                            }),
                          );
                        });
                      }
                      setDocuments(array);
                    }}
                    type="default"
                  />
                </SenFiles>
                <Label>{document.type}</Label>
              </Row>
              <Margin height={40} />
            </>
          ))}

          {!!documentsProject?.length && (
            <>
              <Margin height={40} />
              <ContainerTitle>
                <SeparatorTitle />
                <Title>Documentação para projeto de esgotamento</Title>
              </ContainerTitle>
              <Margin height={40} />
            </>
          )}

          {documentsProject.map((docButton, index: number) => (
            <>
              <ButtonInfo
                isRevision
                key={index && index}
                isActive={docButton.isActive}
                onClick={async () => {
                  const array = await documentsProject.map(
                    (item: any, indexItem) => {
                      const e = item;
                      if (index === indexItem) e.isActive = !e.isActive;
                      return e;
                    },
                  );

                  setDocumentsProject(array);
                }}
              >
                {docButton.label}
                <ArrowUpSVG />
              </ButtonInfo>
              {docButton.isActive && (
                <ContainerGenerateCode key={docButton.key}>
                  <Margin height={12} />
                  <ContentGenerateCode>
                    <DropZone
                      isRevision
                      disabled={!!docButton.file || docButton.codes.length < 1}
                      infoFileName="Anexar arquivo"
                      content={docButton.file}
                      name={`file-${index}`}
                      onFileUploaded={(file: File | null) => {
                        const array = [...documentsProject];
                        array[index].file = file;

                        if (file) {
                          convertFileToBase64(file, (base64: string) => {
                            dispatch(
                              postUploadFilePropertyRegistrationRequest({
                                caseIdSales: caseFCP?.id || '',
                                type: docButton.key,
                                step: '3B',
                                filename: file?.name || '',
                                filedata: base64,
                              }),
                            );
                          });
                        }
                        setDocumentsProject(array);
                      }}
                    />
                    <BoxGeneratedCode>
                      <button
                        type="button"
                        onClick={() => {
                          dispatch(
                            postCreateFileCodesPropertyRegistrationRequest({
                              caseIdSales: caseFCP?.id || '',
                              type: 'FCPE',
                              documentType: docButton.key,
                              processNumber: caseFCP?.caseNumber || '',
                            }),
                          );
                        }}
                      >
                        {docButton.codes.length > 0
                          ? 'Gerar mais códigos'
                          : 'Gerar código'}
                      </button>
                      <div>
                        <p>Seus códigos para preenchimento:</p>
                        {docButton.codes.length > 0 ? (
                          docButton.codes.map((code: any, index: number) => (
                            <p key={index && index} className="code">
                              {code}
                            </p>
                          ))
                        ) : (
                          <p>Você ainda não gerou nenhum código.</p>
                        )}
                      </div>
                    </BoxGeneratedCode>
                    <BoxGeneratedCode>
                      <div>
                        <p>Revisão:</p>
                        <p className="code">{docButton.reviewDescription}</p>
                      </div>
                    </BoxGeneratedCode>
                  </ContentGenerateCode>
                </ContainerGenerateCode>
              )}
              <Margin height={40} />
            </>
          ))}
        </>
        <ButtonBar
          nextBackground={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          nextDisabled={disabled}
          nextLoading={!!loadingUploadFile || !!loadingItem}
          backOnClick={() => Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)}
          nextOnClick={handleClick}
        />
        <Margin height={50} />
      </Main>
    </>
  );
};

export default Sewage;
