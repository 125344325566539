import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { IHistoryData } from '~/store/modules/enrollmentsConsumptionHistory/types';
import { Container, ContainerMobile } from './styles';
import { Breakpoints } from '~/styles';

interface IProps {
  data: IHistoryData;
}

const ContainerConsumption: FC<IProps> = ({ data }) => {
  const isLaptop = useMediaQuery({ minWidth: Breakpoints.laptop.min });

  return isLaptop ? (
    <Container>
      <div className="container-border">
        <span />
      </div>
      <p style={{ flexBasis: '40%' }}>{data.reference}</p>
      <p className="small" style={{ flexBasis: '28%' }}>
        {data.real_consumption}
      </p>
      <p style={{ flexBasis: '27%' }}>{data.billed_consumption}</p>
    </Container>
  ) : (
    <ContainerMobile>
      <div className="container-border">
        <span />
      </div>
      <div className="content">
        <p>
          <b>Referência:</b>&nbsp; {data.reference}
        </p>
        <p>
          <b>Consumo real (m³):</b>&nbsp; {data.real_consumption}
        </p>
        <p>
          <b> Consumo Faturado (m³):</b>&nbsp; {data.billed_consumption}
        </p>
      </div>
    </ContainerMobile>
  );
};

export default ContainerConsumption;
