import React, { FC, useEffect, useState } from 'react';

import { Main, Label, Row, SenFiles, Content } from './styles';

import DropZone from '../../DropZone';
import { Input } from '~/components/Input';
import { Margin } from '~/components/Margin';
import {
  ContainerButton,
  Subtitle,
} from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/styles';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import {
  patchAttachFilesCustomerObligationRequest,
  patchProtocolCustomerObligationRequest,
  patchStatusStepCustomerObligationRequest,
  patchUploadFileCustomerObligationRequest,
} from '~/store/modules/customerObligation/actions';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import convertFileToBase64 from '~/utils/convertBase64';
import formatter from '~/utils/formatter';
import StatusInfo from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/components/StatusInfo';
import { IResponseAttachment } from '~/store/modules/customerObligation/types';
import { AppDispatch } from '~/store';

const Revision: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);
  const idCreate = useSelector((item: IState) => item.customerObligation.idCreate);
  const loading = useSelector(
    (item: IState) => item.customerObligation.loadingDarmCode,
  );
  const loadingAttachFiles = useSelector(
    (item: IState) => item.customerObligation.loadingAttachFiles,
  );
  const loadingUploadFile = useSelector(
    (item: IState) => item.customerObligation.loadingUploadUpdateFile,
  );
  const fileOC = caseOC?.attachments.find(
    (attachment: IResponseAttachment) => attachment.step === '5A',
  );
  const [file, setFile] = useState<File | null>(null);
  const [fileErro, setFileErro] = useState('');
  const [startRequestDate, setStartRequestDate] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [disabledDate, setDisabledDate] = useState(true);

  const handleClick = () => {
    dispatch(
      patchAttachFilesCustomerObligationRequest({
        case: idCreate || caseOC?.id || '',
        step: '5A',
      }),
    );
    dispatch(
      patchProtocolCustomerObligationRequest({
        case: idCreate || caseOC?.id || '',
        startWorkDate: startRequestDate,
      }),
    );
  };

  useEffect(() => {
    if (
      file &&
      !loadingUploadFile &&
      startRequestDate.length === 10 &&
      !disabledDate
    ) {
      return setDisabled(false);
    }
    return setDisabled(true);
  }, [file, startRequestDate, disabled, disabledDate]);

  return (
    <Main>
      <Content>
        <StatusInfo status="warning">
          As informações precisam ser revisadas. Favor, preencha novamente.
        </StatusInfo>
      </Content>
      <Margin height={60} />
      <Subtitle>
        Nesta etapa é necessário o anexo de todos os documentos e informações
        solicitados a seguir para a solicitação de início de obra.
      </Subtitle>
      <Margin height={64} />
      <>
        <Row>
          <SenFiles>
            <DropZone
              disabled={!!file}
              infoFileName="Anexo de carta para marcação de inicio de obra*"
              name="Anexo de carta para marcação de inicio de obra*"
              content={file || null}
              onFileUploaded={(file: File | null) => {
                if (file === null) {
                  setFileErro('');
                }
                if (file && file.size < 100) {
                  return setFileErro('Por favor, verifique o seu arquivo.');
                }

                if (file) {
                  convertFileToBase64(file, (base64: string) => {
                    dispatch(
                      patchUploadFileCustomerObligationRequest({
                        caseIdSales: idCreate || caseOC?.id || '',
                        type: 'start_work_letter',
                        step: '5A',
                        filename: file?.name || '',
                        filedata: base64,
                        idFile: fileOC?.id || '',
                        attachmentKey: fileOC?.key || '',
                      }),
                    );
                  });
                }

                return setFile(file);
              }}
              error={!file || !!fileErro}
              errorText={fileErro}
              type="default"
            />
          </SenFiles>
          <Label>
            {fileOC?.review_description ||
              'Arquivos devem ser anexados em formato PDF.'}
          </Label>
        </Row>
        <Margin height={40} />
        <Input
          styleInput={{ width: `50%`, maxWidth: '335px' }}
          label="Data desejada para iniciar a obra"
          type="date"
          value={startRequestDate}
          min={caseOC?.details.startWorkDates.initial}
          max={caseOC?.details.startWorkDates.final}
          error={startRequestDate.length === 0}
          onChange={(ev) => {
            const date = formatter.formatDate(ev.target.value);
            if (
              caseOC?.details?.startWorkDates.initial &&
              caseOC?.details?.startWorkDates.final
            ) {
              const dateInitial = new Date(caseOC?.details?.startWorkDates.initial);
              const dateFinal = new Date(caseOC?.details?.startWorkDates.final);
              if (new Date(date) < dateInitial || new Date(date) > dateFinal) {
                setStartRequestDate(date);
                return setDisabledDate(true);
              }

              setStartRequestDate(date);
              return setDisabledDate(false);
            }

            setStartRequestDate(date);
            return setDisabledDate(false);
          }}
          maxLength={6}
        />
      </>
      <Margin height={40} />
      <ContainerButton>
        <Button
          backIcon
          backgroundColor={Color.green}
          onClick={() =>
            dispatch(
              patchStatusStepCustomerObligationRequest({
                name: '',
                number: 4,
                substep: '',
                status: stepStatusEnum.SUCCESS,
              }),
            )
          }
        >
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          style={{ opacity: 1 }}
          onClick={handleClick}
          loading={loadingUploadFile || loadingAttachFiles || loading}
          disabled={disabled}
        >
          Continuar
        </Button>
      </ContainerButton>
    </Main>
  );
};

export default Revision;
