import { RouteName } from '~/routes/Routes.name';
import { store } from '~/store';
import { setRouteAuthRedirect } from '~/store/modules/enrollmentsAuth/actions';
import { activateRouteReportLackWater } from '~/store/modules/outsides/actions';
import Navigation from '~/utils/Navigation';

export const PRIVATE_ROUTES = [
  RouteName.REGISTRATIONLIST,
  RouteName.HOME,
  RouteName.NEGATIVECERTIFICATE,
  RouteName.ACCOUNTCONFIG,
  RouteName.INVOICES,
  RouteName.INVOICESCHANGEEXPIRATION,
  RouteName.INVOICESCHANGEDELIVERYTYPE,
  RouteName.INVOICERECEIP,
  RouteName.SIMPLIFIEDINVOICE,
  RouteName.SIMPLIFIEDINVOICEPIX,
  RouteName.SIMPLIFIEDINVOICECREDITCARD,
  RouteName.SIMPLIFIEDINVOICEPAYMENTMETHODS,
  RouteName.CHANGEPHONE,
  RouteName.CHANGEMOBILE,
  RouteName.CHANGEEMAIL,
  RouteName.CHANGEPASSOWORD,
  RouteName.DETAILEDHISTORY,
  RouteName.ENTERPRISE_VIABILITY,
  RouteName.CUSTOMEROBLIGATION,
  RouteName.PROPERTYREGISTRATION,
  RouteName.ACCESSPROFILE,
  RouteName.NOTIFICATIONS,
  RouteName.SMARTMETER,
  RouteName.SMARTMETERSUBSCRIPTION,
  RouteName.WALLET,
  RouteName.WALLETADDCARD,
  RouteName.WALLETDETAILCARD,
  RouteName.SUPPORTCENTER,
  RouteName.SUPPORTCENTERSERVICEORDER,
  RouteName.AUTOMATICDEBIT,
  RouteName.SOCIALTARIFF,
  RouteName.SOCIALTARIFFSOLICITATION,
  RouteName.SOCIALTARIFFREQUEST,
  RouteName.LINKENROLLMENT,
  RouteName.LINKENROLLMENTFORM,
  RouteName.SINGLEPAYMENT,
  RouteName.SINGLEPAYMENTDATAPAYMENT,
  RouteName.SINGLEPAYMENTDATAPAYMENTRECEIPT,
  RouteName.ENTERPRISE_VIABILITY_NEW_PROCESS,
  RouteName.SUPPLYPOSSIBILITYCONSULTATION,
  RouteName.SUPPLYPOSSIBILITYCONSULTATIONVALUESTABLE,
  RouteName.ENTERPRISE_VIABILITY_KNOW_MORE,
  RouteName.ENTERPRISE_VIABILITY_PANEL_SLIPS,
  RouteName.ENTERPRISE_VIABILITY_SLIPS_PAYMENT,
  RouteName.REPORTLACKWATER,
  RouteName.SUPPORTCENTERPROTOCOL,
  RouteName.NEGOTIATIONS,
  RouteName.DEBTNEGOTIATION,
  RouteName.DEBTNEGOTIATIONSINGLEPAYMENT,
  RouteName.DEBTNEGOTIATIONSINGLEPAYMENTDATAPAYMENT,
  RouteName.DEBTNEGOTIATIONTYPEPAYMENT,
  RouteName.DEBTNEGOTIATIONSIMULATION,
  RouteName.DEBTNEGOTIATIONDETAIL,
];

export const isPrivateRoute = (pathname: string) => {
  return PRIVATE_ROUTES.includes(pathname);
};

export const isExistsRoute = (pathname: string) => {
  const ALL_ROUTES = Object.values(RouteName);

  return ALL_ROUTES.includes(pathname);
};

export const handleRedirectIfNotAuthenticated = (pathname: string) => {
  if (isPrivateRoute(pathname)) {
    if (pathname === RouteName.REPORTLACKWATER) {
      store.dispatch(activateRouteReportLackWater());
    } else {
      store.dispatch(setRouteAuthRedirect(pathname));
    }

    Navigation.navigate(RouteName.LOGIN);
  }

  if (!isExistsRoute(pathname)) Navigation.navigate(RouteName.LANDING);
};
