import React, { useEffect, useState } from 'react';
import { Card, Column } from '../../styles';
import { Title } from './styled';
import { Subtitle } from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/styles';
import { Margin } from '~/components/Margin';
import { Input } from '~/components/Input';
import { Button } from '~/components/ButtonV3';
import { Text } from '~/components/TextInfo';
import { ButtonGoBack } from '~/views/RegisterEntrepreneur/styles';
import { RouteName } from '~/routes/Routes.name';
import BoxInfo from './boxInfo';
import { Color } from '~/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateEnrollmentsUsersPasswordRequest,
  updateErrorCloseEnrollmentsPasswordUsers,
} from '~/store/modules/enrollmentsUsers/actions';
import { IState } from '~/store/modules/types';
import { ClipLoader } from 'react-spinners';
import Navigation from '~/utils/Navigation';
import { RepositoriesTypesUsers } from '~/store/modules/enrollmentsUsers/types';
import { updateCurrentPassword } from '~/store/modules/enrollmentsAuth/actions';
import ModalError from '~/components/ModalError';
import Modal from '~/components/Modal';
import { Dialog } from '~/components/Dialog';
import ModalInfoPassword from './ModalInfoPassword';
import { EnumResponseCode } from '~/enums/reponseCodesEnum';
import { AppDispatch } from '~/store';
import { checkPassword } from '~/utils/validation';

const ChangePasswordV3: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [error, SetError] = useState<string>();

  const [password, SetPassowrd] = useState<string>();
  const [oldPassword, SetOldPassword] = useState<string>();

  const [erroPassword, SetErrorPassword] = useState({ next: false, error: false });

  const currentPassword = useSelector(
    (item: IState) => item.enrollmentsAuth.password,
  );
  const loading = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingUpdatePassword,
  );
  const succes = useSelector(
    (item: IState) => item.enrollmentsUsers.successUpdatePassword,
  );
  const errorUpdatePassword = useSelector(
    (item: IState) => item.enrollmentsUsers.errorUpdatePassword,
  );
  const [currentPasswordError, setCurrentPasswordError] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const send = () => {
    if (password && oldPassword) {
      dispatch(
        updateEnrollmentsUsersPasswordRequest({
          password: oldPassword,
          newPassword: password,
        }),
      );
    }
  };

  useEffect(() => {
    if (succes) {
      dispatch(updateCurrentPassword(password || ''));
      Navigation.navigate(RouteName.ACCOUNTCONFIG, {
        item: 'password',
        succes: true,
      });
    }
  }, [succes]);

  useEffect(() => {
    if (errorUpdatePassword && errorUpdatePassword.state) {
      if (errorUpdatePassword?.type === EnumResponseCode.RECENTLY_USED_PASSWORD) {
        setOpenInfo(true);
      } else {
        setOpen(true);
      }
    } else {
      setOpen(false);
    }
  }, [errorUpdatePassword]);

  const validateCurrentPassword = () => {
    if (!oldPassword) return setCurrentPasswordError('');

    if (oldPassword !== currentPassword) {
      return setCurrentPasswordError('Senha inválida');
    }
    return setCurrentPasswordError('');
  };

  return (
    <>
      {open && (
        <Modal open={open} type="error" title="Ops!" v3>
          <ModalError
            onClose={() => dispatch(updateErrorCloseEnrollmentsPasswordUsers())}
          />
        </Modal>
      )}
      {openInfo && (
        <Dialog
          open={openInfo}
          onClose={() => {
            dispatch(updateErrorCloseEnrollmentsPasswordUsers());
            setOpenInfo(false);
          }}
          fullScreen
        >
          <ModalInfoPassword setOpen={setOpenInfo} />
        </Dialog>
      )}
      <Card
        style={{
          borderTop: 'none',
          borderRadius: 8,
          alignItems: 'center',
          maxWidth: 580,
          margin: '64px auto 121px auto',
        }}
      >
        <Column>
          <Margin height={24} />
          <Title>Alterar senha</Title>
          <Margin height={24} />
          <Subtitle>
            {' '}
            Lembre-se: sua senha deve ser formada por 08 caracteres, sendo pelo menos
            um deles em letra maiúscula, um em letra minúscula, um numeral e um
            símbolo (@, #, %, &, *).
          </Subtitle>
          <Margin height={24} />

          <Input
            label="Senha atual"
            labelErroWeigth={500}
            onChange={(e) => {
              dispatch({
                type: RepositoriesTypesUsers.CLEAN,
              });
              SetOldPassword(e.target.value);
            }}
            error={!!currentPasswordError}
            errorText={currentPasswordError}
            errorPosition="bottom"
            onBlur={validateCurrentPassword}
          />
          <Margin height={24} />

          <Input
            label="Nova senha"
            onChange={(e) => {
              SetPassowrd(e.target.value);
              SetError(checkPassword(e.target.value));
            }}
            maxLength={8}
          />
          <Margin height={24} />

          <Input
            label="Confirmação de nova senha"
            errorText="Senhas não coincidem"
            error={erroPassword.error}
            labelErroWeigth={500}
            errorPosition="bottom"
            onChange={(e) => {
              const { value } = e.target;
              if (value === password) {
                SetErrorPassword({ error: false, next: true });
              } else {
                SetErrorPassword({ error: true, next: false });
              }
            }}
            maxLength={8}
          />

          <Margin height={24} />

          {error && error?.length > 0 && <BoxInfo label={error} />}
          <Margin height={24} />
          {oldPassword &&
          password &&
          !currentPasswordError &&
          !error &&
          erroPassword.next ? (
            <Button
              disabled={loading}
              onClick={() => send()}
              backgroundColor={Color.blueSeconde}
            >
              {loading ? (
                <ClipLoader size={12} color="white" />
              ) : (
                <Text color="white" style={{ textAlign: 'center' }}>
                  Continuar
                </Text>
              )}
            </Button>
          ) : (
            <Button disabled onClick={() => console.log()}>
              <Text color="white" style={{ textAlign: 'center' }}>
                Continuar
              </Text>
            </Button>
          )}
          <Margin height={24} />
          <ButtonGoBack style={{ textAlign: 'center' }} to={RouteName.ACCOUNTCONFIG}>
            <div style={{ flex: 1, justifyContent: 'center' }}>Voltar</div>
          </ButtonGoBack>
        </Column>
      </Card>
    </>
  );
};

export default ChangePasswordV3;
