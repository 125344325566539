import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { Margin } from '~/components/Margin';
import DocumentSVG from '~/assets/Icons/DocumentIcon';
import ChartSVG from '~/assets/Icons/ChartIcon';
import NegativeSVG from '~/assets/Icons/CertNegative';
import SupportCenterSVG from '~/assets/Icons/SupportCenterSVG';
import OwnershipSVG from '~/assets/Icons/OwnershipSVG';
import * as S from './styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { Breakpoints, Color } from '~/styles';
import { IState } from '~/store/modules/types';
import AutomaticDebitSVG from '~/assets/Icons/AutomaticDebit';
import { checkToggle } from '~/utils/toggles';
import SocialTariffSVG from '~/assets/Icons/SocialTariff';

const OwerServices = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });
  const isClient = useSelector((state: IState) => state.enrollmentsAuth.isClient);
  const toggles = useSelector((item: IState) => item.togglesMe.data);
  const togglesMe = useSelector((item: IState) => item.togglesMe.data);

  const [directDebit, setDirectDebit] = useState<boolean>(false);
  const [supportCenter, setSupportCenter] = useState<boolean>(false);
  const [ownership, setOwnership] = useState<boolean>(false);
  const [socialTariffView, setSocialTariffView] = useState<boolean>(false);

  const needRenovationSocialTariff = useSelector(
    (item: IState) => item.socialTariff.needRenovation,
  );

  const verifyToggles = async () => {
    setDirectDebit(await checkToggle(toggles, 'DIRECT_DEBIT'));
  };

  useEffect(() => {
    verifyToggles();
  }, [toggles]);

  const verifyTogglesMe = async () => {
    setSupportCenter(await checkToggle(togglesMe, 'SUPPORT_CENTER_NEW'));
    setOwnership(await checkToggle(togglesMe, 'OWNERSHIP_NEW'));
    setSocialTariffView(await checkToggle(toggles, 'TARIFA_SOCIAL'));
  };

  useEffect(() => {
    verifyTogglesMe();
  }, [togglesMe]);

  return (
    <S.Container>
      <S.OwerServices>
        <Margin height={isMobile ? 24 : 84} />
        <S.TitleCard style={{ textAlign: 'left', width: '100%' }}>
          Nossos serviços
        </S.TitleCard>
        <Margin height={isMobile ? 12 : 43} />
        <S.RowServices>
          {isClient && (
            <S.ButtonLink
              bgColor="green"
              withMargin
              onClick={() => Navigation.navigate(RouteName.INVOICES)}
            >
              <DocumentSVG />
              <Margin height={8} />
              <S.ButtonText>Faturas</S.ButtonText>
            </S.ButtonLink>
          )}
          {isClient && supportCenter && (
            <S.ButtonLink
              bgColor="red"
              withMargin
              onClick={() => Navigation.navigate(RouteName.SUPPORTCENTER)}
            >
              <SupportCenterSVG fill="#ffffff" />
              <Margin height={8} />
              <S.ButtonText>
                Minhas
                <br />
                solicitações
              </S.ButtonText>
            </S.ButtonLink>
          )}

          {ownership && (
            <S.ButtonLink
              bgColor="yellow"
              withMargin
              onClick={() => Navigation.navigate(RouteName.OWNERSHIPMANAGEMENT)}
            >
              <OwnershipSVG />
              <Margin height={8} />
              <S.ButtonText>
                Troca de
                <br />
                Titularidade
              </S.ButtonText>
            </S.ButtonLink>
          )}

          {isClient && (
            <S.ButtonLink
              bgColor="red"
              withMargin
              onClick={() => Navigation.navigate(RouteName.NEGATIVECERTIFICATE)}
            >
              <NegativeSVG />
              <Margin height={8} />
              <S.ButtonText>
                Certidão
                <br />
                Negativa
              </S.ButtonText>
            </S.ButtonLink>
          )}
          {isClient && (
            <S.ButtonLink
              bgColor="blue"
              withMargin
              onClick={() => Navigation.navigate(RouteName.DETAILEDHISTORY)}
            >
              <ChartSVG />
              <Margin height={8} />
              <S.ButtonText>
                Histórico
                <br />
                detalhado
              </S.ButtonText>
            </S.ButtonLink>
          )}
          {/* {isEntrepreneur && (
            <S.ButtonLink
              bgColor="blueIntense"
              withMargin
              onClick={() => Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)}
            >
              <DocumentSVG />
              <Margin height={8} />
              <S.ButtonText>Viabilidades</S.ButtonText>
            </S.ButtonLink>
          )} */}
          <S.ButtonLink
            bgColor="blueIntense"
            withMargin
            onClick={() => Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)}
          >
            <DocumentSVG />
            <Margin height={8} />
            <S.ButtonText>Viabilidades</S.ButtonText>
          </S.ButtonLink>
          {directDebit && (
            <S.ButtonLink
              bgColor="blue"
              withMargin
              onClick={() => Navigation.navigate(RouteName.AUTOMATICDEBIT)}
            >
              <AutomaticDebitSVG color={Color.white} />
              <Margin height={8} />
              <S.ButtonText>
                Débito
                <br />
                automático
              </S.ButtonText>
            </S.ButtonLink>
          )}
          {isClient && socialTariffView && (
            <S.ButtonLink
              bgColor="green"
              withMargin
              onClick={() => {
                needRenovationSocialTariff
                  ? Navigation.navigate(RouteName.SOCIALTARIFFSOLICITATION)
                  : Navigation.navigate(RouteName.SOCIALTARIFF);
              }}
            >
              <SocialTariffSVG />
              <Margin height={8} />
              <S.ButtonText>Tarifa social</S.ButtonText>
            </S.ButtonLink>
          )}
        </S.RowServices>
        <Margin height={isMobile ? 13 : 124} />
      </S.OwerServices>
    </S.Container>
  );
};

export default OwerServices;
