import { HTMLInputTypeAttribute, InputHTMLAttributes } from 'react';

import styled from 'styled-components';

import { Color } from '../../styles';
import { Breakpoints } from '../../styles/breakpoints';

export interface IPropStyled extends InputHTMLAttributes<HTMLInputTypeAttribute> {
  isRevision?: boolean;
  error?: boolean;
  payment?: boolean;
}

export interface IPropSPayment {
  payment?: boolean;
}

export const ContainerInputStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;

export const LabelError = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 15px;
  color: ${Color.red};
  text-align: right;
  margin: 8px 0;
  @media (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 11px;
  }
`;

export const InputStyled = styled.input<IPropStyled>`
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  height: 30px;
  color: ${({ error, isRevision }) =>
    error || isRevision ? Color.red : Color.blackSeconde};
  border: 1px solid
    ${({ error, isRevision }) => (error || isRevision ? Color.red : Color.gray)};
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;

  :focus-visible {
    outline: none;
  }

  ::-webkit-input-placeholder {
    font-weight: 300;
    font-size: 16px;
  }

  :-ms-input-placeholder {
    font-weight: 300;
    font-size: 16px;
  }

  :disabled {
    background: ${Color.grayLight};
  }

  ::placeholder {
    font-weight: 300;
    font-size: 16px;
  }
  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    height: 39px;
    border-width: ${({ payment }) => (payment ? 2 : 1)}px;
  }
`;

export const LabelStyled = styled.label<IPropSPayment>`
  color: ${({ payment }) => (payment ? Color.black : Color.blueIntense)};
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-left: ${({ payment }) => payment && 10}px;
  margin-left: ${({ payment }) => payment && 5}px;
`;

export interface IPropsContainerIconStyled {
  error?: boolean;
  errorPosition?: 'top' | 'bottom';
  rigth?: string;
  color?: string;
}

export const ContainerIconStyled = styled.div<IPropsContainerIconStyled>`
  position: absolute;
  right: ${({ rigth }) => rigth || '10px'};
  ${({ color }) => color && `color: ${color}`};
  bottom: ${({ error, errorPosition }) =>
    error && errorPosition === 'bottom' ? '20px' : '9px'};
  cursor: pointer;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    bottom: ${({ error, errorPosition }) =>
      error && errorPosition === 'bottom' ? '20px' : '9px'};
  }
`;

export const ContainerTextStyled = styled.div<IPropsContainerIconStyled>`
  position: absolute;
  right: ${({ rigth }) => rigth || '10px'};
  ${({ color }) => color && `color: ${color}`};
  bottom: ${({ error, errorPosition }) =>
    error && errorPosition === 'bottom' ? '44px' : '11px'};
  cursor: pointer;
`;
