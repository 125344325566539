import { IResponse } from '~/store/modules/toggles/types';

export const checkToggle = async (toggles: IResponse[], toggleName: string) => {
  const toggleExists = await toggles.find(
    (item: IResponse) => item.featureToggle === toggleName,
  );

  if (toggleExists && toggleExists?.toggle) return true;

  return false;
};
