import React, { FC } from 'react';

import { Header } from '../components/Header';
import { TitleHeader } from '../components/text';

import { Container, ContainerBreadCrumb, Main } from './styles';
import { Margin } from '~/components/Margin';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { BreadCrumb } from '~/components/BreadCrumb';
import {
  DpaDpeLocationsEnum,
  DpaDpeLocationsLabelEnum,
  DpaDpeLocationsLabelMap,
} from '~/enums/dpadpeEnum';
import { CuiabaCpaeValuesTable } from './cuiaba';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import { RioCpaeValuesTable } from './rio';

const CpaeValuesTable: FC = () => {
  const caseDetail = useSelector((item: IState) => item.dpadpe.caseDetail);
  const dpadpeLocationData = useSelector(
    (item: IState) => item.dpadpe.dpadpeLocation,
  );

  const dpadpeLocation = caseDetail?.selectedCase
    ? DpaDpeLocationsLabelMap[caseDetail?.enterprise?.city] ||
      DpaDpeLocationsLabelMap[DpaDpeLocationsLabelEnum.RIO]
    : dpadpeLocationData;

  const tables = {} as any;

  tables[DpaDpeLocationsEnum.CUIABA] = {
    table: <CuiabaCpaeValuesTable />,
    label: DpaDpeLocationsLabelEnum.CUIABA,
  };
  tables[DpaDpeLocationsEnum.MIGUEL] = {
    table: <RioCpaeValuesTable />,
    label: DpaDpeLocationsLabelEnum.MIGUEL,
  };
  tables[DpaDpeLocationsEnum.PATY] = {
    table: <RioCpaeValuesTable />,
    label: DpaDpeLocationsLabelEnum.PATY,
  };
  tables[DpaDpeLocationsEnum.RIO] = {
    table: <RioCpaeValuesTable />,
    label: DpaDpeLocationsLabelEnum.RIO,
  };

  const tableData = tables[dpadpeLocation] || tables[DpaDpeLocationsEnum.RIO];

  return (
    <Container>
      <Margin height={24} />
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Viabilidade de empreendimento',
              active: true,
              onClick: () => Navigation.navigate(RouteName.ENTERPRISE_VIABILITY),
            },
            {
              label: 'CPAE',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Margin height={24} />
      <Main>
        <Header>
          <TitleHeader>Tabela de valores CPAE - {tableData.label}</TitleHeader>
        </Header>
        {tableData.table}
        <Margin height={66} />
        <div style={{ width: '100%', display: 'flex' }}>
          <Button
            onClick={() =>
              Navigation.navigate(RouteName.SUPPLYPOSSIBILITYCONSULTATION)
            }
            backgroundColor={Color.blueSeconde}
            style={{ width: '25%', marginLeft: 'auto', marginRight: '80px' }}
          >
            OK
          </Button>
        </div>

        <Margin height={57} />
      </Main>
    </Container>
  );
};

export default CpaeValuesTable;
