import styled from 'styled-components';
import { Breakpoints } from '~/styles';

export const Main = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow:
    0px 0px 8px 2px rgba(44, 144, 160, 0.1),
    0px 0px 24px 8px rgba(39, 126, 140, 0.06);
  border-radius: 8px;
`;

export const ContainerTitle = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  border-bottom: 1px solid #ebeced;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    padding: 24px 40px;
  }
`;

export const TitleText = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #202022;
`;

export const ButtonClose = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const ContainerInfo = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 16px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    padding: 24px 40px;
  }
`;

export const DescriptionText = styled.p`
  max-width: 550px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #202022;
`;
