import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import { useDispatch, useSelector } from 'react-redux';
import { Button } from '~/common/Button';
import { ButtonLink, InputToken } from '~/common';
import { Margin } from '~/components/Margin';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';
import { Main, ResendTextStyled, ContainerInputs, NavWrapper } from './styles';
import {
  postCloseErrorEnrollmentsValidateSmsRegister,
  postEnrollmentsUsersSendRecoveryRequest,
  postEnrollmentsUsersSendRecoveryRecoveryRequest,
} from '~/store/modules/enrollmentsUsers/actions';
import { Card } from '~/common/Card';
import Navigation from '~/utils/Navigation';
import { history } from '~/store';
import { IRequestSendRecovery } from '~/store/modules/enrollmentsUsers/types';
import { AppDispatch } from '~/store';

const PasswordExpirationCheckCode: React.FC = () => {
  const dataHistory = history.location.state as IRequestSendRecovery;

  const dispatch = useDispatch<AppDispatch>();

  const loading = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingValidateSmsRegister,
  );
  const loadingResendSms = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingRegisterSendSms,
  );
  const error = useSelector(
    (item: IState) => item.enrollmentsUsers.errorValidateSmsRegister,
  );
  const [code, setCode] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [tokenSent, setTokenSent] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(60);

  useEffect(() => {
    dispatch(postCloseErrorEnrollmentsValidateSmsRegister());
  }, []);

  useEffect(() => {
    if (code.length >= 6) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [code]);

  useEffect(() => {
    const interval: any = setInterval(() => {
      if (tokenSent && counter > 0) {
        return setCounter(counter - 1);
      }
      return setTokenSent(false);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [tokenSent, counter]);

  const handleClick = () => {
    dispatch(
      postEnrollmentsUsersSendRecoveryRequest({
        cnp: dataHistory.cnp || '',
        shippingChannel: dataHistory.shippingChannel,
      }),
    );
    setTokenSent(true);
    setCounter(60);
  };

  return (
    <Main>
      <Card
        title={
          dataHistory && dataHistory?.shippingChannel === 'email'
            ? 'Confirmação via e-mail'
            : 'Confirmação via SMS'
        }
      >
        <p>
          {dataHistory && dataHistory?.shippingChannel === 'email'
            ? 'Para seguir, favor informar o código de confirmação enviado para seu email.'
            : 'Para seguir, favor informar o código de confirmação enviado para o número.'}
        </p>
        <Margin height={40} />
        <ContainerInputs>
          <InputToken
            valueLength={6}
            value={code}
            handleChange={(e) => setCode(formatter.formatarNumber(e) || '')}
            error={error ? 'Código incorreto' : ''}
          />
        </ContainerInputs>
        <Margin height={40} />
        <ResendTextStyled>
          Caso não tenha recebido o{' '}
          {dataHistory && dataHistory?.shippingChannel === 'email'
            ? 'e-mail'
            : 'SMS'}
          , clique em: <br />
          <ButtonLink
            type="button"
            onClick={handleClick}
            disabled={tokenSent}
            style={{ textDecoration: tokenSent ? 'none' : 'underline' }}
          >
            {tokenSent ? `Tentar novamente em ${counter}s` : 'Reenviar código'}
          </ButtonLink>
        </ResendTextStyled>
        <Margin height={24} />
        <NavWrapper>
          <Button
            onClick={() => {
              if (disabled || loading) return;
              ReactGA.event({
                category: 'Expiração de senha',
                action: '[Expiração de Senha][WEB][Código de Confirmação] Continuar',
              });

              dispatch(
                postEnrollmentsUsersSendRecoveryRecoveryRequest({
                  cnp: dataHistory.cnp,
                  token: code,
                }),
              );
            }}
            loading={!!(loading || loadingResendSms)}
            disabled={disabled}
          >
            Continuar
          </Button>
          <ButtonLink type="button" color="gray" onClick={() => Navigation.goBack()}>
            Voltar
          </ButtonLink>
        </NavWrapper>
      </Card>
    </Main>
  );
};

export default PasswordExpirationCheckCode;
