import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { clearProtocolCustomerObligationRequest } from '~/store/modules/customerObligation/actions';
import Form from './Form';
import Status from './Status';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import Revision from './Revision';
import { AppDispatch } from '~/store';

const DarmRequest: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);

  useEffect(
    () => () => {
      dispatch(clearProtocolCustomerObligationRequest());
    },
    [],
  );

  return (
    <>
      {caseOC?.step.status === stepStatusEnum.FORM && <Form />}
      {(caseOC?.step.status === stepStatusEnum.WAIT ||
        caseOC?.step.status === stepStatusEnum.SUCCESS) && <Status />}
      {caseOC?.step?.status === stepStatusEnum.REVIEW && <Revision />}
    </>
  );
};

export default DarmRequest;
