import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Color } from '~/styles';
import CreatableSelect from 'react-select/creatable';
import { LabelStyled } from '../Input/styles';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  className?: string;
  options: any[];
  disabled?: boolean;
  onChange: any;
}

const InputSelectTwo: React.FC<IProps> = ({
  className,
  options,
  onChange,
  label,
  placeholder,
  disabled,
}) => {
  return (
    <InputWrapper className={className}>
      {label && <LabelStyled style={{ marginBottom: -5 }}>{label}</LabelStyled>}
      <CreatableSelect
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        isDisabled={disabled}
        styles={{
          option: (baseStyles) => ({
            ...baseStyles,
            background: 'white',
            color: 'black',
          }),
        }}
        formatCreateLabel={() => {
          return <div>Nenhum item encontrado</div>;
        }}
        options={options}
      />
    </InputWrapper>
  );
};

export default InputSelectTwo;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InputLabel = styled.label`
  color: ${Color.blueIntense};
  font-weight: 400;
`;

export const CustomField = styled.input<{ error: boolean }>`
  -webkit-appearance: none;
  border-radius: 0px;
  box-sizing: border-box;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  padding: 0px 12px;
  height: 36px;
  width: 100%;
  outline: none;
  border: ${(p) =>
    p.error ? `2px solid ${Color.red}` : `1px solid ${Color.grayLightSeconde}`};
  &:hover {
    cursor: pointer;
  }
`;

export const HelperMessage = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${Color.red};
  align-self: flex-end;
`;

export const TextLabel = styled.div`
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 12px;
`;
