import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { ContainerButtons, Main } from './styles';
import {
  deleteEnrollmentsCardRequest,
  patchEnrollmentsCardDefaultRequest,
} from '~/store/modules/enrollmentsCards/actions';

import { Margin } from '~/components/Margin';
import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import { AppDispatch } from '~/store';

interface IProps {
  setOpen: (status: boolean) => void;
  cardId: number;
  updateDefault?: boolean;
}

const ModalAddCard: FC<IProps> = ({ setOpen, cardId, updateDefault }) => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Main>
      <Margin height={39} />
      <ContainerButtons>
        <ButtonText
          text="Cancelar"
          typeBtn="content"
          backgroundColor={Color.green}
          Color={Color.white}
          onClick={() => {
            setOpen(false);
          }}
          style={{ height: '68px' }}
        />
        <Margin width={23} />
        <ButtonText
          text={updateDefault ? 'Atualizar' : 'Excluir'}
          typeBtn="content"
          backgroundColor={updateDefault ? Color.blueSeconde : Color.red}
          Color={Color.white}
          onClick={() => {
            if (updateDefault) {
              dispatch(patchEnrollmentsCardDefaultRequest(cardId, true));
            } else {
              dispatch(deleteEnrollmentsCardRequest(cardId));
            }
            setOpen(false);
          }}
          style={{ height: '68px' }}
        />
      </ContainerButtons>
    </Main>
  );
};

export default ModalAddCard;
