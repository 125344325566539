import React, { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Margin } from '~/components/Margin';
import { Breakpoints, Color } from '~/styles';
import { history } from '~/store';

import {
  BrandName,
  CardNumber,
  ContainerBody,
  ContainerCardNumber,
  ContainerChangeCard,
  ContainerIconBack,
  ContainerInfoCard,
  ContainerSeparator,
  ContainerWalletDetail,
  Header,
  ItemInfoCard,
  LabelItemInfoCard,
  Main,
  Separator,
  TitleItemInfoCard,
} from './styles';
import { ButtonText } from '~/components/ButtonText';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { deleteErrorCloseEnrollmentsCardRequest } from '~/store/modules/enrollmentsCards/actions';
import Modal from '~/components/Modal';
import ModalAddCard from './Modal';
import formatter from '~/utils/formatter';
import WalletVisaDetailSVG from '~/assets/Icons/WalletVisaDetail';
import WalletHiperCardDetailSVG from '~/assets/Icons/WalletHiperCardDetail';
import WalletDiscoverDetailSVG from '~/assets/Icons/WalletDiscoverDetail';
import WalletMasterCardDetailSVG from '~/assets/Icons/WalletMasterCardDetail';
import WalletEloDetailSVG from '~/assets/Icons/WalletEloDetail';
import WalletAmericanExpressDetailSVG from '~/assets/Icons/WalletAmericanExpressDetail';

import ArrowBackSVG from '~/assets/Icons/ArrowBack';
import LoadingComponent from '~/components/Loading';
import TrashSVG from '~/assets/Icons/Trash';
import DefaultCardSVG from '~/assets/Icons/DefaultCard';
import { findTypeCard } from '~/utils/cards';
import { AppDispatch } from '~/store';

interface IStateHistory {
  id: number;
  brand: string;
  cardNumber: string;
  validity: string;
  type: string;
  isPrincipal: boolean;
}

export const brandCardIcon = {
  VISA: <WalletVisaDetailSVG />,
  HIPERCARD: <WalletHiperCardDetailSVG />,
  DISCOVER: <WalletDiscoverDetailSVG />,
  MASTERCARD: <WalletMasterCardDetailSVG />,
  ELO: <WalletEloDetailSVG />,
  AMERICAN_EXPRESS: <WalletAmericanExpressDetailSVG />,
} as any;

const WalletDetailCard: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });
  const dispatch = useDispatch<AppDispatch>();

  const loading = useSelector(
    (state: IState) => state.enrollmentsCards.loadingDeleteCard,
  );
  const loadingPatchCard = useSelector(
    (state: IState) => state.enrollmentsCards.loadingPatchCard,
  );
  const successCreateCard = useSelector(
    (item: IState) => item.enrollmentsCards.successCreateCard,
  );
  const error = useSelector((item: IState) => item.enrollmentsCards.errorCreateCard);

  const [open, setOpen] = useState<boolean>(false);
  const [openDefault, setOpenDefault] = useState<boolean>(false);
  const { id, brand, cardNumber, validity, type, isPrincipal } = history.location
    .state as IStateHistory;

  useEffect(() => {
    dispatch(deleteErrorCloseEnrollmentsCardRequest());
  }, []);

  useEffect(() => {
    if (successCreateCard || (error && error?.state)) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [successCreateCard, error]);

  return (
    <>
      <Modal open={open} type="info" title="Quer mesmo excluir o cartão?">
        <ModalAddCard setOpen={setOpen} cardId={id} />
      </Modal>
      <Modal
        open={openDefault}
        type="info"
        title="Quer mesmo atualizar o cartão principal?"
      >
        <ModalAddCard setOpen={setOpenDefault} cardId={id} updateDefault />
      </Modal>
      <Main>
        {(loading || loadingPatchCard) && (
          <LoadingComponent
            labelWait={loadingPatchCard ? 'Atualizando...' : 'Excluindo...'}
          />
        )}
        <ContainerWalletDetail>
          <Header>Carteira</Header>
          <ContainerBody>
            <Margin height={isMobile ? 16 : 21} />
            {!isMobile && (
              <>
                <ContainerIconBack
                  onClick={() => Navigation.navigate(RouteName.WALLET)}
                >
                  <ArrowBackSVG />
                </ContainerIconBack>
                <Margin height={20} />
                <Separator />
                <Margin height={16} />
              </>
            )}
            <ContainerInfoCard>
              {isMobile ? (
                <>
                  <BrandName>
                    {brand.toLowerCase()}
                    <br />
                    <CardNumber>{`**** **** **** ${formatter.formatarNumber(
                      cardNumber,
                    )}`}</CardNumber>
                  </BrandName>
                  {brandCardIcon[brand]}
                </>
              ) : (
                <>
                  {brandCardIcon[brand]}
                  <Margin width={28} />
                  <BrandName>{`${brand.toLowerCase()} - ${findTypeCard(
                    type,
                  )}`}</BrandName>
                </>
              )}
            </ContainerInfoCard>
            {!isMobile && (
              <>
                <Margin height={25} />
                <ContainerCardNumber>
                  <CardNumber>{`**** **** **** ${formatter.formatarNumber(
                    cardNumber,
                  )}`}</CardNumber>
                </ContainerCardNumber>
                <Margin height={17} />
              </>
            )}
            {isMobile && (
              <>
                <Margin height={24} />
                <ItemInfoCard>
                  <TitleItemInfoCard>Tipo de cartão</TitleItemInfoCard>
                  <LabelItemInfoCard>Cartão de crédito</LabelItemInfoCard>
                </ItemInfoCard>
              </>
            )}
            <ItemInfoCard>
              <TitleItemInfoCard>Data de vencimento</TitleItemInfoCard>
              <LabelItemInfoCard>{validity}</LabelItemInfoCard>
            </ItemInfoCard>
            <Margin height={20} />
            {!isPrincipal && (
              <>
                <ContainerChangeCard>
                  <DefaultCardSVG />
                  <Margin width={20} />
                  <ButtonText
                    text="Definir como principal"
                    typeBtn="canceled"
                    Color={Color.grayDark}
                    onClick={() => setOpenDefault(true)}
                    style={{ width: 'auto', backgroundColor: 'transparent' }}
                  />
                </ContainerChangeCard>
                <Margin height={10} />
                <ContainerSeparator>
                  <Separator />
                </ContainerSeparator>
              </>
            )}
            <ContainerChangeCard>
              <TrashSVG />
              <Margin width={21} />
              <ButtonText
                text="Excluir cartão"
                typeBtn="canceled"
                Color={Color.red}
                onClick={() => setOpen(true)}
                style={{ width: 'auto', backgroundColor: 'transparent' }}
              />
            </ContainerChangeCard>
          </ContainerBody>
        </ContainerWalletDetail>
      </Main>
    </>
  );
};

export default WalletDetailCard;
