import React, { Dispatch, FC, SetStateAction } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import {
  ContaineInvoices,
  ContainerButtonBack,
  ContainerButtonsList,
  ContainerCardFull,
  ContainerSinglePayment,
  PaymentButton,
  TextList,
} from '../styles';
import { Button } from '~/components/ButtonV3';
import MyCard from '../MyCard';
import { IState } from '~/store/modules/types';
import {
  clearDischargeDebts,
  clearEasyDuplicateInvoices,
  getEasyDuplicateInvoices,
} from '~/store/modules/outsides/actions';
import { Breakpoints, Color } from '~/styles';
import { Margin } from '~/components/Margin';
import Invoice from '../Invoice';
import EmptyMessage from '~/components/EmptyMessage';
import LoadingComponent from '~/components/Loading';
import { useMediaQuery } from 'react-responsive';
import { PaymentIcon } from '../../Invoices/icons';
import InvoiceDischargeDebts from '../InvoiceDischargeDebts';
import Pagination from './Pagination';
import { AppDispatch } from '~/store';

interface ICardDebtsProps {
  recaptchaTokens: any;
  cnp: string;
  setCnp: Dispatch<SetStateAction<string>>;
  enrollment: string;
  setEnrollment: Dispatch<SetStateAction<string>>;
  typeList: 'invoices' | 'others';
  setTypeList: Dispatch<SetStateAction<'invoices' | 'others'>>;
}

const CardDebts: FC<ICardDebtsProps> = ({
  recaptchaTokens,
  cnp,
  setCnp,
  enrollment,
  setEnrollment,
  typeList,
  setTypeList,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });
  const isTablet = useMediaQuery({ maxWidth: Breakpoints.laptop.max - 1 });

  const easyInvoices = useSelector((item: IState) => item.outsides.easyInvoices);
  const dataDischargeDebts = useSelector(
    (item: IState) => item.outsides.dataDischargeDebts,
  );
  const loading = useSelector((item: IState) => item.outsides.loading);

  const outsidesCredentials = useSelector(
    (item: IState) => item.outsidesCredentials.data,
  );

  const dataRequest = {
    ...recaptchaTokens,
    cnp,
    enrollmentId: enrollment?.split('-', 1)[0],
  };

  const handleGetInvoices = (page: number) => {
    dispatch(
      getEasyDuplicateInvoices({
        ...dataRequest,
        params: {
          page,
          size: 5,
        },
        searchInvoices: true,
      }),
    );
  };

  const handleGetDebts = (page: number) => {
    dispatch(
      getEasyDuplicateInvoices({
        ...dataRequest,
        params: {
          page,
          size: 5,
        },
        searchDebts: true,
      }),
    );
  };

  return (
    <>
      {loading && <LoadingComponent labelWait="Carregando..." />}
      <MyCard type="full" title="Faturas simplificadas">
        <ContainerCardFull>
          <Margin height={isMobile ? 28 : isTablet ? 34 : 26} />
          {typeList === 'invoices' &&
            easyInvoices &&
            easyInvoices?.pagination?.totalItens > 1 && (
              <>
                <ContainerSinglePayment
                  style={{ paddingLeft: !isTablet ? '24px' : undefined }}
                >
                  <div>
                    <p>Você possui pendências!</p>
                    <p>Pague todas suas pendências em até 12X sem juros!</p>
                  </div>
                  <PaymentButton
                    type="button"
                    onClick={() => {
                      ReactGA.event({
                        category: 'Pagamento Único',
                        action:
                          '[Ñ Logado][Pag. Único][WEB] Faturas - Pagamento Único',
                      });

                      ReactGA.event({
                        category: 'Pagamento Único',
                        action: '[QD][C3][WEB] – Pagamento único',
                      });

                      dispatch(
                        getEasyDuplicateInvoices({
                          ...dataRequest,
                          params: {
                            page: 1,
                            size: easyInvoices?.pagination.totalItens,
                          },
                          routeData: {
                            invoices: easyInvoices?.invoices,
                            typeView: 'group',
                            enrollment,
                            cnp,
                          },
                        }),
                      );
                    }}
                  >
                    Pagamento único
                    <PaymentIcon />
                  </PaymentButton>
                </ContainerSinglePayment>
                <Margin height={16} />
              </>
            )}
          <ContainerButtonsList typeList={typeList}>
            <button type="button" onClick={() => setTypeList('invoices')}>
              Faturas
            </button>
            <button type="button" onClick={() => setTypeList('others')}>
              Outros
            </button>
          </ContainerButtonsList>
          {typeList === 'invoices' ? (
            easyInvoices && easyInvoices?.invoices?.length > 0 ? (
              <>
                <TextList>Faturas vinculadas à sua matrícula:</TextList>
                {easyInvoices?.invoices?.map((invoice: any) => (
                  <ContaineInvoices key={invoice.invoice_id}>
                    <Margin height={16} />
                    <Invoice
                      key={invoice.invoice_id}
                      data={invoice}
                      cnp={cnp}
                      enrollment={enrollment}
                    />
                  </ContaineInvoices>
                ))}
                <Margin height={26} />
                <Pagination handleGetData={handleGetInvoices} />
              </>
            ) : (
              <>
                <Margin height={40} />
                <EmptyMessage textMessage="Matrícula sem faturas pendentes!" />
              </>
            )
          ) : dataDischargeDebts && dataDischargeDebts?.dados?.length > 0 ? (
            <>
              <TextList>
                Parcelas de serviço, negociações e outras cobranças vinculadas ao seu
                CPF/CNPJ:
              </TextList>
              {dataDischargeDebts?.dados?.map((invoice: any) => {
                return (
                  <ContaineInvoices key={invoice.id}>
                    <Margin height={16} />
                    <InvoiceDischargeDebts
                      key={invoice.id}
                      data={invoice}
                      cnp={cnp}
                      enrollment={enrollment}
                    />
                  </ContaineInvoices>
                );
              })}
              <Margin height={26} />
              <Pagination handleGetData={handleGetDebts} />
            </>
          ) : (
            <>
              <Margin height={40} />
              <EmptyMessage textMessage="CPF/CNPJ não possui dívidas em aberto" />
            </>
          )}
          <Margin height={16} />
          <ContainerButtonBack>
            <Button
              type="button"
              color={Color.gray}
              onClick={() => {
                if (!outsidesCredentials || !outsidesCredentials?.remember) {
                  setCnp('');
                  setEnrollment('');
                }
                setTypeList('invoices');
                dispatch(clearEasyDuplicateInvoices());
                dispatch(clearDischargeDebts());
              }}
            >
              Voltar
            </Button>
          </ContainerButtonBack>
          <Margin height={56} />
        </ContainerCardFull>
      </MyCard>
    </>
  );
};

export default CardDebts;
