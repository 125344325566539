import React, { FC, useRef, InputHTMLAttributes, FormEvent } from 'react';
import UploadSVG from '~/assets/Icons/UploadSVG';
import TrashIconSVG from '~/assets/Icons/TrashIconSVG';
import { Wrapper, Upload, UploadWrapper, UploadSpecs, Button } from './styles';

interface IUploadProps extends InputHTMLAttributes<HTMLInputElement> {
  children: string;
  className?: string;
  handleUpload: (image: File | null) => void;
  content: any;
  handlePreview: (image: File) => void;
  note?: string;
}

const UploadField: FC<IUploadProps> = ({
  children,
  className,
  content,
  handleUpload,
  handlePreview,
  note,
  ...props
}) => {
  const hiddenFileInput = useRef<any>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    const image = (event.target as HTMLInputElement).files?.[0];
    handleUpload(image || null);
  };

  const handleDeleteFile = () => {
    props.name && handleUpload(null);
  };

  const breakLineOfString = (texto: string) => {
    console.log(texto.split('\n'));
    return texto.split('\n').map((linha, index, array) =>
      index === array.length - 1 ? (
        linha
      ) : (
        <React.Fragment key={linha}>
          {linha}
          <br />
        </React.Fragment>
      ),
    );
  };

  return (
    <>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        value={content ? `C:\\fakepath\\${content?.name}` : ''}
        style={{ display: 'none' }}
        accept=".pdf,.jpg,.png"
        {...props}
      />
      {content ? (
        <Wrapper className={className}>
          <UploadWrapper>
            <Button onClick={handleClick}>
              {content.type === 'application/pdf' ? (
                <embed src={URL.createObjectURL(content)} type="application/pdf" />
              ) : (
                <img src={URL.createObjectURL(content)} alt={content.name} />
              )}
            </Button>
            <Upload
              className="archive"
              selected
              type="button"
              onClick={() => handlePreview(content)}
            >
              {content.name.length > 20
                ? `${content.name.slice(0, 20)}...`
                : content.name}
              <span>Ver arquivo</span>
            </Upload>
            <Button className="empty" type="button" onClick={handleDeleteFile}>
              <TrashIconSVG />
            </Button>
          </UploadWrapper>
        </Wrapper>
      ) : (
        <Wrapper className={className}>
          <UploadWrapper>
            <Upload type="button" onClick={handleClick}>
              {breakLineOfString(children)}
              {note && (
                <>
                  <br />
                  <i>{note}</i>
                </>
              )}
            </Upload>
            <Button className="right" type="button" onClick={handleClick}>
              <UploadSVG />
            </Button>
          </UploadWrapper>
          <UploadSpecs>Envie no formato PDF ou JPG</UploadSpecs>
        </Wrapper>
      )}
    </>
  );
};

export { UploadField };
