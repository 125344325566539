import { createBrowserHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session';
import { persistReducer, persistStore } from 'redux-persist';
import { thunk } from 'redux-thunk';

import rootReducer from './modules/rootReducer';

import configsAppJsonImport from '~/configs/app.config';
import { apiCache } from './cache/apiCache';

const configsAppJson = configsAppJsonImport as any;

const history = createBrowserHistory();

const middleware = [apiCache.middleware, thunk];

const persistConfig = {
  key: 'root',
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, rootReducer());

const store = configureStore({
  reducer: persistedReducer,
  devTools: configsAppJson.NODE_ENV !== 'PRD',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middleware),
});

const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export { history, store, persistor };
