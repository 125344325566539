import React, { FC, useEffect, useState } from 'react';
import DropZone from '../../../../components/DropZone';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import convertFileToBase64 from '~/utils/convertBase64';
import { Margin } from '~/components/Margin';
import { Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import ButtonBar from '../../../../components/ButtonBar';
import {
  Main,
  DescriptionTitle,
  ButtonInfo,
  ContainerGenerateCode,
  BoxGeneratedCode,
  ContainerTitle,
  SeparatorTitle,
  Title,
  ContentGenerateCode,
} from './styles';
import {
  getFileCodesPropertyRegistrationRequest,
  patchAttachmentPropertyRegistrationRequest,
  postCreateFileCodesPropertyRegistrationRequest,
  postUploadFilePropertyRegistrationRequest,
} from '~/store/modules/propertyRegistration/actions';
import ArrowUpSVG from '~/assets/Icons/arrowUp';
import modeloCarimbo from '../Modelo do Carimbo de OC.dwg';
import manualDesenho from '../Manual de Desenho Tecnico.pdf';
import StatusInfo from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/PropertyRegistration/components/StatusInfo';
import { Link } from 'react-router-dom';
import { AppDispatch } from '~/store';

const Water: FC = () => {
  const fileCodes = useSelector(
    (item: IState) => item.propertyRegistration.fileCodes,
  );
  const loadingUploadFile = useSelector(
    (item: IState) => item.propertyRegistration.loadingUploadFile,
  );
  const loadingItem = useSelector(
    (item: IState) => item.propertyRegistration.loadingItem,
  );

  const caseFCP = useSelector((item: IState) => item.propertyRegistration.dataItem);
  const [documents, setDocuments] = useState<any[]>([
    {
      key: 'CARRIED_OUT_SUPPLY_WORK_MEMORIAL_DESCRIPTIVE',
      label: 'Memorial descritivo da obra de abastecimento a ser executada*',
      isActive: false,
      file: null,
    },
    {
      key: 'CARRIED_OUT_SUPPLY_WORK_CALCULATION_MEMORIAL',
      label: 'Memorial de cálculo da obra de abastecimento a ser executada*',
      isActive: false,
      file: null,
    },
    {
      key: 'FINAL_INTERNAL_SUPPLY_PROJECT',
      label: 'Projeto de abastecimento interno definitivo*',
      isActive: false,
      file: null,
    },
  ]);
  const [disabled, setDisabled] = useState(true);

  const dispatch = useDispatch<AppDispatch>();

  const handleClick = () => {
    return dispatch(
      patchAttachmentPropertyRegistrationRequest({
        caseId: caseFCP?.id || '',
        step: '3B',
      }),
    );
  };

  const verifyDocuments = async () => {
    if (
      documents.filter((document) => !document.file).length > 0 ||
      loadingUploadFile
    ) {
      return setDisabled(true);
    }
    return setDisabled(false);
  };

  useEffect(() => {
    verifyDocuments();
  }, [document, loadingUploadFile]);

  useEffect(() => {
    documents.map((document) => {
      return dispatch(
        getFileCodesPropertyRegistrationRequest({
          case: caseFCP?.id || '',
          documentType: document.key,
          type: caseFCP?.type || 'FCPA',
        }),
      );
    });
  }, []);

  const pushCodeButton = async () => {
    const CARRIED_OUT_SUPPLY_WORK_CALCULATION_MEMORIAL_CODES: Array<string> = [];
    const CARRIED_OUT_SUPPLY_WORK_MEMORIAL_DESCRIPTIVE_CODES: Array<string> = [];
    const FINAL_INTERNAL_SUPPLY_PROJECT_CODES: Array<string> = [];

    await fileCodes.CARRIED_OUT_SUPPLY_WORK_CALCULATION_MEMORIAL.map(
      (CARRIED_OUT_SUPPLY_WORK_CALCULATION_MEMORIAL) => {
        return CARRIED_OUT_SUPPLY_WORK_CALCULATION_MEMORIAL_CODES.push(
          CARRIED_OUT_SUPPLY_WORK_CALCULATION_MEMORIAL.code,
        );
      },
    );

    await fileCodes.CARRIED_OUT_SUPPLY_WORK_MEMORIAL_DESCRIPTIVE.map(
      (CARRIED_OUT_SUPPLY_WORK_MEMORIAL_DESCRIPTIVE) => {
        return CARRIED_OUT_SUPPLY_WORK_MEMORIAL_DESCRIPTIVE_CODES.push(
          CARRIED_OUT_SUPPLY_WORK_MEMORIAL_DESCRIPTIVE.code,
        );
      },
    );

    await fileCodes.FINAL_INTERNAL_SUPPLY_PROJECT.map(
      (FINAL_INTERNAL_SUPPLY_PROJECT) => {
        return FINAL_INTERNAL_SUPPLY_PROJECT_CODES.push(
          FINAL_INTERNAL_SUPPLY_PROJECT.code,
        );
      },
    );

    const array = [...documents];

    const codesDocument = await array.map((document) => {
      if (document.key === 'CARRIED_OUT_SUPPLY_WORK_CALCULATION_MEMORIAL') {
        return {
          ...document,
          codes: CARRIED_OUT_SUPPLY_WORK_CALCULATION_MEMORIAL_CODES,
        };
      }

      if (document.key === 'CARRIED_OUT_SUPPLY_WORK_MEMORIAL_DESCRIPTIVE') {
        return {
          ...document,
          codes: CARRIED_OUT_SUPPLY_WORK_MEMORIAL_DESCRIPTIVE_CODES,
        };
      }

      if (document.key === 'FINAL_INTERNAL_SUPPLY_PROJECT') {
        return {
          ...document,
          codes: FINAL_INTERNAL_SUPPLY_PROJECT_CODES,
        };
      }

      return document;
    });

    setDocuments(codesDocument);
  };

  useEffect(() => {
    pushCodeButton();
  }, [fileCodes]);

  return (
    <>
      <Main>
        <DescriptionTitle>
          Nesta etapa é necessário o anexo de todos os documentos solicitados a
          seguir conforme a orientação.
        </DescriptionTitle>
      </Main>
      <Margin height={64} />
      <Main>
        <>
          <StatusInfo status="info">
            1. Você deve gerar um código por página para cada documento.
            <br />
            <Margin height={8} />
            2. O Projeto deverá ser desenvolvido conforme o{' '}
            <Link to={manualDesenho} target="_blank" download={manualDesenho}>
              Manual de desenho técnico da Iguá.
            </Link>{' '}
            (clique para o download).
            <br />
            <Margin height={8} />
            3. Os arquivos devem ser anexados em <b>formato PDF</b> e com{' '}
            <Link to={modeloCarimbo} target="_blank" download={modeloCarimbo}>
              Carimbo Padrão Iguá.
            </Link>{' '}
            (clique para o download).
          </StatusInfo>
          <Margin height={40} />
          <ContainerTitle>
            <SeparatorTitle />
            <Title>Documentação para projeto de abastecimento</Title>
          </ContainerTitle>
          <Margin height={40} />

          {documents.map((docButton, index: number) => (
            <>
              <ButtonInfo
                key={index && index}
                isActive={docButton.isActive}
                onClick={async () => {
                  const array = await documents.map((item: any, indexItem) => {
                    const e = item;
                    if (index === indexItem) e.isActive = !e.isActive;
                    return e;
                  });

                  setDocuments(array);
                }}
              >
                {docButton.label}
                <ArrowUpSVG />
              </ButtonInfo>
              {docButton.isActive && (
                <ContainerGenerateCode key={docButton.key}>
                  <Margin height={12} />
                  <ContentGenerateCode>
                    <DropZone
                      disabled={!!docButton.file || docButton.codes.length < 1}
                      infoFileName="Anexar arquivo"
                      content={docButton.file}
                      name={`file-${index}`}
                      onFileUploaded={(file: File | null) => {
                        const array = [...documents];
                        array[index].file = file;
                        if (file) {
                          convertFileToBase64(file, (base64: string) => {
                            dispatch(
                              postUploadFilePropertyRegistrationRequest({
                                caseIdSales: caseFCP?.id || '',
                                type: docButton.key,
                                step: '3B',
                                filename: file?.name || '',
                                filedata: base64,
                              }),
                            );
                          });
                        }
                        setDocuments(array);
                      }}
                    />
                    <BoxGeneratedCode>
                      <button
                        type="button"
                        onClick={() => {
                          dispatch(
                            postCreateFileCodesPropertyRegistrationRequest({
                              caseIdSales: caseFCP?.id || '',
                              type: 'FCPA',
                              documentType: docButton.key,
                              processNumber: caseFCP?.caseNumber || '',
                            }),
                          );
                        }}
                      >
                        {docButton.codes.length > 0
                          ? 'Gerar mais códigos'
                          : 'Gerar código'}
                      </button>
                      <div>
                        <p>Seus códigos para preenchimento:</p>
                        {docButton.codes.length > 0 ? (
                          docButton.codes.map((code: any, index: number) => (
                            <p key={index && index} className="code">
                              {code}
                            </p>
                          ))
                        ) : (
                          <p>Você ainda não gerou nenhum código.</p>
                        )}
                      </div>
                    </BoxGeneratedCode>
                  </ContentGenerateCode>
                </ContainerGenerateCode>
              )}
            </>
          ))}
        </>
        <Margin height={40} />
        <ButtonBar
          nextBackground={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          nextDisabled={disabled}
          nextLoading={!!loadingUploadFile || !!loadingItem}
          backOnClick={() => Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)}
          nextOnClick={handleClick}
        />
        <Margin height={50} />
      </Main>
    </>
  );
};

export default Water;
