import styled from 'styled-components';
import { Column, Row, TitleCard } from '../styles';
import { Color, Breakpoints } from '~/styles';
import { Text } from '~/components/TextInfo';

export { Column, Row, TitleCard };

export const Container = styled.div`
  width: 100%;
  background-color: #fafafa;
`;

export const OwerServices = styled(Row)`
  max-width: 1010px;
  margin: 0 auto;
  height: auto;
  width: -webkit-fill-available;
  align-items: flex-start;
  flex-direction: column;
  @media (max-width: ${Breakpoints.laptop.max}px) {
    align-items: flex-start;
    height: auto;
    padding: 30px 100px;
  }
  @media (max-width: ${Breakpoints.tablet.max}px) {
    padding: 17px 30px;
    overflow: scroll;
  }
  @media (max-width: ${Breakpoints.mobile.max}px) {
    height: auto;
  }
`;

export const RowServices = styled.div`
  display: flex;
  flex-direction: row;
`;

interface IBtn {
  bgColor: 'green' | 'blue' | 'red' | 'blueIntense' | 'yellow';
  withMargin?: boolean;
}

export const ButtonLink = styled.button<IBtn>`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  width: 103px;
  height: 103px;
  border-radius: 9px;
  border: none;
  background-color: ${({ bgColor }) =>
    bgColor === 'green'
      ? Color.green
      : bgColor === 'blue'
        ? Color.blueSeconde
        : bgColor === 'red'
          ? Color.redSecond
          : bgColor === 'yellow'
            ? Color.yellow
            : Color.blueIntense};
  box-shadow: 0px 2.76838px 17.9945px rgba(0, 0, 0, 0.19);

  & svg {
    height: 28px;
    margin-top: 20px;
  }

  margin-right: ${({ withMargin }) => (withMargin ? '23px' : '0')};

  @media (max-width: ${Breakpoints.laptop.max}px) {
    width: 105px;
    height: 105px;
    & svg {
      margin-top: 20px;
    }
  }
  @media (max-width: 565px) {
    display: flex;
  }

  @media (max-width: ${Breakpoints.mobile.max}px) {
    width: 90px;
    height: 90px;
    & svg {
      margin-top: 15px;
    }
    margin-left: 7px;
    margin-right: 7px;
  }
  @media (max-width: 372px) {
    margin-right: 5px;
    width: auto;
    min-width: 75px;
    height: 75px;
  }
`;

export const ButtonText = styled(Text).attrs({
  color: '#FCFCFC',
  size: 12,
  weight: 600,
  textAlign: 'center',
})`
  @media (max-width: ${Breakpoints.laptop.max}px) {
    font-size: 12px;
  }
  @media (max-width: ${Breakpoints.mobile.max}px) {
    font-size: 10px;
  }
  @media (max-width: 372px) {
    margin-top: -7px;
  }
`;
