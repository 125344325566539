import React, { FC, useEffect, useState } from 'react';
import { Td, Th, Tr, TableContainer, Input, Container, Label } from './styles';
import { Subtitle } from '../styles';
import { Margin } from '~/components/Margin';

interface IProps {
  form: any;
  formBoolean: {
    hasWashingMachine: boolean;
    hasWashingTank: boolean;
    hasKitchen: boolean;
    accordingly: boolean;
  };
  onChange: (e: string, type: string) => void;
  setForm: (prevState: any) => void;
}

const Table: FC<IProps> = ({ form, formBoolean, onChange, setForm }: IProps) => {
  const [internalInspectionBox, setInternalInspectionBox] = useState(false);
  const [soapBox, setSoapBox] = useState(false);
  const [greaseTrap, setGreaseTrap] = useState(false);
  const [irainWaterTank, setIrainWaterTank] = useState(false);

  useEffect(() => {
    const { hasKitchen, hasWashingMachine, hasWashingTank } = formBoolean;

    if (hasWashingMachine || hasWashingTank || hasKitchen) {
      setInternalInspectionBox(true);
      setIrainWaterTank(true);
    } else {
      setInternalInspectionBox(false);
      setIrainWaterTank(false);
    }

    if (hasWashingMachine || hasWashingTank) {
      setSoapBox(true);
    } else {
      setSoapBox(false);
    }

    if (hasKitchen) {
      setGreaseTrap(true);
    } else {
      setGreaseTrap(false);
    }
  }, [formBoolean]);

  useEffect(() => {
    if (internalInspectionBox) {
      setForm((prevState: any) => ({
        ...prevState,
        internalInspectionBoxLength: {
          ...prevState.internalInspectionBoxLength,
          isRequired: true,
        },
        internalInspectionBoxWidth: {
          ...prevState.internalInspectionBoxWidth,
          isRequired: true,
        },
        internalInspectionBoxDepth: {
          ...prevState.internalInspectionBoxDepth,
          isRequired: true,
        },
      }));
    } else {
      setForm((prevState: any) => ({
        ...prevState,
        internalInspectionBoxLength: {
          ...prevState.internalInspectionBoxLength,
          isRequired: false,
        },
        internalInspectionBoxWidth: {
          ...prevState.internalInspectionBoxWidth,
          isRequired: false,
        },
        internalInspectionBoxDepth: {
          ...prevState.internalInspectionBoxDepth,
          isRequired: false,
        },
      }));
    }

    if (soapBox) {
      setForm((prevState: any) => ({
        ...prevState,
        soapBoxLength: {
          ...prevState.soapBoxLength,
          isRequired: true,
        },
        soapBoxWidth: {
          ...prevState.soapBoxWidth,
          isRequired: true,
        },
        soapBoxDepth: {
          ...prevState.soapBoxDepth,
          isRequired: true,
        },
      }));
    } else {
      setForm((prevState: any) => ({
        ...prevState,
        soapBoxLength: {
          ...prevState.soapBoxLength,
          isRequired: false,
        },
        soapBoxWidth: {
          ...prevState.soapBoxWidth,
          isRequired: false,
        },
        soapBoxDepth: {
          ...prevState.soapBoxDepth,
          isRequired: false,
        },
      }));
    }

    if (irainWaterTank) {
      setForm((prevState: any) => ({
        ...prevState,
        irainWaterTankLength: {
          ...prevState.irainWaterTankLength,
          isRequired: true,
        },
        irainWaterTankWidth: {
          ...prevState.irainWaterTankWidth,
          isRequired: true,
        },
        irainWaterTankDepth: {
          ...prevState.irainWaterTankDepth,
          isRequired: true,
        },
      }));
    } else {
      setForm((prevState: any) => ({
        ...prevState,
        irainWaterTankLength: {
          ...prevState.irainWaterTankLength,
          isRequired: false,
        },
        irainWaterTankWidth: {
          ...prevState.irainWaterTankWidth,
          isRequired: false,
        },
        irainWaterTankDepth: {
          ...prevState.irainWaterTankDepth,
          isRequired: false,
        },
      }));
    }

    if (greaseTrap) {
      setForm((prevState: any) => ({
        ...prevState,
        greaseTrapLength: {
          ...prevState.greaseTrapLength,
          isRequired: true,
        },
        greaseTrapWidth: {
          ...prevState.greaseTrapWidth,
          isRequired: true,
        },
        greaseTrapDepth: {
          ...prevState.greaseTrapDepth,
          isRequired: true,
        },
      }));
    } else {
      setForm((prevState: any) => ({
        ...prevState,
        greaseTrapLength: {
          ...prevState.greaseTrapLength,
          isRequired: false,
        },
        greaseTrapWidth: {
          ...prevState.greaseTrapWidth,
          isRequired: false,
        },
        greaseTrapDepth: {
          ...prevState.greaseTrapDepth,
          isRequired: false,
        },
      }));
    }
  }, [internalInspectionBox, soapBox, greaseTrap, irainWaterTank]);

  if (!internalInspectionBox && !soapBox && !greaseTrap && !irainWaterTank) {
    return null;
  }

  return (
    <>
      <Subtitle>
        Preencha todos os campos abaixo, indicando as dimensões: (diâmetro, caso
        redondo)*
      </Subtitle>
      <Margin height={50} />
      <TableContainer>
        <Tr color="gray">
          <Th />
          <Th>Comprimento (mm)</Th>
          <Th>Largura (mm)</Th>
          <Th>Profundidade (mm)</Th>
        </Tr>
        {greaseTrap && (
          <Tr color="white">
            <Td>Caixa de gordura</Td>
            <Td>
              <Container>
                <Input
                  error={!!form.greaseTrapLength.error}
                  value={form.greaseTrapLength.value}
                  onChange={(e) => onChange(e.target.value, 'greaseTrapLength')}
                />
                <Label error={!!form.greaseTrapLength.error}>Milímetros</Label>
              </Container>
            </Td>
            <Td>
              <Container>
                <Input
                  error={!!form.greaseTrapWidth.error}
                  value={form.greaseTrapWidth.value}
                  onChange={(e) => onChange(e.target.value, 'greaseTrapWidth')}
                />
                <Label error={!!form.greaseTrapWidth.error}>Milímetros</Label>
              </Container>
            </Td>
            <Td>
              <Container>
                <Input
                  error={!!form.greaseTrapDepth.error}
                  value={form.greaseTrapDepth.value}
                  onChange={(e) => onChange(e.target.value, 'greaseTrapDepth')}
                />
                <Label error={!!form.greaseTrapDepth.error}>Milímetros</Label>
              </Container>
            </Td>
          </Tr>
        )}
        {soapBox && (
          <Tr color="lightGray">
            <Td>Caixa de sabão</Td>
            <Td>
              <Container>
                <Input
                  error={!!form.soapBoxLength.error}
                  value={form.soapBoxLength.value}
                  onChange={(e) => onChange(e.target.value, 'soapBoxLength')}
                />
                <Label error={!!form.soapBoxLength.error}>Milímetros</Label>
              </Container>
            </Td>
            <Td>
              <Container>
                <Input
                  error={!!form.soapBoxWidth.error}
                  value={form.soapBoxWidth.value}
                  onChange={(e) => onChange(e.target.value, 'soapBoxWidth')}
                />
                <Label error={!!form.soapBoxWidth.error}>Milímetros</Label>
              </Container>
            </Td>
            <Td>
              <Container>
                <Input
                  error={!!form.soapBoxDepth.error}
                  value={form.soapBoxDepth.value}
                  onChange={(e) => onChange(e.target.value, 'soapBoxDepth')}
                />
                <Label error={!!form.soapBoxDepth.error}>Milímetros</Label>
              </Container>
            </Td>
          </Tr>
        )}
        {internalInspectionBox && (
          <Tr color="white">
            <Td>Caixa de inspeção interna</Td>
            <Td>
              <Container>
                <Input
                  error={!!form.internalInspectionBoxLength.error}
                  value={form.internalInspectionBoxLength.value}
                  onChange={(e) =>
                    onChange(e.target.value, 'internalInspectionBoxLength')
                  }
                />
                <Label error={!!form.internalInspectionBoxLength.error}>
                  Milímetros
                </Label>
              </Container>
            </Td>
            <Td>
              <Container>
                <Input
                  error={!!form.internalInspectionBoxWidth.error}
                  value={form.internalInspectionBoxWidth.value}
                  onChange={(e) =>
                    onChange(e.target.value, 'internalInspectionBoxWidth')
                  }
                />
                <Label error={!!form.internalInspectionBoxWidth.error}>
                  Milímetros
                </Label>
              </Container>
            </Td>
            <Td>
              <Container>
                <Input
                  error={!!form.internalInspectionBoxDepth.error}
                  value={form.internalInspectionBoxDepth.value}
                  onChange={(e) =>
                    onChange(e.target.value, 'internalInspectionBoxDepth')
                  }
                />
                <Label error={!!form.internalInspectionBoxDepth.error}>
                  Milímetros
                </Label>
              </Container>
            </Td>
          </Tr>
        )}
        {irainWaterTank && (
          <Tr color="lightGray">
            <Td>Caixa de águas pluviais</Td>
            <Td>
              <Container>
                <Input
                  error={!!form.irainWaterTankLength.error}
                  value={form.irainWaterTankLength.value}
                  onChange={(e) => onChange(e.target.value, 'irainWaterTankLength')}
                />
                <Label error={!!form.irainWaterTankLength.error}>Milímetros</Label>
              </Container>
            </Td>
            <Td>
              <Container>
                <Input
                  error={!!form.irainWaterTankWidth.error}
                  value={form.irainWaterTankWidth.value}
                  onChange={(e) => onChange(e.target.value, 'irainWaterTankWidth')}
                />
                <Label error={!!form.irainWaterTankWidth.error}>Milímetros</Label>
              </Container>
            </Td>
            <Td>
              <Container>
                <Input
                  error={!!form.irainWaterTankDepth.error}
                  value={form.irainWaterTankDepth.value}
                  onChange={(e) => onChange(e.target.value, 'irainWaterTankDepth')}
                />
                <Label error={!!form.irainWaterTankDepth.error}>Milímetros</Label>
              </Container>
            </Td>
          </Tr>
        )}
      </TableContainer>
    </>
  );
};

export default Table;
