import { IRequestRegisterCase } from '../store/modules/dpadpe/types';
import { IRequestRegisterCharacter } from '../store/modules/dpadpeDataMenager/types';
import { api } from './api';

export interface IPropsRequest {
  cpae: boolean;
  oc: boolean;
  fcp: boolean;
  page: number;
  limit: number;
  protocol: string;
  status: string;
}

export interface IPropsRequestSlip {
  cancelled: boolean;
  pending: boolean;
  paid: boolean;
  page: number;
  limit: number;
  protocol: string;
}

function getListCases({
  cpae,
  oc,
  fcp,
  page,
  limit,
  protocol,
  status,
}: IPropsRequest) {
  return new Promise((resolve, reject) => {
    try {
      const getCases: any = async () => {
        const response = await api.get(
          'v3/supply-depletion-possibility-declaration/cases',
          {
            params: {
              cpae,
              oc,
              fcp,
              page,
              limit,
              protocol,
              status,
            },
          },
        );
        return response.data;
      };
      resolve(getCases());
    } catch (error) {
      reject(error);
    }
  });
}

function getListSlips({
  cancelled,
  pending,
  paid,
  page,
  limit,
  protocol,
}: IPropsRequestSlip) {
  return new Promise((resolve, reject) => {
    try {
      const getCases: any = async () => {
        const response = await api.get(
          'v3/supply-depletion-possibility-declaration/slips',
          {
            params: {
              cancelled,
              pending,
              paid,
              page,
              limit,
              protocol,
            },
          },
        );
        return response.data;
      };
      resolve(getCases());
    } catch (error) {
      reject(error);
    }
  });
}

function getSlip({ slipId }: { slipId: string }) {
  return new Promise((resolve, reject) => {
    try {
      const getSlip: any = async () => {
        const response = await api.get(
          `v3/supply-depletion-possibility-declaration/slips/${slipId}`,
        );
        return response.data;
      };
      resolve(getSlip());
    } catch (error) {
      reject(error);
    }
  });
}

function downloadSlipService({ slipId }: { slipId: string }) {
  return new Promise((resolve, reject) => {
    try {
      const getSlip: any = async () => {
        const response = await api.get(
          `v3/supply-depletion-possibility-declaration/slips/${slipId}/download`,
          {
            responseType: 'blob',
          },
        );
        return response.data;
      };
      resolve(getSlip());
    } catch (error) {
      reject(error);
    }
  });
}

function getSupplyPossibilityConsultationCase(id: string) {
  return new Promise((resolve, reject) => {
    try {
      const getCase: any = async () => {
        const response = await api.get(
          `v3/supply-possibility-consultation/case/${id}`,
        );
        return response.data;
      };
      resolve(getCase());
    } catch (error) {
      reject(error);
    }
  });
}

function createSupplyPossibilityConsultationCase(data: IRequestRegisterCase) {
  return new Promise((resolve, reject) => {
    try {
      const registerCase: any = async () => {
        const response = await api.post(
          'v3/supply-possibility-consultation/cases',
          data,
          {
            timeout: 600000,
          },
        );

        return response.data;
      };
      resolve(registerCase());
    } catch (error) {
      reject(error);
    }
  });
}

function updateSupplyPossibilityConsultationCase(
  data: IRequestRegisterCase,
  case_id: string,
) {
  return new Promise((resolve, reject) => {
    try {
      const registerCase: any = async () => {
        const response = await api.patch(
          `v3/supply-possibility-consultation/cases/${case_id}`,
          data,
          {
            timeout: 120000,
          },
        );

        return response.data;
      };
      resolve(registerCase());
    } catch (error) {
      reject(error);
    }
  });
}

function getOperationsService() {
  return new Promise((resolve, reject) => {
    try {
      const getOperations: any = async () => {
        const response = await api.get(
          `v3/supply-depletion-possibility-declaration/operations`,
        );
        return response.data;
      };
      resolve(getOperations());
    } catch (error) {
      reject(error);
    }
  });
}

function createSupplyPossibilityConsultationCharacteristics(
  data: IRequestRegisterCharacter[],
  case_id: string,
) {
  return new Promise((resolve, reject) => {
    try {
      const registerCharacter: any = async () => {
        const response = await api.post(
          `v3/supply-possibility-consultation/cases/${case_id}/characteristics`,
          data,
        );
        return response.data;
      };
      resolve(registerCharacter());
    } catch (error) {
      reject(error);
    }
  });
}

function getListStatuses() {
  return new Promise((resolve, reject) => {
    try {
      const getStatuses: any = async () => {
        const response = await api.get(
          'v3/supply-depletion-possibility-declaration/statuses',
        );
        return response.data;
      };
      resolve(getStatuses());
    } catch (error) {
      reject(error);
    }
  });
}

export {
  getListCases,
  getListSlips,
  getSlip,
  downloadSlipService,
  getOperationsService,
  getListStatuses,
  getSupplyPossibilityConsultationCase,
  createSupplyPossibilityConsultationCase,
  updateSupplyPossibilityConsultationCase,
  createSupplyPossibilityConsultationCharacteristics,
};
