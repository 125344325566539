import React from 'react';
import { useDispatch } from 'react-redux';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import ReactGA from 'react-ga4';

import { Margin } from '~/components/Margin';
import {
  AmountText,
  Border,
  ButtonPayment,
  Container,
  ContainerInfo,
  DueDateText,
  MonthText,
  PaymentText,
  ButtonWrap,
  BoxHeader,
} from './styles';
import { IEasyInvoices } from '~/store/modules/outsides/types';
import formatter from '~/utils/formatter';
import { statusColor } from '~/utils/invoiceStatus';
import ArrowSVG from '~/assets/Icons/Arrow';
import { getOutsidesInvoicesInstallmentsRequest } from '~/store/modules/outsides/actions';
import { RouteName } from '~/routes/Routes.name';
import { AppDispatch } from '~/store';

interface IProps {
  data: IEasyInvoices;
  cnp: string;
  enrollment: string;
}

const Invoice: React.FC<IProps> = ({ data, cnp, enrollment }) => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Container>
      <Border color={statusColor(data?.status?.label || '', true)} />
      <ContainerInfo>
        <BoxHeader>
          <MonthText>{`${format(new Date(data?.issue_date_hour), 'MMMM', {
            locale: ptBR,
          })}/${format(new Date(data?.issue_date_hour), 'yyyy')}`}</MonthText>
          <DueDateText color={statusColor(data?.status?.label || '', true)}>
            {data?.status?.label === 'VENCIDA' ? 'Vencida ' : 'Vence '} em{' '}
            {moment(data?.maturity_date).format('DD/MM/YY')}
          </DueDateText>
        </BoxHeader>
        <Margin height={16} />
        <PaymentText />
        <Margin height={16} />
        <AmountText color={statusColor(data?.status?.label || '', true)}>
          {formatter.formatCurrency(data?.amount)}
        </AmountText>
        <ButtonWrap>
          <ButtonPayment
            type="button"
            onClick={() => {
              if (data.pix_code) {
                ReactGA.event({
                  category: 'Pagamento',
                  action: 'Pagar 2ª via - PIX',
                });
              } else {
                ReactGA.event({
                  category: 'Pagamento',
                  action: 'Pagar 2ª via - Boleto',
                });

                ReactGA.event({
                  category: 'Pagamento Único',
                  action: '[Ñ Logado][Pag. Única Fatura][WEB] Pagar',
                });

                ReactGA.event({
                  category: 'Pagamento de uma única dívida',
                  action: '[QD][C3][WEB] – Pagamento de uma única dívida',
                });
              }

              dispatch(
                getOutsidesInvoicesInstallmentsRequest(
                  cnp,
                  data?.amount || 0,
                  {
                    typeView: 'detail',
                    invoicesGroup: [
                      {
                        ...data,
                      },
                    ],
                    totalAmount: data?.amount,
                    cnp,
                    enrollment,
                    qr_code_key: data?.pix_code,
                    bar_code_number: data?.bar_code_number,
                  },
                  RouteName.EASYDUPLICATESINGLEPAYMENTDATAPAYMENT,
                ),
              );
            }}
          >
            <p>Pagar</p>
            <ArrowSVG />
          </ButtonPayment>
        </ButtonWrap>
      </ContainerInfo>
    </Container>
  );
};

export default Invoice;
