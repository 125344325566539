import React, { useEffect } from 'react';

import Navigation from '~/utils/Navigation';

import { Main, Wrapper } from './styles';
import { Card } from '~/common/Card';
import { Button } from '~/common/Button';
import { RouteName } from '~/routes/Routes.name';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';
import { putCloseSuccessUsersUpdatePassword } from '~/store/modules/enrollmentsUsers/actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '~/store';

const ForgotPasswordSuccess: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(putCloseSuccessUsersUpdatePassword());
  }, []);

  return (
    <Main>
      <Card
        title="senha recuperada"
        style={{ padding: isMobile ? '32px 20px' : '32px 100px', maxWidth: '600px' }}
      >
        <p>
          Senha recuperada com sucesso!
          <br />
          Volte ao login para concluir seu acesso.
        </p>
        <Wrapper>
          <Button onClick={() => Navigation.navigate(RouteName.LOGIN)}>
            Voltar para login
          </Button>
        </Wrapper>
      </Card>
    </Main>
  );
};

export default ForgotPasswordSuccess;
