import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { IState } from '../types';
import {
  IDateQuery,
  IResponseEnrollmentsConsumptionHistory,
  RepositoriesTypesEnrollmentsConsumptionHistory,
} from './types';
import { fetchEnrollmentsConsumptionHistory } from '~/store/cache/apiCache';
import { AppDispatch } from '~/store';

const filterData = async (data: IResponseEnrollmentsConsumptionHistory) => {
  const average = data.historyDataAverage;
  const averageIot = data.iotDataAverage;

  const totalHistoryData = data?.historyData?.reduce(
    (total: any, number: any) => total + number.real_consumption,
    0,
  );

  const historyData = data?.historyData.map((item) => {
    return {
      ...item,
      formatedReference: `${format(new Date(item.read_date_reference), 'dd')} de ${format(
        new Date(item.read_date_reference),
        'MMM',
        {
          locale: ptBR,
        },
      )}.`,
      formatedConsumption: item.real_consumption,
      average_consumption: data.historyDataAverage,
      amt: data.alert?.is_active ? data.alert?.cubic_meter : null,
    };
  });

  const totalHistoryIotData = data?.iotData?.reduce(
    (total: any, number: any) => total + number.consumption_value,
    0,
  );

  const iotData = data?.iotData.map((item) => {
    return {
      ...item,
      formatedReference: `${format(new Date(item.reading_date), 'dd')} de ${format(
        new Date(item.reading_date),
        'MMM',
        {
          locale: ptBR,
        },
      )}.`,
      formatedConsumption: item.consumption_value,
      average_consumption: data.iotDataAverage,
      read_date_reference: item.reading_date,
      amt: data.alert?.is_active ? data.alert?.cubic_meter : null,
    };
  });

  return {
    ...data,
    historyData,
    iotData,
    average,
    averageIot,
    totalHistoryData,
    totalHistoryIotData: totalHistoryIotData.toFixed(2),
  };
};

const getEnrollmentsConsumptionHistoryRequest =
  (base?: IDateQuery) => async (dispatch: AppDispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesEnrollmentsConsumptionHistory.ENROLLMENTS_GET_CONSUMPTION_HISTORY_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = await dispatch(
        fetchEnrollmentsConsumptionHistory.initiate({
          enrollmentId: registration,
          urlQuery: base,
        }),
      );

      if (response.error) throw response.error;

      return dispatch({
        type: RepositoriesTypesEnrollmentsConsumptionHistory.ENROLLMENTS_GET_CONSUMPTION_HISTORY_METHOD_SUCCESS,
        payload: {
          data: response?.data ? await filterData(response.data) : null,
          loading: false,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesEnrollmentsConsumptionHistory.ENROLLMENTS_GET_CONSUMPTION_HISTORY_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
          loading: false,
        },
      });
    }
  };

export { getEnrollmentsConsumptionHistoryRequest };
