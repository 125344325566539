import React from 'react';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Routes from '../routes';
import { history, store, persistor } from '../store';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

export default function () {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HistoryRouter history={history}>
          <Routes />
        </HistoryRouter>
      </PersistGate>
    </Provider>
  );
}
