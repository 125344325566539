import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Main, ButtonInfo, ContainerGenerateCode, BoxGeneratedCode } from './styles';
import StatusInfo from '../../../components/StatusInfo';
import modeloCarimbo from './Modelo do Carimbo de OC.dwg';
import manualDesenho from './Manual de Desenho Tecnico.pdf';
import ArrowUpSVG from '~/assets/Icons/arrowUp';
import { Margin } from '~/components/Margin';
import DropZone from '../DropZone';
import {
  getFileCodesCustomerObligationRequest,
  patchAttachFilesCustomerObligationRequest,
  patchStatusStepCustomerObligationRequest,
  postCreateFileCodesCustomerObligationRequest,
  postUploadFileCustomerObligationRequest,
  setSuccessAttachFilesCodeTrue,
} from '~/store/modules/customerObligation/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import convertFileToBase64 from '~/utils/convertBase64';
import {
  ContainerButton,
  Subtitle,
} from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/styles';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import sourceDocuments from './documents.json';
import {
  EnumCustomerObligationFilesStep,
  stepStatusEnum,
} from '~/enums/customerObligationEnum';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { isArrayContained } from '~/utils/validation';
import { AppDispatch } from '~/store';

export interface IDocuments {
  key:
    | 'executed_work_descriptive_memorial'
    | 'executed_work_calculation_memorial'
    | 'executed_work_executive_project';
  label: string;
  isActive: boolean;
  file: null | File;
  codes: Array<string>;
  description?: string;
  id?: string;
  attachKey?: string;
  error?: string;
}

const FormGenerateCode: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);

  const idCreate = useSelector((item: IState) => item.customerObligation.idCreate);

  const fileCodes = useSelector((item: IState) => item.customerObligation.fileCodes);
  const loadingAttachFiles = useSelector(
    (item: IState) => item.customerObligation.loadingAttachFiles,
  );

  const successAttachFilesCode = useSelector(
    (item: IState) => item.customerObligation.successAttachFilesCode,
  );

  const loadingUploadFile = useSelector(
    (item: IState) => item.customerObligation.loadingUploadFile,
  );
  const isRevision = caseOC?.attachments.find(
    (attachment) => attachment.review === true,
  );

  const [disabled, setDisabled] = useState<boolean>(false);
  const [documents, setDocuments] = useState<IDocuments[]>(
    sourceDocuments as IDocuments[],
  );

  const handleClick = () => {
    if (caseOC?.realStep.number === 2 && caseOC?.realStep.substep === 'B') {
      return dispatch(
        patchAttachFilesCustomerObligationRequest({
          case: idCreate || caseOC?.id || '',
          step: '2B',
        }),
      );
    }
    return dispatch(
      patchStatusStepCustomerObligationRequest({
        name: '',
        number: 2,
        substep: 'B',
        status: stepStatusEnum.SUCCESS,
      }),
    );
  };

  const verifyAlreadyUploadDocuments = () => {
    const [
      executed_work_calculation_memorial,
      executed_work_descriptive_memorial,
      executed_work_executive_project,
    ] = [
      'executed_work_calculation_memorial',
      'executed_work_descriptive_memorial',
      'executed_work_executive_project',
    ].map((type) =>
      caseOC?.attachments.find((attachment) => attachment.type === type),
    );

    if (
      !!executed_work_calculation_memorial &&
      !!executed_work_descriptive_memorial &&
      !!executed_work_executive_project
    )
      dispatch(setSuccessAttachFilesCodeTrue());
  };

  useEffect(() => {
    verifyAlreadyUploadDocuments();
  }, [caseOC?.caseNumber]);

  useEffect(() => {
    if (successAttachFilesCode) setDisabled(true);
  }, [successAttachFilesCode]);

  useEffect(() => {
    const attachmentsTypes = caseOC?.attachments.map(
      (attachment) => attachment.type,
    );
    const isInsertedFiles = isArrayContained(
      EnumCustomerObligationFilesStep.TWO_B,
      attachmentsTypes || [],
    );
    if (!isInsertedFiles) {
      return setDisabled(true);
    }
    if (!documents[0].file || !documents[1].file || !documents[2].file) {
      return setDisabled(true);
    }
    if (loadingUploadFile) {
      return setDisabled(true);
    }

    return setDisabled(false);
  }, [documents, caseOC?.attachments, loadingUploadFile]);

  useEffect(() => {
    if (!caseOC?.approvedDocuments?.OCA) {
      documents.map((document) => {
        return dispatch(
          getFileCodesCustomerObligationRequest({
            case: idCreate || caseOC?.id || '',
            documentType: document.key,
            type: caseOC?.type || 'oca',
          }),
        );
      });
    }
  }, []);

  const pushCodeButton = async () => {
    let executed_work_descriptive_memorial_codes: Array<string> = [];
    let executed_work_calculation_memorial_codes: Array<string> = [];
    let executed_work_executive_project_codes: Array<string> = [];

    await fileCodes.executed_work_descriptive_memorial.map(
      (executed_work_descriptive_memorial) => {
        return executed_work_descriptive_memorial_codes.push(
          executed_work_descriptive_memorial.code,
        );
      },
    );

    await fileCodes.executed_work_calculation_memorial.map(
      (executed_work_calculation_memorial) => {
        return executed_work_calculation_memorial_codes.push(
          executed_work_calculation_memorial.code,
        );
      },
    );

    await fileCodes.executed_work_executive_project.map(
      (executed_work_executive_project) => {
        return executed_work_executive_project_codes.push(
          executed_work_executive_project.code,
        );
      },
    );

    let array = [...documents];

    const codesDocument = await array.map((document) => {
      if (document.key === 'executed_work_descriptive_memorial') {
        return {
          ...document,
          codes: executed_work_descriptive_memorial_codes,
        };
      }

      if (document.key === 'executed_work_calculation_memorial') {
        return {
          ...document,
          codes: executed_work_calculation_memorial_codes,
        };
      }

      if (document.key === 'executed_work_executive_project') {
        return {
          ...document,
          codes: executed_work_executive_project_codes,
        };
      }

      return document;
    });

    setDocuments(codesDocument);
  };

  useEffect(() => {
    pushCodeButton();
  }, [fileCodes]);

  return (
    <>
      {!isRevision && (
        <>
          <Subtitle>
            Nesta etapa é necessário o anexo de todos os documentos solicitados a
            seguir conforme a orientação.
          </Subtitle>
          <Margin height={64} />
        </>
      )}
      <Main>
        <>
          <StatusInfo status="info">
            1. Você deve gerar um código por página para cada documento.
            <br />
            <Margin height={8} />
            2. O Projeto deverá ser desenvolvido conforme o{' '}
            <Link to={manualDesenho} target="_blank">
              Manual de desenho técnico da Iguá.
            </Link>{' '}
            (clique para o download).
            <br />
            <Margin height={8} />
            3. Os arquivos devem ser anexados em <b>formato PDF</b> e com{' '}
            <Link to={modeloCarimbo} target="_blank">
              Carimbo Padrão Iguá.
            </Link>{' '}
            (clique para o download).
          </StatusInfo>
          <Margin height={40} />
          {documents.map((docButton, index: number) => (
            <>
              <ButtonInfo
                key={index && index}
                isActive={docButton.isActive}
                onClick={async () => {
                  const array = await documents.map((item: any, indexItem) => {
                    item.isActive = false;
                    if (index === indexItem) item.isActive = true;
                    return item;
                  });

                  setDocuments(array);
                }}
              >
                {docButton.label}
                <ArrowUpSVG />
              </ButtonInfo>
              {docButton.isActive && (
                <div key={docButton.key}>
                  <Margin height={12} />
                  <ContainerGenerateCode>
                    <DropZone
                      disabled={!!docButton.file || docButton.codes.length < 1}
                      infoFileName="Anexar arquivo"
                      content={docButton.file}
                      name={`file-${index}`}
                      onFileUploaded={(file: File | null) => {
                        const array = [...documents];
                        if (file && file.size < 100) {
                          array[index].error = 'Por favor, verifique o seu arquivo.';
                          return setDocuments(array);
                        }
                        array[index].file = file;
                        if (file === null) {
                          array[index].error = '';
                          return setDocuments(array);
                        }
                        if (file) {
                          convertFileToBase64(file, (base64: string) => {
                            dispatch(
                              postUploadFileCustomerObligationRequest({
                                caseIdSales: idCreate || caseOC?.id || '',
                                type: docButton.key,
                                step: '2B',
                                filename: file?.name || '',
                                filedata: base64,
                              }),
                            );
                          });
                        }

                        return setDocuments(array);
                      }}
                      error={!!docButton.error}
                      errorText={docButton.error}
                    />
                    <BoxGeneratedCode>
                      <button
                        type="button"
                        onClick={() => {
                          dispatch(
                            postCreateFileCodesCustomerObligationRequest({
                              caseIdSales: idCreate || caseOC?.id || '',
                              type: caseOC?.type?.toLocaleLowerCase() || 'oca',
                              documentType: docButton.key,
                              processNumber: caseOC?.caseNumber || ``,
                            }),
                          );
                        }}
                      >
                        Gerar código
                      </button>
                      <div>
                        <p>Seus códigos para preenchimento:</p>
                        {docButton.codes.length > 0 ? (
                          docButton.codes.map((code, index: number) => (
                            <p key={index && index} className="code">
                              {code}
                            </p>
                          ))
                        ) : (
                          <p>Você ainda não gerou nenhum código.</p>
                        )}
                      </div>
                    </BoxGeneratedCode>
                  </ContainerGenerateCode>
                </div>
              )}
            </>
          ))}
        </>
      </Main>
      <Margin height={40} />
      <ContainerButton isRevision={!!isRevision}>
        <Button
          backIcon
          backgroundColor={Color.green}
          onClick={() => Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)}
        >
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          style={{ opacity: 1 }}
          onClick={handleClick}
          loading={loadingUploadFile || loadingAttachFiles}
          disabled={disabled}
        >
          Continuar
        </Button>
      </ContainerButton>
    </>
  );
};

export default FormGenerateCode;
