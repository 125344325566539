import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';
import { Main, NavWrapper } from './styles';
import {
  postCloseErrorEnrollmentsValidateSmsRegister,
  postEnrollmentsUsersSendRecoveryRequest,
  postEnrollmentsUsersSendRecoveryRecoveryRequest,
  postCloseEnrollmentsUsersSendRecovery,
} from '~/store/modules/enrollmentsUsers/actions';
import Navigation from '~/utils/Navigation';
import { history } from '~/store';
import { IRequestSendRecovery } from '~/store/modules/enrollmentsUsers/types';
import { EnumResponseCode } from '~/enums/reponseCodesEnum';
import ModalDefaultError from '~/design_system/components/ModalDefaultError';
import { CardForm } from '~/design_system/components/CardForm';
import { Button } from '~/design_system/components/Button';
import { IEnumButtonVariant } from '~/design_system/components/Button/enum';
import { InputToken } from '~/design_system/components/InputToken';
import Message from '~/design_system/components/Message';
import { IEnumMessageType } from '~/design_system/components/Message/enum';
import { AppDispatch } from '~/store';

const ForgotPasswordCheckCode: React.FC = () => {
  const dataHistory = history.location.state as IRequestSendRecovery;

  const dispatch = useDispatch<AppDispatch>();

  const loading = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingValidateSmsRegister,
  );
  const loadingSendCodeRecovery = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingSendCodeRecovery,
  );
  const dataSendCode = useSelector(
    (item: IState) => item.enrollmentsUsers.dataSendCode,
  );
  const errorSendCodeRecovery = useSelector(
    (item: IState) => item.enrollmentsUsers.errorSendCodeRecovery,
  );
  const error = useSelector(
    (item: IState) => item.enrollmentsUsers.errorValidateSmsRegister,
  );
  const [code, setCode] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [helperMessage, setHelperMessage] = useState<string>('');
  const [tokenSent, setTokenSent] = useState<boolean>(true);
  const [counter, setCounter] = useState<number>(dataSendCode?.expiresDate || 60);

  const errorNumberAttemptsExceeded =
    error?.type === EnumResponseCode.EXCESS_ATTEMPTS;
  const titleCard =
    dataHistory && dataHistory?.shippingChannel === 'email' ? 'e-mail' : 'SMS';
  const descriptionCard =
    dataHistory && dataHistory?.shippingChannel === 'email' ? 'email.' : 'número.';

  useEffect(() => {
    dispatch(postCloseErrorEnrollmentsValidateSmsRegister());
    dispatch(postCloseEnrollmentsUsersSendRecovery());
  }, []);

  useEffect(() => {
    if (code.length >= 6 && !errorNumberAttemptsExceeded) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [code, errorNumberAttemptsExceeded]);

  useEffect(() => {
    if (error) return setHelperMessage('Código incorreto.');

    const interval: any = setInterval(() => {
      if (tokenSent && counter > 0) {
        setHelperMessage(`Código expira em ${counter} segundos`);
        return setCounter(counter - 1);
      }

      setHelperMessage('');

      return setTokenSent(false);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [error, tokenSent, counter]);

  const handleClick = async () => {
    if (tokenSent) return;

    await dispatch(
      postEnrollmentsUsersSendRecoveryRequest({
        cnp: dataHistory?.cnp || '',
        shippingChannel: dataHistory?.shippingChannel,
      }),
    );

    setTokenSent(true);
    setCounter(dataSendCode?.expiresDate || 60);
  };

  useEffect(() => {
    if (errorNumberAttemptsExceeded) {
      setCode('');
      setHelperMessage('');
      setTokenSent(false);
      setCounter(dataSendCode?.expiresDate || 60);
    }
  }, [error, errorNumberAttemptsExceeded]);

  return (
    <Main>
      {!!errorSendCodeRecovery?.state && (
        <ModalDefaultError
          onClick={() => dispatch(postCloseEnrollmentsUsersSendRecovery())}
        />
      )}
      <CardForm
        title={`Confirmação via ${titleCard}`}
        description={`Para seguir, favor informar o código de confirmação enviado para seu  ${descriptionCard}`}
        maxWidth={361}
        doubleGap
      >
        <InputToken
          valueLength={6}
          value={code}
          handleChange={(e) => {
            setCode(formatter.formatarNumber(e) || '');
            if (error) {
              dispatch(postCloseErrorEnrollmentsValidateSmsRegister());
              setHelperMessage('');
            }
          }}
          helperMessage={helperMessage}
          error={!!error}
          onClick={handleClick}
          disabled={errorNumberAttemptsExceeded}
        />
        {errorNumberAttemptsExceeded && (
          <Message
            type={IEnumMessageType.WARNING}
            title="Atenção!"
            messageText="Número de tentativas excedido. Por favor, refaça o processo de recuperação de senha."
          />
        )}
        <NavWrapper>
          <Button
            onClick={() => {
              if (disabled || loading) return;
              ReactGA.event({
                category: 'Expiração de senha',
                action: '[Expiração de Senha][WEB][Código de Confirmação] Continuar',
              });

              dispatch(
                postEnrollmentsUsersSendRecoveryRecoveryRequest(
                  {
                    cnp: dataHistory?.cnp,
                    token: code,
                  },
                  true,
                ),
              );
            }}
            loading={!!(loading || loadingSendCodeRecovery)}
            disabled={disabled}
          >
            Continuar
          </Button>
          <Button
            variant={IEnumButtonVariant.TEXT}
            onClick={() => Navigation.goBack()}
          >
            Voltar
          </Button>
        </NavWrapper>
      </CardForm>
    </Main>
  );
};

export default ForgotPasswordCheckCode;
