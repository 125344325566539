import React, { FC } from 'react';
import ReactGA from 'react-ga4';
import { Main } from './styles';

import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import { SuccessIcon } from '~/components/ModalCopyDataUpdate/Icons';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { IState } from '~/store/modules/types';
import { useDispatch, useSelector } from 'react-redux';
import { setRouteAuthRedirect } from '~/store/modules/enrollmentsAuth/actions';
import { AppDispatch } from '~/store';

interface IProps {
  onClose: () => void;
}

const ModalNegotiationSuccess: FC<IProps> = ({ onClose }) => {
  const dispatch = useDispatch<AppDispatch>();

  const token = useSelector((state: IState) => state.enrollmentsAuth.token);

  const handleNavigation = () => {
    if (token) {
      Navigation.navigate(RouteName.NEGOTIATIONS);
    } else {
      dispatch(setRouteAuthRedirect(RouteName.NEGOTIATIONS));
      Navigation.navigate(RouteName.LOGIN);
    }
  };

  return (
    <Main>
      <SuccessIcon />
      <h1>Pagamento efetuado</h1>
      <p>
        Você poderá acompanhar o status e detalhes da negociação em
        <button type="button" onClick={handleNavigation}>
          Minhas negociações
        </button>
        .
      </p>
      <ButtonText
        id="ok-modal-sucesso"
        backgroundColor={Color.blueSeconde}
        typeBtn="content"
        text="OK"
        onClick={() => {
          ReactGA.event({
            category: 'Tarifa Social',
            action: `[Ñ Logado][Tarifa Social][Modal de Sucesso] – Botão OK`,
          });
          onClose();
        }}
      />
    </Main>
  );
};

export default ModalNegotiationSuccess;
