import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Routes, Route } from 'react-router-dom';

import { CardNotClient } from '~/components/CardNotClient';
import ModalUpdateEnrollment from '~/components/ModalUpdateEnrollment';
import LayoutApp from '~/components/Layout';
import ChangeEmail from '~/views/ChangeEmail';
import ChangePhone from '~/views/ChangePhone';
import ChangePhoneMobile from '~/views/ChangePhoneMobile';
import Invoices from '~/views/Invoices';
import InvoicesChangeDueDate from '~/views/Invoices/InvoiceChangeDueDate';
import RegistrationsList from '~/views/RegistrationsList';
import SimplifiedInvoiceNew from '~/views/SimplifiedInvoiceV4';
import SimplifiedInvoicePixNew from '~/views/SimplifiedInvoice/PaymentMethods/Pix';
import SimplifiedInvoiceCreditCardNew from '~/views/SimplifiedInvoice/PaymentMethods/CreditCard';
import NegativeCertificatePage from '~/views/NegativeCertificate';
import HomeVersionTwo from '~/views/Home';
import AccountConfig from '~/views/AccountConfig';
import Notifications from '../views/Notifications';
import SmartMeter from '~/views/SmartMeter';
import SupportCenter from '~/views/SupportCenter';
import { IState } from '~/store/modules/types';
import { RouteName } from './Routes.name';

import DetailsHistory from '~/views/DetailsHistory';
import SupplyPossibilityConsultation from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/SupplyPossibilityConsultation';
import AccessProfile from '~/views/AccessProfile';
import Wallet from '~/views/Wallet';
import WalletAddCard from '~/views/WalletAddCard';
import WalletDetailCard from '~/views/WalletDetailCard';
import AutomaticDebit from '~/views/AutomaticDebit';
import SocialTariff from '~/views/SocialTariff';
import SocialTariffSolicitation from '~/views/SocialTariffSolicitation';
import SocialTariffRequest from '~/views/SocialTariffRequest';
import CustomerObligation from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation';
import { Dialog } from '~/components/Dialog';
import {
  clearModalDuplicateInvoices,
  clearModalSalesforceSatisfaction,
  clearRouteReportLackWater,
} from '~/store/modules/outsides/actions';
import {
  clearRouteAuthRedirect,
  getEnrollmentsAuthDestroySessionRequest,
} from '~/store/modules/enrollmentsAuth/actions';
import Navigation from '~/utils/Navigation';
import { checkToggle } from '~/utils/toggles';
import LinkEnrollment from '~/views/LinkEnrollment';
import LinkEnrollmentForm from '~/views/LinkEnrollmentForm';
import { clearModalNotClient } from '~/store/modules/enrollmentsUsersNotClient/actions';
import ModalNotClient from '~/components/ModalNotClient';
import PropertyRegistration from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/PropertyRegistration';
import ChangePassword from '~/views/AccountConfig/Options/Access/changePassword';
import { history } from '~/store';
import SinglePayment from '~/views/SinglePayment';
import SinglePaymentDataPayment from '~/views/SinglePaymentDataPayment';
import SinglePaymentDataPaymentReceipt from '~/views/SinglePaymentDataPaymentReceipt';
import InvoiceChangeDeliveryType from '~/views/Invoices/InvoiceChangeDeliveryType';
import SmartMeterSubscribesPayment from '~/views/SmartMeterSubscribesPayment';
import SupportCenterDetail from '~/views/SupportCenterDetail';
import ModalSalesforceSatisfaction from '~/components/ModalSalesforceSatisfaction';
import SupplyAndDepletionPossibilityDeclarationPanel from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/Panel';
import { EnterpriseViabilityNewProcess } from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/location';
import CpaeValuesTable from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/SupplyPossibilityConsultation/ValuesTable';
import SupplyAndDepletionPossibilityDeclarationPanelSlips from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/PanelSlips';
import { SupplyAndDepletionPossibilityDeclarationKnowMore } from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/KnowMore';
import SimplifiedInvoicePayments from '~/views/SimplifiedInvoicePayments';
import SimplifiedInvoicePaymentsReceipt from '~/views/SimplifiedInvoicePaymentsReceipt';
import SupplyAndDepletionPossibilityDeclarationSlips from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/SlipsPayment';
import ReportLackWater from '~/views/ReportLackWater';
import SupportCenterProtocol from '~/views/SupportCenterProtocol';
import ModalReportLackWaterInfo from '~/components/ModalReportLackWaterInfo';
import LoadingComponent from '~/components/Loading';
import { getErrorCloseServiceValidateLackWaterRequest } from '~/store/modules/orderServices/actions';
import Negotiations from '~/views/Negotiations';
import DebtNegotiation from '~/views/DebtNegotiation';
import DebtNegotiationSinglePayment from '~/views/DebtNegotiationSinglePayment';
import DebtNegotiationSinglePaymentDataPayment from '~/views/DebtNegotiationSinglePaymentDataPayment';
import DebtNegotiationTypePayment from '~/views/DebtNegotiationTypePayment';
import DebtNegotiationSimulation from '~/views/DebtNegotiationSimulation';
import DebtNegotiationDetail from '~/views/DebtNegotiationDetail';
import { AppDispatch } from '~/store';

function RouteAppWithLayout({ Component, titleNotClient }: any) {
  const dispatch = useDispatch<AppDispatch>();

  const dataHistory = history.location.state as any;

  const modalSalesforceSatisfaction = useSelector(
    (item: IState) => item.outsides.modalSalesforceSatisfaction,
  );
  const modalEnrollmentNotExists = useSelector(
    (item: IState) => item.enrollmentsAuth.modalEnrollmentNotExists,
  );
  const modalNotExists = useSelector(
    (item: IState) => item.enrollmentsUsersNotClient.modalNotExists,
  );
  const toggles = useSelector((item: IState) => item.toggles.data);
  const togglesMe = useSelector((item: IState) => item.togglesMe.data);
  const isEntrepreneur = useSelector(
    (state: IState) => state.enrollmentsAuth.isEntrepreneur,
  );
  const isManager = useSelector((state: IState) => state.enrollmentsAuth.isManager);
  const isClient = useSelector((state: IState) => state.enrollmentsAuth.isClient);
  const dataValidateLackWater = useSelector(
    (item: IState) => item.orderServices.dataValidateLackWater,
  );
  const reportLackWaterRoute = useSelector(
    (item: IState) => item.outsides.reportLackWaterRoute,
  );
  const loadingValidateLackWater = useSelector(
    (item: IState) => item.orderServices.loadingValidateLackWater,
  );

  const isNotClient = () => {
    return !isClient && !isEntrepreneur && !isManager;
  };

  const [satisfactionSurvey, setSatisfactionSurvey] = useState<boolean>(false);
  const [modalReportLackWater, setModalReportLackWater] = useState<boolean>(false);

  const verifyToggles = async () => {
    setSatisfactionSurvey(
      await checkToggle(togglesMe, 'SATISFACTION_SURVEY_LOGGED'),
    );
  };

  useEffect(() => {
    if (togglesMe && togglesMe.length > 0) verifyToggles();
  }, [togglesMe]);

  const handleOnCloseReportLackWaterInfo = () => {
    dispatch(clearRouteReportLackWater());
    dispatch(getErrorCloseServiceValidateLackWaterRequest());

    setModalReportLackWater(false);
  };

  useEffect(() => {
    if (
      (dataValidateLackWater?.type_info && !dataValidateLackWater?.validate) ||
      (dataValidateLackWater?.type_info &&
        !dataValidateLackWater?.validate &&
        reportLackWaterRoute)
    ) {
      setModalReportLackWater(true);
    }
  }, [reportLackWaterRoute, dataValidateLackWater]);

  return (
    <LayoutApp>
      {loadingValidateLackWater && <LoadingComponent labelWait="Carregando..." />}
      <Dialog open={modalReportLackWater} onClose={handleOnCloseReportLackWaterInfo}>
        <ModalReportLackWaterInfo
          onClose={handleOnCloseReportLackWaterInfo}
          typeInfo={dataValidateLackWater?.type_info || 'water_supply_interrupted'}
        />
      </Dialog>
      {modalEnrollmentNotExists && (
        <Dialog
          open={modalEnrollmentNotExists}
          onClose={async () => {
            dispatch(clearModalDuplicateInvoices());
            dispatch(getEnrollmentsAuthDestroySessionRequest());
            Navigation.navigate(
              (await checkToggle(toggles, 'LAYOUT_V3_WEB'))
                ? RouteName.LOGIN
                : RouteName.LANDING,
            );
          }}
          fullScreen
        >
          <ModalUpdateEnrollment />
        </Dialog>
      )}
      {modalNotExists && (
        <Dialog
          open={modalNotExists}
          onClose={() => dispatch(clearModalNotClient())}
          fullScreen
        >
          <ModalNotClient />
        </Dialog>
      )}
      {satisfactionSurvey && modalSalesforceSatisfaction && (
        <Dialog
          open={modalSalesforceSatisfaction}
          onClose={async () => dispatch(clearModalSalesforceSatisfaction())}
        >
          <ModalSalesforceSatisfaction />
        </Dialog>
      )}
      {isNotClient() && titleNotClient ? (
        <CardNotClient title={dataHistory?.titleNotClient || titleNotClient || ''} />
      ) : (
        <Component />
      )}
    </LayoutApp>
  );
}

const AppRoutes: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const registrations = useSelector(
    (state: IState) => state.enrollmentsAuth.registrations,
  );
  const registrationCheckEnrollment = useSelector(
    (state: IState) => state.enrollmentsAuth.registrationCheck?.enrollment,
  );
  const loadingAuth = useSelector((state: IState) => state.enrollmentsAuth.loading);
  const firstAccess = useSelector(
    (state: IState) => state.enrollmentsAuth.firstAccess,
  );
  const isEntrepreneur = useSelector(
    (state: IState) => state.enrollmentsAuth.isEntrepreneur,
  );
  const isManager = useSelector((state: IState) => state.enrollmentsAuth.isManager);
  const isClient = useSelector((state: IState) => state.enrollmentsAuth.isClient);
  const isCutover = useSelector((state: IState) => state.enrollmentsAuth.isCutover);

  const dataCreateClient = useSelector(
    (item: IState) => item.enrollmentsUsers.dataCreateClient,
  );

  const dataCreateNotClient = useSelector(
    (item: IState) => item.enrollmentsUsers.dataCreateNotClient,
  );

  const routeAuthRedirect = useSelector(
    (item: IState) => item.enrollmentsAuth.routeAuthRedirect,
  );
  const loadingEnrollmentsAuth = useSelector(
    (item: IState) => item.enrollmentsAuth.loading,
  );

  const [hasRedirected, setHasRedirected] = useState(false);

  const isNotClient = () => {
    return !isClient && !isEntrepreneur && !isManager;
  };

  const redirectRoutesEntrepreneur = [RouteName.ACCOUNTCONFIG];

  const redirects = {
    accessProfile: RouteName.ACCESSPROFILE,
    entrepreneur: redirectRoutesEntrepreneur.includes(routeAuthRedirect)
      ? routeAuthRedirect
      : RouteName.ENTERPRISE_VIABILITY,
    manager: RouteName.HOME,
    multiRegistrations: RouteName.REGISTRATIONLIST,
    notClient: routeAuthRedirect || RouteName.HOME,
    registerInfoClient: RouteName.REGISTERINFOCLIENT,
    default: routeAuthRedirect || RouteName.HOME,
  };

  const resolveRedirect = () => {
    if ((isEntrepreneur && isClient) || (isManager && isClient))
      return 'accessProfile';

    if (isManager) return 'manager';

    if (isEntrepreneur) return 'entrepreneur';

    if (firstAccess && dataCreateNotClient?.documentNumber && !isClient)
      return 'notClient';

    if (firstAccess && !dataCreateClient?.isNotClient) {
      if ((registrations && registrations.length > 1) || loadingAuth)
        return 'multiRegistrations';

      return 'default';
    }

    if (isCutover) return 'default';

    if ((registrations && registrations.length > 1) || loadingAuth)
      return 'multiRegistrations';

    return 'default';
  };

  const redirect = resolveRedirect();
  const targetPath = redirects[redirect];

  useEffect(() => {
    !loadingEnrollmentsAuth && setHasRedirected(true);
  }, []);

  useEffect(() => {
    if (
      isNotClient() &&
      !loadingEnrollmentsAuth &&
      routeAuthRedirect &&
      !hasRedirected
    ) {
      setHasRedirected(true);
      dispatch(clearRouteAuthRedirect());
    }

    if (
      isClient &&
      registrationCheckEnrollment &&
      !loadingEnrollmentsAuth &&
      routeAuthRedirect &&
      !hasRedirected
    ) {
      setHasRedirected(true);
      dispatch(clearRouteAuthRedirect());
    }

    if (!loadingEnrollmentsAuth && !hasRedirected) {
      setHasRedirected(true);
    }
  }, [loadingEnrollmentsAuth, routeAuthRedirect, hasRedirected, targetPath]);

  return (
    <>
      {!hasRedirected && <Navigate to={targetPath} replace />}
      <Routes>
        <Route
          element={<RouteAppWithLayout Component={RegistrationsList} />}
          path={RouteName.REGISTRATIONLIST}
        />
        <Route
          element={<RouteAppWithLayout Component={HomeVersionTwo} />}
          path={RouteName.HOME}
        />
        <Route
          element={
            <RouteAppWithLayout
              Component={NegativeCertificatePage}
              titleNotClient="Certidão negativa"
            />
          }
          path={RouteName.NEGATIVECERTIFICATE}
        />
        <Route
          element={<RouteAppWithLayout Component={AccountConfig} />}
          path={RouteName.ACCOUNTCONFIG}
        />
        <Route
          element={
            <RouteAppWithLayout Component={Invoices} titleNotClient="Faturas" />
          }
          path={RouteName.INVOICES}
        />
        <Route
          element={
            <RouteAppWithLayout
              Component={InvoicesChangeDueDate}
              titleNotClient="Faturas"
            />
          }
          path={RouteName.INVOICESCHANGEEXPIRATION}
        />
        <Route
          element={
            <RouteAppWithLayout
              Component={InvoiceChangeDeliveryType}
              titleNotClient="Faturas"
            />
          }
          path={RouteName.INVOICESCHANGEDELIVERYTYPE}
        />
        <Route
          element={
            <RouteAppWithLayout Component={SimplifiedInvoicePaymentsReceipt} />
          }
          path={RouteName.INVOICERECEIP}
        />
        <Route
          element={
            <RouteAppWithLayout
              Component={SimplifiedInvoiceNew}
              titleNotClient="EMISSÃO DE SEGUNDA VIA"
            />
          }
          path={RouteName.SIMPLIFIEDINVOICE}
        />
        <Route
          element={<RouteAppWithLayout Component={SimplifiedInvoicePixNew} />}
          path={RouteName.SIMPLIFIEDINVOICEPIX}
        />
        <Route
          element={<RouteAppWithLayout Component={SimplifiedInvoiceCreditCardNew} />}
          path={RouteName.SIMPLIFIEDINVOICECREDITCARD}
        />
        <Route
          element={<RouteAppWithLayout Component={SimplifiedInvoicePayments} />}
          path={RouteName.SIMPLIFIEDINVOICEPAYMENTMETHODS}
        />
        <Route
          element={<RouteAppWithLayout Component={ChangePhone} />}
          path={RouteName.CHANGEPHONE}
        />
        <Route
          element={<RouteAppWithLayout Component={ChangePhoneMobile} />}
          path={RouteName.CHANGEMOBILE}
        />
        <Route
          element={<RouteAppWithLayout Component={ChangeEmail} />}
          path={RouteName.CHANGEEMAIL}
        />
        <Route
          element={<RouteAppWithLayout Component={ChangePassword} />}
          path={RouteName.CHANGEPASSOWORD}
        />
        <Route
          element={
            <RouteAppWithLayout
              Component={DetailsHistory}
              titleNotClient="HISTÓRICO DETALHADO"
            />
          }
          path={RouteName.DETAILEDHISTORY}
        />
        <Route
          element={
            <RouteAppWithLayout
              Component={SupplyAndDepletionPossibilityDeclarationPanel}
            />
          }
          path={RouteName.ENTERPRISE_VIABILITY}
        />
        <Route
          element={<RouteAppWithLayout Component={CustomerObligation} />}
          path={RouteName.CUSTOMEROBLIGATION}
        />
        <Route
          element={<RouteAppWithLayout Component={PropertyRegistration} />}
          path={RouteName.PROPERTYREGISTRATION}
        />
        <Route element={<AccessProfile />} path={RouteName.ACCESSPROFILE} />
        <Route
          element={<RouteAppWithLayout Component={Notifications} />}
          path={RouteName.NOTIFICATIONS}
        />
        <Route
          element={<RouteAppWithLayout Component={SmartMeter} />}
          path={RouteName.SMARTMETER}
        />
        <Route
          element={<RouteAppWithLayout Component={SmartMeterSubscribesPayment} />}
          path={RouteName.SMARTMETERSUBSCRIPTION}
        />
        <Route
          element={<RouteAppWithLayout Component={Wallet} />}
          path={RouteName.WALLET}
        />
        <Route
          element={<RouteAppWithLayout Component={WalletAddCard} />}
          path={RouteName.WALLETADDCARD}
        />
        <Route
          element={<RouteAppWithLayout Component={WalletDetailCard} />}
          path={RouteName.WALLETDETAILCARD}
        />
        <Route
          element={
            <RouteAppWithLayout
              Component={SupportCenter}
              titleNotClient="Minhas solicitações"
            />
          }
          path={RouteName.SUPPORTCENTER}
        />
        <Route
          element={<RouteAppWithLayout Component={SupportCenterDetail} />}
          path={RouteName.SUPPORTCENTERSERVICEORDER}
        />
        <Route
          element={
            <RouteAppWithLayout
              Component={AutomaticDebit}
              titleNotClient="Débito automático"
            />
          }
          path={RouteName.AUTOMATICDEBIT}
        />
        <Route
          element={
            <RouteAppWithLayout
              Component={SocialTariff}
              titleNotClient="Tarifa social"
            />
          }
          path={RouteName.SOCIALTARIFF}
        />
        <Route
          element={<RouteAppWithLayout Component={SocialTariffSolicitation} />}
          path={RouteName.SOCIALTARIFFSOLICITATION}
        />
        <Route
          element={<RouteAppWithLayout Component={SocialTariffRequest} />}
          path={RouteName.SOCIALTARIFFREQUEST}
        />
        <Route
          element={<RouteAppWithLayout Component={LinkEnrollment} />}
          path={RouteName.LINKENROLLMENT}
        />
        <Route
          element={<RouteAppWithLayout Component={LinkEnrollmentForm} />}
          path={RouteName.LINKENROLLMENTFORM}
        />
        <Route
          element={<RouteAppWithLayout Component={SinglePayment} />}
          path={RouteName.SINGLEPAYMENT}
        />
        <Route
          element={<RouteAppWithLayout Component={SinglePaymentDataPayment} />}
          path={RouteName.SINGLEPAYMENTDATAPAYMENT}
        />
        <Route
          element={
            <RouteAppWithLayout Component={SinglePaymentDataPaymentReceipt} />
          }
          path={RouteName.SINGLEPAYMENTDATAPAYMENTRECEIPT}
        />
        <Route
          element={<RouteAppWithLayout Component={EnterpriseViabilityNewProcess} />}
          path={RouteName.ENTERPRISE_VIABILITY_NEW_PROCESS}
        />
        <Route
          element={<RouteAppWithLayout Component={SupplyPossibilityConsultation} />}
          path={RouteName.SUPPLYPOSSIBILITYCONSULTATION}
        />
        <Route
          element={<RouteAppWithLayout Component={CpaeValuesTable} />}
          path={RouteName.SUPPLYPOSSIBILITYCONSULTATIONVALUESTABLE}
        />
        <Route
          element={
            <RouteAppWithLayout
              Component={SupplyAndDepletionPossibilityDeclarationKnowMore}
            />
          }
          path={RouteName.ENTERPRISE_VIABILITY_KNOW_MORE}
        />
        <Route
          element={
            <RouteAppWithLayout
              Component={SupplyAndDepletionPossibilityDeclarationPanelSlips}
            />
          }
          path={RouteName.ENTERPRISE_VIABILITY_PANEL_SLIPS}
        />
        <Route
          element={
            <RouteAppWithLayout
              Component={SupplyAndDepletionPossibilityDeclarationSlips}
            />
          }
          path={RouteName.ENTERPRISE_VIABILITY_SLIPS_PAYMENT}
        />
        <Route
          element={<RouteAppWithLayout Component={ReportLackWater} />}
          path={RouteName.REPORTLACKWATER}
        />
        <Route
          element={<RouteAppWithLayout Component={SupportCenterProtocol} />}
          path={RouteName.SUPPORTCENTERPROTOCOL}
        />
        <Route
          element={<RouteAppWithLayout Component={Negotiations} />}
          path={RouteName.NEGOTIATIONS}
        />
        <Route
          element={<RouteAppWithLayout Component={DebtNegotiation} />}
          path={RouteName.DEBTNEGOTIATION}
        />
        <Route
          element={<RouteAppWithLayout Component={DebtNegotiationSinglePayment} />}
          path={RouteName.DEBTNEGOTIATIONSINGLEPAYMENT}
        />
        <Route
          element={
            <RouteAppWithLayout
              Component={DebtNegotiationSinglePaymentDataPayment}
            />
          }
          path={RouteName.DEBTNEGOTIATIONSINGLEPAYMENTDATAPAYMENT}
        />
        <Route
          element={<RouteAppWithLayout Component={DebtNegotiationTypePayment} />}
          path={RouteName.DEBTNEGOTIATIONTYPEPAYMENT}
        />
        <Route
          element={<RouteAppWithLayout Component={DebtNegotiationSimulation} />}
          path={RouteName.DEBTNEGOTIATIONSIMULATION}
        />
        <Route
          element={<RouteAppWithLayout Component={DebtNegotiationDetail} />}
          path={RouteName.DEBTNEGOTIATIONDETAIL}
        />
      </Routes>
    </>
  );
};

export default AppRoutes;
