import { EndpointBuilder, BaseQueryFn } from '@reduxjs/toolkit/query';
import { IEnumCacheConfig } from '../enum';
import {
  IFetchOrderServicesInputsLackWaterList,
  IFetchOrderServicesList,
} from '../types';
import {
  IResponseGetListByCnp,
  IResponseInputsLackWater,
} from '~/store/modules/orderServices/types';

export const orderServicesEndpoints = (
  builder: EndpointBuilder<BaseQueryFn, IEnumCacheConfig, 'cache'>,
) => ({
  fetchOrderServicesList: builder.query<
    IResponseGetListByCnp,
    IFetchOrderServicesList
  >({
    query: ({ enrollment, params }) => ({
      url: `v3/order-services/enrollment/${enrollment}`,
      method: 'GET',
      params: {
        ...params,
      },
    }),
    providesTags: [IEnumCacheConfig.ORDER_SERVICES_LIST],
  }),
  fetchOrderServicesInputsLackWaterList: builder.query<
    IResponseInputsLackWater,
    IFetchOrderServicesInputsLackWaterList
  >({
    query: ({ identificadorInput, operationId }) => ({
      url: `v3/options/${identificadorInput}/operation/${operationId}`,
      method: 'GET',
    }),
    providesTags: [IEnumCacheConfig.ORDER_SERVICES_INPUTS_LACKWATER_LIST],
  }),
});
