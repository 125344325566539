import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Main, Card, BoxQuestion, ContainerBreadCrumb } from './styles';
import QuestionSVG from '~/assets/Icons/QuestionIcon';
import questionInfoImg from './assets/question-info.png';
import { BreadCrumb } from './BreadCrumb';
import { Margin } from '~/components/Margin';
import { Input } from '~/components/Input';
import { ButtonText } from '~/components/ButtonText';
import { InfoIcon } from './icon';
import { Color } from '~/styles';
import formatter from '~/utils/formatter';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import {
  getEnrollmentsUsersNotClientValidate,
  getEnrollmentsUsersNotClientValidateClear,
  getLocationsForReregistration,
} from '~/store/modules/enrollmentsUsersNotClient/actions';
import { IState } from '~/store/modules/types';
import { InputSelect } from '~/components/InputSelect';
import { IResponseGetLocations } from '~/store/modules/enrollmentsUsersNotClient/types';
import { EnumResponseCode } from '~/enums/reponseCodesEnum';
import { AppDispatch } from '~/store';

const LinkEnrollment: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const loading = useSelector(
    (item: IState) => item.enrollmentsUsersNotClient.loading,
  );
  const error = useSelector((item: IState) => item.enrollmentsUsersNotClient.error);
  const locations =
    useSelector((item: IState) => item.enrollmentsUsersNotClient.locations) || [];

  const [registration, setRegistration] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [locationsOrdered, setLocationOrdered] = useState<IResponseGetLocations[]>(
    [],
  );
  const [questionInfo, setQuestionInfo] = useState<boolean>(false);
  const [errorRegistration, setErrorRegistration] = useState<string>(``);
  const [errorLocation, setErrorLocation] = useState<string>(``);
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    dispatch(getEnrollmentsUsersNotClientValidateClear());
    dispatch(getLocationsForReregistration());
  }, []);

  useEffect(() => {
    if (locations)
      setLocationOrdered(
        locations.sort((a: IResponseGetLocations, b: IResponseGetLocations) =>
          a.label.localeCompare(b.label),
        ),
      );
  }, [locations]);

  useEffect(() => {
    if (
      error &&
      error?.type?.toUpperCase() ===
        EnumResponseCode.BAD_REQUEST_ENROLLMENT_NOT_IN_SALES
    )
      return setErrorRegistration(`Matrícula inválida`);

    if (
      error &&
      error?.type?.toUpperCase() ===
        EnumResponseCode.BAD_REQUEST_ENROLLMENT_NOT_IN_OPERATION
    ) {
      setErrorRegistration(`Matrícula ou localidade incorreta`);
      return setErrorLocation(`Matrícula ou localidade incorreta`);
    }

    setErrorRegistration(``);
    return setErrorLocation(``);
  }, [error]);

  useEffect(() => {
    setDisabled(
      !registration ||
        registration.length < 3 ||
        !location ||
        location === `Selecione`,
    );
  }, [location, registration]);

  return (
    <Main>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Vincular matrícula',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Card>
        <h1>Vincular matrícula</h1>
        <p>Para começar, digite a matrícula informada na sua conta:</p>
        <Margin height={24} />
        <div
          className="container-input"
          style={{ alignItems: error ? `center` : `end` }}
        >
          <Input
            value={registration}
            onChange={(ev) => {
              if (error) dispatch(getEnrollmentsUsersNotClientValidateClear());
              setRegistration(formatter.enrollment(ev.target.value));
            }}
            label="Matrícula*"
            placeholder="Matrícula com dígito"
            autoFocus
            error={!!errorRegistration}
            errorText={errorRegistration}
            errorPosition="bottom"
          />
          <Margin width={24} />
          <button type="button" onClick={() => setQuestionInfo(!questionInfo)}>
            <QuestionSVG />
          </button>
        </div>
        <Margin height={24} />
        {questionInfo && (
          <>
            <BoxQuestion>
              <div>
                <InfoIcon />
                <h2>Encontre o número da matrícula na sua fatura!</h2>
              </div>
              <Margin height={24} />
              <img src={questionInfoImg} alt="Número da matrícula na sua fatura" />
              <Margin height={24} />
              <p>
                Digite a matrícula informada na sua conta para ter acesso ao
                histórico detalhado, parcelamento de fatura e muito mais!
                <br /> <br />
                Caso não possua fatura, você pode continuar seu cadastro sem a
                matrícula.
              </p>
            </BoxQuestion>
            <Margin height={24} />
          </>
        )}
        <InputSelect
          label="Localidade*"
          value={location}
          values={[{ label: `Selecione`, value: null }, ...locationsOrdered]}
          onChange={(ev) => {
            if (error) dispatch(getEnrollmentsUsersNotClientValidateClear());
            setLocation(ev.target.value);
          }}
          error={!!errorLocation}
          errorText={errorLocation}
          errorPosition="bottom"
        />
        <Margin height={24} />
        <ButtonText
          text="Continuar"
          typeBtn="content"
          Color={Color.white}
          backgroundColor={disabled ? Color.gray : Color.green}
          onClick={() => {
            if (disabled) return;
            dispatch(
              getEnrollmentsUsersNotClientValidate({
                enrollment: registration,
                location,
              }),
            );
          }}
          loading={loading}
        />
        <Margin height={24} />
        <ButtonText
          text="Voltar"
          typeBtn="content"
          backgroundColor={Color.green}
          onClick={() => Navigation.navigate(RouteName.HOME)}
        />
      </Card>
    </Main>
  );
};

export default LinkEnrollment;
