import React, {
  useRef,
  useEffect,
  MouseEvent,
  TouchEvent,
  FC,
  Dispatch,
  SetStateAction,
  ChangeEvent,
} from 'react';
import {
  ContainerSliderTrack,
  LegendContainer,
  LegendText,
  SliderContainer,
  SliderProgress,
  SliderTrack,
  ThumbContainer,
  ValuesContainer,
  ValueText,
  ValueTotalContainer,
  ValueTotalText,
} from './styles';
import { SliderIcon } from './Icons';
import formatter from '~/utils/formatter';

interface ISliderProps {
  minLimit: number;
  maxLimit: number;
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
}

const Slider: FC<ISliderProps> = ({ minLimit, maxLimit, value, setValue }) => {
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const isDragging = useRef<boolean>(false);

  const handleOnChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const valueFormat = Number(formatter.formatarDecimalNumber(ev.target.value));
    setValue(valueFormat);
  };

  const handleOnBlur = (ev: ChangeEvent<HTMLInputElement>) => {
    const valueFormat = Number(formatter.formatarDecimalNumber(ev.target.value));

    if (valueFormat >= minLimit && valueFormat <= maxLimit) {
      setValue(valueFormat);
    } else if (valueFormat < minLimit) {
      setValue(minLimit);
    } else if (valueFormat > maxLimit) {
      setValue(maxLimit);
    }
  };

  const roundToNearestFive = (num: number): number => {
    return Math.round(num / 5) * 5;
  };

  const calculateValueFromPosition = (clientX: number): number => {
    if (!sliderRef.current) return minLimit;

    const rect = sliderRef.current.getBoundingClientRect();
    const offsetX = Math.max(0, Math.min(clientX - rect.left, rect.width));
    const percentage = offsetX / rect.width;

    const newValue = minLimit + percentage * (maxLimit - minLimit);
    return Math.max(minLimit, Math.min(roundToNearestFive(newValue), maxLimit));
  };

  const handleStart = (
    event: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>,
  ) => {
    isDragging.current = true;

    if ('touches' in event) {
      handleMove(event.touches[0].clientX);
    } else {
      handleMove(event.clientX);
    }
  };

  const handleMove = (clientX: number): void => {
    if (!isDragging.current) return;

    const newValue = calculateValueFromPosition(clientX);
    setValue(newValue);
  };

  const handleEnd = (): void => {
    isDragging.current = false;
  };

  useEffect(() => {
    const onMouseMove = (ev: globalThis.MouseEvent) => handleMove(ev.clientX);
    const onTouchMove = (ev: globalThis.TouchEvent) =>
      handleMove(ev.touches[0].clientX);

    if (isDragging.current) {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', handleEnd);

      document.addEventListener('touchmove', onTouchMove);
      document.addEventListener('touchend', handleEnd);
    } else {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', handleEnd);

      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', handleEnd);
    }

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', handleEnd);

      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', handleEnd);
    };
  }, [isDragging.current]);

  const calculateProgressWidth = ((value - minLimit) / (maxLimit - minLimit)) * 100;
  const progressWidth =
    calculateProgressWidth > 100
      ? 100
      : calculateProgressWidth < 0
        ? 0
        : calculateProgressWidth;

  return (
    <SliderContainer>
      <ValueTotalContainer>
        <ValueTotalText
          value={formatter.formatCurrency(value)}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          type="text"
          inputMode="decimal"
          pattern="[0-9]*[.,]?[0-9]{0,2}"
        />{' '}
      </ValueTotalContainer>
      <ValuesContainer>
        <ValueText>{formatter.formatCurrency(minLimit)}</ValueText>
        <ValueText>{formatter.formatCurrency(maxLimit)}</ValueText>
      </ValuesContainer>
      <ContainerSliderTrack>
        <SliderTrack
          ref={sliderRef}
          onMouseDown={(e) => handleStart(e)}
          onTouchStart={(e) => handleStart(e)}
        >
          <SliderProgress style={{ width: `${progressWidth}%` }} />
          <ThumbContainer style={{ left: `${progressWidth}%` }}>
            <SliderIcon />
          </ThumbContainer>
        </SliderTrack>
      </ContainerSliderTrack>
      <LegendContainer>
        <LegendText>Mín.</LegendText>
        <LegendText>Máx.</LegendText>
      </LegendContainer>
    </SliderContainer>
  );
};

export default Slider;
