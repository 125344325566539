import React, { useState, useEffect, FC } from 'react';
import moment from 'moment';
import formatter from '~/utils/formatter';
import { statusColor } from '~/utils/invoiceStatus';
import { PaymentContainer } from '../../Material/PaymentContainer';
import { InputLabel } from '../../Material/InputLabel';
import { BannerPix } from '../../Material/BannerPix';
import CreditCard from '../../CreditCard';
import {
  Main,
  Wrapper,
  ContentWrapper,
  DetailsForm,
  Row,
  InvoiceAmount,
  Divider,
  CreditWrapper,
} from '../styles';

import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import Modal from '~/components/Modal';
import ModalError from '../../ModalError';
import { checkToggle } from '~/utils/toggles';
import LoadingComponent from '~/components/Loading';

interface IProps {
  bgColor: string;
  status: string;
}

interface IInvoiceDetails {
  statusColor: string;
  data: any;
}

const InvoiceDetails: FC<IInvoiceDetails> = ({ statusColor, data }) => {
  return (
    <DetailsForm style={{ justifyContent: 'flex-end' }}>
      <Row>
        <InvoiceAmount color={statusColor} style={{ height: 0 }}>
          {formatter.formatCurrency(data?.amount)}
        </InvoiceAmount>
      </Row>
      <Row>
        <InputLabel label="Cliente" defaultValue={data?.consumer?.name} />
      </Row>
      <Row>
        <InputLabel label="Endereço" defaultValue={data?.consumer?.address} />
      </Row>
      <Row columns={2}>
        <InputLabel
          label="Data Leitura"
          defaultValue={moment(data?.read_date_hour).format('DD/MM/YYYY')}
        />
        <InputLabel
          label="Tipo"
          defaultValue={data?.rate_type || 'Nenhuma informação encontrada'}
        />
      </Row>
      <Row columns={3}>
        <InputLabel
          label="Consumo"
          defaultValue={data?.billed_consumption || 'Nenhuma informação encontrada'}
        />
        <InputLabel
          label="Período"
          defaultValue={data?.reference || 'Nenhuma informação encotrada'}
        />
        <InputLabel
          label="Vencimento"
          defaultValue={
            moment(data?.maturity_date).format('DD/MM/YYYY') ||
            'Nenhuma informação encontrada'
          }
        />
      </Row>
      <Row>
        <InputLabel
          label="Detalhamento da Fatura"
          defaultValue={data?.items.map((item: any) => item.description)}
        />
      </Row>
    </DetailsForm>
  );
};

const SimplifiedInvoiceCreditCard: FC<IProps> = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [existPix, setExistPix] = useState<boolean>(false);

  const dataDetail = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.dataDetail,
  );
  const toggles = useSelector((item: IState) => item.togglesMe.data);
  const error = useSelector((item: IState) => item.enrollmentsPayment.error);
  const loadingDownload = useSelector(
    (item: IState) => item.enrollmentsInvoices.loadingDownload,
  );

  const verifyToggles = async () => {
    setExistPix(await checkToggle(toggles, 'PIX'));
  };

  useEffect(() => {
    verifyToggles();
  }, [toggles]);

  useEffect(() => {
    if (error) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [error]);

  const errorMessage = () => {
    switch (error?.type) {
      case 'INVALID_DATA':
        return 'Dados inválidos';
      case 'UNAUTHORIZED_TRANSACTION':
        return 'Transação não autorizada';
      case 'UNAUTHORIZED_TRANSACTION_TRY_AGAIN_LATER':
        return 'Transação não concluída';
      case 'UNAUTHORIZED_TRANSACTION_CONTACT_THE_OPERATOR':
        return 'Transação não concluída';
      default:
        return 'Transação não concluída';
    }
  };

  return (
    <Main>
      {loadingDownload && <LoadingComponent labelWait="Baixando..." />}
      <Modal open={open} type="error" title={errorMessage()}>
        <ModalError />
      </Modal>
      <PaymentContainer
        bgColor={statusColor(dataDetail?.status?.label || '', true)}
        status={dataDetail?.status?.label || ''}
      >
        <Wrapper>
          <ContentWrapper>
            <InvoiceDetails
              data={dataDetail}
              statusColor={statusColor(dataDetail?.status?.label || '', true)}
            />
            <BannerPix existPix={existPix} />
          </ContentWrapper>
          <Divider />
          <CreditWrapper>
            <CreditCard
              paymentAmount={dataDetail?.amount || 0}
              infoInvoiceId={dataDetail?.invoice_id}
              infoReference={dataDetail?.reference || ''}
              possibleQuantityInstallments={
                dataDetail?.possible_quantity_installments || 0
              }
            />
          </CreditWrapper>
        </Wrapper>
      </PaymentContainer>
    </Main>
  );
};

export default SimplifiedInvoiceCreditCard;
