import React, { FC, useEffect, useState } from 'react';

import { Main, Label, Row, SenFiles } from './styles';

import DropZone from '../DropZone';
import { Link } from 'react-router-dom';
import declaracaoInteressado from '../files/declaracaoInteressado.docx';
import termoResponsabilidade from '../files/termoResponsabilidade.docx';
import listaMateriais from '../files/listaMateriais.xlsx';
import {
  patchAttachFilesCustomerObligationRequest,
  patchStatusStepCustomerObligationRequest,
  postUploadFileCustomerObligationRequest,
} from '~/store/modules/customerObligation/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import convertFileToBase64 from '~/utils/convertBase64';
import {
  ContainerButton,
  Subtitle,
} from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/styles';
import { Margin } from '~/components/Margin';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import {
  EnumCustomerObligationFilesStep,
  stepStatusEnum,
} from '~/enums/customerObligationEnum';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { isArrayContained } from '~/utils/validation';
import { AppDispatch } from '~/store';

export interface IDocuments {
  key:
    | 'owner_proxy_giving_powers_to_interested'
    | 'all_involved_identification_document'
    | 'interested_statement_informing_who_execute_work'
    | 'responsibility_term_company_execute_work'
    | 'oc_constant_work_art'
    | 'legal_entity_registration_certificate_executing_company'
    | 'individual_registration_certificate_technician_charge'
    | 'technical_manager_identification'
    | 'legal_entities_contractors_national_register'
    | 'executing_company_national_register_of_legal_entities'
    | 'social_contract_contracting_company'
    | 'social_contract_executing_company'
    | 'property_purchase_and_sale_deed'
    | 'materials_list'
    | 'performing_company_registered_with_recad';
  label: string;
  file: null | File;
  type:
    | 'default'
    | 'statementInterested'
    | 'statementResponsibility'
    | 'listMaterials'
    | 'CSV';
  description?: string;
  id?: string;
  attachKey?: string;
  error?: string;
}

const Attachments: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);

  const idCreate = useSelector((item: IState) => item.customerObligation.idCreate);
  const loadingAttachFiles = useSelector(
    (item: IState) => item.customerObligation.loadingAttachFiles,
  );
  const loadingUploadFile = useSelector(
    (item: IState) => item.customerObligation.loadingUploadUpdateFile,
  );
  const isRevision = caseOC?.attachments.find(
    (attachment) => attachment.review === true,
  );

  const [documents, setDocuments] = useState<IDocuments[]>([
    {
      key: 'owner_proxy_giving_powers_to_interested',
      label: 'Procuração do proprietário dando poderes ao Interessado*',
      file: null,
      type: 'default',
      error: '',
    },
    {
      key: 'all_involved_identification_document',
      label: 'Documento de identificação de todos os envolvidos*',
      file: null,
      type: 'default',
      error: '',
    },
    {
      key: 'interested_statement_informing_who_execute_work',
      label: 'Declaração do Interessado informando quem irá executar a obra*',
      file: null,
      type: 'statementInterested',
      error: '',
    },
    {
      key: 'responsibility_term_company_execute_work',
      label: 'Termo de Responsabilidade da empresa que irá executar a obra*',
      file: null,
      type: 'statementResponsibility',
      error: '',
    },
    {
      key: 'oc_constant_work_art',
      label: 'ART da obra constante na O.C*',
      file: null,
      type: 'default',
      error: '',
    },
    {
      key: 'legal_entity_registration_certificate_executing_company',
      label: 'Certidão de Registro de Pessoa Jurídica da empresa executora (CREA)*',
      file: null,
      type: 'default',
      error: '',
    },
    {
      key: 'individual_registration_certificate_technician_charge',
      label: 'Certidão de Registro de Pessoa Física do responsável técnico (CREA)*',
      file: null,
      type: 'default',
      error: '',
    },
    {
      key: 'technical_manager_identification',
      label: 'Identificação do responsável técnico (registro CAU ou CREA)*',
      file: null,
      type: 'default',
      error: '',
    },
    {
      key: 'legal_entities_contractors_national_register',
      label:
        'Cadastro Nacional de Pessoa Jurídica do contratante (Receita Federal)*',
      file: null,
      type: 'default',
      error: '',
    },
    {
      key: 'executing_company_national_register_of_legal_entities',
      label:
        'Cadastro Nacional de Pessoa Jurídica da empresa executora (Receita Federal)*',
      file: null,
      type: 'default',
      error: '',
    },
    {
      key: 'social_contract_contracting_company',
      label: 'Contrato Social da empresa contratante*',
      file: null,
      type: 'default',
      error: '',
    },
    {
      key: 'social_contract_executing_company',
      label: 'Contrato Social da empresa executora*',
      file: null,
      type: 'default',
      error: '',
    },
    {
      key: 'property_purchase_and_sale_deed',
      label: 'Escritura de compra e venda do imóvel*',
      file: null,
      type: 'default',
      error: '',
    },
    {
      key: 'materials_list',
      label: 'Lista de Materiais',
      file: null,
      type: 'listMaterials',
      error: '',
    },
    {
      key: 'performing_company_registered_with_recad',
      label:
        'Empresa executora cadastrada no RECAD da S/COR (Resolução SECONSERVA nº35)*',
      file: null,
      type: 'default',
      error: '',
    },
  ]);

  const [disabled, setDisabled] = useState(true);
  const disabledDocuments = false;

  const handleClick = () => {
    if (caseOC?.realStep.number === 2 && caseOC?.realStep.substep === 'A') {
      return dispatch(
        patchAttachFilesCustomerObligationRequest({
          case: idCreate || caseOC?.id || '',
          step: '2A',
        }),
      );
    }
    return dispatch(
      patchStatusStepCustomerObligationRequest({
        name: '',
        number: 2,
        substep: 'B',
        status: stepStatusEnum.FORM,
      }),
    );
  };

  const verifyDocuments = async () => {
    const attachmentsTypes = caseOC?.attachments.map(
      (attachment) => attachment.type,
    );
    const isInsertedFiles = isArrayContained(
      EnumCustomerObligationFilesStep.TWO_A,
      attachmentsTypes || [],
    );
    if (!isInsertedFiles) {
      return setDisabled(true);
    }
    if (
      (await documents.filter((document) => !document.file)).length > 0 ||
      loadingUploadFile
    ) {
      return setDisabled(true);
    }
    return setDisabled(false);
  };

  useEffect(() => {
    verifyDocuments();
  }, [documents, loadingUploadFile, caseOC?.attachments]);

  return (
    <>
      {!isRevision && (
        <>
          <Subtitle>
            Nesta etapa é necessário o anexo de todos os documentos solicitados a
            seguir conforme a orientação dada à direita de cada anexo.
          </Subtitle>
          <Margin height={64} />
        </>
      )}
      <Main>
        {documents.map((document, index: number) => (
          <Row key={document.key}>
            <SenFiles>
              <DropZone
                disabled={!!document.file || disabledDocuments}
                disabledDeleteFile={disabledDocuments}
                infoFileName={document.label}
                name={document.label}
                content={document.file || null}
                onFileUploaded={(file: File | null) => {
                  const array = [...documents];
                  if (file && file.size < 100) {
                    array[index].error = 'Por favor, verifique o seu arquivo.';
                    return setDocuments(array);
                  }
                  array[index].file = file;
                  if (file === null) {
                    array[index].error = '';
                    return setDocuments(array);
                  }
                  if (file) {
                    convertFileToBase64(file, (base64: string) => {
                      dispatch(
                        postUploadFileCustomerObligationRequest({
                          caseIdSales: idCreate || caseOC?.id || '',
                          type: document.key,
                          step: '2A',
                          filename: file?.name || '',
                          filedata: base64,
                        }),
                      );
                    });
                  }
                  return setDocuments(array);
                }}
                type={document.type}
                error={!!document.error}
                errorText={document.error}
              />
            </SenFiles>
            {document.type === 'default' && (
              <Label>Arquivos devem ser anexados em formato PDF.</Label>
            )}
            {document.type === 'statementInterested' && (
              <Label>
                Arquivo deve ser preenchido e anexado em formato DOCX.
                <Link to={declaracaoInteressado} target="_blank">
                  Declaração do interessado
                </Link>
                &ensp;(clique para o download).
              </Label>
            )}
            {document.type === 'statementResponsibility' && (
              <Label>
                Arquivo deve ser preenchido e anexado em formato DOCX.
                <Link to={termoResponsabilidade} target="_blank">
                  Termo de responsabilidade
                </Link>
                &ensp;(clique para o download).
              </Label>
            )}
            {document.type === 'listMaterials' && (
              <Label>
                Arquivo deve ser preenchido e anexado em formato CSV ou excel.
                <Link to={listaMateriais} target="_blank">
                  Lista de Materiais
                </Link>
                &ensp;(clique para o download).
              </Label>
            )}
            {document.type === 'CSV' && (
              <Label>Arquivos devem ser anexados em formato CSV.</Label>
            )}
          </Row>
        ))}
      </Main>
      <Margin height={40} />
      <ContainerButton isRevision={!!isRevision}>
        <Button
          backIcon
          backgroundColor={Color.green}
          onClick={() => {
            Navigation.navigate(RouteName.ENTERPRISE_VIABILITY);
          }}
        >
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          style={{ opacity: 1 }}
          onClick={handleClick}
          loading={loadingUploadFile || loadingAttachFiles}
          disabled={disabled}
        >
          Continuar
        </Button>
      </ContainerButton>
    </>
  );
};

export default Attachments;
