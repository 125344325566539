import styled from 'styled-components';
import { colors } from '~/design_system/styles/colors';
import { Breakpoints } from '~/styles';

export const Container = styled.div`
  width: 100%;
  height: 130px;
  border: 1px solid ${colors.grey_20};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  position: relative;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    height: 64px;
  }
`;

export const Border = styled.div`
  height: 100%;
  width: 8px;
  background: ${colors.grey_20};
  border-radius: 8px 0 0 8px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 17px;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    gap: 40px;
  }
`;

export const RowSkeleton = styled.div`
  position: absolute;
  right: 16px;
  bottom: 18px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ContainerSkeleton = styled.div`
  width: 100%;
  height: 20px;
`;
