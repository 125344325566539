import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Main } from './styles';
import { IState } from '~/store/modules/types';
import { Margin } from '~/components/Margin';
import { Color } from '~/styles';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import ButtonBar from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/PropertyRegistration/components/ButtonBar';
import StatusInfo from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/PropertyRegistration/components/StatusInfo';
import {
  patchStepLocalPropertyRegistratioRequest,
  patchStepPropertyRegistratioRequest,
} from '~/store/modules/propertyRegistration/actions';
import { AppDispatch } from '~/store';

const Status: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const caseFCP = useSelector((item: IState) => item.propertyRegistration.dataItem);

  const loadingDownloadFile = useSelector(
    (item: IState) => item.propertyRegistration.loadingDownloadFile,
  );

  const loadingPatch = useSelector(
    (item: IState) => item.propertyRegistration.loadingPatch,
  );

  const [disabled, setDisabled] = useState(true);

  const { WAIT, WAITING_FOR_CONNECTION, SUCCESS } = stepStatusEnum;

  const status = caseFCP?.step?.status || WAIT;

  useEffect(() => {
    if (status === SUCCESS) setDisabled(false);
  }, [caseFCP, status]);

  const handleClick = () => {
    return dispatch(
      patchStepPropertyRegistratioRequest({ case: caseFCP?.id || '', step: 'SIX' }),
    );
  };
  const statusesContent: any = {};

  statusesContent[WAIT] = (
    <StatusInfo status="wait">
      Os documentos foram enviados para análise. Você pode acompanhar o andamento na
      tela inicial da <b>Viabilidade de Empreendimento</b>.
    </StatusInfo>
  );

  statusesContent[WAITING_FOR_CONNECTION] = (
    <>
      <StatusInfo status="success">
        Sua solicitação de esgoto foi aprovada e será executada em um prazo de 15
        dias.
      </StatusInfo>
      <Margin height={15} />
      <StatusInfo status="warning">
        Após a execução da sua ligação você estará apto à solicitar a declaração de
        habite-se.
      </StatusInfo>
    </>
  );

  statusesContent[SUCCESS] = (
    <StatusInfo status="success" title="Ligação de esgoto executada">
      Para solicitar a sua declaração afim de habite-se, siga para a próxima etapa.
    </StatusInfo>
  );

  return (
    <Main>
      {statusesContent[status]}
      <Margin height={40} />
      <ButtonBar
        nextLabel="Solicitar habite-se"
        nextBackground={disabled ? Color.grayLightSeconde : Color.blueSeconde}
        nextDisabled={disabled}
        nextLoading={!!loadingDownloadFile || !!loadingPatch}
        backOnClick={() => {
          dispatch(
            patchStepLocalPropertyRegistratioRequest({
              name: '',
              number: 4,
              substep: '',
              status: stepStatusEnum.SUCCESS,
            }),
          );
        }}
        nextOnClick={handleClick}
      />
      <Margin height={50} />
    </Main>
  );
};

export default Status;
