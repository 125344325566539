import React from 'react';
import reactDom from 'react-dom';

import { useSpring, animated } from 'react-spring';
import * as Icons from '~/assets/Icons';

import * as S from './styles';

import { Margin } from '../Margin';

interface IProps {
  open: boolean;
  setOpen?: (state: boolean) => void;
  children?: React.ReactNode;
  type: 'success' | 'error' | 'warning' | 'info';
  title?: string;
  v3?: boolean;
  newStyle?: boolean;
  hidden?: boolean;
  maxWidth?: number;
  maxHeight?: number;
}

const Modal: React.FC<IProps> = ({
  open,
  children,
  type,
  title,
  v3,
  newStyle,
  hidden,
  maxWidth,
  maxHeight,
}) => {
  const ANIM = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 100,
  });

  const AnimatedContain = animated(S.Contain);

  return reactDom.createPortal(
    <S.Main open={open}>
      <AnimatedContain
        as={animated.div}
        color={type}
        style={ANIM}
        v3={v3}
        newStyle={newStyle}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
      >
        {!hidden && (
          <>
            {type === 'success' && <Icons.SuccessSVG />}
            {type === 'error' && <Icons.ErrorIconSVG v3={v3} />}
            {type === 'warning' && <Icons.InfoSVG color="#FAB419" />}
            {type === 'info' && <Icons.InfoSVG newStyle={newStyle} />}
            <Margin height={v3 ? 30 : 23} />
          </>
        )}
        {title && (
          <>
            <S.Title type={type} v3={v3} newStyle={newStyle}>
              {title}
            </S.Title>
            <Margin height={32} />
          </>
        )}
        {children}
      </AnimatedContain>
    </S.Main>,
    document.getElementById('modal-root')!,
  );
};

export default Modal;
