import { Reducer } from 'redux';
import { RepositoriesTypesDpaDpe, IRepositoriesStateDpaDpe } from './types';

const INITIAL_STATE: IRepositoriesStateDpaDpe = {
  data: [],
  dataSlips: [],
  pagination: {
    totalItens: 0,
    maxPages: 0,
    page: 0,
  },
  paginationSlips: {
    totalItens: 0,
    maxPages: 0,
    page: 0,
  },
  caseDetail: {
    selectedCase: false,
    id: '',
    status: '',
    case_number: '',
    attachments: [],
    review_fields: [],
    review_comments: null,
    enterprise: {
      owner: '',
      email: '',
      cpf_cnpj: '',
      cep: '',
      street: '',
      neighborhood: '',
      number: '',
      complement: '',
      city: '',
      state: '',
    },
    interested: {
      name: '',
      qualification: '',
      address: '',
      email: '',
      phone: '',
    },
    characteristics: [],
    created_at: '',
  },
  dpadpeLocation: '',
  operations: [],
  loading: false,
  error: null,
  errorCaseDetail: null,
  dataSlip: null,
  dataStatuses: {
    cpae: [],
    fcp: [],
    oc: [],
  },
} as IRepositoriesStateDpaDpe;

const reducer: Reducer<IRepositoriesStateDpaDpe, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesDpaDpe.DPADPE_LIST_STATUSES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesDpaDpe.DPADPE_LIST_STATUSES_SUCCESS:
      return {
        ...state,
        dataStatuses: action.payload.data,
        loading: false,
      };
    case RepositoriesTypesDpaDpe.DPADPE_LIST_STATUSES_FAILURE:
      return {
        ...state,
        dataStatuses: null,
        loading: false,
      };
    case RepositoriesTypesDpaDpe.DPADPE_LIST_STATUSES_FAILURE_CLOSE:
      return {
        ...state,
        dataStatuses: null,
        loading: false,
      };

    case RepositoriesTypesDpaDpe.DPADPE_LISTCASES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesDpaDpe.DPADPE_LISTCASES_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        pagination: action.payload.data.pagination,
        loading: false,
      };
    case RepositoriesTypesDpaDpe.DPADPE_LISTCASES_FAILURE:
      return {
        ...state,
        data: [],
        pagination: {
          totalItens: 0,
          maxPages: 0,
          page: 0,
        },
        error: action.payload?.error,
        loading: false,
      };
    case RepositoriesTypesDpaDpe.DPADPE_LISTCASES_FAILURE_CLOSE:
      return {
        ...state,
        error: null,
      };

    case RepositoriesTypesDpaDpe.DPADPE_LIST_OPERATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesDpaDpe.DPADPE_LIST_OPERATIONS_SUCCESS:
      return {
        ...state,
        operations: action.payload.data,
        loading: false,
      };
    case RepositoriesTypesDpaDpe.DPADPE_LIST_OPERATIONS_FAILURE:
      return {
        ...state,
        operations: [],
        error: action.payload?.error,
        loading: false,
      };

    case RepositoriesTypesDpaDpe.DPADPE_REGISTER_VIABILITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesDpaDpe.DPADPE_UPDATE_VIABILITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesDpaDpe.DPADPE_REGISTER_VIABILITY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RepositoriesTypesDpaDpe.DPADPE_REGISTER_VIABILITY_FAILURE:
      return {
        ...state,
        error: action.payload?.error,
        loading: false,
      };

    case RepositoriesTypesDpaDpe.DPADPE_SET_SELECTED_CASE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesDpaDpe.DPADPE_SET_SELECTED_CASE_SUCCESS:
      return {
        ...state,
        caseDetail: {
          ...action.payload.data,
          selectedCase: true,
        },
        loading: false,
      };
    case RepositoriesTypesDpaDpe.DPADPE_SET_SELECTED_CASE_FAILURE:
      return {
        ...state,
        errorCaseDetail: action.payload?.error,
        loading: false,
      };
    case RepositoriesTypesDpaDpe.DPADPE_SET_SELECTED_CASE_FAILURE_CLOSE:
      return {
        ...state,
        errorCaseDetail: null,
        loading: false,
      };

    case RepositoriesTypesDpaDpe.DPADPE_RESET_SELECTED_CASE:
      return {
        ...state,
        caseDetail: {
          ...INITIAL_STATE.caseDetail,
        },
      };
    case RepositoriesTypesDpaDpe.DPADPE_SET_LOCATION:
      return {
        ...state,
        dpadpeLocation: action.payload,
      };
    case RepositoriesTypesDpaDpe.DPADPE_RESET_LOCATION:
      return {
        ...state,
        dpadpeLocation: '',
      };

    case RepositoriesTypesDpaDpe.DPADPE_LISTS_SLIPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesDpaDpe.DPADPE_LISTS_SLIPS_SUCCESS:
      return {
        ...state,
        dataSlips: action.payload.data.data,
        paginationSlips: action.payload.data.pagination,
        loading: false,
      };
    case RepositoriesTypesDpaDpe.DPADPE_LISTS_SLIPS_FAILURE:
      return {
        ...state,
        dataSlips: [],
        paginationSlips: {
          totalItens: 0,
          maxPages: 0,
          page: 0,
        },
        error: action.payload?.error,
        loading: false,
      };

    case RepositoriesTypesDpaDpe.DPADPE_GET_SLIPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesDpaDpe.DPADPE_GET_SLIPS_SUCCESS:
      return {
        ...state,
        dataSlip: action.payload.data,
        loading: false,
      };
    case RepositoriesTypesDpaDpe.DPADPE_GET_SLIPS_FAILURE:
      return {
        ...state,
        dataSlip: null,
        error: action.payload?.error,
        loading: false,
      };

    case RepositoriesTypesDpaDpe.DPADPE_DOWNLOAD_SLIPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesDpaDpe.DPADPE_DOWNLOAD_SLIPS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RepositoriesTypesDpaDpe.DPADPE_DOWNLOAD_SLIPS_FAILURE:
      return {
        ...state,
        error: action.payload?.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default reducer;
