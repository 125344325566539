import { EndpointBuilder, BaseQueryFn } from '@reduxjs/toolkit/query';
import { IEnumCacheConfig } from '../enum';
import { IFetchMetricRegisterAuth, IFetchMetricRegisterOutsides } from '../types';
import formatter from '~/utils/formatter';

export const metricEndpoints = (
  builder: EndpointBuilder<BaseQueryFn, IEnumCacheConfig, 'cache'>,
) => ({
  fetchMetricRegisterAuth: builder.query<null, IFetchMetricRegisterAuth>({
    query: (payload) => ({
      url: `v3/metrics/`,
      method: 'POST',
      data: { ...payload },
    }),
    providesTags: [IEnumCacheConfig.METRIC_REGISTER_AUTH],
  }),
  fetchMetricRegisterOutsides: builder.query<null, IFetchMetricRegisterOutsides>({
    query: ({ cnp, data }) => ({
      url: `v3/metrics/${formatter.formatarNumber(cnp)}`,
      method: 'POST',
      data: {
        ...data,
      },
    }),
    providesTags: [IEnumCacheConfig.METRIC_REGISTER_OUTSIDES],
  }),
});
