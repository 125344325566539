import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { colors } from '~/design_system/styles/colors';
import { Container } from './styles';

interface ISkeletonCustomProps {
  width?: number;
  height?: number;
}
const SkeletonCustom: FC<ISkeletonCustomProps> = ({
  width = '100%',
  height = '100%',
}) => {
  return (
    <Container>
      <Skeleton baseColor={colors.grey_20} width={width} height={height} />
    </Container>
  );
};

export default SkeletonCustom;
