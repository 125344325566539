import React, { FC, useEffect, useState } from 'react';
import { PageNumber } from '../styles';

export interface IResponsePagination {
  totalItens: number;
  maxPages: number;
  page: number;
}

interface IProps {
  setPage: (e: number) => void;
  pagination: IResponsePagination;
}

export const PaginationDefault: FC<IProps> = ({ setPage, pagination }: IProps) => {
  const [pages, setPages] = useState<Array<number>>([]);

  useEffect(() => {
    console.log(`pagination`, pagination);
    if (pagination && pagination.maxPages > 0) {
      const arrayPages: any = [];

      for (let index = 1; index <= pagination.maxPages; index++) {
        arrayPages.push(index);
      }
      setPages(arrayPages);
    }
  }, [pagination]);

  return pages.map((page) => (
    <PageNumber
      key={page}
      isActive={page === pagination?.page}
      type="button"
      onClick={() => {
        if (pagination?.page === page) return;

        if (pagination?.page) {
          setPage(page);
        }
      }}
    >
      {page}
    </PageNumber>
  ));
};
