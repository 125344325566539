import { IEnumErrorResponse } from '~/enums/errorsEnum';

export enum RepositoriesTypesNotifications {
  NOTIFICATIONS_ACTIVATE_MODAL = '@repositories/NOTIFICATIONS_ACTIVATE_MODAL',
  NOTIFICATIONS_CLEAR_MODAL = '@repositories/NOTIFICATIONS_CLEAR_MODAL',

  NOTIFICATIONS_GET_LIST_METHOD_REQUEST = '@repositories/NOTIFICATIONS_GET_LIST_METHOD_REQUEST',
  NOTIFICATIONS_GET_LIST_METHOD_SUCCESS = '@repositories/NOTIFICATIONS_GET_LIST_METHOD_SUCCESS',
  NOTIFICATIONS_GET_LIST_METHOD_FAILURE = '@repositories/NOTIFICATIONS_GET_LIST_METHOD_FAILURE',
  NOTIFICATIONS_GET_LIST_METHOD_FAILURE_CLOSE = '@repositories/NOTIFICATIONS_GET_LIST_METHOD_FAILURE_CLOSE',

  NOTIFICATIONS_POST_CREATE_METHOD_REQUEST = '@repositories/NOTIFICATIONS_POST_CREATE_METHOD_REQUEST',
  NOTIFICATIONS_POST_CREATE_METHOD_SUCCESS = '@repositories/NOTIFICATIONS_POST_CREATE_METHOD_SUCCESS',
  NOTIFICATIONS_POST_CREATE_METHOD_FAILURE = '@repositories/NOTIFICATIONS_POST_CREATE_METHOD_FAILURE',
  NOTIFICATIONS_POST_CREATE_METHOD_FAILURE_CLOSE = '@repositories/NOTIFICATIONS_POST_CREATE_METHOD_FAILURE_CLOSE',

  NOTIFICATIONS_PUT_ID_METHOD_REQUEST = '@repositories/NOTIFICATIONS_PUT_ID_METHOD_REQUEST',
  NOTIFICATIONS_PUT_ID_METHOD_SUCCESS = '@repositories/NOTIFICATIONS_PUT_ID_METHOD_SUCCESS',
  NOTIFICATIONS_PUT_ID_METHOD_FAILURE = '@repositories/NOTIFICATIONS_PUT_ID_METHOD_FAILURE',
  NOTIFICATIONS_PUT_ID_METHOD_FAILURE_CLOSE = '@repositories/NOTIFICATIONS_PUT_ID_METHOD_FAILURE_CLOSE',

  NOTIFICATIONS_PUT_ALL_METHOD_REQUEST = '@repositories/NOTIFICATIONS_PUT_ALL_METHOD_REQUEST',
  NOTIFICATIONS_PUT_ALL_METHOD_SUCCESS = '@repositories/NOTIFICATIONS_PUT_ALL_METHOD_SUCCESS',
  NOTIFICATIONS_PUT_ALL_METHOD_FAILURE = '@repositories/NOTIFICATIONS_PUT_ALL_METHOD_FAILURE',
  NOTIFICATIONS_PUT_ALL_METHOD_FAILURE_CLOSE = '@repositories/NOTIFICATIONS_PUT_ALL_METHOD_FAILURE_CLOSE',
}

export interface IResponseListNotifications {
  id: number;
  user_id: number;
  role: string;
  enrollment: number;
  title: string;
  body: string;
  fcm_message_id: string;
  is_read: boolean;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface IRequestCreateNotification {
  role: string;
  enrollment: string;
  title: string;
  body: string;
  fcm_message_id: string;
}

interface IResponseNotification {
  id: number;
  user_id: number;
  role: string;
  enrollment: number;
  title: string;
  body: string;
  fcm_message_id: string;
  is_read: true;
  deletedAt: null | string;
  createdAt: string;
  updatedAt: string;
}

export interface IResponseUpdateNotification {
  message: string;
  data: IResponseNotification;
}

export interface IResponseUpdateAllNotification {
  message: string;
  data: IResponseNotification[];
}

export interface IRepositoriesStateNotifications {
  readonly data: IResponseListNotifications[] | [];
  readonly modalNotifications: boolean;
  readonly loading: boolean;
  readonly error: IEnumErrorResponse | null;
}
