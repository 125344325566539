import React, { ReactNode } from 'react';
import { Container } from './styles';

interface IProps {
  children: ReactNode;
  onClick: () => void;
  type:
    | 'buttons'
    | 'newProcess'
    | 'filters-pending'
    | 'filters-cancel'
    | 'filters-paid';
  select?: boolean;
}

const ButtonGeneric = ({ children, onClick, type, select }: IProps) => {
  return (
    <Container types={type} select={select} onClick={() => onClick()}>
      {children}
    </Container>
  );
};

export default ButtonGeneric;
