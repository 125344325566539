import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Main, Card, ContainerBreadCrumb, ContainerMaturityDay } from './styles';
import { Margin } from '~/components/Margin';
import { InputSelect } from '~/components/InputSelect';
import { ButtonText } from '~/components/ButtonText';
import Modal from '~/components/Modal';
import ModalError from '~/components/ModalError';
import { Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { IState } from '~/store/modules/types';
import { BreadCrumb } from '~/components/BreadCrumb';
import {
  getEnrollmentsTypesInvoicesRequest,
  putEnrollmentsTypesInvoicesRequest,
  putErrorCloseEnrollmentsTypesInvoices,
} from '~/store/modules/enrollmentsInvoices/actions';
import LoadingComponent from '~/components/Loading';
import { AppDispatch } from '~/store';

interface InvoiceType {
  id: number;
  label: string;
}

interface IResponseTypesInvoices {
  [key: string]: InvoiceType;
}

const InvoiceChangeDeliveryType: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const dataTypesInvoices = useSelector(
    (item: IState) =>
      item.enrollmentsInvoices
        ?.dataTypesInvoices as unknown as IResponseTypesInvoices,
  );
  const type_invoice = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.type_invoice,
  );
  const loading = useSelector(
    (item: IState) => item.enrollmentsInvoices.loadingPutTypesInvoices,
  );
  const loadingTypesInvoices = useSelector(
    (item: IState) => item.enrollmentsInvoices.loadingTypesInvoices,
  );
  const success = useSelector(
    (item: IState) => item.enrollmentsInvoices.successPutTypesInvoices,
  );
  const error = useSelector(
    (item: IState) => item.enrollmentsInvoices.errorPutTypesInvoices,
  );
  const [deliveryTypes, setDeliveryTypes] = useState<any[]>([]);
  const [typeInvoice, setTypeInvoice] = useState<string>(type_invoice?.label || '');
  const disabled: boolean = dataTypesInvoices && type_invoice?.label === typeInvoice;
  const backgroundColor = disabled ? Color.grayLightSeconde : Color.green;

  useEffect(() => {
    if (success) {
      Navigation.navigate(RouteName.INVOICES);
    }
  }, [success]);

  const convertDeliveryType = (label: string, capitalize?: boolean) => {
    switch (label) {
      case 'FÍSICO':
        return capitalize ? 'Física' : 'física';
      case 'E-MAIL/FÍSICO':
        return capitalize ? 'E-mail/Física' : 'e-mail/física';
      default:
        return capitalize ? 'E-mail' : 'e-mail';
    }
  };

  useEffect(() => {
    if (dataTypesInvoices) {
      const array: any[] = [];

      Object.keys(dataTypesInvoices).map((item) =>
        array?.push({
          label: convertDeliveryType(item, true),
          value: item,
        }),
      );

      setDeliveryTypes(array);
    }
  }, [loadingTypesInvoices]);

  useEffect(() => {
    dispatch(getEnrollmentsTypesInvoicesRequest());
  }, []);

  return (
    <Main>
      {loadingTypesInvoices && <LoadingComponent labelWait="Carregando..." />}
      <Modal open={!!(error && error.state)} type="error" title="Ops!" v3>
        <ModalError
          onClose={() => dispatch(putErrorCloseEnrollmentsTypesInvoices())}
        />
      </Modal>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Faturas',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Card>
        <h1>Alterar tipo de entrega da fatura</h1>
        <ContainerMaturityDay>
          <p>
            O tipo de entrega atual é{' '}
            {type_invoice?.label && convertDeliveryType(type_invoice?.label)}
          </p>
        </ContainerMaturityDay>
        <p>Escolha o tipo de entrega que deseja para suas próximas faturas.</p>
        <Margin height={24} />
        <div className="container-input">
          <InputSelect
            label="Tipo de entrega"
            name="deliveryType"
            values={deliveryTypes}
            value={typeInvoice}
            onChange={(ev) => setTypeInvoice(ev.target.value)}
            errorPosition="bottom"
          />
        </div>
        <Margin height={24} />
        <ButtonText
          text="Salvar"
          typeBtn="content"
          Color={Color.white}
          backgroundColor={backgroundColor}
          onClick={() => {
            if (disabled) return;

            dispatch(
              putEnrollmentsTypesInvoicesRequest(
                dataTypesInvoices[typeInvoice]?.id?.toString(),
                dataTypesInvoices[typeInvoice]?.label,
              ),
            );
          }}
          loading={loading}
        />
        <Margin height={24} />
        <ButtonText
          text="Voltar"
          typeBtn="content"
          backgroundColor={Color.green}
          onClick={() => Navigation.navigate(RouteName.INVOICES)}
        />
      </Card>
    </Main>
  );
};

export default InvoiceChangeDeliveryType;
