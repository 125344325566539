import React, { FC, useEffect, useState } from 'react';

import { Container } from '~/common';
import {
  ButtonCodeInvoice,
  ButtonPayment,
  Card,
  ContainerBreadCrumb,
  ContainerButtonPayment,
  ContainerCodeBarLabelInvoice,
  ContainerInfoPayment,
  ContainerQRCode,
  Content,
  Divisor,
  HeaderInfoPayment,
  Line,
  Main,
  Row,
  RowContainer,
  RowInfo,
  SubTitle,
  Wrapper,
} from './styles';
import { RouteName } from '~/routes/Routes.name';
import Navigation from '~/utils/Navigation';
import { BreadCrumb } from '~/components/BreadCrumb';
import { Margin } from '~/components/Margin';
import { QRCode } from '~/components/QRCode';

import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import LoadingComponent from '~/components/Loading';
import CloseSVG from './components/CloseIcons';
import { CopySuccessIcon } from '~/views/EasyDuplicate/Icons';
import CopyIcon from '~/assets/Icons/Copy';
import BarcodeSVG from './components/BarcodeIcon';
import { PixSVG } from './components/PixIcon';
import moment from 'moment';
import formatter from '~/utils/formatter';
import BorderSVG from './components/BorderIcon';
import clipboardCopy from 'clipboard-copy';
import { downloadSlipRequest } from '~/store/modules/dpadpe/actions';
import { AppDispatch } from '~/store';

const SupplyAndDepletionPossibilityDeclarationSlips: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((item: IState) => item.dpadpe.loading);
  const slip = useSelector((item: IState) => item.dpadpe.dataSlip);

  const [code, setCode] = useState('');
  const [method, setMethod] = useState('pixCode');
  const [copied, setCopied] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isClickedDownload, setIsClickedDownload] = useState(false);
  const [downloaded, setDownloaded] = useState(false);

  useEffect(() => {
    setCopied(false);
    method === 'barCode'
      ? setCode(slip?.barcode ? slip?.barcode.toString() : '')
      : setCode(slip?.pixCode || '');
  }, [method]);

  const handleCopy = () => {
    if (isClicked || copied) return;

    setIsClicked(true);

    setTimeout(() => {
      clipboardCopy(code);
      setIsClicked(false);
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 5000);
    }, 2000);
  };

  const handleDownload = () => {
    if (isClickedDownload || downloaded) return;

    setIsClickedDownload(true);

    setTimeout(() => {
      dispatch(downloadSlipRequest({ slipId: slip?.id || `` }));
    }, 2000);
  };

  useEffect(() => {
    console.log({ loading, isClickedDownload });

    if (isClickedDownload && !loading) {
      setIsClickedDownload(false);
      setDownloaded(true);
      setTimeout(() => {
        setDownloaded(false);
      }, 5000);
    }
  }, [loading]);

  isClickedDownload;

  return (
    <>
      {loading && !isClickedDownload && <LoadingComponent />}
      <Main>
        <Container size="newLarge">
          <Wrapper>
            <Margin height={18} />
            <ContainerBreadCrumb>
              <BreadCrumb
                links={[
                  {
                    label: 'Home',
                    active: false,
                    onClick: () => Navigation.navigate(RouteName.HOME),
                  },
                  {
                    label: 'Acessar Boletos',
                    active: true,
                  },
                ]}
              />
            </ContainerBreadCrumb>

            <Margin height={24} />

            <Card>
              <Margin height={30} />
              <Content>
                <RowContainer>
                  <h1>Boleto para pagamento</h1>
                  <button
                    type="button"
                    onClick={() => {
                      Navigation.navigate(
                        RouteName.ENTERPRISE_VIABILITY_PANEL_SLIPS,
                      );
                    }}
                  >
                    <CloseSVG />
                  </button>
                </RowContainer>
                <Margin height={24} />
                <Line />
                <Margin height={24} />

                {/* <Content>
                <Text>
                  Bem-vindo à sua Tela de Boletos! Aqui, você encontrará um resumo
                  completo de todas as suas transações financeiras. Esta página é
                  projetada para ajudá-lo a manter o controle das suas faturas de
                  forma simples e eficaz.
                  <br />
                  <br />
                  Esta Tela de Faturas foi projetada para tornar a gestão financeira
                  mais simples e transparente. Esperamos que você a encontre útil
                  para acompanhar suas transações e manter suas finanças em ordem .
                  <br />
                  Seja bem-vindo(a) ao seu painel de controle financeiro!
                </Text>
              </Content>

              <Margin height={40} />

              <ContainerTitle>
                <Line />
                <TitleLine>Boletos</TitleLine>
              </ContainerTitle> 
              <Margin height={40} />
              */}
                <SubTitle>Selecione a forma de pagamento:</SubTitle>
                <ContainerButtonPayment>
                  <ButtonPayment
                    selected={method === 'pixCode'}
                    onClick={() =>
                      !isClicked &&
                      !isClickedDownload &&
                      !copied &&
                      !downloaded &&
                      setMethod('pixCode')
                    }
                  >
                    <PixSVG />
                    <Margin width={15} />
                    PIX
                  </ButtonPayment>
                  <ButtonPayment
                    selected={method === 'barCode'}
                    onClick={() =>
                      !isClicked &&
                      !isClickedDownload &&
                      !copied &&
                      !downloaded &&
                      setMethod('barCode')
                    }
                  >
                    <BarcodeSVG />
                    <Margin width={15} />
                    Boleto
                  </ButtonPayment>
                </ContainerButtonPayment>

                <Margin height={16} />
                <Wrapper>
                  <RowInfo>
                    <p>Nome da operação: {slip?.operation}</p>
                  </RowInfo>
                  <RowInfo backgroundWhite>
                    <p>Matrícula: {slip?.enrollment}</p>
                  </RowInfo>
                  <RowInfo>
                    <p>Nome do cliente: {slip?.customer.name}</p>
                  </RowInfo>
                  <RowInfo backgroundWhite>
                    <p>Nome do cliente comercial: {slip?.customer.commercialName}</p>
                  </RowInfo>
                  <RowInfo>
                    <p>Vencimento: {moment(slip?.dueDate).format('DD/MM/YYYY')}</p>
                  </RowInfo>
                  <RowInfo backgroundWhite>
                    <p>
                      Data de emissão: {moment(slip?.issueDate).format('DD/MM/YYYY')}
                    </p>
                  </RowInfo>
                  <RowInfo>
                    <p>Status do boleto: {slip?.status}</p>
                  </RowInfo>
                  <RowInfo backgroundWhite>
                    <p>Valor:</p>
                    <p>{formatter.formatCurrency(slip?.amount)}</p>
                  </RowInfo>
                </Wrapper>
                <Margin height={32} />
                <ContainerButtonPayment>
                  {method === 'pixCode' && (
                    <>
                      <ContainerQRCode>
                        <BorderSVG className="topRight" />
                        <BorderSVG className="topLeft" />
                        <span>
                          <QRCode codeValue={slip?.pixCode || ''} />
                        </span>
                        <BorderSVG className="bottomRight" />
                        <BorderSVG className="bottomLeft" />
                      </ContainerQRCode>
                      <Margin width={40} />
                    </>
                  )}
                  <ContainerInfoPayment>
                    <HeaderInfoPayment>
                      {method === 'pixCode'
                        ? 'Pagar com o PIX é muito mais fácil!'
                        : 'Pague o seu boleto com o código de barras'}
                    </HeaderInfoPayment>
                    <Margin height={32} />
                    <div>
                      <p>1 Abra o aplicativo de pagamento ou banco</p>
                      <p>2 Escolha a opção de pagamento via boleto</p>
                      <p>
                        3{' '}
                        {method === 'pixCode'
                          ? `Aponte a câmera para a tela para escanear o QR code`
                          : `Copie o código abaixo e cole no seu app`}
                      </p>
                      <p>4 Confirme os detalhes da transação</p>
                    </div>
                    <Margin height={32} />
                    <p>
                      {method === 'pixCode'
                        ? 'Você também pode pagar com o código PIX abaixo.'
                        : 'Você também pode usar o código para pagar em qualquer lotérica!'}
                    </p>
                  </ContainerInfoPayment>
                </ContainerButtonPayment>
                <Margin height={16} />

                <Divisor />
                <ContainerCodeBarLabelInvoice>
                  {method === 'barCode' ? (
                    <span>Código de barras</span>
                  ) : (
                    <span>Código PIX</span>
                  )}
                  {method === 'barCode' ? (
                    <p>{slip?.barcode}</p>
                  ) : (
                    <p>{slip?.pixCode}</p>
                  )}
                </ContainerCodeBarLabelInvoice>
                <Row>
                  <ButtonCodeInvoice
                    type="button"
                    onClick={handleCopy}
                    clicked={isClicked}
                    copied={copied}
                  >
                    {!isClicked && (
                      <>
                        <p>{copied ? 'Código copiado!' : `Copiar`}</p>
                        <Margin width={8} />
                        <div>
                          {copied ? (
                            <CopySuccessIcon />
                          ) : (
                            <CopyIcon color="#000" v3 />
                          )}
                        </div>
                      </>
                    )}
                  </ButtonCodeInvoice>
                  <ButtonCodeInvoice
                    type="button"
                    onClick={handleDownload}
                    clicked={isClickedDownload}
                    copied={downloaded}
                  >
                    {!isClickedDownload && (
                      <>
                        <p>{downloaded ? 'Baixado!' : `Baixar`}</p>
                        <Margin width={8} />
                        <div>
                          {downloaded ? (
                            <CopySuccessIcon />
                          ) : (
                            <svg
                              width="18"
                              height="19"
                              viewBox="0 0 18 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 10.5L5.5 7.5M9 10.5L12.5 7.5M9 10.5L9 6L9 1.5"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M1.5 14.5L1.96575 16.3638C2.04686 16.6883 2.23412 16.9763 2.49775 17.1822C2.76138 17.3881 3.08626 17.4999 3.42075 17.5H14.5792C14.9137 17.4999 15.2386 17.3881 15.5023 17.1822C15.7659 16.9763 15.9531 16.6883 16.0343 16.3638L16.5 14.5"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>

                            // <CopyIcon color="#000" v3 />
                          )}
                        </div>
                      </>
                    )}
                  </ButtonCodeInvoice>
                </Row>
              </Content>
            </Card>
          </Wrapper>
        </Container>
      </Main>
    </>
  );
};

export default SupplyAndDepletionPossibilityDeclarationSlips;
