import { IResponseGetAutomaticDebits } from '~/store/modules/automaticDebits/types';

const formatDateAccount = (data: IResponseGetAutomaticDebits) => {
  const dateAccount = data?.inclusion_date.replaceAll('/', '');

  const day = dateAccount?.substring(0, 2);
  const month = dateAccount?.substring(2, 4);
  const year = dateAccount?.substring(4, dateAccount.length);

  return `${month}-${day}-${year}`;
};

const verifyDateDebtAccount = (
  data: IResponseGetAutomaticDebits,
  maturity_date: string,
) => {
  if (data) {
    return (
      new Date(maturity_date).getTime() >=
      new Date(formatDateAccount(data)).getTime()
    );
  }
};

export const existsAccountDebt = (
  data: IResponseGetAutomaticDebits | null,
  maturity_date: string,
) => {
  return (
    data &&
    (data.situation === 'Cadastrado' || data.situation === 'Confirmado') &&
    verifyDateDebtAccount(data, maturity_date)
  );
};
