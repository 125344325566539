import { api } from './api';

function togglesMeServices(enrollment?: string) {
  return new Promise((resolve, reject) => {
    try {
      const getTogglesMe: any = async () => {
        const response = await api.get('v3/toggles', {
          params: {
            enrollment,
          },
        });
        return response.data;
      };
      resolve(getTogglesMe());
    } catch (error) {
      reject(error);
    }
  });
}

export { togglesMeServices };
