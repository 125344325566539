import React from 'react';
import styled from 'styled-components';

import Footer from '../Footer';
import Header from '../Header';
import { LayoutContainer, Main } from './styles';

interface IProps {
  children: any;
}

const LayoutApp = ({ children }: IProps) => {
  return (
    <LayoutContainer className="body">
      <Header />
      <Main>{children}</Main>
      <Footer />
    </LayoutContainer>
  );
};

export default LayoutApp;
