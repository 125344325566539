import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { setCaseValues } from '~/store/modules/dpadpeDataMenager/actions';

import { Row } from './styles';
import { IPropsForm } from '../typesForm';
import TitleStep from '../TitleStep';
import { SubTitle } from '../SubTitle';
import FieldInput from '../FieldInput';
import formatter from '~/utils/formatter';
import { AppDispatch } from '~/store';

const SecondStep: FC<IPropsForm> = ({ values, isDisabled }) => {
  const dispatch = useDispatch<AppDispatch>();

  const caseValues = useSelector(
    (item: IState) => item.dpadpeDataMenager.caseValues,
  );

  const caseDetail = useSelector((item: IState) => item.dpadpe.caseDetail);

  useEffect(() => {
    dispatch(setCaseValues({ ...caseValues, qualification: 'Proprietário' }));
  }, []);

  return (
    <>
      <TitleStep title="Dados do interessado" />

      <SubTitle style={{ marginTop: 50 }}>
        Nesta fase serão solicitados os dados do Interessado (o proprietário pode ser
        ou não o próprio interessado). O interessado deverá preencher os campos
        abaixo com os seus dados. Note que todo campo com o sinal * (asterisco)
        deverá obrigatoriamente ser preenchido.
      </SubTitle>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          padding: '0 80px',
          marginTop: 20,
        }}
      >
        <Row>
          <div style={{ flex: 1 }}>
            <FieldInput
              label="Nome*"
              name="name"
              disabled={isDisabled}
              value={caseDetail?.selectedCase ? values?.name : undefined}
              maxLength={40}
            />
          </div>

          <div style={{ width: 40 }} />

          <div style={{ flex: 1 }}>
            <FieldInput
              selectForm
              label="Qualificação*"
              name="qualification"
              disabled={isDisabled}
              value={caseDetail?.selectedCase ? values?.qualification : undefined}
              values={[
                {
                  label: '',
                  value: '',
                },
                {
                  label: 'Proprietário',
                  value: 'Proprietário',
                },
                {
                  label: 'Procurador',
                  value: 'Procurador',
                },
                {
                  label: 'Outro',
                  value: 'Outro',
                },
              ]}
            />
          </div>
        </Row>
        <Row>
          <div style={{ flex: 1 }}>
            <FieldInput
              label="Email*"
              name="interestedEmail"
              disabled={isDisabled}
              value={caseDetail?.selectedCase ? values?.interestedEmail : undefined}
            />
          </div>
          <div style={{ width: 40 }} />
          <div style={{ flex: 1 }}>
            <FieldInput
              label="Endereço*"
              name="interestedAddress"
              disabled={isDisabled}
              value={caseDetail?.selectedCase ? values?.address : undefined}
              maxLength={60}
            />
          </div>
        </Row>

        <Row>
          <div style={{ flex: 1 }}>
            <FieldInput
              label="Telefone*"
              name="phone"
              disabled={isDisabled}
              value={
                caseDetail?.selectedCase
                  ? formatter.formatarPhone(values?.phone)
                  : undefined
              }
            />
          </div>
          <div style={{ width: 40 }} />
          <div style={{ flex: 1 }} />
        </Row>
      </div>
    </>
  );
};
export default SecondStep;
