import { Dispatch } from 'redux';
import ReactGA from 'react-ga4';
import {
  getOrderAddressLackWaterService,
  getOrderDownloadLackWaterService,
  getOrderInputsLackWaterService,
  getOrderValidateLackWaterService,
  getServiceOrdersService,
  getServiceOrderTypesService,
  postOrderCreateLackWaterService,
  postServiceOrderCreateService,
} from '~/services/orderServices';
import { IState } from '../types';
import {
  RepositoriesTypesOrderServices,
  IResponseGetListByCnp,
  IRequestQueryOrders,
  IRequestPostCreate,
  IResponsePostCreate,
  IResponseValidateLackWater,
  IRequestCreateLackWater,
  IResponseAddressLackWater,
  IResponseInputsLackWater,
} from './types';
import Navigation from '~/utils/Navigation';
import onShareBlob from '~/utils/onShareBlob';
import { RouteName } from '~/routes/Routes.name';
import { RepositoriesTypesOutsides } from '../outsides/types';
import { AppDispatch } from '~/store';
import {
  fetchOrderServicesInputsLackWaterList,
  fetchOrderServicesList,
} from '~/store/cache/apiCache';

const getOrderServicesListRequest =
  (params?: IRequestQueryOrders) => async (dispatch: AppDispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_LIST_BY_CNP_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = await dispatch(
        fetchOrderServicesList.initiate({
          enrollment: registration,
          params,
        }),
      );

      if (response.error) throw response.error;

      return dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_LIST_BY_CNP_METHOD_SUCCESS,
        payload: {
          data: response?.data,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_LIST_BY_CNP_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getErrorCloseServiceOrdersListRequest = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_LIST_BY_CNP_METHOD_CLOSE,
  });
};

const getOrderServicesListTypesRequest =
  () => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_LIST_TYPE_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const response = (await getServiceOrderTypesService(
        enrollmentsAuth.registrationCheck?.operation.id || 0,
      )) as IResponseGetListByCnp;

      return dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_LIST_TYPE_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_LIST_TYPE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const postServiceOrderAttachmentsRequest =
  (data: IRequestPostCreate) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_POST_ATTACHMENTS_METHOD_REQUEST,
      });

      const responseCreate = (await postServiceOrderCreateService(
        data,
      )) as IResponsePostCreate;

      return dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_POST_ATTACHMENTS_METHOD_SUCCESS,
        payload: {
          data: responseCreate,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_POST_ATTACHMENTS_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const postErrorCloseServiceOrderAttachmentsRequest =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesOrderServices.ORDER_SERVICES_POST_ATTACHMENTS_METHOD_CLOSE,
    });
  };

const getOrderServicesValidateLackWaterRequest =
  (route?: string) => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_VALIDATE_LACKWATER_METHOD_REQUEST,
      });

      const { enrollmentsAuth, outsides } = getState() as IState;

      const reportLackWaterRoute = outsides?.reportLackWaterRoute;

      const response = (await getOrderValidateLackWaterService(
        enrollmentsAuth.registrationCheck?.enrollment || '',
      )) as IResponseValidateLackWater;

      if ((route || reportLackWaterRoute) && response.validate) {
        Navigation.navigate(route || RouteName.REPORTLACKWATER);

        dispatch({
          type: RepositoriesTypesOutsides.OUTSIDES_ROUTE_REPORT_LACK_WATER_CLEAR,
        });
      }

      return dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_VALIDATE_LACKWATER_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_VALIDATE_LACKWATER_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getErrorCloseServiceValidateLackWaterRequest =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_VALIDATE_LACKWATER_METHOD_CLOSE,
    });
  };

const getOrderServicesInputsLackWaterRequest =
  () => async (dispatch: AppDispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_INPUTS_LACKWATER_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const operationId = enrollmentsAuth.registrationCheck?.operation.id || 0;

      const response = await dispatch(
        fetchOrderServicesInputsLackWaterList.initiate({
          identificadorInput: 'FALTA D AGUA',
          operationId,
        }),
      );

      if (response.error) throw response.error;

      return dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_INPUTS_LACKWATER_METHOD_SUCCESS,
        payload: {
          data: response?.data,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_INPUTS_LACKWATER_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const postOrderCreateLackWaterRequest =
  (data: IRequestCreateLackWater) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_POST_CREATE_LACKWATER_METHOD_REQUEST,
      });

      const response = (await postOrderCreateLackWaterService(data)) as any;

      ReactGA.event({
        category: 'Falta d’água',
        action: '[WEB][Falta D’água][AL][ Solicitação efetuada com Sucesso]',
      });

      return dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_POST_CREATE_LACKWATER_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error: any) {
      ReactGA.event({
        category: 'Falta d’água',
        action: '[WEBFalta D’água][AL] [Solicitação não efetuada]',
      });

      return dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_POST_CREATE_LACKWATER_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const postErrorCloseServiceCreateLackWaterRequest =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesOrderServices.ORDER_SERVICES_POST_CREATE_LACKWATER_METHOD_CLOSE,
    });
  };

const getOrderServicesAddressLackWaterRequest =
  () => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_ADDRESS_LACKWATER_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const response = (await getOrderAddressLackWaterService(
        enrollmentsAuth.registrationCheck?.enrollment || '',
      )) as IResponseAddressLackWater;

      return dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_ADDRESS_LACKWATER_METHOD_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_ADDRESS_LACKWATER_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getOrderServicesDownloadLackWaterRequest =
  (caseNumber: string) => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_DOWNLOAD_LACKWATER_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const response = (await getOrderDownloadLackWaterService(
        caseNumber,
        enrollmentsAuth.registrationCheck?.enrollment || '',
      )) as any;

      onShareBlob({ data: response, fileName: `protocolo-${caseNumber}` });

      return dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_DOWNLOAD_LACKWATER_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesOrderServices.ORDER_SERVICES_GET_DOWNLOAD_LACKWATER_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

export {
  getOrderServicesListRequest,
  getErrorCloseServiceOrdersListRequest,
  getOrderServicesListTypesRequest,
  postServiceOrderAttachmentsRequest,
  postErrorCloseServiceOrderAttachmentsRequest,
  getOrderServicesValidateLackWaterRequest,
  getErrorCloseServiceValidateLackWaterRequest,
  getOrderServicesInputsLackWaterRequest,
  postOrderCreateLackWaterRequest,
  postErrorCloseServiceCreateLackWaterRequest,
  getOrderServicesAddressLackWaterRequest,
  getOrderServicesDownloadLackWaterRequest,
};
