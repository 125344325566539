import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import {
  ContainerBreadCrumb,
  ContainerForgotPassword,
  Content,
  Divider,
  Main,
  Wrapper,
} from './styles';
import { Container, Input } from '~/common';
import { Button } from '~/components/ButtonV3';
import { BreadCrumb, IDatePage } from './BreadCrumb';
import MyCard from './MyCard';
import { IState } from '~/store/modules/types';
import {
  clearDischargeDebts,
  clearEasyDuplicateInvoices,
  getEasyDuplicateInvoices,
  getErrorCloseDischargeDebts,
  getErrorCloseEasyDuplicateInvoices,
  getOutsidesRegistrationUpdateRequest,
  postErrorCloseOutsidesRegistrationUpdate,
} from '~/store/modules/outsides/actions';
import { IEasyInvoices } from '~/store/modules/outsides/types';
import formatter from '~/utils/formatter';
import { Breakpoints, Color } from '~/styles';
import { Margin } from '~/components/Margin';
import { CardSubtitle, CardTitle } from './MyCard/styles';
import { Link } from 'react-router-dom';
import { RouteName } from '~/routes/Routes.name';
import { UsefulLinks } from './UsefulLinks';
import LoadingComponent from '~/components/Loading';
import { checkCnp } from '~/utils/validation';
import Navigation from '~/utils/Navigation';
import { useMediaQuery } from 'react-responsive';
import { history } from '~/store';
import { saveEasyInvoicesCredentials } from '~/store/modules/outsidesCredentials/actions';
import InputCheckedV3 from '~/common/InputCheckedV3';
import { Recaptcha } from '~/components/Recaptcha';
import { showRecaptchaV2 } from '~/utils/showRecaptcha';
import CardDebts from './CardDebts';
import { EnumResponseCode } from '~/enums/reponseCodesEnum';
import { AppDispatch } from '~/store';
import ModalDefaultError from '~/design_system/components/ModalDefaultError';

export interface Values {
  cnp: string;
  enrollment: string;
  invoice: IEasyInvoices | null;
  method: 'pixCode' | 'barCode';
  type: 'CPF' | 'CNPJ';
  customError: string;
  invoiceList: IEasyInvoices[] | null;
  disabled: boolean;
}

const EasyDuplicate: FC = () => {
  const dataHistory = history.location.state as any;

  const dispatch = useDispatch<AppDispatch>();

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });
  const isTablet = useMediaQuery({ maxWidth: Breakpoints.laptop.max - 1 });

  const easyInvoices = useSelector((item: IState) => item.outsides.easyInvoices);
  const dataDischargeDebts = useSelector(
    (item: IState) => item.outsides.dataDischargeDebts,
  );
  const loading = useSelector((item: IState) => item.outsides.loading);
  const dataCredentials = useSelector(
    (item: IState) => item.outsides.dataCredentials,
  );
  const loadingInstallments = useSelector(
    (item: IState) => item?.outsides?.loadingInstallments,
  );
  const error = useSelector((item: IState) => item.outsides.error);

  const outsidesCredentials = useSelector(
    (item: IState) => item.outsidesCredentials.data,
  );

  const [cnp, setCnp] = useState<string>('');
  const [enrollment, setEnrollment] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [selectedInvoice, setSelectedInvoice] = useState<IEasyInvoices | null>(null);
  const [errorCnp, setErrorCnp] = useState<boolean>(false);
  const [errorCnpMessage, setErrorCnpMessage] = useState<string>('');
  const [errorRegistrationMessage, setErrorRegistrationMessage] =
    useState<string>('');
  const [typeList, setTypeList] = useState<'invoices' | 'others'>('invoices');
  const [rememberChecked, setRememberChecked] = useState<boolean>(true);
  const linksDefault: IDatePage[] = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(RouteName.LANDING),
    },
    {
      label: 'Segunda via fácil',
      active: true,
      onClick: () => {
        dispatch(clearEasyDuplicateInvoices());
        setSelectedInvoice(null);
        setTypeList('invoices');
      },
    },
  ];
  const [links, setLinks] = useState<IDatePage[]>(linksDefault);
  const [recaptchaTokenV3, setRecaptchaTokenV3] = useState<string | null>(null);
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState<string | null>(null);

  const [showRecaptcha, setShowRecaptcha] = useState(showRecaptchaV2(error || null));

  const [modalError, setModalError] = useState<boolean>(false);

  const recaptchaTokens = {
    recaptchaTokenV3: recaptchaTokenV3 || '',
    recaptchaTokenV2: recaptchaTokenV2 || '',
  };
  const defaultError = error && error.message === EnumResponseCode.TOO_MANY_REQUESTS;

  useEffect(() => {
    setShowRecaptcha(showRecaptchaV2(error || null));
  }, [error]);

  useEffect(() => {
    if (dataCredentials?.cnp && dataCredentials.enrollment) {
      setCnp(dataCredentials.cnp);
      setEnrollment(dataCredentials.enrollment);
    }
  }, [dataCredentials]);

  const clearErrors = () => {
    dispatch(getErrorCloseEasyDuplicateInvoices());
    dispatch(getErrorCloseDischargeDebts());
    dispatch(postErrorCloseOutsidesRegistrationUpdate());
  };

  useEffect(() => {
    clearErrors();

    if (
      outsidesCredentials &&
      outsidesCredentials.cnp &&
      outsidesCredentials.enrollment &&
      outsidesCredentials.remember
    ) {
      setCnp((outsidesCredentials && outsidesCredentials.cnp) || '');
      setEnrollment((outsidesCredentials && outsidesCredentials.enrollment) || '');
    }

    setTypeList('invoices');

    if (dataHistory) {
      setCnp(dataHistory?.cnp);
      setEnrollment(dataHistory?.enrollment);
    } else {
      dispatch(clearEasyDuplicateInvoices());
      dispatch(clearDischargeDebts());
    }

    ReactGA.event({
      category: 'Faturas',
      action: 'Segunda via fácil',
    });
  }, []);

  useEffect(() => {
    if (
      (easyInvoices && easyInvoices?.invoices?.length > 0) ||
      (dataDischargeDebts && dataDischargeDebts?.dados?.length > 0)
    ) {
      const arrayLinks: IDatePage[] = [...linksDefault];
      arrayLinks[1].active = false;
      arrayLinks.push({
        label: 'Faturas simplificadas',
        active: true,
        onClick: () => setSelectedInvoice(null),
      });

      setLinks(arrayLinks);
    } else {
      setLinks(linksDefault);
    }
  }, [easyInvoices, dataDischargeDebts, selectedInvoice]);

  useEffect(() => {
    if (!cnp || !enrollment) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [cnp, enrollment]);

  useEffect(() => {
    if (
      (error && error.message === EnumResponseCode.NOT_FOUND_USER) ||
      defaultError
    ) {
      setErrorRegistrationMessage(
        `${
          formatter.formatarNumber(cnp)?.length === 11 ? 'CPF' : 'CNPJ'
        }/ Matrícula não encontrado, tente novamente`,
      );
      setErrorCnp(true);
    }

    if (
      error &&
      error.message === EnumResponseCode.BAD_REQUEST_ENROLLMENT_NOT_IN_USER
    ) {
      setErrorRegistrationMessage('MATRÍCULA INVÁLIDA');
    }

    if (
      error &&
      (error.message === EnumResponseCode.INVALID_REQUEST ||
        error.message === EnumResponseCode.VALIDATIONS_FAILED ||
        error.message === EnumResponseCode.GENERIC)
    ) {
      setModalError(true);
    } else {
      setModalError(false);
    }
  }, [error]);

  useEffect(() => {
    setErrorCnpMessage('');
    setErrorCnp(false);
  }, [cnp]);

  useEffect(() => {
    setErrorRegistrationMessage('');
    dispatch(getErrorCloseEasyDuplicateInvoices());
  }, [enrollment]);

  useEffect(() => {
    if (
      (!easyInvoices || easyInvoices?.invoices?.length === 0) &&
      !loading &&
      dataDischargeDebts &&
      dataDischargeDebts?.dados?.length > 0
    ) {
      setTypeList('others');
    }
  }, [easyInvoices, loading, dataDischargeDebts]);

  const getInvoices = async () => {
    if (!cnp) {
      setErrorCnpMessage('Preenchimento obrigatório');
      setErrorCnp(true);
      return;
    }

    if (cnp) {
      const cnpIsvalid = checkCnp(cnp);

      if (!cnpIsvalid) {
        setErrorCnpMessage('CPF/CNPJ INVÁLIDO');
        setErrorCnp(true);
        return;
      }
    }

    if (!enrollment) {
      setErrorRegistrationMessage('Preenchimento obrigatório');
      return;
    }

    await dispatch(
      getEasyDuplicateInvoices({
        ...recaptchaTokens,
        cnp,
        enrollmentId: enrollment.split('-', 1)[0],
        params: {
          page: 1,
          size: 5,
        },
      }),
    );

    dispatch(
      saveEasyInvoicesCredentials({
        cnp,
        enrollment,
        remember: rememberChecked,
      }),
    );

    dispatch(getOutsidesRegistrationUpdateRequest(cnp));
  };

  return (
    <Main>
      {modalError && <ModalDefaultError onClick={() => clearErrors()} />}
      <Container>
        <Recaptcha
          setRecaptchaTokenV3={setRecaptchaTokenV3}
          setRecaptchaTokenV2={setRecaptchaTokenV2}
          showRecaptchaV2={showRecaptcha}
          onClose={() => {
            dispatch(getErrorCloseEasyDuplicateInvoices());
            dispatch(getErrorCloseDischargeDebts());
            dispatch(postErrorCloseOutsidesRegistrationUpdate());
          }}
        />
        <Wrapper>
          {!isMobile && !isTablet && (
            <ContainerBreadCrumb>
              <BreadCrumb links={links} />
            </ContainerBreadCrumb>
          )}
          <Content>
            {loadingInstallments && <LoadingComponent labelWait="Carregando..." />}
            {easyInvoices?.invoices ? (
              <CardDebts
                recaptchaTokens={recaptchaTokens}
                cnp={cnp}
                setCnp={setCnp}
                enrollment={enrollment}
                setEnrollment={setEnrollment}
                typeList={typeList}
                setTypeList={setTypeList}
              />
            ) : (
              <>
                <MyCard
                  title="SEGUNDA VIA FÁCIL"
                  subtitle="Digite o CPF/CNPJ do titular e número da matrícula para ter acesso direto a fatura de um imóvel."
                >
                  <Input
                    name="cnp"
                    label="CPF ou CNPJ*"
                    value={cnp}
                    onChange={(e) => setCnp(formatter.cpfCnpjMask(e.target.value))}
                    error={errorCnp}
                    errorText={errorCnpMessage}
                    inputMode="numeric"
                    borderColor={Color.blueIntense}
                  />
                  <Margin height={12} />
                  <Input
                    name="enrollment"
                    label="Matrícula*"
                    value={enrollment}
                    onChange={(e) =>
                      setEnrollment(formatter.enrollment(e.target.value))
                    }
                    error={!!errorRegistrationMessage}
                    errorText={errorRegistrationMessage}
                    inputMode="numeric"
                    borderColor={Color.blueIntense}
                  />
                  <ContainerForgotPassword>
                    <InputCheckedV3
                      checked={rememberChecked}
                      onChange={(ev) => setRememberChecked(ev.target.checked)}
                      label="Lembrar-me"
                    />
                  </ContainerForgotPassword>
                  <Margin height={isMobile ? 12 : 24} />
                  <Button
                    type="button"
                    loading={loading}
                    backgroundColor={disabled ? Color.grayLightSeconde : Color.green}
                    disabled={disabled}
                    onClick={getInvoices}
                    style={{
                      height: '48px',
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '17px',
                    }}
                  >
                    Continuar
                  </Button>
                  <Margin height={isMobile ? 0 : 60} />
                </MyCard>
                {!isMobile && (
                  <>
                    {!isTablet && <Divider />}
                    {isTablet && <Margin height={81} />}
                    <MyCard type="link">
                      <CardTitle style={{ textAlign: 'left' }}>
                        Serviços digi iguá
                      </CardTitle>
                      <CardSubtitle style={{ textAlign: 'left' }}>
                        Faça seu <Link to={RouteName.LOGIN}>login</Link> e tenha
                        acesso a muito mais!
                      </CardSubtitle>
                      <UsefulLinks />
                    </MyCard>
                  </>
                )}
              </>
            )}
          </Content>
        </Wrapper>
      </Container>
    </Main>
  );
};

export default EasyDuplicate;
