import onShareFile from '~/utils/onShareFile';
import { IState } from '../types';
import { RepositoriesTypesNegativeCertificatesHistory } from './types';
import { AppDispatch } from '~/store';
import {
  fetchNegativeCertificateDownload,
  fetchNegativeCertificatesList,
} from '~/store/cache/apiCache';

const getEnrollmentsNegativeCertificatesRequest =
  () => async (dispatch: AppDispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_GET_NEGATIVE_CERTIFICATES_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = await dispatch(
        fetchNegativeCertificatesList.initiate({ enrollmentId: registration }),
      );

      if (response.error) throw response.error;

      return dispatch({
        type: RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_GET_NEGATIVE_CERTIFICATES_METHOD_SUCCESS,
        payload: {
          data: response?.data,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_GET_NEGATIVE_CERTIFICATES_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getEnrollmentsNegativeCertificateDownloadServiceRequest =
  (year: number) => async (dispatch: AppDispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_DOWNLOAD_NEGATIVE_CERTIFICATES_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = await dispatch(
        fetchNegativeCertificateDownload.initiate({
          enrollmentId: registration,
          year,
        }),
      );

      if (response.error) throw response.error;

      onShareFile({
        buffer: response?.data?.buffer || '',
        fileName: 'certidao-negativa.pdf',
      });

      return dispatch({
        type: RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_DOWNLOAD_NEGATIVE_CERTIFICATES_METHOD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesNegativeCertificatesHistory.ENROLLMENTS_DOWNLOAD_NEGATIVE_CERTIFICATES_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

export {
  getEnrollmentsNegativeCertificatesRequest,
  getEnrollmentsNegativeCertificateDownloadServiceRequest,
};
