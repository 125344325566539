import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import {
  Border,
  ContainerDescription,
  Description,
  DescriptionButton,
  DescriptionSmall,
} from '../styles';
import { Color } from '~/styles';
import ContinueButton from '../ContinueButton';
import formatter from '~/utils/formatter';
import {
  ContainerChangeAmount,
  ContainerInstallments,
  ContainerMoreInstallments,
} from './styles';
import BackButton from '../BackButton';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { IResponseDataDebtsNegotiationInstallmentsInstallment } from '~/store/modules/negotiation/types';
import { RadioCustom } from '~/design_system/components/Radio';
import { IOptionRadio } from '~/design_system/components/Radio/types';

interface IStepTwoProps {
  setStep: Dispatch<SetStateAction<number>>;
  entryValue: number;
  installment: string;
  setInstallment: Dispatch<SetStateAction<string>>;
  setInstallmentSelected: Dispatch<
    SetStateAction<IResponseDataDebtsNegotiationInstallmentsInstallment | undefined>
  >;
}
type IShowButtonMoreInstallments = 'show_more' | 'show_less' | '';

const StepTwo: FC<IStepTwoProps> = ({
  setStep,
  entryValue,
  installment,
  setInstallment,
  setInstallmentSelected,
}) => {
  const dataDebtsNegotiationInstallments = useSelector(
    (item: IState) => item.negotiation.dataDebtsNegotiationInstallments,
  );
  const hasMoreInstallments = dataDebtsNegotiationInstallments?.length > 12;

  const [installments, setInstallments] = useState<Array<IOptionRadio>>([]);
  const [showButtonMoreInstallments, setShowButtonMoreInstallments] =
    useState<IShowButtonMoreInstallments>('');

  const options =
    showButtonMoreInstallments && showButtonMoreInstallments === 'show_more'
      ? installments.slice(0, 12)
      : installments;

  const updateButtonMoreInstallments = (type: IShowButtonMoreInstallments) =>
    setShowButtonMoreInstallments(type);

  const formatInstallmentsToRadio = () => {
    const array: Array<IOptionRadio> = [];

    dataDebtsNegotiationInstallments?.map((installments) => {
      array.push({
        value: installments.id,
        label: `${installments.installmentCount} X ${formatter.formatCurrency(
          installments.installmentAmount,
        )}`,
      });
      return installments;
    });

    setInstallment(array[0]?.value);

    setInstallments(array);
  };

  const handleBackOnClick = () => {
    setStep(0);
  };

  const handleOnClick = () => {
    ReactGA.event({
      category: 'Negociação de dívidas',
      action:
        '[Negociação de dívidas][Simule uma negociação] Etapa 02 – Continuar para confirmação.',
    });

    const installmentSelected = dataDebtsNegotiationInstallments?.find(
      (negotiationInstallment) => negotiationInstallment?.id === installment,
    );

    setInstallmentSelected(installmentSelected);
    setStep(2);
  };

  useEffect(() => {
    formatInstallmentsToRadio();
    hasMoreInstallments && updateButtonMoreInstallments('show_more');
  }, []);

  return (
    <>
      <ContainerDescription>
        <Description>Escolha o número de parcelas</Description>
        <DescriptionSmall>
          O parcelamento será somado às próximas faturas.
        </DescriptionSmall>
      </ContainerDescription>
      <Border />
      <ContainerChangeAmount>
        <Description>Entrada {formatter.formatCurrency(entryValue)}</Description>
        <DescriptionButton type="button" onClick={handleBackOnClick}>
          Mudar entrada
        </DescriptionButton>
      </ContainerChangeAmount>
      <ContainerInstallments>
        <RadioCustom
          options={options}
          selected={installment}
          setSelected={setInstallment}
        />
        {!!showButtonMoreInstallments && (
          <ContainerMoreInstallments>
            <DescriptionButton
              type="button"
              onClick={() =>
                updateButtonMoreInstallments(
                  showButtonMoreInstallments === 'show_more'
                    ? 'show_less'
                    : 'show_more',
                )
              }
            >
              {showButtonMoreInstallments === 'show_more'
                ? 'Veja mais opções'
                : 'Veja menos opções'}
            </DescriptionButton>
          </ContainerMoreInstallments>
        )}
      </ContainerInstallments>
      <ContinueButton
        label="Continuar"
        backgroundColor={Color?.blueSeconde}
        onClick={handleOnClick}
      />
      <BackButton label="voltar" onClick={handleBackOnClick} />
    </>
  );
};

export default StepTwo;
