import React, { FC, useEffect, useState } from 'react';

import {
  Main,
  Label,
  Row,
  SenFiles,
  ContainerTitle,
  SeparatorTitle,
  Title,
  Content,
} from './styles';

import DropZone from '../DropZone';

import {
  patchUploadFileCustomerObligationRequest,
  patchAttachFilesCustomerObligationRequest,
  patchStatusStepCustomerObligationRequest,
} from '~/store/modules/customerObligation/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import StatusInfo from '../../../components/StatusInfo';
import { Margin } from '~/components/Margin';
import { IDocuments as IDocumentsDocumentationApproval } from '../Form';
import convertFileToBase64 from '~/utils/convertBase64';

import { IResponseAttachment } from '~/store/modules/customerObligation/types';
import { ContainerButton } from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/styles';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import { AppDispatch } from '~/store';

const DocumentationApprovalRevision: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [documents] = useState([
    {
      key: 'executive_project',
      label: 'Projeto Executivo*',
      file: null,
      type: 'default',
    },
    {
      key: 'signaling_plan',
      label: 'Planta de Sinalização*',
      file: null,
      type: 'default',
    },
    {
      key: 'scheduled_works_or_repairs_request_form',
      label:
        'Formulário de Requerimento para obras ou reparos programados (ANEXO I)*',
      file: null,
      type: 'default',
    },
    {
      key: 'timeline',
      label: 'Cronograma (ANEXO IV)*',
      file: null,
      type: 'default',
    },
    {
      key: 'commitment_term',
      label: 'Termo de Compromisso (ANEXO V)*',
      file: null,
      type: 'default',
    },
    {
      key: 'responsibility_and_waiver_term',
      label: 'Termo de Responsabilidade e Renúncia (ANEXO VII)*',
      file: null,
      type: 'default',
    },
    {
      key: 'design_elements_declaration',
      label: 'Declaração dos elementos de projeto (ANEXO XVIII)*',
      file: null,
      type: 'default',
    },
    {
      key: 'technical_justification_letter',
      label: 'Carta justificativa técnica*',
      file: null,
      type: 'letter',
    },
  ]);

  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);

  const idCreate = useSelector((item: IState) => item.customerObligation.idCreate);

  const [isRevisionDocuments, setIsRevisionDocuments] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [documentsFilter, setDocumentsFilter] = useState<
    Array<IDocumentsDocumentationApproval>
  >([]);

  const loadingUploadFile = useSelector(
    (item: IState) => item.customerObligation.loadingUploadUpdateFile,
  );
  const loadingAttachFiles = useSelector(
    (item: IState) => item.customerObligation.loadingAttachFiles,
  );

  const verifyDocuments = async () => {
    if (
      documentsFilter.filter((document) => !document.file).length > 0 ||
      loadingUploadFile
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const sendDocsRevision = async () => {
    dispatch(
      patchAttachFilesCustomerObligationRequest({
        case: idCreate || caseOC?.id || '',
        step: '3',
      }),
    );
  };

  useEffect(() => {
    verifyDocuments();
  }, [isRevisionDocuments, documentsFilter, loadingUploadFile]);

  const filterRevisionDocuments: Array<any> = [];

  const getFileRevisionData = async () => {
    caseOC?.attachments.forEach((attachment: IResponseAttachment) => {
      if (attachment.review === true && attachment.step === '3') {
        documents.map((e) => {
          if (e.key === attachment.type) {
            setIsRevisionDocuments(true);
            return filterRevisionDocuments.push({
              ...e,
              id: attachment.id,
              description: attachment.review_description,
              error: '',
            });
          }
          return null;
        });
      }
    });

    setDocumentsFilter(filterRevisionDocuments);
    return null;
  };

  useEffect(() => {
    getFileRevisionData();
  }, []);

  return (
    <>
      <Main>
        <>
          <Content>
            <StatusInfo status="warning">
              Os documentos abaixo precisam ser revisados. Favor, leia o comentário e
              anexe um novo arquivo.
            </StatusInfo>
          </Content>
          <Margin height={60} />
          {isRevisionDocuments && (
            <>
              <ContainerTitle>
                <SeparatorTitle />
                <Title>Revisão de Documentos</Title>
              </ContainerTitle>
              <Margin height={74} />
              <Content>
                {documentsFilter?.map((document, index: number) => (
                  <Row key={document.key}>
                    <SenFiles>
                      <DropZone
                        disabled={!!document.file}
                        infoFileName={document.label}
                        name={document.label}
                        content={document.file || null}
                        onFileUploaded={async (file: File | null) => {
                          const array = [...documentsFilter];
                          if (file && file.size < 100) {
                            array[index].error =
                              'Por favor, verifique o seu arquivo.';
                            return setDocumentsFilter(array);
                          }
                          array[index].file = file;
                          if (file === null) {
                            array[index].error = '';
                            return setDocumentsFilter(array);
                          }
                          if (file) {
                            convertFileToBase64(file, (base64: string) => {
                              dispatch(
                                patchUploadFileCustomerObligationRequest({
                                  idFile: document?.id || '',
                                  caseIdSales: idCreate || caseOC?.id || '',
                                  attachmentKey: document?.key,
                                  type: document.key,
                                  step: '3',
                                  filename: file?.name || '',
                                  filedata: base64,
                                }),
                              );
                            });
                          }

                          return setDocumentsFilter(array);
                        }}
                        type={document.type}
                        error={!!document.error && !document.file}
                        errorText={document.error}
                      />
                    </SenFiles>
                    <Label>{document.description}</Label>
                  </Row>
                ))}
              </Content>
              <Margin height={24} />
            </>
          )}
        </>
      </Main>
      <Margin height={40} />
      <ContainerButton isRevision>
        <Button
          backIcon
          backgroundColor={Color.green}
          onClick={() => {
            dispatch(
              patchStatusStepCustomerObligationRequest({
                name: '',
                number: 2,
                substep: 'B',
                status: stepStatusEnum.SUCCESS,
              }),
            );
          }}
        >
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          style={{ opacity: 1 }}
          onClick={() => sendDocsRevision()}
          loading={loadingAttachFiles || loadingUploadFile}
          disabled={disabled}
        >
          Continuar
        </Button>
      </ContainerButton>
    </>
  );
};

export default DocumentationApprovalRevision;
