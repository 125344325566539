import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Main } from './styles';
import { IState } from '~/store/modules/types';
import {
  postErrorCloseEnrollmentsCreateCardRequest,
  postSuccessCloseEnrollmentsCreateCardRequest,
} from '~/store/modules/enrollmentsCards/actions';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

import { Button } from '~/components/Button';
import { Margin } from '~/components/Margin';
import { AppDispatch } from '~/store';

const ModalAddCard: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const successCreateCard = useSelector(
    (item: IState) => item.enrollmentsCards.successCreateCard,
  );

  return (
    <Main>
      <p>
        {successCreateCard
          ? 'Cartão adicionado à carteira.'
          : 'Cartão não foi adicionado. Tente novamente mais tarde!'}
      </p>
      <Margin height={39} />
      <Button
        modalType={successCreateCard ? 'success' : 'error'}
        text="Entendi"
        type="button"
        onClick={() => {
          dispatch(postSuccessCloseEnrollmentsCreateCardRequest());
          dispatch(postErrorCloseEnrollmentsCreateCardRequest());
          if (successCreateCard) Navigation.navigate(RouteName.WALLET);
        }}
      />
    </Main>
  );
};

export default ModalAddCard;
