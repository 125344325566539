import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Main,
  Card,
  ContainerBreadCrumb,
  Title,
  Border,
  Content,
  TextOption,
  ContainerOptions,
} from './styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import TypePayment from './TypePayment';
import { IState } from '~/store/modules/types';
import { INegotationProps } from '../types';
import LoadingComponent from '~/components/Loading';
import Modal from '~/components/Modal';
import ModalError from '~/components/ModalError';
import { postErrorCloseDebtsNegotiation } from '~/store/modules/negotiation/actions';
import { AppDispatch } from '~/store';

const NegotiationTypePayment: FC<INegotationProps> = ({ links }) => {
  const dispatch = useDispatch<AppDispatch>();

  const dataDebtsNegotiation = useSelector(
    (item: IState) => item.negotiation.dataDebtsNegotiation,
  );
  const loading = useSelector(
    (item: IState) => item.negotiation.loadingDebtsNegotiationCreate,
  );
  const errorDebtsNegotiationCreate = useSelector(
    (item: IState) => item.negotiation.errorDebtsNegotiationCreate,
  );

  const sightCampaignPaymentCondition =
    dataDebtsNegotiation?.campaignPaymentConditions?.find(
      (campaignPaymentCondition) =>
        campaignPaymentCondition?.paymentConditionDescription?.toUpperCase() ===
        'A VISTA',
    );
  const installmentCampaignPaymentCondition =
    dataDebtsNegotiation?.campaignPaymentConditions?.find(
      (campaignPaymentCondition) =>
        campaignPaymentCondition?.paymentConditionDescription?.toUpperCase() ===
        'PARCELADO',
    );

  return (
    <Main>
      {loading && <LoadingComponent />}
      <Modal open={!!errorDebtsNegotiationCreate} type="error" title="Ops!" v3>
        <ModalError onClose={() => dispatch(postErrorCloseDebtsNegotiation())} />
      </Modal>
      <ContainerBreadCrumb>
        <BreadCrumb links={links} />
      </ContainerBreadCrumb>
      <Card>
        <Content>
          <Title>Escolha o tipo de pagamento</Title>
        </Content>
        <Border />
        <Content>
          <TextOption>Selecione a opção desejada:</TextOption>
          <ContainerOptions>
            {installmentCampaignPaymentCondition && (
              <TypePayment
                title="Parcele na fatura"
                description="Defina o valor a ser pago de entrada e receba o restante parcelado nas próximas faturas."
                data={{
                  ...installmentCampaignPaymentCondition,
                }}
                typeButton="simulation"
                maximumDownPayment={
                  sightCampaignPaymentCondition?.minimumDownPayment
                }
                campaignPaymentConditionId={
                  sightCampaignPaymentCondition?.campaignPaymentConditionId
                }
              />
            )}
            {sightCampaignPaymentCondition && (
              <TypePayment
                hasDiscount
                title="Pague à vista"
                description={
                  <>
                    Pague o valor total no PIX, boleto ou em até{' '}
                    <b>12x sem juros no cartão de crédito.</b>
                  </>
                }
                data={{
                  ...sightCampaignPaymentCondition,
                }}
                typeButton="payment"
              />
            )}
          </ContainerOptions>
        </Content>
      </Card>
    </Main>
  );
};

export default NegotiationTypePayment;
