import { Dispatch } from 'redux';
import { AppDispatch } from '~/store';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import {
  createSupplyPossibilityConsultationCase,
  createSupplyPossibilityConsultationCharacteristics,
  updateSupplyPossibilityConsultationCase,
  getSupplyPossibilityConsultationCase,
  downloadSlipService,
  getSlip,
} from '~/services/viability';
import {
  fetchDpadpeListCases,
  fetchDpadpeListSlips,
  fetchDpadpeListOperations,
  fetchDpadpeListStatuses,
  apiCache,
} from '~/store/cache/apiCache';
import { IPropsRequest, IPropsRequestSlip } from '~/services/viability';
import { IEnumCacheConfig } from '~/store/cache/enum';
import { IState } from '../types';
import {
  RepositoriesTypesDpaDpe,
  IResponseGetSlips,
  IOperations,
  IRequestListCases,
  IRequestListSlip,
} from './types';

export interface IDataCase {
  case_id?: string;
  enterprise: {
    owner: string;
    email: string;
    cpf_cnpj: string;
    cep: string;
    street: string;
    neighborhood: string;
    number: string;
    complement: string;
    city: string;
    state: string;
  };
  interested: {
    name: string;
    qualification: string | null;
    address: string;
    email: string;
    phone: string;
  };
  files: {
    identification_document_file: File | null;
    location_plan_file: File | null;
    situation_plan_file: File | null;
  };
}

const getListStatusesRequest = () => async (dispatch: AppDispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesDpaDpe.DPADPE_LIST_STATUSES_REQUEST,
    });

    const response = await dispatch(fetchDpadpeListStatuses.initiate());

    if (response.error) throw response.error;

    dispatch({
      type: RepositoriesTypesDpaDpe.DPADPE_LIST_STATUSES_SUCCESS,
      payload: { data: response?.data },
    });
  } catch (error: any) {
    dispatch({
      type: RepositoriesTypesDpaDpe.DPADPE_LIST_STATUSES_FAILURE,
      payload: {
        error: {
          type: error?.data?.type,
          status: error?.status,
        },
      },
    });
  }
};

const getErrorCloseListStatusesRequest = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: RepositoriesTypesDpaDpe.DPADPE_LIST_STATUSES_FAILURE_CLOSE,
  });
};

const listCasesRequest =
  (data: IRequestListCases) => async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesDpaDpe.DPADPE_LISTCASES_REQUEST,
      });

      const response = await dispatch(fetchDpadpeListCases.initiate(data));

      console.log({ response: response?.data });

      if (response.error) throw response.error;
      dispatch({
        type: RepositoriesTypesDpaDpe.DPADPE_LISTCASES_SUCCESS,
        payload: { data: response.data },
      });
    } catch (error: any) {
      dispatch({
        type: RepositoriesTypesDpaDpe.DPADPE_LISTCASES_FAILURE,
        payload: {
          error: {
            type: error?.data?.type,
            status: error?.status,
          },
        },
      });
    }
  };

const getListSlipsRequest =
  (data: IRequestListSlip) => async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesDpaDpe.DPADPE_LISTS_SLIPS_REQUEST,
        payload: data,
      });

      const response = await dispatch(fetchDpadpeListSlips.initiate(data));

      if (response.error) throw response.error;

      dispatch({
        type: RepositoriesTypesDpaDpe.DPADPE_LISTS_SLIPS_SUCCESS,
        payload: { data: response.data },
      });
    } catch (error) {
      dispatch({
        type: RepositoriesTypesDpaDpe.DPADPE_LISTS_SLIPS_FAILURE,
      });
    }
  };

const getSlipsRequest =
  (data: { slipId: string }) => async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesDpaDpe.DPADPE_GET_SLIPS_REQUEST,
        payload: data,
      });

      const response = (await getSlip(data)) as IResponseGetSlips;

      dispatch({
        type: RepositoriesTypesDpaDpe.DPADPE_GET_SLIPS_SUCCESS,
        payload: { data: response },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: RepositoriesTypesDpaDpe.DPADPE_GET_SLIPS_FAILURE,
      });
    }
  };

const downloadSlipRequest =
  (data: { slipId: string }) => async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesDpaDpe.DPADPE_DOWNLOAD_SLIPS_REQUEST,
        payload: data,
      });
      const response = (await downloadSlipService(data)) as any;

      const anchorElement = document.createElement('a');
      document.body.appendChild(anchorElement);
      anchorElement.style.display = 'none';
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      anchorElement.href = url;
      anchorElement.download = `Boleto-${data.slipId}`;
      anchorElement.click();
      window.URL.revokeObjectURL(url);
      dispatch({
        type: RepositoriesTypesDpaDpe.DPADPE_DOWNLOAD_SLIPS_SUCCESS,
      });
    } catch (error: any) {
      dispatch({
        type: RepositoriesTypesDpaDpe.DPADPE_DOWNLOAD_SLIPS_FAILURE,
        payload: {
          status: error?.response?.status || '',
          type: error?.response?.type || '',
        },
      });
    }
  };

const getConsultationCaseRequest = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesDpaDpe.DPADPE_SET_SELECTED_CASE_REQUEST,
    });

    const response = (await getSupplyPossibilityConsultationCase(id)) as IDataCase;

    dispatch({
      type: RepositoriesTypesDpaDpe.DPADPE_SET_SELECTED_CASE_SUCCESS,
      payload: { data: response },
    });

    Navigation.navigate(RouteName.SUPPLYPOSSIBILITYCONSULTATION);
  } catch (error: any) {
    dispatch({
      type: RepositoriesTypesDpaDpe.DPADPE_SET_SELECTED_CASE_FAILURE,
      payload: {
        error: {
          status: error?.status,
          type: error?.data?.type,
        },
      },
    });
  }
};

const getErrorCloseConsultationCaseRequest = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: RepositoriesTypesDpaDpe.DPADPE_SET_SELECTED_CASE_FAILURE_CLOSE,
  });
};

const getOperationsRequest = () => async (dispatch: AppDispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesDpaDpe.DPADPE_LIST_OPERATIONS_REQUEST,
    });
    const response = await dispatch(fetchDpadpeListOperations.initiate());

    if (response.error) throw response.error;

    const data = response.data?.map((e: IOperations) => {
      const processTypes = e.processTypes.map((p) => ({
        ...p,
        value: p.name,
      }));

      return { ...e, value: e.name, processTypes };
    });
    dispatch({
      type: RepositoriesTypesDpaDpe.DPADPE_LIST_OPERATIONS_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    dispatch({
      type: RepositoriesTypesDpaDpe.DPADPE_LIST_OPERATIONS_FAILURE,
      payload: {
        status: error?.response?.status || '',
        type: error?.response?.type || '',
      },
    });
  }
};

const registerViabilityRequest =
  (data: any) => async (dispatch: Dispatch, getState: any) => {
    const initialCharacter = {
      lots_quantity: '',
      lot_area: '',
      number_unities_by_lot: '',
      number_unities_by_building: '',
      number_stores: '',
      number_commercial_rooms: '',
      quantity_edifications: '',
      built_area: '',
      number_rooms_unity: '',
      dependecies: '',
      quantity_apartments_unity: '',
      type: '',
      quantity: '',
      number_rooms: '',
      quantity_apartments: '',
      business_structure: '',
      industrial_process_predicted_discharge: '',
      industrial_process_predicted_consume: '',
      number_employees_day: '',
      business_structure_enterprise: '',
      number_consumers_day: '',
    };

    try {
      dispatch({
        type: RepositoriesTypesDpaDpe.DPADPE_REGISTER_VIABILITY_REQUEST,
        payload: { data },
      });
      const state = getState() as IState;
      const { caseValues } = state.dpadpeDataMenager;
      const valuesObject = state.dpadpeDataMenager.ValuesObject;
      const { type } = state.dpadpeDataMenager;

      const requestCase = (await createSupplyPossibilityConsultationCase({
        ...data,
      })) as any;

      const array: Array<any> = [];

      valuesObject?.forEach((character: any) => {
        let object = {};

        if (type === 'object') {
          character.forEach((characterItem: any) => {
            const key = characterItem.key.replace(/[0-9]/g, '');
            object = { ...object, [key]: characterItem.value };
          });

          array.push({
            ...initialCharacter,
            ...object,
            name: caseValues?.characteristic_type,
            characteristic_type: caseValues?.characteristic_type,
          });
        } else {
          character.fields.forEach((characterItem: any) => {
            characterItem.forEach((characterItemObject: any) => {
              const key = characterItemObject.key.replace(/[0-9]/g, '');
              object = { ...object, [key]: characterItemObject.value };
            });

            array.push({
              ...initialCharacter,
              ...object,
              name: caseValues?.characteristic_type,
              characteristic_type: caseValues?.characteristic_type,
            });
          });
        }
      });

      await dispatch(
        apiCache.util.invalidateTags([IEnumCacheConfig.DPA_DPE_LIST_CASES]),
      );

      await createSupplyPossibilityConsultationCharacteristics(
        array,
        requestCase?.case_id,
      );
      dispatch({
        type: RepositoriesTypesDpaDpe.DPADPE_REGISTER_VIABILITY_SUCCESS,
      });
      setTimeout(() => {
        Navigation.navigate(RouteName.ENTERPRISE_VIABILITY);
      }, 1000);
    } catch (error: any) {
      dispatch({
        type: RepositoriesTypesDpaDpe.DPADPE_REGISTER_VIABILITY_FAILURE,
        payload: {
          status: error?.response?.status || '',
          type: error?.response?.type || '',
        },
      });
    }
  };

const postErrorCloseRegisterViabilityRequest =
  () => async (dispatch: AppDispatch) => {
    dispatch({
      type: RepositoriesTypesDpaDpe.DPADPE_REGISTER_VIABILITY_FAILURE_CLOSE,
    });
  };

const updateViabilityRequest = (data: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesDpaDpe.DPADPE_UPDATE_VIABILITY_REQUEST,
      payload: { data },
    });

    await updateSupplyPossibilityConsultationCase({ ...data }, data.case_id);

    dispatch({
      type: RepositoriesTypesDpaDpe.DPADPE_REGISTER_VIABILITY_SUCCESS,
    });

    await dispatch(
      apiCache.util.invalidateTags([IEnumCacheConfig.DPA_DPE_LIST_CASES]),
    );
    setTimeout(() => {
      Navigation.navigate(RouteName.ENTERPRISE_VIABILITY);
    }, 1000);
  } catch (error: any) {
    dispatch({
      type: RepositoriesTypesDpaDpe.DPADPE_REGISTER_VIABILITY_FAILURE,
      payload: {
        status: error?.response?.status || '',
        type: error?.response?.type || '',
      },
    });
  }
};

const resetSelectedCase = () => ({
  type: RepositoriesTypesDpaDpe.DPADPE_RESET_SELECTED_CASE,
});

const setLocation = (data: string) => ({
  type: RepositoriesTypesDpaDpe.DPADPE_SET_LOCATION,
  payload: data,
});

const resetLocation = () => ({
  type: RepositoriesTypesDpaDpe.DPADPE_RESET_LOCATION,
});

export {
  getListStatusesRequest,
  getErrorCloseListStatusesRequest,
  listCasesRequest,
  getListSlipsRequest,
  getSlipsRequest,
  downloadSlipRequest,
  getConsultationCaseRequest,
  getErrorCloseConsultationCaseRequest,
  getOperationsRequest,
  registerViabilityRequest,
  postErrorCloseRegisterViabilityRequest,
  updateViabilityRequest,
  resetSelectedCase,
  setLocation,
  resetLocation,
};
