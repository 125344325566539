import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Container = styled.div`
  flex: 1;

  /*   padding: 118px 460px 100px 460px; */

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    justify-content: flex-start;
    padding: 18px 20px 19px 20px;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;

  nav {
    max-width: 1196px;
    margin: 0 auto;
  }
`;

export const Main = styled.div`
  flex: 1;

  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.19);

  border-radius: 24px;

  overflow: hidden;

  line-height: 25px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 1196px;
    margin: 0 auto;
  }
`;

export const Button = styled.button`
  background: #ffffff;
  border-radius: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1;
  padding: 22px 0;

  border: none;

  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.19);

  font-size: 20px;

  filter: drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.25));
  cursor: pointer;
`;

export const TableContainer = styled.table`
  width: calc(100% - 80px);
  margin: 0px 40px;
  color: #495057;
`;

export const Tr = styled.tr<{ color: 'gray' | 'lightGray' | 'white' }>`
  background: ${(props) => {
    const colors: Record<'gray' | 'lightGray' | 'white', string> = {
      gray: '#E9ECEF',
      lightGray: '#F8F9FA',
      white: Color.white,
    };

    return colors[props.color] || colors.white;
  }};
  color: '#495057';
`;

export const Td = styled.td`
  padding: 12px 17px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  width: 20%;
`;

export const Th = styled.th`
  padding: 12px 20px;
  font-weight: 500;
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  background: transparent;
  margin: -8px;
  padding: 8px;
`;
