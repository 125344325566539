import styled from 'styled-components';

interface IPropsStatusInvoice {
  color: string;
}

export const Container = styled.div<IPropsStatusInvoice>`
  width: 100%;
  height: 64px;
  border: 1px solid ${({ color }) => color};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
  cursor: pointer;

  .status {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${({ color }) => color};

    span {
      color: #000;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
    }
  }

  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #000;
    flex-basis: 35%;

    &.small {
      flex-basis: 25%;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 89px;
      height: 32px;
      padding: 12px;
      border-radius: 8px;
      background: #e9ecef;
      cursor: pointer;

      &.button-receipt {
        width: fit-content;
        padding: 10px;
      }

      span {
        width: 53px;
        color: #000;
        font-size: 14px;
        font-weight: 500;
      }

      svg {
        width: 12px;
        height: 10px;
        transform: rotate(180deg);
      }
    }
  }

  .container-receipt {
    display: flex;
    align-items: center;

    .button-receipt {
      margin-right: 24px;

      svg {
        transform: none;
      }
    }

    span {
      color: #000;
      font-size: 14px;
      font-weight: 500;
    }

    .button-detail {
      width: fit-content;
      padding: 10px;
      margin-left: auto;
      margin-right: 16px;
    }

    .button-payment {
      margin-left: auto;
      margin-right: 16px;
    }
  }
`;

export const ContainerBorder = styled.div<IPropsStatusInvoice>`
  flex-basis: 5%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  span {
    position: absolute;
    left: 0;
    height: 100%;
    width: 8px;
    background: ${({ color }) => color};
    border-radius: 8px 0 0 8px;
  }
`;

export const ContainerMobile = styled.div<IPropsStatusInvoice>`
  width: 100%;
  height: 140px;
  border: 1px solid ${({ color }) => color};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;

  .container-border {
    flex-basis: 5%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    span {
      position: absolute;
      left: 0;
      height: 100%;
      width: 8px;
      background: ${({ color }) => color};
      border-radius: 8px 0 0 8px;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    p,
    span {
      color: #000;
      font-size: 14px;
      font-weight: 300;
    }

    p {
      width: calc(100% - 32px);
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .status {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: ${({ color }) => color};

      span {
        color: #000;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
      }
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 89px;
      height: 32px;
      padding: 12px;
      border-radius: 8px;
      background: #e9ecef;
      cursor: pointer;

      &.button-receipt {
        width: fit-content;
        padding: 10px;
      }

      span {
        width: 53px;
        color: #000;
        font-size: 14px;
        font-weight: 500;
      }

      svg {
        width: 12px;
        height: 10px;
        transform: rotate(180deg);
      }
    }
  }
`;
