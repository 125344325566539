import React, { FC } from 'react';
import AlertSVG from '~/assets/Icons/alert';
import ClockSVG from '~/assets/Icons/Clock';
import ConfirmedSVG from '~/assets/Icons/Confirmed';
import TrashSVG from '~/assets/Icons/Trash';
import { Margin } from '~/components/Margin';
import { SituationAccount } from '~/store/modules/automaticDebits/types';
import { Color } from '~/styles';
import { Container } from './styles';

const dataStatus = {
  'Aguardando Confirmação': {
    icon: <ClockSVG />,
    description: `Débito em conta solicitado. <br /> Aguardando confimação.`,
  },
  Cadastrado: {
    icon: <ConfirmedSVG />,
    description: 'Débito em conta cadastrado.',
  },
  Confirmado: {
    icon: <ConfirmedSVG />,
    description: 'Débito em conta cadastrado.',
  },
  Recusado: {
    icon: <AlertSVG width={22} height={20} />,
    description: 'Recusado. Favor entrar em contato <br /> com seu Banco!',
  },
  'Enviado Exclusão': {
    icon: <ClockSVG color={Color.red} />,
    description: 'Aguardando cancelamento.',
  },
  Excluído: {
    icon: <TrashSVG />,
    description: 'Cancelado.',
  },
};

const DebitSatusInformation: FC<SituationAccount> = ({ situation }) => {
  return (
    <Container situation={situation}>
      {dataStatus[situation]?.icon}
      <Margin width={13} />
      <p dangerouslySetInnerHTML={{ __html: dataStatus[situation]?.description }} />
    </Container>
  );
};

export default DebitSatusInformation;
