import React, { FC, useEffect, useState } from 'react';

import { Main, Row, SenFiles } from './styles';

import DropZone from '../../DropZone';
import { Margin } from '~/components/Margin';
import {
  ContainerButton,
  Subtitle,
} from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/styles';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import {
  patchAttachFilesCustomerObligationRequest,
  patchProtocolCustomerObligationRequest,
  patchStatusStepCustomerObligationRequest,
  postUploadFileCustomerObligationRequest,
} from '~/store/modules/customerObligation/actions';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import convertFileToBase64 from '~/utils/convertBase64';
import StatusInfo from '../../../../components/StatusInfo';
import { AppDispatch } from '~/store';

const Form: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);
  const idCreate = useSelector((item: IState) => item.customerObligation.idCreate);
  const loading = useSelector(
    (item: IState) => item.customerObligation.loadingDarmCode,
  );
  const loadingAttachFiles = useSelector(
    (item: IState) => item.customerObligation.loadingAttachFiles,
  );
  const loadingUploadFile = useSelector(
    (item: IState) => item.customerObligation.loadingUploadFile,
  );
  const [file, setFile] = useState<File | null>(null);
  const [fileErro, setFileErro] = useState('');
  const [startRequestDate] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [disabledDate] = useState(true);

  const handleClick = () => {
    dispatch(
      patchAttachFilesCustomerObligationRequest({
        case: idCreate || caseOC?.id || '',
        step: '7B',
      }),
    );
    dispatch(
      patchProtocolCustomerObligationRequest({
        case: idCreate || caseOC?.id || '',
        materialInspectionDate: startRequestDate,
      }),
    );
  };

  useEffect(() => {
    if (
      file &&
      !loadingUploadFile &&
      startRequestDate.length === 10 &&
      !disabledDate
    ) {
      return setDisabled(false);
    }
    return setDisabled(true);
  }, [file, startRequestDate, disabled, disabledDate]);

  return (
    <>
      <StatusInfo status="info">
        Os arquivos devem ser anexados em <b>formato PDF</b>.
      </StatusInfo>
      <Margin height={40} />
      <Subtitle>
        Nesta etapa é necessário o anexo de todos os documentos e informações
        solicitados a seguir para a solicitação de início de obra.
      </Subtitle>
      <Margin height={64} />
      <Main>
        <Row>
          <SenFiles>
            <DropZone
              disabled={!!file}
              infoFileName="Anexo 13* (protocolado pela Seconserva)*"
              name="Anexo 13* (protocolado pela Seconserva)*"
              content={file || null}
              onFileUploaded={(file: File | null) => {
                if (file === null) {
                  setFileErro('');
                }
                if (file && file.size < 100) {
                  return setFileErro('Por favor, verifique o seu arquivo.');
                }

                if (file) {
                  convertFileToBase64(file, (base64: string) => {
                    dispatch(
                      postUploadFileCustomerObligationRequest({
                        caseIdSales: idCreate || caseOC?.id || '',
                        type: 'work_material_invoice',
                        step: '5B',
                        filename: file?.name || '',
                        filedata: base64,
                      }),
                    );
                  });
                }

                return setFile(file);
              }}
              type="default"
              error={!!fileErro}
              errorText={fileErro}
            />
          </SenFiles>
        </Row>
      </Main>
      <Margin height={40} />
      <ContainerButton>
        <Button
          backIcon
          backgroundColor={Color.green}
          onClick={() =>
            dispatch(
              patchStatusStepCustomerObligationRequest({
                name: '',
                number: 5,
                substep: 'A',
                status: stepStatusEnum.SUCCESS,
              }),
            )
          }
        >
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          style={{ opacity: 1 }}
          onClick={handleClick}
          loading={loadingUploadFile || loadingAttachFiles || loading}
          disabled={disabled}
        >
          Solicitar AIO
        </Button>
      </ContainerButton>
    </>
  );
};

export default Form;
