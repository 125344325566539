import styled, { css } from 'styled-components';
import { colors } from '~/design_system/styles/colors';
import { Breakpoints } from '~/styles';

export const Separador = styled.span`
  border-bottom: 1px solid ${colors.grey_20};
  padding-bottom: 40px;
`;

export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0;
  margin: 0 auto;
  padding: 0;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% - 48px);
    padding: 24px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: calc(100% - 64px);
    max-width: 1170px;
    padding: 32px;
  }
`;

export const ContentCards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    gap: 24px;
  }
`;

export const MainCard = styled.div`
  width: 100%;
  border: 1px solid #ebeced;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const ContainerTitle = styled.section`
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 12px;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0 24px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) and (max-width: ${Breakpoints
      .laptop.max}px) {
    height: 64px;
  }
`;

export const TitleText = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #202022;
  text-transform: capitalize;
`;

export const ContainerInfo = styled.section`
  min-height: 384px;
  background: #f5fcfd;
  border-top: 1px solid #ebeced;
  padding: 40px 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  z-index: 1;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    padding: 24px;
  }
`;

export const ContainerMainBackgroundIcon = styled.div`
  position: absolute;
  right: 0;
  z-index: -1;
  display: none;

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    display: inherit;
  }
`;

const textCss = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #202022;
`;

export const DescriptionText = styled.p<{ hasMaxWidth?: boolean }>`
  max-width: ${({ hasMaxWidth }) => (hasMaxWidth ? '550px' : undefined)};
  ${textCss};
`;

export const DescriptionTextUlContainer = styled.ul`
  list-style: none;
  padding-left: 8px;
`;
export const DescriptionTextLine = styled.li`
  ${textCss};
  position: relative;
  padding-left: 16px;

  ::before {
    content: '•';
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 20px;
  }
`;

export const LinkRegistrationButton = styled.button`
  max-width: 98px;
  height: 40px;
  background: #32a2b4;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 12px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #ffffff;
`;

export const ContainerCards = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;
    gap: 24px;
  }
`;
