import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import {
  Banner,
  BorderText,
  Container,
  ContainerImgs,
  ContainerText,
  ContentBanner,
  ContentText,
} from './styles';
import bannerSocialTariff from '~/assets/Images/banner-social-tariff.jpeg';
import slideDepaDpe from '~/assets/Images/silede-dpadpe.jpeg';
import slideApps from '~/assets/Images/slide-apps.png';
import appleStoreImg from '~/assets/Images/apple-store.png';
import googleStoreImg from '~/assets/Images/google-store.png';
import bannerSimplePayment from '~/assets/Images/banner-simple-payment.png';
import bannerLoosen from '~/assets/Images/banner-desaperta.png';
import bannerReportLackWater from '~/assets/Images/banner-report-lack-water.png';
import { useDispatch, useSelector } from 'react-redux';
import { activateModalAuth } from '~/store/modules/enrollmentsAuth/actions';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { IState } from '~/store/modules/types';
import { checkToggle } from '~/utils/toggles';
import { AppDispatch } from '~/store';

const SlideShow: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const toggles = useSelector((item: IState) => item.toggles.data);

  let slideActive = 0;

  const [slideItems, setSlideItems] = useState([
    {
      id: 0,
      title: 'Tarifa social',
      description: 'É desconto para você! Clique e faça sua socilitação',
      img: {
        desktop: bannerSocialTariff,
        mobile: '',
      },
      active: true,
      objectFit: false,
      onClick: () => {
        ReactGA.event({
          category: 'Tarifa Social',
          action: `[Ñ Logado][Banner] – Tarifa Social`,
        });

        Navigation.navigate(RouteName.SOCIALTARIFFOUTSIDE);
      },
    },
    {
      id: 1,
      title: 'DPA/DPE',
      description: 'Faça o seu cadastro e acompanhe o progresso das solicitações!',
      img: {
        desktop: slideDepaDpe,
        mobile: '',
      },
      active: false,
      objectFit: false,
      onClick: () => {
        Navigation.navigate(RouteName.SUPPLYDEPLETIONPOSSIBILITYDECLARATIONLANDING);
      },
    },
    {
      id: 2,
      title: 'Pagamento único',
      description: 'Parcele suas faturas em até 12X no cartão de crédito',
      img: {
        desktop: bannerSimplePayment,
        mobile: '',
      },
      active: false,
      objectFit: false,
      onClick: () => dispatch(activateModalAuth(RouteName.INVOICES)),
    },
    {
      id: 3,
      title: 'Baixe o app!',
      description: 'Nossos serviços na palma da sua mão.',
      img: {
        desktop: slideApps,
        mobile: '',
      },
      active: false,
      objectFit: false,
    },
  ]);

  useEffect(() => {
    if (toggles && toggles.length > 0) verifyToggles();
  }, [toggles]);

  const updateSlideActive = async (index: number) => {
    setSlideItems((prevState) => {
      const array = prevState.map((item: any, indexItem) => {
        item.active = false;
        if (index === indexItem) item.active = true;
        return item;
      });

      return array;
    });

    slideActive = index + 1 === slideItems.length ? 0 : index + 1;
  };

  const checkedSlide = async () => {
    updateSlideActive(slideActive);
    setTimeout(() => checkedSlide(), 5000);
  };

  const updateSlides = (hasLoosenToggle: boolean, hasReportLackWater: boolean) => {
    setSlideItems((prevState) => {
      if (hasLoosenToggle) {
        prevState[0] = {
          ...prevState[0],
          title: 'De$aperta',
          description: 'A Iguá ajuda você a dar uma folga para o bolso',
          img: {
            desktop: bannerLoosen,
            mobile: '',
          },
          onClick: () => {
            window.open(
              'https://www.igua.com.br/storage/74/db/12/campanha-de-renegoci-atfsz.pdf',
              '_blank',
            );
          },
        };
      }

      if (hasReportLackWater) {
        prevState[1] = {
          ...prevState[1],
          title: 'Falta d’água',
          description: 'Clique e solicite o reparo na sua região',
          img: {
            desktop: bannerReportLackWater,
            mobile: '',
          },
          onClick: () => dispatch(activateModalAuth(RouteName.INVOICES)),
        };
      }

      return prevState;
    });
  };

  const verifyToggles = async () => {
    const hasLoosenToggle = await checkToggle(toggles, 'BANNER_DESAPERTA');
    const hasReportLackWater = await checkToggle(toggles, 'REPORT_LACK_WATER');

    updateSlides(hasLoosenToggle, hasReportLackWater);

    checkedSlide();
  };

  return (
    <Container>
      <ContentBanner>
        {slideItems.map((item: any, index) => (
          <Banner
            key={index && index.toString()}
            src={item.img.desktop}
            active={item.active}
            objectFit={item.objectFit}
            alt="banner"
            onClick={item?.onClick}
          />
        ))}
        <ContainerImgs active={slideItems[3].active}>
          <a
            href="https://play.google.com/store/apps/details?id=com.iguaapp"
            target="_blank"
            rel="noreferrer"
          >
            <img src={googleStoreImg} alt="ícone - loja google" />
          </a>
          <a
            href="https://apps.apple.com/br/app/digi-igu%C3%A1/id1588069396"
            target="_blank"
            rel="noreferrer"
          >
            <img src={appleStoreImg} alt="ícone - loja apple" />
          </a>
        </ContainerImgs>
      </ContentBanner>
      <ContainerText>
        {slideItems.map((item: any, index) => (
          <ContentText
            key={index && index}
            type="button"
            onClick={() => {
              updateSlideActive(index);
            }}
            active={item.active}
          >
            <BorderText active={item.active} />
            <div className="box-text">
              <h2>{item.title}</h2>
              <p>{item.description}</p>
            </div>
          </ContentText>
        ))}
      </ContainerText>
    </Container>
  );
};

export default SlideShow;
