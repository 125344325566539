import React from 'react';
import { useDispatch } from 'react-redux';
import { IResponseListSlips } from '~/store/modules/dpadpe/types';
import EmptyMessage from '~/components/EmptyMessage';
import { Margin } from '~/components/Margin';
import { verifyStatus } from '../validator';
import {
  TableContainer,
  TbodyContainer,
  TheadContainer,
  BorderCase,
  AlertIconContainer,
} from './styles';
import {
  downloadSlipRequest,
  getSlipsRequest,
} from '~/store/modules/dpadpe/actions';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import formatter from '~/utils/formatter';
import ArrowRightLineSVG from '~/assets/Icons/arrowRightLine';
import { EmptyContainer } from '../styles';
import moment from 'moment';
import DownloadSVG from '../components/DownloadIcon';
import { AppDispatch } from '~/store';

const Table = ({ cases }: { cases: IResponseListSlips[] }) => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <TableContainer>
      <TheadContainer>
        <tr>
          <th
            style={{ width: '25%', textAlign: 'left', margin: '0px -15px 0px 15px' }}
          >
            Status
          </th>
          <th style={{ width: '25%' }}>Data de vencimento</th>
          <th style={{ width: '25%' }}>Valor</th>
        </tr>
      </TheadContainer>
      <TbodyContainer>
        <Margin height={15} />
        {cases?.length > 0 ? (
          cases.map((caseItem: any) => (
            <>
              <tr key={caseItem.id}>
                <td
                  style={{
                    width: '25%',
                    fontWeight: 700,
                    color: verifyStatus(caseItem.status).color,
                    textAlign: 'left',
                    justifyContent: 'start',
                  }}
                >
                  <BorderCase color={verifyStatus(caseItem.status).color} />
                  <Margin width={21} />
                  {caseItem.status}
                </td>

                <td
                  style={{
                    width: '25%',
                    fontWeight: 500,
                    color: '#000',
                  }}
                >
                  {moment(caseItem.dueDate).format('DD/MM/YYYY')}
                </td>

                <td
                  style={{
                    width: '25%',
                    fontWeight: 500,
                    color: '#000',
                  }}
                >
                  {formatter.formatCurrency(caseItem.amount)}
                </td>

                <td
                  style={{
                    width: '25%',
                    fontSize: '12px',
                    fontWeight: 700,
                    color: '#000',
                    textAlign: 'left',
                    justifyContent: 'flex-end',
                  }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      dispatch(downloadSlipRequest({ slipId: caseItem.id }));
                    }}
                  >
                    <AlertIconContainer style={{ background: '#F2F2F2' }}>
                      <DownloadSVG />
                    </AlertIconContainer>
                  </button>
                  <Margin width={12} />
                  <button
                    type="button"
                    onClick={() => {
                      dispatch(getSlipsRequest({ slipId: caseItem.id }));
                      Navigation.navigate(
                        RouteName.ENTERPRISE_VIABILITY_SLIPS_PAYMENT,
                      );
                    }}
                  >
                    <AlertIconContainer>
                      Pagar
                      <Margin width={12} />
                      <ArrowRightLineSVG color="#212529" />
                    </AlertIconContainer>
                  </button>
                  <Margin width={12} />
                </td>
              </tr>
              <Margin height={12} />
            </>
          ))
        ) : (
          <tr>
            <EmptyContainer colSpan={3}>
              <EmptyMessage textMessage="Você ainda não possui processos!" />
            </EmptyContainer>
          </tr>
        )}
      </TbodyContainer>
    </TableContainer>
  );
};

export default Table;
