import React, { FC } from 'react';

import { TableContainer, Tr, Td } from '../styles';
import { Margin } from '~/components/Margin';

export const RioCpaeValuesTable: FC = () => {
  return (
    <>
      <TableContainer>
        <Tr style={{ height: '38px' }} color="gray">
          <th
            style={{ padding: '6px 20px', fontWeight: 600, fontSize: '16px' }}
            colSpan={7}
          >
            Domiciliar
          </th>
        </Tr>
        <Tr style={{ height: '48px' }} color="white">
          <Td style={{ textAlign: 'left', fontWeight: '500', minWidth: '100px' }}>
            UNIDADES
          </Td>
          <Td style={{ textAlign: 'left', fontWeight: '500', minWidth: '85px' }}>
            VALOR
          </Td>
          <Td style={{ textAlign: 'left', fontWeight: '500', minWidth: '80px' }}>
            IPCA 19/20 4,31%
          </Td>
          <Td style={{ textAlign: 'left', fontWeight: '500' }}>REGULAMENTO IGUÁ</Td>
          <Td style={{ textAlign: 'left', fontWeight: '500', minWidth: '165px' }}>
            REAJUSTE IGUÁ 22/23 (11,82%)
          </Td>
          <Td style={{ textAlign: 'left', fontWeight: '500', minWidth: '165px' }}>
            REAJUSTE IGUÁ 23/24 (10,24%)
          </Td>
          <Td style={{ textAlign: 'left', fontWeight: '500', minWidth: '165px' }}>
            REAJUSTE IGUÁ 24/25 (11,49%)
          </Td>
        </Tr>
        <Tr style={{ height: '48px' }} color="lightGray">
          <Td>1-10</Td>
          <Td>-</Td>
          <Td>-</Td>
          <Td>-</Td>
          <Td>-</Td>
          <Td>-</Td>
          <Td>-</Td>
        </Tr>
        <Tr style={{ height: '48px' }} color="white">
          <Td>11-20</Td>
          <Td>R$ 1.000,00</Td>
          <Td>R$ 1.043,10</Td>
          <Td>R$ 1.146,00</Td>
          <Td>R$ 1.281,46</Td>
          <Td>R$ 1.412,63</Td>
          <Td>R$ 1.574,94</Td>
        </Tr>
        <Tr style={{ height: '48px' }} color="lightGray">
          <Td>21-35</Td>
          <Td>R$ 1.750,00</Td>
          <Td>R$ 1.825,43</Td>
          <Td>R$ 2.005,51</Td>
          <Td>R$ 2.242,56</Td>
          <Td>R$ 2.472,12</Td>
          <Td>R$ 2.756,17</Td>
        </Tr>
        <Tr style={{ height: '48px' }} color="white">
          <Td>36-50</Td>
          <Td>R$ 2.500,00</Td>
          <Td>R$ 2.607,75</Td>
          <Td>R$ 2.865,00</Td>
          <Td>R$ 3.203,64</Td>
          <Td>R$ 3.531,58</Td>
          <Td>R$ 3.937,36</Td>
        </Tr>
        <Tr style={{ height: '48px' }} color="lightGray">
          <Td>
            A partir de 51 <br />
            (por unidade)
          </Td>
          <Td>R$ 50,00</Td>
          <Td>R$ 52,16</Td>
          <Td>R$ 57,31</Td>
          <Td>R$ 64,08</Td>
          <Td>R$ 70,64</Td>
          <Td>R$ 78,76</Td>
        </Tr>
      </TableContainer>

      <Margin height={24} />

      <TableContainer>
        <Tr style={{ height: '38px' }} color="gray">
          <th style={{ padding: '12px 20px', fontWeight: 600 }} colSpan={7}>
            Industrial e Comercial
          </th>
        </Tr>
        <Tr style={{ height: '48px' }} color="white">
          <Td style={{ textAlign: 'left', fontWeight: '500', minWidth: '100px' }}>
            UNIDADES
          </Td>
          <Td style={{ textAlign: 'left', fontWeight: '500', minWidth: '85px' }}>
            VALOR
          </Td>
          <Td style={{ textAlign: 'left', fontWeight: '500', minWidth: '80px' }}>
            IPCA 19/20 4,31%
          </Td>
          <Td style={{ textAlign: 'left', fontWeight: '500' }}>REGULAMENTO IGUÁ</Td>
          <Td style={{ textAlign: 'left', fontWeight: '500', minWidth: '135px' }}>
            REAJUSTE IGUÁ 22/23
          </Td>
          <Td style={{ textAlign: 'left', fontWeight: '500', minWidth: '135px' }}>
            REAJUSTE IGUÁ 23/24
          </Td>
          <Td style={{ textAlign: 'left', fontWeight: '500', minWidth: '135px' }}>
            REAJUSTE IGUÁ 24/25
          </Td>
        </Tr>
        <Tr style={{ height: '48px' }} color="lightGray">
          <Td>1-50</Td>
          <Td>R$ 2.500,00</Td>
          <Td>R$ 2.607,75</Td>
          <Td>R$ 2.865,00</Td>
          <Td>R$ 3.203,64</Td>
          <Td>R$ 3.531,58</Td>
          <Td>R$ 3.937,36</Td>
        </Tr>
        <Tr style={{ height: '48px' }} color="white">
          <Td>
            A partir de 51 <br />
            (por unidade)
          </Td>
          <Td>R$ 50,00</Td>
          <Td>R$ 52,16</Td>
          <Td>R$ 57,31</Td>
          <Td>R$ 64,08</Td>
          <Td>R$ 70,64</Td>
          <Td>R$ 78,76</Td>
        </Tr>
      </TableContainer>
    </>
  );
};
