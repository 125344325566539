import styled, { css } from 'styled-components';
import { Breakpoints } from '~/styles';
import { BREAKPOINTS, COLORS } from '~/utils/constants';

interface IPropsType {
  type?: '' | 'link' | 'full';
}

export const Card = styled.div<IPropsType>`
  width: 100%;
  max-width: ${({ type }) => !type && 'calc(400px - 96px)'};
  padding: ${({ type }) =>
    type === 'link' ? '42px 91px 0 39px' : '26px 16px 16px 16px'};
  border-radius: 16px;
  border-top: 16px solid ${COLORS.secondary};
  box-shadow: 0px 4px 12px 6px ${COLORS.black}12;

  ${({ type }) =>
    type === 'full' &&
    css`
      align-self: center;
      margin: 0 auto;
      padding: 0;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
    `}

  @media screen and (max-width: ${Breakpoints.mobile.max}px) {
    max-width: calc(300px - 32px);

    ${({ type }) =>
      type === 'full' &&
      css`
        max-width: 300px;
      `}
  }

  @media screen and (min-width: ${Breakpoints.tablet
      .min}px) and (max-width: ${Breakpoints.laptop.max - 1}px) {
    max-width: ${({ type }) =>
      type === 'link' ? 'calc(400px - 39px)' : 'calc(400px - 96px)'};
    padding: ${({ type }) =>
      type === 'link' ? '37px 8px 0 31px' : '42px 48px 16px 48px'};

    ${({ type }) =>
      type === 'full' &&
      css`
        max-width: 464px;
        padding: 0;
      `}
  }

  @media screen and (min-width: ${Breakpoints.laptop.max}px) {
    max-width: ${({ type }) => !type && 'calc(400px - 96px)'};
    padding: ${({ type }) =>
      type === 'link' ? '42px 8px 0 31px' : '42px 48px 0 48px'};

    ${({ type }) =>
      type === 'full' &&
      css`
        max-width: 700px;
        padding: 0;
      `}
  }

  @media screen and (min-width: ${Breakpoints.desktopLarge.max}px) {
    max-width: ${({ type }) => !type && 'calc(480px - 96px)'};
    padding: ${({ type }) =>
      type === 'link' ? '42px 91px 51px 39px' : '42px 48px 0 48px'};

    ${({ type }) =>
      type === 'full' &&
      css`
        max-width: 700px;
        padding: 0;
      `}

    ${({ type }) =>
      type === 'link' &&
      css`
        max-width: calc(560px - 130px);
      `}
  }
`;

export const CardContainer = styled.div`
  margin: 0px auto;
  width: 100%;
  height: 100%;
`;

export const CardWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .back-button-desktop {
    @media (max-width: ${BREAKPOINTS.mobileLandscape}) {
      display: none;
    }
  }
`;

export const CardTitle = styled.p<IPropsType>`
  color: ${COLORS.secondary};
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;

  ${({ type }) =>
    type === 'full' &&
    css`
      width: calc(100% - 32px);
      padding: 0 16px;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      max-width: 540px;
      margin: 16px auto;
      text-transform: none;
      text-align: left;
    `}

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0;

    ${({ type }) =>
      type === 'full' &&
      css`
        font-size: 28px;
      `}
  }
`;

export const CardSubtitle = styled.p`
  margin-top: 24px;
  margin-bottom: 26px;
  color: ${COLORS.gray600};
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;

  a {
    color: ${COLORS.highlight};
    font-weight: 500;
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    margin-bottom: 42px;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${COLORS.gray400};
`;
