import React, { FC } from 'react';

import FadeLoader from 'react-spinners/FadeLoader';

import { Main, Label } from './styles';

import { Margin } from '../Margin';
import { Color } from '~/styles';

interface ILoading {
  labelWait?: string;
}

const LoadingComponent: FC<ILoading> = ({ labelWait }) => {
  return (
    <Main>
      <FadeLoader color={Color.white} loading />
      <Margin height={10} />
      <Label>{labelWait ?? 'carregando...'}</Label>
    </Main>
  );
};

export default LoadingComponent;
