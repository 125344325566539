import React, { FC } from 'react';
import { Container, ContainerGroupSkeleton } from './styles';
import SkeletonCustom from '~/design_system/components/Skeleton';

const EasyInvoiceSkeletons: FC = () => {
  return (
    <Container>
      <SkeletonCustom height={207} />
      <ContainerGroupSkeleton>
        <SkeletonCustom height={15} />
        <SkeletonCustom height={100} />
        <SkeletonCustom height={36} />
      </ContainerGroupSkeleton>
    </Container>
  );
};

export default EasyInvoiceSkeletons;
