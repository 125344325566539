import styled, { css } from 'styled-components';
import { COLORS } from '~/utils/constants';
import { IButton } from '.';

const VARIANTS = {
  primary: css`
    --bg: ${COLORS.primary};
    --color: ${COLORS.white};
    --border-color: ${COLORS.primary};
  `,
  secondary: css`
    --bg: ${COLORS.highlight};
    --color: ${COLORS.white};
    --border-color: ${COLORS.highlight};
  `,
  error: css`
    --bg: ${COLORS.danger};
    --color: ${COLORS.white};
    --border-color: ${COLORS.danger};
  `,
};

const SX = {
  outline: css`
    background: transparent;
    color: var(--border-color);
  `,
};

export const MyButton = styled.button<IButton>`
  /* CSS variables initialization */
  ${(props) => (props.variant ? VARIANTS[props.variant] : VARIANTS.primary)}

  padding: 12px 24px;
  height: 48px;
  width: 100%;
  height: 48px;
  max-width: 400px;
  background: var(--bg);
  border-radius: 8px;
  border: 2px solid var(--border-color);
  color: var(--color);
  font-family: var(--font-body);
  font-size: 1rem;
  font-weight: 500;
  transition:
    ease background 160ms,
    color 160ms,
    opacity 160ms;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    opacity: 0.96;
  }

  /* Apply "outline" style conditionally */
  ${(props) => (props.outline ? SX.outline : null)}

  :disabled {
    cursor: auto;
    background: ${COLORS.gray400};
    border-color: ${COLORS.gray400};
  }
`;
