import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

import HomeManager from '../HomeManager';
import HomeNotClient from '../HomeNotClient';
import { checkToggle } from '~/utils/toggles';
import HomeClientNew from '../HomeClient';
import NotClient from '../NotClient';
import HomeCutoverClient from '../HomeCutoverClient';

const ResolveHome: FC = () => {
  const isManager = useSelector((state: IState) => state.enrollmentsAuth.isManager);
  const isClient = useSelector((state: IState) => state.enrollmentsAuth.isClient);
  const isEntrepreneur = useSelector(
    (state: IState) => state.enrollmentsAuth.isEntrepreneur,
  );
  const isCutover = useSelector((state: IState) => state.enrollmentsAuth.isCutover);
  const togglesMe = useSelector((item: IState) => item.togglesMe.data);
  const [reRegistration, setReRegistration] = useState<boolean>(true);

  const verifyTogglesMe = async () => {
    setReRegistration(await checkToggle(togglesMe, 'RE_REGISTRATION_2'));
  };

  useEffect(() => {
    if (togglesMe && togglesMe?.length > 0) verifyTogglesMe();
  }, [togglesMe]);

  if (isManager || isEntrepreneur) return <HomeManager />;

  if (isCutover) return <HomeCutoverClient />;

  if (!isClient) {
    return reRegistration ? <NotClient /> : <HomeNotClient />;
  }

  return <HomeClientNew />;
};

export default ResolveHome;
