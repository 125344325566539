import { IEnumErrorResponse } from '~/enums/errorsEnum';

export enum RepositoriesTypesDpaDpe {
  DPADPE_LIST_STATUSES_REQUEST = '@repositories/DPADPE_LIST_STATUSES_REQUEST',
  DPADPE_LIST_STATUSES_SUCCESS = '@repositories/DPADPE_LIST_STATUSES_SUCCESS',
  DPADPE_LIST_STATUSES_FAILURE = '@repositories/DPADPE_LIST_STATUSES_FAILURE',
  DPADPE_LIST_STATUSES_FAILURE_CLOSE = '@repositories/DPADPE_LIST_STATUSES_FAILURE_CLOSE',

  DPADPE_LISTCASES_REQUEST = '@repositories/DPADPE_LISTCASES_REQUEST',
  DPADPE_LISTCASES_SUCCESS = '@repositories/DPADPE_LISTCASES_SUCCESS',
  DPADPE_LISTCASES_FAILURE = '@repositories/DPADPE_LISTCASES_FAILURE',
  DPADPE_LISTCASES_FAILURE_CLOSE = '@repositories/DPADPE_LISTCASES_FAILURE_CLOSE',

  DPADPE_LISTS_SLIPS_REQUEST = '@repositories/DPADPE_LISTS_SLIPS_REQUEST',
  DPADPE_LISTS_SLIPS_SUCCESS = '@repositories/DPADPE_LISTS_SLIPS_SUCCESS',
  DPADPE_LISTS_SLIPS_FAILURE = '@repositories/DPADPE_LISTS_SLIPS_FAILURE',

  DPADPE_DOWNLOAD_SLIPS_REQUEST = '@repositories/DPADPE_DOWNLOAD_SLIPS_REQUEST',
  DPADPE_DOWNLOAD_SLIPS_SUCCESS = '@repositories/DPADPE_DOWNLOAD_SLIPS_SUCCESS',
  DPADPE_DOWNLOAD_SLIPS_FAILURE = '@repositories/DPADPE_DOWNLOAD_SLIPS_FAILURE',

  DPADPE_GET_SLIPS_REQUEST = '@repositories/DPADPE_GET_SLIPS_REQUEST',
  DPADPE_GET_SLIPS_SUCCESS = '@repositories/DPADPE_GET_SLIPS_SUCCESS',
  DPADPE_GET_SLIPS_FAILURE = '@repositories/DPADPE_GET_SLIPS_FAILURE',

  DPADPE_REGISTER_VIABILITY_REQUEST = '@repositories/DPADPE_REGISTER_VIABILITY_REQUEST',
  DPADPE_REGISTER_VIABILITY_SUCCESS = '@repositories/DPADPE_REGISTER_VIABILITY_SUCCESS',
  DPADPE_REGISTER_VIABILITY_FAILURE = '@repositories/DPADPE_REGISTER_VIABILITY_FAILURE',
  DPADPE_REGISTER_VIABILITY_FAILURE_CLOSE = '@repositories/DPADPE_REGISTER_VIABILITY_FAILURE_CLOSE',

  DPADPE_UPDATE_VIABILITY_REQUEST = '@repositories/DPADPE_UPDATE_VIABILITY_REQUEST',

  DPADPE_SET_SELECTED_CASE_REQUEST = '@repositories/DPADPE_SET_SELECTED_CASE_REQUEST',
  DPADPE_SET_SELECTED_CASE_SUCCESS = '@repositories/DPADPE_SET_SELECTED_CASE_SUCCESS',
  DPADPE_SET_SELECTED_CASE_FAILURE = '@repositories/DPADPE_SET_SELECTED_CASE_FAILURE',
  DPADPE_SET_SELECTED_CASE_FAILURE_CLOSE = '@repositories/DPADPE_SET_SELECTED_CASE_FAILURE_CLOSE',
  DPADPE_RESET_SELECTED_CASE = '@repositories/DPADPE_RESET_SELECTED_CASE',

  DPADPE_LIST_OPERATIONS_REQUEST = '@repositories/DPADPE_LIST_OPERATIONS_REQUEST',
  DPADPE_LIST_OPERATIONS_SUCCESS = '@repositories/DPADPE_LIST_OPERATIONS_SUCCESS',
  DPADPE_LIST_OPERATIONS_FAILURE = '@repositories/DPADPE_LIST_OPERATIONS_FAILURE',

  DPADPE_SET_LOCATION = '@repositories/DPADPE_SET_LOCATION',
  DPADPE_RESET_LOCATION = '@repositories/DPADPE_RESET_LOCATION',
}

export interface IRequestListCases {
  cpae: boolean;
  oc: boolean;
  fcp: boolean;
  page: number;
  limit: number;
  protocol: string;
  status: string;
}

export interface IRequestListSlip {
  cancelled: boolean;
  pending: boolean;
  paid: boolean;
  page: number;
  limit: number;
  protocol: string;
}

interface ICharacteristic {
  name?: string;
  characteristic_type?: string;
  lots_quantity: string | null;
  lot_area: string | null;
  number_unities_by_lot: string | null;
  number_unities_by_building: string | null;
  number_stores: string | null;
  number_commercial_rooms: string | null;
  quantity_edifications: string | null;
  built_area: string | null;
  number_rooms_unity: string | null;
  dependecies: string | null;
  quantity_apartments_unity: string | null;
  type: string | null;
  quantity: string | null;
  number_rooms: string | null;
  quantity_apartments: string | null;
  business_structure: string | null;
  industrial_process_predicted_discharge: string | null;
  industrial_process_predicted_consume: string | null;
  number_employees_day: string | null;
  business_structure_enterprise: string | null;
  number_consumers_day: string | null;
}

export interface IResponseListCases {
  id: string;
  caseNumber: string;
  caseType: string;
  case_number: string;
  status: string;
  created_at: string;
  cpae: Cpae;
}

export interface IResponseListStatuses {
  oc: string[] | [];
  cpae: string[] | [];
  fcp: string[] | [];
}

export interface IResponseListSlips {
  id: string;
  amount: number;
  status: string;
  dueDate: string;
}

export interface IResponsePaginationListCases {
  totalItens: number;
  maxPages: number;
  page: number;
}

export interface IResponseGetSlips {
  id: string;
  amount: number;
  dueDate: string;
  issueDate: string;
  status: string;
  operation?: string;
  customer: {
    name?: string;
    commercialName?: string;
  };
  barcode?: string;
  pixCode?: string;
  enrollment?: string;
}

export interface Cpae {
  id: string;
  status: string;
  caseNumber: string;
  alerts: Alerts;
}

export interface Alerts {
  oca: boolean;
  oce: boolean;
  fcpa: boolean;
  fcpe: boolean;
}

export interface IResponseList {
  id: string;
  owner: string;
  email: string;
  cpf_cnpj: string;
  cep: string;
  street: string;
  neighborhood: string;
  number: string;
  complement: string;
  city: string;
  state: string;
  qualification: string | null;
  phone: string;
  status: string;
  case_number: string;
  created_at: string;
  review_fields: string[] | null;
  review_comments: string | null;
  characteristics: ICharacteristic[];
}

interface IRegisterCaseEnterprise {
  owner: string;
  email: string;
  cpf_cnpj: string;
  cep: string;
  street: string;
  neighborhood: string;
  number: string;
  complement: string;
  city: string;
  state: string;
}

interface IRegisterCaseInterested {
  name: string;
  qualification: string;
  address: string;
  email: string;
  phone: string;
}

interface IRegisterCaseFiles {
  identification_document_file: File;
  location_plan_file: File;
  situation_plan_file: File;
}

export interface IRequestRegisterCase {
  data: {
    enterprise: IRegisterCaseEnterprise;
    interested: IRegisterCaseInterested;
  };
  files: IRegisterCaseFiles;
}

export interface ICaseDetail {
  selectedCase?: boolean;
  id: string;
  status: string;
  case_number: string;
  attachments: Array<any>;
  review_fields: string[] | null;
  review_comments: string | null;
  enterprise: {
    owner: string;
    email: string;
    cpf_cnpj: string;
    cep: string;
    street: string;
    neighborhood: string;
    number: string;
    complement: string;
    city: string;
    state: string;
  };
  interested: {
    name: string;
    qualification: string | null;
    address: string;
    email: string;
    phone: string;
  };
  characteristics: ICharacteristic[] | [];
  created_at: string;
}

export interface IOperations {
  id: number;
  label: string;
  name: string;
  processTypes: IProcessType[];
  createdAt: string;
  updatedAt: string;
}

export interface IProcessType {
  id: number;
  label: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface IRepositoriesStateDpaDpe {
  readonly data: IResponseListCases[] | [];
  readonly dataSlips: IResponseListSlips[] | [];
  readonly dataSlip: IResponseGetSlips | null;
  readonly dataStatuses: IResponseListStatuses;
  readonly paginationSlips: IResponsePaginationListCases;
  readonly pagination: IResponsePaginationListCases;
  readonly caseDetail: ICaseDetail;
  readonly dpadpeLocation: string;
  readonly operations: IOperations[];
  readonly error: IEnumErrorResponse | null;
  readonly errorCaseDetail: IEnumErrorResponse | null;
  readonly loading: boolean;
}
