import styled, { css } from 'styled-components';
import { Breakpoints, Color } from '~/styles';

const cssText = css`
  flex: 1;
  color: #495057;
  white-space: nowrap;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentRow = styled.div<{
  backgroundColor: string;
}>`
  height: 44px;
  display: flex;
  align-items: center;
  background: ${({ backgroundColor }) => backgroundColor};
  padding: 0 12px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    padding: 0 20px;
  }
`;

export const TextHeader = styled.p`
  ${cssText};
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    font-size: 18px;
  }
`;

export const Text = styled.p`
  ${cssText};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: left;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    font-size: 16px;
  }
`;

export const ButtonDetail = styled.button`
  ${cssText};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  text-decoration-line: underline;
  color: ${Color.blueSeconde};
  background: none;
  border: none;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    font-size: 16px;
  }
`;
