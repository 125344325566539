import React, { FC, useEffect } from 'react';
import creditCardType from 'credit-card-type';

import { ContainerPayment, BoxDivider, ContainerCardTemplate } from '../styles';
import CreditCardTemplate from './CardTemplate';

import { Input } from '~/components/Input';
import { InputSelect } from '~/components/InputSelect';
import formatter from '~/utils/formatter';

interface IInstallment {
  label?: string;
  value?: number;
}

interface IError {
  message: string;
  error: boolean;
}

interface IErrors {
  number: IError;
  validity: IError;
}

interface IProps {
  dataPayment: any;
  setDataPayment: (state: any) => void;
  installments: IInstallment[];
  brandCard: string;
  setBrandCard: (state: string) => void;
  errors: IErrors;
  setErrors: (state: any) => void;
}

const Payment: FC<IProps> = ({
  dataPayment,
  setDataPayment,
  installments,
  brandCard,
  setBrandCard,
  errors,
  setErrors,
}) => {
  const ALLOWED_CARDS = [
    {
      brand: null,
      issuer: 'default',
    },
    {
      brand: 'Master',
      issuer: 'mastercard',
    },
    {
      brand: 'Visa',
      issuer: 'visa',
    },
    {
      brand: 'Hiper',
      issuer: 'hipercard',
    },
    {
      brand: 'Elo',
      issuer: 'elo',
    },
  ];

  const findBrand = () => {
    let brandType = creditCardType(
      (dataPayment.cardNumber || '').replace(/\D/g, ''),
    ) as any;

    if (brandType.length > 1) brandType = [{ type: 'undefined' }];
    else if (brandType.length >= 0) brandType = brandType[0];

    const brand = ALLOWED_CARDS.find(
      (itemBrand) => itemBrand.issuer === brandType?.type,
    );

    if (brand?.brand) setBrandCard(brand.issuer);
    else setBrandCard('');
  };

  useEffect(() => {
    findBrand();
  }, [dataPayment.cardNumber]);

  const validateCardNumber = () => {
    if (
      (dataPayment.cardNumber && dataPayment.cardNumber.length < 19) ||
      (dataPayment.cardNumber && dataPayment.cardNumber.length > 0 && !brandCard)
    ) {
      setErrors(({ number, validity }: any) => ({
        number: { ...number, error: true },
        validity,
      }));

      return false;
    }
    setErrors(({ number, validity }: any) => ({
      number: { ...number, error: false },
      validity,
    }));

    return true;
  };

  const validateCardValidity = () => {
    if (dataPayment.validity && dataPayment.validity.length < 7) {
      setErrors(({ number, validity }: any) => ({
        number,
        validity: { ...validity, error: true },
      }));

      return false;
    }
    setErrors(({ number, validity }: any) => ({
      number,
      validity: { ...validity, error: false },
    }));

    return true;
  };

  return (
    <ContainerPayment>
      <BoxDivider>
        <ContainerCardTemplate>
          <CreditCardTemplate
            holder={dataPayment.cardHolder}
            cardNumber={dataPayment.cardNumber}
            securityCode={dataPayment.securityCode}
            validity={dataPayment.validity}
            issuer={brandCard}
          />
        </ContainerCardTemplate>
      </BoxDivider>
      <BoxDivider>
        <div className="container-inputs">
          <InputSelect
            label="Parcelas"
            name="installment"
            values={installments}
            onChange={(ev) => {
              setDataPayment({ ...dataPayment, installment: ev.target.value });
            }}
            errorPosition="bottom"
          />
        </div>
        <div className="container-inputs">
          <Input
            label="Número do cartão"
            name="cardNumber"
            value={dataPayment.cardNumber}
            onChange={(ev) =>
              setDataPayment({
                ...dataPayment,
                cardNumber: formatter.creditCard(ev.target.value) || '',
              })
            }
            onBlur={validateCardNumber}
            errorText={errors.number.message}
            error={errors.number.error}
            errorPosition="bottom"
            maxLength={19}
          />
        </div>
        <div className="container-inputs">
          <Input
            label="Nome do titular"
            name="cardHolder"
            value={dataPayment.cardHolder}
            onChange={(ev) =>
              setDataPayment({
                ...dataPayment,
                cardHolder: ev.target.value,
              })
            }
            maxLength={40}
          />
        </div>
        <div className="container-inputs container-inputs-box">
          <Input
            label="Validade"
            name="validity"
            value={dataPayment.validity}
            onChange={(ev) =>
              setDataPayment({
                ...dataPayment,
                validity: formatter.creditCardValidity(ev.target.value),
              })
            }
            onBlur={validateCardValidity}
            errorText={errors.validity.message}
            error={errors.validity.error}
            errorPosition="bottom"
          />
          <Input
            label="CVV"
            name="securityCode"
            value={dataPayment.securityCode}
            onChange={(ev) =>
              setDataPayment({
                ...dataPayment,
                securityCode: formatter.formatarNumber(ev.target.value),
              })
            }
            errorPosition="bottom"
            maxLength={3}
          />
        </div>
      </BoxDivider>
    </ContainerPayment>
  );
};

export default Payment;
