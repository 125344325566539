import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ButtonText } from '~/components/ButtonText';
import { Margin } from '~/components/Margin';
import { RouteName } from '~/routes/Routes.name';
import { Breakpoints, Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import SocialTariffStatus from './SocialTariffStatus';
import {
  getSocialTariffRequest,
  postErrorCloseSocialTariffAttachmentsRequest,
} from '~/store/modules/socialTariff/actions';

import declaracaoMoradia from './declaracao-moradia.pdf';

import {
  Container,
  ContainerBreadCrumb,
  ContainerButtons,
  ContainerText,
  CustomTooltip,
  Header,
  Main,
  Text,
} from './styles';
import { IState } from '~/store/modules/types';
import LoadingComponent from '~/components/Loading';
import { useMediaQuery } from 'react-responsive';
import { BreadCrumb } from '~/components/BreadCrumb';
import { AppDispatch } from '~/store';

const SocialTariffRio: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch<AppDispatch>();

  const data = useSelector((item: IState) => item.socialTariff.data);
  const loading = useSelector((item: IState) => item.socialTariff.loading);

  useEffect(() => {
    dispatch(getSocialTariffRequest());
    dispatch(postErrorCloseSocialTariffAttachmentsRequest());
  }, []);

  return (
    <Main>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Tarifa Social',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      {loading && <LoadingComponent labelWait="Carregando..." />}
      <Container>
        <Header>Tarifa social</Header>
        <ContainerText>
          {data && (
            <>
              <SocialTariffStatus status={data.status} />
              <Margin height={isMobile ? 36 : 0} />
            </>
          )}
          <CustomTooltip icon>
            Atenção! Solicitação digital disponível apenas para o Rio de Janeiro.
            Para demais localidades, entre em contato com a sua operação!
          </CustomTooltip>
          <Text>
            A tarifa social nos serviços públicos de abastecimento de água e
            esgotamento sanitário é um benefício concedido à clientes consumidores
            das concessionárias do Grupo Iguá, onde há normativa oficial, publicada
            pelo poder concedente, para tal aplicação.
            <br />
            <br />
            <h3>Quem pode ser beneficiado?</h3>
            <br />
            A tarifa social poderá beneficiar os moradores de baixa renda em áreas de
            ocupação não regular, em habitações multifamiliares regulares e
            irregulares ou em empreendimentos habitacionais de interesse social,
            caracterizados como tal pelos governos municipais, estaduais ou do
            Distrito Federal ou pelo governo federal, desde que mantido o limite
            máximo de renda mensal per capita de meio salário mínimo.
            <br />
            <br />
            <h3>
              Qual a documentação necessária para cadastro e atualização de dados na
              tarifa social?
            </h3>
            <br />
            Depois de inscrito na Plataforma do Digi Iguá, o responsável pela família
            deve solicitar o cadastro da tarifa social de água e esgotamento
            sanitário e apresentar os seguintes documentos:
            <br />
            <br />
            <h3>Para habitação em favela</h3>
            <br />
            <ul>
              <li>Documento de identidade (CPF)</li>
              <li>
                Documentação FAFERJ (local de favela), da associação de moradores ou
                vistoria de localização realizada pela Iguá (Não é obrigatório o
                seguimento do processo)
              </li>
              <li>
                Comprovante de moradia ( conta de luz, documento de usucapião ou RGI
                - Registro geral de imóvel)
              </li>
              <li>
                <Link to={declaracaoMoradia} target="_blank">
                  Declaração de moradia padrão Iguá
                </Link>{' '}
                <i>(clique para download)</i>
              </li>
            </ul>
            <br />
            <br />
            <h3>Para conjunto habitacional</h3>
            <br />
            <ul>
              <li>Documento de identidade (CPF)</li>
              <li>Documento do órgão financiador (com nome do titular)</li>
              <li>IPTU (casas isoladas)</li>
              <li>
                Ata do condomínio onde conste a eleição do síndico (ou documento que
                conste o responsável pelo conjunto)
              </li>
              <li>
                <Link to={declaracaoMoradia} target="_blank">
                  Declaração de moradia padrão Iguá
                </Link>{' '}
                <i>(clique para download)</i>
              </li>
            </ul>
            <br />
            <br />
            <h3>Para área de interesse social</h3>
            <br />
            <ul>
              <li>Documento de identidade (CPF)</li>
              <li>IPTU ou outro documento que comprove a posse</li>
              <li>
                Vistoria de localização realizada pela Iguá (Na ausência de
                documentação)
              </li>
              <li>
                <Link to={declaracaoMoradia} target="_blank">
                  Declaração de moradia padrão Iguá
                </Link>{' '}
                <i>(clique para download)</i>
              </li>
            </ul>
            <br />
            <br />
            <h3>Para habitação Popular</h3>
            <br />
            <ul>
              <li>Documento de identidade (CPF)</li>
              <li>
                {' '}
                Documento comprobatório da cessão do terreno emitido pelo órgão
                público (incluindo nome do titular)
              </li>
              <li>
                <Link to={declaracaoMoradia} target="_blank">
                  Declaração de moradia padrão Iguá
                </Link>{' '}
                <i>(clique para download)</i>
              </li>
            </ul>
            <br />
            <br />
            <h3>Quem já tem o desconto pode perdê-lo?</h3>
            <br />
            Sim. Para não perder esse benefício, o cliente deverá manter seu cadastro
            ativo e atualizado da seguinte forma:
            <br />
            <br />
            1. Mantenha ativo o seu cadastro e com dados atualizados no Ministério de
            Desenvolvimento Social (MDS) da sua cidade.
            <br />
            2. Se você recebeu uma carta da Iguá junto com a sua fatura, solicitando
            a atualização do seu cadastro, fique atento e entre em contato conosco,
            através dos nossos canais de atendimento. <br />
            3. Para continuidade do desconto na tarifa de água e esgoto, os
            consumidores que receberem o comunicado, deverão realizar o
            recadastramento através dos nossos canais de atendimento. As
            concessionárias obrigatoriamente, pelo menos uma vez por ano, deverão
            verificar se os beneficiados com a tarifa social de água e esgoto atendem
            aos critérios estabelecidos para continuidade do desconto.
            <br />
            <br />
            <h3>Existe prazo para a realização do recadastramento?</h3>
            <br />
            Não. O cliente deve solicitar o recadastramento antes do término da
            vigência.
            <br />
            <br />
            <h3>Informações importantes:</h3>
            <br />
            Cada família terá direito ao benefício da tarifa social em apenas uma
            unidade consumidora.
            <br />
            <br />
            <b style={{ fontWeight: 700 }}>Atenção:</b> Faturas anteriores da data da
            solicitação não tem direito a revisão.
            <br />
            <br />
            Para mais informações entre em contato com nossos canais de atendimento.
          </Text>
        </ContainerText>
        <Margin height={40} />
        <ContainerButtons>
          {isMobile ? (
            <>
              <ButtonText
                text="Solicitar tarifa social"
                typeBtn="content"
                Color={Color.white}
                backgroundColor={Color.blueSeconde}
                style={{ visibility: data ? 'hidden' : undefined }}
                nextIcon
                onClick={() =>
                  Navigation.navigate(RouteName.SOCIALTARIFFSOLICITATION)
                }
              />
              <Margin height={24} />{' '}
              <ButtonText
                text="Voltar"
                typeBtn="content"
                Color={Color.white}
                backgroundColor={Color.green}
                backIcon
                onClick={() => Navigation.goBack()}
              />
            </>
          ) : (
            <>
              <ButtonText
                text="Voltar"
                typeBtn="content"
                Color={Color.white}
                backgroundColor={Color.green}
                backIcon
                onClick={() => Navigation.goBack()}
              />
              <ButtonText
                text="Solicitar tarifa social"
                typeBtn="content"
                Color={Color.white}
                backgroundColor={Color.blueSeconde}
                style={{ visibility: data ? 'hidden' : undefined }}
                nextIcon
                onClick={() =>
                  Navigation.navigate(RouteName.SOCIALTARIFFSOLICITATION)
                }
              />
            </>
          )}
        </ContainerButtons>
      </Container>
    </Main>
  );
};

export default SocialTariffRio;
