import React, { useState, useEffect, FC } from 'react';
import moment from 'moment';
import clipboardCopy from 'clipboard-copy';
import Navigation from '~/utils/Navigation';
import { statusColor } from '~/utils/invoiceStatus';
import { checkToggle } from '~/utils/toggles';
import { QRCode } from '~/components/QRCode';
import { PaymentContainer } from '../../Material/PaymentContainer';
import { InputLabel } from '../../Material/InputLabel';
import { ButtonCopyCode } from '../../Material/ButtonCopyCode';
import { ButtonIcon } from '../../Material/ButtonIcon';
import { BannerPix } from '../../Material/BannerPix';
import PixIcon from '~/assets/Icons/PixSVG';
import ArrowBack from '~/assets/Icons/ArrowBackCurvedSGV';
import AutomaticDebitSVG from '~/assets/Icons/AutomaticDebit';
import { existsAccountDebt } from '~/utils/verifyAccountExists';
import {
  Main,
  Wrapper,
  ContentWrapper,
  DetailsForm,
  Row,
  Divider,
  InfoWrapper,
  InfoPix,
  NavWrapper,
  AutomaticDebitWrapper,
  InvoiceAmount,
  ContainerDownloadVoucher,
} from '../styles';

import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { pixRegisterCopyClipboard } from '~/services/pix';
import formatter from '~/utils/formatter';
import DownloadSVG from '~/assets/Icons/DownloadIcon';
import { ButtonLabel } from '../../Material/ButtonLabel';
import { getPaymentReceiptRequest } from '~/store/modules/payment/actions';
import { RouteName } from '~/routes/Routes.name';
import { AppDispatch } from '~/store';

interface IInvoiceDetails {
  data: any;
  dataAutomaticDebit: any;
  statusColor: string;
  showAmount?: boolean;
}

interface IInfoDetails {
  code: string;
  copyPixCode: (source: string) => void;
  showCopiedCode: boolean;
}

export const InvoiceDetails: FC<IInvoiceDetails> = ({
  data,
  statusColor,
  dataAutomaticDebit,
  showAmount,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const enrollment = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.enrollment,
  );

  return (
    <DetailsForm style={{ justifyContent: showAmount ? 'flex-end' : undefined }}>
      {showAmount && (
        <Row>
          <InvoiceAmount color={statusColor} style={{ height: 0 }}>
            {formatter.formatCurrency(data?.amount)}
          </InvoiceAmount>
        </Row>
      )}
      {data?.type_payment === 'CARD_DIGIIGUA' && (
        <Row>
          <ContainerDownloadVoucher>
            <button
              type="button"
              onClick={() =>
                dispatch(
                  getPaymentReceiptRequest({
                    isAuthenticated: true,
                    routeInvoiceReceipt: RouteName?.INVOICERECEIP,
                    queryParam: {
                      invoices: {
                        enrollmentId: enrollment || '',
                        invoceId: data?.invoice_id || 0,
                      },
                    },
                  }),
                )
              }
            >
              <DownloadSVG />
              Baixar comprovante
            </button>
          </ContainerDownloadVoucher>
        </Row>
      )}
      {existsAccountDebt(dataAutomaticDebit, data?.maturity_date || '') && (
        <Row>
          <AutomaticDebitWrapper>
            <AutomaticDebitSVG width={42} height={36} />
            <p>Débito automático em conta cadastrado</p>
          </AutomaticDebitWrapper>
        </Row>
      )}
      <Row>
        <InputLabel label="Cliente" defaultValue={data?.consumer?.name} />
      </Row>
      <Row>
        <InputLabel label="Endereço" defaultValue={data?.consumer?.address} />
      </Row>
      <Row columns={2}>
        <InputLabel
          label="Data Leitura"
          defaultValue={moment(data?.read_date_hour).format('DD/MM/YYYY')}
        />
        <InputLabel
          label="Tipo"
          defaultValue={data?.rate_type || 'Nenhuma informação encontrada'}
        />
      </Row>
      <Row columns={3}>
        <InputLabel
          label="Consumo"
          defaultValue={data?.billed_consumption || 'Nenhuma informação encontrada'}
        />
        <InputLabel
          label="Período"
          defaultValue={data?.reference || 'Nenhuma informação encotrada'}
        />
        <InputLabel
          label="Vencimento"
          defaultValue={
            moment(data?.maturity_date).format('DD/MM/YYYY') ||
            'Nenhuma informação encontrada'
          }
        />
      </Row>
      <Row>
        <InputLabel
          label="Detalhamento da Fatura"
          defaultValue={data?.items.map((item: any) => item.description)}
        />
      </Row>
      {showAmount && (
        <Row>
          <ButtonLabel label="Código de barras">{data?.bar_code_number}</ButtonLabel>
        </Row>
      )}
    </DetailsForm>
  );
};

const InfoDetails: FC<IInfoDetails> = ({ code, copyPixCode, showCopiedCode }) => {
  const paragraphs = [
    '1.Acesse seu Internet Banking ou app de pagamentos;',
    '2.Escolha pagar via Pix;',
    '3.Escaneie o código ao lado',
    'Ou, escolha pagar via Pix pelo seu Internet Banking ou app de pagamentos. Depois cole o seguite código:',
  ];

  return (
    <InfoPix>
      {paragraphs.map((p) => (
        <p className="topic" key={p.slice(4)}>
          {p}
        </p>
      ))}
      <ButtonCopyCode
        onClick={() => copyPixCode('code')}
        showCopyMsg={showCopiedCode}
      >
        {code}
      </ButtonCopyCode>
      <p className="highlight">
        *Após pagamento pix, sua fatura constará como paga em até 24hrs.
      </p>
    </InfoPix>
  );
};

const SimplifiedInvoicePix: FC = () => {
  const [showCopiedCode, setShowCopiedCode] = useState(false);
  const [showCopiedButton, setShowCopiedButton] = useState(false);
  const [existPix, setExistPix] = useState<boolean>(false);

  const toggles = useSelector((item: IState) => item.togglesMe.data);
  const dataDetail = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.dataDetail,
  );
  const registration = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.enrollment,
  );
  const dataAutomaticDebit = useSelector(
    (item: IState) => item?.automaticDebits.data,
  );

  const verifyToggles = async () => {
    setExistPix(await checkToggle(toggles, 'PIX'));
  };

  useEffect(() => {
    verifyToggles();
  }, [toggles]);

  const copyPixCode = (source: string) => {
    const request = {
      invoice_id: dataDetail?.invoice_id,
      amount: dataDetail?.amount,
      qrcode: dataDetail?.qr_code,
      qrcode_key: dataDetail?.qr_code_key,
      pix_key: dataDetail?.pix_key,
      barcode_number: dataDetail?.bar_code_number,
      registration_id: registration,
      metric_type: 'PIX_L',
    };
    pixRegisterCopyClipboard(request);
    clipboardCopy(dataDetail?.qr_code_key || '');

    if (source === 'code') {
      setShowCopiedCode(true);
      return setTimeout(() => {
        setShowCopiedCode(false);
      }, 5000);
    }
    setShowCopiedButton(true);
    return setTimeout(() => {
      setShowCopiedButton(false);
    }, 5000);
  };

  return (
    <Main>
      <PaymentContainer
        bgColor={statusColor(dataDetail?.status?.label || '', true)}
        status={dataDetail?.status?.label || ''}
      >
        <Wrapper>
          <ContentWrapper>
            <InvoiceDetails
              dataAutomaticDebit={dataAutomaticDebit}
              data={dataDetail}
              statusColor={statusColor(dataDetail?.status?.label || '', true)}
              showAmount
            />
            <BannerPix existPix={existPix} />
          </ContentWrapper>
          <Divider />
          <InfoWrapper>
            <QRCode codeValue={dataDetail?.qr_code_key || ''} />
            <Divider className="vertical" />
            <InfoDetails
              showCopiedCode={showCopiedCode}
              code={dataDetail?.qr_code_key || ''}
              copyPixCode={copyPixCode}
            />
          </InfoWrapper>
          <Divider />
          <NavWrapper>
            <ButtonIcon
              msg="Código copiado!"
              showMsg={showCopiedButton}
              onClick={() => copyPixCode('')}
            >
              <PixIcon fill="#FFFFFF" />
              Copiar código PIX
            </ButtonIcon>
            <ButtonIcon color="white" onClick={() => Navigation.goBack()}>
              <ArrowBack />
              Voltar
            </ButtonIcon>
          </NavWrapper>
        </Wrapper>
      </PaymentContainer>
    </Main>
  );
};

export default SimplifiedInvoicePix;
