import React, { FC } from 'react';
import { ButtonText } from '~/components/ButtonText';
import { Margin } from '~/components/Margin';
import { Color } from '~/styles';
import Navigation from '~/utils/Navigation';

import {
  Border,
  BoxContent,
  Container,
  ContainerBorder,
  ContainerBreadCrumb,
  ContainerButtons,
  Content,
  Header,
  Main,
} from './styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import { RouteName } from '~/routes/Routes.name';
import ReportLackWaterStepFour from './ReportLackWaterStepFour';
import { getOrderServicesDownloadLackWaterRequest } from '~/store/modules/orderServices/actions';
import { history } from '~/store';
import { IOrderService } from '~/store/modules/orderServices/types';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import LoadingComponent from '~/components/Loading';
import { AppDispatch } from '~/store';

const SupportCenterProtocol: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const data = history?.location?.state as IOrderService;

  const loading = useSelector(
    (item: IState) => item.orderServices.loadingDownloadLackWater,
  );

  return (
    <Main>
      {loading && <LoadingComponent labelWait="Baixando..." />}
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Minhas solicitações',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Container>
        <Header />
        <BoxContent>
          <Content>
            <h1>Minhas solicitações</h1>
          </Content>
          <ContainerBorder>
            <Border />
          </ContainerBorder>
          <Content>
            <ReportLackWaterStepFour />
          </Content>
        </BoxContent>
        <Margin height={24} />
        <Content>
          <ContainerButtons>
            <ButtonText
              text="Minhas solicitações"
              typeBtn="content"
              Color={Color.green}
              backgroundColor={Color.white}
              onClick={() => Navigation.navigate(RouteName.SUPPORTCENTER)}
            />
            <ButtonText
              text="Baixar protocolo"
              typeBtn="content"
              Color={Color.white}
              backgroundColor={Color.green}
              onClick={() =>
                dispatch(getOrderServicesDownloadLackWaterRequest(data?.protocol))
              }
            />
          </ContainerButtons>
        </Content>
        <Margin height={64} />
      </Container>
    </Main>
  );
};

export default SupportCenterProtocol;
