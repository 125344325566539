import { EndpointBuilder, BaseQueryFn } from '@reduxjs/toolkit/query';
import { IEnumCacheConfig } from '../enum';
import {
  IResponseNegativeCertificateDownload,
  IResponseNegativeCertificates,
} from '~/store/modules/enrollmentsNegativeCertificates/types';

export const enrollmentsNegativeCertificatesEndpoints = (
  builder: EndpointBuilder<BaseQueryFn, IEnumCacheConfig, 'cache'>,
) => ({
  fetchNegativeCertificatesList: builder.query<
    IResponseNegativeCertificates[],
    { enrollmentId: string }
  >({
    query: ({ enrollmentId }) => ({
      url: `v3/enrollments/${enrollmentId}/negative-certificates`,
      method: 'GET',
    }),
    providesTags: [IEnumCacheConfig.ENROLLMENTS_NEGATIVE_CERTIFICATES_LIST],
  }),
  fetchNegativeCertificateDownload: builder.query<
    IResponseNegativeCertificateDownload,
    { enrollmentId: string; year: number }
  >({
    query: ({ enrollmentId, year }) => ({
      url: `v3/enrollments/${enrollmentId}/negative-certificates/${year}/download`,
      method: 'GET',
    }),
    providesTags: [IEnumCacheConfig.ENROLLMENTS_NEGATIVE_CERTIFICATES_DOWNLOAD],
  }),
});
