import React, { FC, useEffect, useState } from 'react';

import { Container } from '~/common';
import {
  Card,
  ContainerBreadCrumb,
  ContainerTitle,
  Content,
  ContentButton,
  ContentRow,
  Line,
  Main,
  NotionContainer,
  Text,
  TitleFilter,
  TitleLine,
  Wrapper,
} from './styles';
import { RouteName } from '~/routes/Routes.name';
import Navigation from '~/utils/Navigation';
import { BreadCrumb } from '~/components/BreadCrumb';
import { Margin } from '~/components/Margin';
import PlusSVG from '~/assets/Icons/Plus';
import { Color } from '~/styles';
import Table from './Table';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import {
  getListStatusesRequest,
  getErrorCloseListStatusesRequest,
  listCasesRequest,
  getErrorCloseConsultationCaseRequest,
} from '~/store/modules/dpadpe/actions';
import { Link } from 'react-router-dom';
import ButtonGeneric from './components/ButtonGeneric';
import InputSearch from './components/InputSearch';
import FilterSVG from '~/assets/Icons/FilterSVG';
import Pagination from '~/components/Pagination';
import LoadingComponent from '~/components/Loading';
import { InputSelect } from '~/components/InputSelect';
import { AppDispatch } from '~/store';
import ModalDefaultError from '~/design_system/components/ModalDefaultError';

const SupplyAndDepletionPossibilityDeclarationPanel: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isEntrepreneur = useSelector(
    (item: IState) => item.enrollmentsAuth.isEntrepreneur,
  );
  const loading = useSelector((item: IState) => item.dpadpe.loading);
  const cases = useSelector((item: IState) => item.dpadpe.data);
  const statuses = useSelector((item: IState) => item.dpadpe.dataStatuses);
  const pagination = useSelector((item: IState) => item.dpadpe.pagination);
  const errorCaseDetail = useSelector((item: IState) => item.dpadpe.errorCaseDetail);

  const [protocol, setProtocol] = useState('');
  const [status, setStatus] = useState('');
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  const [isCPAEActive, setIsCPAEActive] = useState(false);
  const [isOcActive, setIsOcActive] = useState(false);
  const [isFCPActive, setFCPActive] = useState(false);
  const [statusSelect, setStatusSelect] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getErrorCloseListStatusesRequest());
    dispatch(getErrorCloseConsultationCaseRequest());
    dispatch(getListStatusesRequest());
  }, []);

  useEffect(() => {
    if (!isCPAEActive && !isOcActive && !isFCPActive) setStatus(``);

    let optionsSelect: string[] = [`Todos`];

    if (isCPAEActive) optionsSelect = [...optionsSelect, ...statuses.cpae];
    if (isOcActive) optionsSelect = [...optionsSelect, ...statuses.oc];
    if (isFCPActive) optionsSelect = [...optionsSelect, ...statuses.fcp];

    const statusesSelect = [...new Set(optionsSelect)]
      .map((status) => ({
        label: status,
        value: status === `Todos` ? `` : status,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    setStatusSelect(statusesSelect);
  }, [statuses, isCPAEActive, isOcActive, isFCPActive]);

  useEffect(() => {
    dispatch(
      listCasesRequest({
        cpae: isCPAEActive,
        oc: isOcActive,
        fcp: isFCPActive,
        page,
        limit: 10,
        protocol,
        status: isCPAEActive || isOcActive || isFCPActive ? status : ``,
      }),
    );
  }, [isCPAEActive, isOcActive, isFCPActive, protocol, status, page]);

  return (
    <>
      {loading && <LoadingComponent />}
      {errorCaseDetail && (
        <ModalDefaultError
          onClick={() => dispatch(getErrorCloseConsultationCaseRequest())}
        />
      )}
      <Main>
        <Container size="newLarge">
          <Wrapper>
            <Margin height={18} />
            <ContainerBreadCrumb>
              <BreadCrumb
                links={[
                  {
                    label: 'Home',
                    active: false,
                    onClick: () =>
                      !isEntrepreneur && Navigation.navigate(RouteName.HOME),
                  },
                  {
                    label: 'Viabilidade de empreendimento',
                    active: true,
                  },
                ]}
              />
            </ContainerBreadCrumb>

            <Margin height={24} />

            <Card>
              <Margin height={30} />
              <Content>
                <h1>Painel de solicitações (DPA/DPE)</h1>
              </Content>
              <Margin height={24} />
              <Line />
              <Margin height={24} />

              <Content>
                <Text>
                  Os processos para emissão da declaração de possibilidade de
                  abastecimento e esgotamento (DPA/DPE) são obrigatórios para novos
                  empreendimentos. Esse processo é aberto para que os grandes
                  clientes possam verificar se no local do empreendimento existe
                  possibilidade de abastecimento e esgotamento através da
                  concessionária ou se deverão executar algum tipo de Obrigação do
                  Cliente para que esse seja feito.
                  <br />
                  <br />
                  Para empreendimentos localizados no <b>Rio de Janeiro e Cuiabá</b>,
                  é possível solicitar e acompanhar o progresso das solicitações no{' '}
                  <b>Painel de viabilidades</b>. Para demais regiões, deve ser
                  consultado a operação através dos nossos canais de atendimento.{' '}
                  <Link to={RouteName.ENTERPRISE_VIABILITY_KNOW_MORE}>
                    Saiba mais
                  </Link>
                </Text>
              </Content>

              <Margin height={40} />

              <ContainerTitle>
                <Line />
                <TitleLine>Processos</TitleLine>
              </ContainerTitle>
              <Margin height={40} />
              <Content>
                <ContentRow>
                  <InputSearch
                    onChange={(e) => {
                      clearTimeout(timeoutId);
                      setTimeoutId(
                        setTimeout(() => {
                          setPage(1);
                          setProtocol(e.target.value);
                        }, 800),
                      );
                    }}
                  />
                  <Margin width={24} />
                  <ButtonGeneric
                    select={isFilter}
                    type="buttons"
                    onClick={() => {
                      setIsFilter(!isFilter);
                      setIsCPAEActive(false);
                      setIsOcActive(false);
                      setFCPActive(false);
                    }}
                  >
                    <>
                      Filtro
                      <Margin width={10} />
                      <FilterSVG />
                    </>
                  </ButtonGeneric>
                  <Margin width={24} />
                  <ButtonGeneric
                    type="buttons"
                    onClick={() =>
                      Navigation.navigate(RouteName.ENTERPRISE_VIABILITY_PANEL_SLIPS)
                    }
                  >
                    <>
                      Acessar Boletos
                      <Margin width={10} />
                      <NotionContainer>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="16"
                          viewBox="0 0 13 16"
                          fill="none"
                        >
                          <path
                            d="M3.25 11.2H9.75V12.8H3.25V11.2ZM3.25 8H9.75V9.6H3.25V8ZM8.125 0H1.625C0.73125 0 0 0.72 0 1.6V14.4C0 15.28 0.723125 16 1.61687 16H11.375C12.2688 16 13 15.28 13 14.4V4.8L8.125 0ZM11.375 14.4H1.625V1.6H7.3125V5.6H11.375V14.4Z"
                            fill="#999999"
                          />
                        </svg>
                      </NotionContainer>
                    </>
                  </ButtonGeneric>
                  <Margin width={24} />
                  <ButtonGeneric
                    type="newProcess"
                    onClick={() =>
                      Navigation.navigate(RouteName.ENTERPRISE_VIABILITY_NEW_PROCESS)
                    }
                  >
                    <>
                      <PlusSVG color={Color.white} />
                      <Margin width={8} />
                      Novo Processo
                    </>
                  </ButtonGeneric>
                  <ContentButton />
                </ContentRow>
                {isFilter && (
                  <>
                    <TitleFilter>Selecione os filtros de sua busca:</TitleFilter>
                    <ContentRow>
                      <ButtonGeneric
                        type="filters"
                        onClick={() => {
                          setIsCPAEActive(!isCPAEActive);
                          setPage(1);
                        }}
                        select={isCPAEActive}
                      >
                        CPAE
                      </ButtonGeneric>
                      <ButtonGeneric
                        type="filters"
                        onClick={() => {
                          setIsOcActive(!isOcActive);
                          setPage(1);
                        }}
                        select={isOcActive}
                      >
                        OC
                      </ButtonGeneric>
                      <ButtonGeneric
                        type="filters"
                        onClick={() => {
                          setFCPActive(!isFCPActive);
                          setPage(1);
                        }}
                        select={isFCPActive}
                      >
                        FCP
                      </ButtonGeneric>
                      {/* <ButtonGeneric
                        type="filters"
                        onClick={() => console.log('em desenvolvimento')}
                      >
                        Ánalise de Projeto
                      </ButtonGeneric> */}
                    </ContentRow>
                    {(!!isCPAEActive || !!isFCPActive || !!isOcActive) && (
                      <>
                        <Margin height={24} />
                        <ContentRow style={{ width: `405px` }}>
                          <InputSelect
                            style={{ fontWeight: 400, fontSize: '14px' }}
                            label="Status"
                            values={statusSelect}
                            onChange={(ev: any) => setStatus(ev.target.value)}
                          />
                        </ContentRow>
                      </>
                    )}
                  </>
                )}
                <Margin height={30} />
                <Table cases={cases} />
                <Pagination setPage={setPage} pagination={pagination} />
              </Content>
            </Card>
          </Wrapper>
        </Container>
      </Main>
    </>
  );
};

export default SupplyAndDepletionPossibilityDeclarationPanel;
