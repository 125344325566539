import React, { FC } from 'react';

import { TableContainer, Tr, Td } from '../styles';

export const CuiabaCpaeValuesTable: FC = () => {
  return (
    <TableContainer>
      <Tr style={{ height: '38px' }} color="gray">
        <th
          style={{
            padding: '6px 20px',
            fontWeight: 600,
            fontSize: '18px',
          }}
          colSpan={2}
        >
          Valores CPAE
        </th>
      </Tr>
      <Tr style={{ height: '48px' }} color="white">
        <Td style={{ width: '50%' }}>DPA/DPE</Td>
        <Td style={{ width: '50%' }}>R$ 188,94</Td>
      </Tr>
      <Tr style={{ height: '48px' }} color="lightGray">
        <Td style={{ width: '50%' }}>Tarifa de correção de projeto</Td>
        <Td style={{ width: '50%' }}>R$ 543,87</Td>
      </Tr>
      <Tr style={{ height: '48px' }} color="white">
        <Td style={{ width: '50%' }}>
          Análise de projeto de esgotamento sanitário e <br />
          Análise de projeto de abastecimento de água
        </Td>
        <Td style={{ width: '50%' }}>R$ 4,70 x (quantidade de lotes)</Td>
      </Tr>
    </TableContainer>
  );
};
