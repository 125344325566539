import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import DecarbonometerImg from '~/assets/Images/landing-descarbonometro.png';
import ReportLackWaterImg from '~/assets/Images/report-lack-water.png';
import UnderstandAccountImg from '~/assets/Images/landing-entenda-sua-conta.png';
import EasyDuplicateImg from '~/assets/Images/landing-segunda-via-facil.png';
import QuitacaoImg from '~/assets/Images/quitacao.png';
import Modal from '~/components/Modal';
import { RouteName } from '~/routes/Routes.name';
import { clearModalAuth } from '~/store/modules/enrollmentsAuth/actions';
import {
  activateRouteReportLackWater,
  clearDischargeDebts,
  clearEasyDuplicateInvoices,
  clearModalDuplicateInvoices,
  clearRouteReportLackWater,
  getErrorCloseEasyDuplicateInvoices,
} from '~/store/modules/outsides/actions';
import { IState } from '~/store/modules/types';
import Navigation from '~/utils/Navigation';
import SlideShow from './SlideShow';
import { ButtonAction, ContainerButtonAction, Main } from './styles';
import { checkToggle } from '~/utils/toggles';
import ModalErrorCall from './ModalErrorCall';
import { AppDispatch } from '~/store';

const Landing: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const modal = useSelector((item: IState) => item.outsides.modal);
  const toggles = useSelector((item: IState) => item.toggles.data);
  const [openErrorCall, setOpenErrorCall] = useState<boolean>(false);
  const [dischargeDebts, setDischargeDebts] = useState<boolean>(false);
  const [reportLackWater, setReportLackWater] = useState<boolean>(false);
  const showNegotiation = true;

  const verifyToggles = async () => {
    setDischargeDebts(await checkToggle(toggles, 'DEBT_SETTLEMENT'));
    setReportLackWater(await checkToggle(toggles, 'REPORT_LACK_WATER'));
  };

  useEffect(() => {
    if (toggles && toggles.length > 0) verifyToggles();
  }, [toggles]);

  useEffect(() => {
    dispatch(clearModalDuplicateInvoices());
    dispatch(getErrorCloseEasyDuplicateInvoices());
    dispatch(clearModalAuth());
    dispatch(clearDischargeDebts());
    dispatch(clearRouteReportLackWater());
  }, []);

  useEffect(() => {
    if (!modal) {
      dispatch(clearEasyDuplicateInvoices());
    }
  }, [modal]);

  const activeRouteReportLackWater = () => {
    ReactGA.event({
      category: 'Falta d’água',
      action: '[WEB][NL][Novos serviços][Falta d’água]',
    });
    dispatch(activateRouteReportLackWater());
    Navigation.navigate(RouteName.LOGIN);
  };

  const handleClearRouteReportLackWater = () => {
    dispatch(clearRouteReportLackWater());
  };

  return (
    <>
      <Modal open={openErrorCall} type="info" title="Aviso">
        <ModalErrorCall setOpen={setOpenErrorCall} />
      </Modal>
      <Main>
        <SlideShow />
        <ContainerButtonAction>
          <ButtonAction
            type="button"
            onClick={() => {
              handleClearRouteReportLackWater();
              Navigation.navigate(RouteName.EASYDUPLICATE);
            }}
          >
            <img src={EasyDuplicateImg} alt="segunda via fácil" />
            Segunda via fácil
          </ButtonAction>
          {dischargeDebts ? (
            <>
              {showNegotiation ? (
                <ButtonAction
                  type="button"
                  onClick={() => {
                    ReactGA.event({
                      category: 'Negociação de dívidas',
                      action:
                        '[Negociação de dívidas][Landing page] Negociação de dívidas',
                    });
                    Navigation.navigate(RouteName.DISCHARGEDEBTS);
                    clearRouteReportLackWater();
                  }}
                >
                  <img src={QuitacaoImg} alt="quitação de dívidas" />
                  Negociação de dívidas
                </ButtonAction>
              ) : (
                <ButtonAction
                  type="button"
                  onClick={() => {
                    ReactGA.event({
                      category: 'Quitação de dívidas',
                      action: '[QD][C4][WEB] - Quitação de dívidas',
                    });
                    Navigation.navigate(RouteName.DISCHARGEDEBTS);
                    clearRouteReportLackWater();
                  }}
                >
                  <img src={QuitacaoImg} alt="quitação de dívidas" />
                  Quitação de dívidas
                </ButtonAction>
              )}
              {reportLackWater ? (
                <ButtonAction
                  type="button"
                  onClick={() => activeRouteReportLackWater()}
                  id="NL-novos-serviços-falta-d’água"
                >
                  <img src={ReportLackWaterImg} alt="entenda sua conta" />
                  Falta d’água
                </ButtonAction>
              ) : (
                <ButtonAction
                  type="button"
                  onClick={() =>
                    window.open('https://igua.com.br/informacoes-para-voce')
                  }
                >
                  <img src={UnderstandAccountImg} alt="entenda sua conta" />
                  Entenda sua conta
                </ButtonAction>
              )}
            </>
          ) : (
            <>
              {reportLackWater ? (
                <ButtonAction
                  type="button"
                  onClick={() => activeRouteReportLackWater()}
                  id="NL-novos-serviços-falta-d’água"
                >
                  <img src={ReportLackWaterImg} alt="entenda sua conta" />
                  Falta d’água
                </ButtonAction>
              ) : (
                <ButtonAction
                  type="button"
                  onClick={() =>
                    window.open('https://igua.com.br/informacoes-para-voce')
                  }
                >
                  <img src={UnderstandAccountImg} alt="entenda sua conta" />
                  Entenda sua conta
                </ButtonAction>
              )}
              <ButtonAction
                type="button"
                onClick={() => window.open('https://igua.com.br/sustentabilidade')}
              >
                <img src={DecarbonometerImg} alt="sustentabilidade" />
                Sustentabilidade
              </ButtonAction>
            </>
          )}
        </ContainerButtonAction>
      </Main>
    </>
  );
};

export default Landing;
