import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { v4 } from 'uuid';

import configsAppJsonImport from '~/configs/app.config';
import versionsAppJsonImport from '~/configs/versions.json';
import { EnumResponseCode } from '~/enums/reponseCodesEnum';
import { RouteName } from '~/routes/Routes.name';
import { store } from '~/store';
import {
  activateModalEnrollmentNotExists,
  getEnrollmentsAuthDestroySessionRequest,
} from '~/store/modules/enrollmentsAuth/actions';
import { setUnavailableService } from '~/store/modules/unavailableService/actions';
import Navigation from '~/utils/Navigation';
import { checkToggle } from '~/utils/toggles';

const configsAppJson = configsAppJsonImport as any;
const versionsAppJson = versionsAppJsonImport as any;

class HttpClient {
  api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: configsAppJson?.api[configsAppJson?.NODE_ENV]?.url,
      timeout: 30000,
      timeoutErrorMessage: 'Tempo limite da requisição excedido.',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.initInterceptors();
  }

  setInitialHeaders() {
    const session_id = localStorage.getItem('x-session-id');
    if (session_id) {
      this.api.defaults.headers.common['x-session-id'] = session_id;
    }
  }

  initInterceptors() {
    this.api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      config.headers['x-igua-transaction-id'] = `${v4()}`;
      config.headers['x-plataform'] = `web`;
      config.headers['x-app-version'] = versionsAppJson.version;
      config.headers['x-igua-user-ip'] = '0.0.0.0';

      if (localStorage.getItem('@digiIgua_data_userLogin')) {
        config.headers.authorization = `Bearer ${localStorage
          .getItem('@digiIgua_data_userLogin')
          ?.replaceAll('"', '')}`;
      }

      const session_id = localStorage.getItem('x-session-id');
      if (session_id) {
        config.headers['x-session-id'] = session_id;
      }

      return config;
    });
    this.api.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      async (error: AxiosError) => {
        const stateStore = store.getState();

        const toggles = stateStore.toggles.data;
        const { token } = stateStore.enrollmentsAuth;

        const err = {
          ...error,
          status: error?.response?.status,
          data: error?.response?.data,
        };

        if (
          error?.response?.status === 503 ||
          error.code === 'ECONNABORTED' ||
          error?.response?.status === undefined
        ) {
          store.dispatch(setUnavailableService(true));
        }

        if (
          error?.response?.status === 400 &&
          typeof error?.response?.data === 'object' &&
          error?.response?.data !== null &&
          'type' in error.response.data &&
          (error.response.data as { type: string }).type ===
            EnumResponseCode.BAD_REQUEST_ENROLLMENT_NOT_IN_USER
        ) {
          store.dispatch(activateModalEnrollmentNotExists());
        }

        if (error?.response?.status === 401 && token) {
          if (error?.response.config.url === 'v3/sessions')
            return Promise.reject(err);
          store.dispatch(getEnrollmentsAuthDestroySessionRequest());
          Navigation.navigate(
            (await checkToggle(toggles, 'LAYOUT_V3_WEB'))
              ? RouteName.LOGIN
              : RouteName.LANDING,
          );
          return Promise.reject(err);
        }

        return Promise.reject(err);
      },
    );
  }
}

export const { api } = new HttpClient();
