import React, { FC } from 'react';
import ReactGA from 'react-ga4';
import { ContainerButtons, Wrapper } from './styles';
import { Button } from '~/components/Button';
import { InfoIcon } from './Icons';

export type ModalPaymentUnavailableTypeInfo = 'credit_card' | 'pix' | 'billet';

interface IModalReportLackWaterInfoProps {
  onClose: () => void;
  typeInfo: ModalPaymentUnavailableTypeInfo;
}

const ModalPaymentUnavailable: FC<IModalReportLackWaterInfoProps> = ({
  onClose,
  typeInfo,
}) => {
  const message = {
    credit_card: (
      <p>
        Pagamento via cartão não disponível no momento, <br /> por favor tente outra
        forma de pagamento.
      </p>
    ),
    pix: (
      <p>
        Pagamento via PIX não disponível no momento, <br /> por favor tente outra
        forma de pagamento.
      </p>
    ),
    billet: (
      <p>
        Pagamento via boleto não disponível no momento, <br /> por favor tente outra
        forma de pagamento.
      </p>
    ),
  };

  const actionLabel = {
    credit_card: {
      category: 'Modal de indisponibilidade',
      action:
        '[Pagamento][Quitação de Dívidas][Pagamento Via Cartão] – Modal de indisponibilidade',
    },
    pix: {
      category: 'Modal de indisponibilidade',
      action:
        '[Pagamento][Pagamento Único][Pagamento Via PIX] – Modal de indisponibilidade',
    },
    billet: {
      category: 'Modal de indisponibilidade',
      action:
        '[Pagamento][Pagamento Único][Pagamento Via Boleto] – Modal de indisponibilidade',
    },
  };

  return (
    <Wrapper>
      <div>
        <InfoIcon />
      </div>
      <h1>Atenção</h1>
      {message[typeInfo]}
      <ContainerButtons>
        <Button
          text="Entendi"
          type="button"
          onClick={() => {
            ReactGA.event({
              category: actionLabel[typeInfo]?.category,
              action: actionLabel[typeInfo]?.action,
            });
            onClose();
          }}
        />
      </ContainerButtons>
    </Wrapper>
  );
};

export default ModalPaymentUnavailable;
