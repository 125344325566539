import { EndpointBuilder, BaseQueryFn } from '@reduxjs/toolkit/query';
import { IEnumCacheConfig } from '../enum';
import { IFetchEnrollmentsConsumptionHistory } from '../types';
import {
  IDateQuery,
  IResponseEnrollmentsConsumptionHistory,
} from '~/store/modules/enrollmentsConsumptionHistory/types';
import { format, subYears } from 'date-fns';

const getUrl = (query?: IDateQuery) => {
  const dateDefault = {
    start: format(subYears(new Date(), 1), 'dd/MM/yyyy'),
    end: format(new Date(), 'dd/MM/yyyy'),
  };

  const urlQuery = `?start_date=${
    query ? query.start : dateDefault.start
  }&end_date=${query ? query.end : dateDefault.end}`;

  return urlQuery;
};

export const enrollmentsConsumptionHistoryEndpoints = (
  builder: EndpointBuilder<BaseQueryFn, IEnumCacheConfig, 'cache'>,
) => ({
  fetchEnrollmentsConsumptionHistory: builder.query<
    IResponseEnrollmentsConsumptionHistory,
    IFetchEnrollmentsConsumptionHistory
  >({
    query: ({ enrollmentId, urlQuery }) => ({
      url: `v3/enrollments/${enrollmentId}/consumption-history${getUrl(urlQuery)}`,
      method: 'GET',
    }),
    providesTags: [IEnumCacheConfig.ENROLLMENTS_CONSUMPTION_HISTORY_DATA],
  }),
});
