import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import {
  ContainerFooter,
  ContainerLinks,
  ContainerLogo,
  ContainerOperation,
  ContainerRecaptcha,
  ContainerSocialNetwork,
  TextLink,
  TextRecaptcha,
} from './styles';
import SocialNetworkLinkedinSVG from '~/assets/Icons/SocialNetworkLinkedin';
import SocialNetworkFacebookSVG from '~/assets/Icons/SocialNetworkFacebook';
import SocialNetworkInstagramSVG from '~/assets/Icons/SocialNetworkInstagramSVG';
import SocialNetworkYoutubeSVG from '~/assets/Icons/SocialNetworkYoutube';
import SocialNetworkIguaSVG from '~/assets/Icons/SocialNetworkIgua';
import operationAddressJson from './operationAddress.json';
import { Margin } from '../Margin';
import IguaLogoSVG from '~/assets/Icons/IguaLogo';
import { getCoords, getLocation } from '~/utils/location';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';

interface InfoOperation {
  operation: string;
  adress?: string;
  phone?: string;
}

const Footer = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });

  const [infoOperation, setInfoOperation] = useState<InfoOperation>({
    operation: '',
    adress: '',
    phone: '',
  });

  useEffect(() => {
    const findLocation = async () => {
      try {
        const coords = await getCoords();
        const location = await getLocation(coords.latitude, coords.longitude);
        // const location = await getLocation(-23.58906, -46.643408);
        const operationInfo = operationAddressJson.find(
          (item) => item.city === location.city,
        );
        const defaultOperation = operationAddressJson.find(
          (item) => item.city === 'São Paulo',
        );
        setInfoOperation(
          operationInfo ||
            defaultOperation || {
              operation: '',
              adress: '',
              phone: '',
            },
        );
      } catch (error) {
        console.log(error);
      }
    };
    findLocation();
  }, []);

  const currentYear = new Date().getFullYear();

  return (
    <ContainerFooter>
      <ContainerLogo>
        <div>
          <IguaLogoSVG />
        </div>
        <ContainerOperation>
          <select
            defaultValue="teste"
            name="select"
            onChange={(ev) => {
              const operationInfo = operationAddressJson.find(
                (item) => item.operation === ev.target.value,
              );
              setInfoOperation(
                operationInfo || {
                  operation: '',
                  adress: '',
                  phone: '',
                },
              );
            }}
          >
            {operationAddressJson.map((operation) => (
              <option key={operation.operation} value={operation.operation}>
                {operation.operation}
              </option>
            ))}
          </select>
          {infoOperation.adress && <Margin width={30} />}
          <div>
            <p>{infoOperation.adress}</p>
            <Margin height={7} />
            <p>{infoOperation.phone}</p>
          </div>
        </ContainerOperation>
      </ContainerLogo>
      <ContainerSocialNetwork>
        <p>© {currentYear} - Digi Iguá - Todos os direitos reservados</p>
        <div>
          <TextLink
            href="https://www.linkedin.com/company/iguasaneamento/"
            target="_blank"
            rel="noreferrer"
          >
            <SocialNetworkLinkedinSVG />
          </TextLink>
          <Margin width={15} />
          <TextLink
            href="https://www.facebook.com/iguasaneamento/"
            target="_blank"
            rel="noreferrer"
          >
            <SocialNetworkFacebookSVG />
          </TextLink>
          <Margin width={15} />
          <TextLink
            href="https://www.instagram.com/iguasaneamento/"
            target="_blank"
            rel="noreferrer"
          >
            <SocialNetworkInstagramSVG />
          </TextLink>
          <Margin width={15} />
          <TextLink
            href="https://www.youtube.com/iguasaneamento"
            target="_blank"
            rel="noreferrer"
          >
            <SocialNetworkYoutubeSVG />
          </TextLink>
          <Margin width={15} />
          <a href="https://igua.com.br/" target="_blank" rel="noreferrer">
            <SocialNetworkIguaSVG />
          </a>
        </div>
      </ContainerSocialNetwork>
      {isMobile && <Margin height={6} />}
      <ContainerLinks>
        <div>
          <TextLink
            href="/termos-de-uso.pdf"
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              ReactGA.event({
                category: 'Termos de Uso',
                action: '[Digi Iguá][Footer] – Termos de Uso',
              })
            }
          >
            Termos de Uso
          </TextLink>
          <Margin width={6} />
          <p>|</p>
          <Margin width={6} />
          <TextLink
            href="https://igua.com.br/politica-de-privacidade"
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              ReactGA.event({
                category: 'Política de Privacidade',
                action: '[Digi Iguá][Footer] – Política de Privacidade',
              })
            }
          >
            Política de Privacidade
          </TextLink>
        </div>
        {isMobile && <Margin height={12} />}
        <div>
          {!isMobile && (
            <>
              <Margin width={6} />
              <p>|</p>
              <Margin width={6} />
            </>
          )}
          <TextLink
            href="https://igua.com.br/pdf/politica-de-cookies.pdf"
            target="_blank"
            onClick={() =>
              ReactGA.event({
                category: 'Política de Cookies',
                action: '[Digi Iguá][Footer] – Política de Cookies',
              })
            }
            rel="noreferrer"
          >
            Política de Cookies
          </TextLink>
        </div>
      </ContainerLinks>
      <ContainerRecaptcha>
        <TextRecaptcha>
          O Digi Iguá é protegido por reCAPTCHA e a{' '}
          <TextLink
            href="https://policies.google.com/privacy?hl=pt-BR"
            target="_blank"
            rel="noreferrer"
          >
            Política de privacidade
          </TextLink>{' '}
          e{' '}
          <TextLink
            href="https://policies.google.com/terms?hl=pt-BR"
            target="_blank"
            rel="noreferrer"
          >
            Termos de serviço
          </TextLink>{' '}
          do Google
        </TextRecaptcha>
      </ContainerRecaptcha>
    </ContainerFooter>
  );
};

export default Footer;
