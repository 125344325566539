import { history } from '../store';

function navigate(pathName: string, state?: any): void {
  history.push(pathName, state || undefined);
}

function replace(pathName: string, state?: any): void {
  history.push(pathName, state || undefined);
}

function goBack() {
  history.back();
}

export default { navigate, replace, goBack };
