import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Margin } from '~/components/Margin';
import { IState } from '~/store/modules/types';
import { Color } from '~/styles';

import {
  Border,
  Main,
  ContainerCardInvoice,
  CardInvoice,
  ContainerInfo,
  ContainerSvg,
  RowInfo,
  CustomCardForm,
} from './styles';

import { Breakpoints } from '~/styles/breakpoints';

import ArrowRightSVG from '~/assets/Icons/arrowRight';
import { setEnrollmentsAuthRegistratioCheckRequest } from '~/store/modules/enrollmentsAuth/actions';
import SkeletonRegistrationsList from './Skeleton';
import { AppDispatch } from '~/store';

const RegistrationsList: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });
  const registrations = useSelector(
    (item: IState) => item.enrollmentsAuth.registrations,
  );
  const loading = useSelector((item: IState) => item.enrollmentsAuth.loading);
  const dispatch = useDispatch<AppDispatch>();

  const verifyMaturityDate = (maturityDate: any) => {
    const [day, month, year]: any =
      maturityDate && maturityDate.toString().split('/');

    const currentDate = new Date(moment().format('YYYY-MM-DD')).getTime();

    const formatMaturityDate = new Date(
      moment(`${year}-${month}-${day}`).format('YYYY-MM-DD'),
    ).getTime();

    return currentDate > formatMaturityDate;
  };

  const invoiceStatus = (invoices: any) => {
    if (!Array.isArray(invoices))
      return {
        color: Color.gray,
        invoiceExist: false,
        invoiceText: 'Nenhuma fatura',
      };

    const paidOff = invoices.filter(
      (invoice: any) => invoice?.status?.label?.toLocaleLowerCase() === 'quitada',
    );

    const wait = invoices.filter(
      (invoice: any) =>
        invoice?.status?.label?.toLocaleLowerCase() === 'Aguardando Pagamento',
    );

    const overDue = invoices.filter((invoice: any) =>
      verifyMaturityDate(invoice?.due_date),
    );

    if (paidOff && paidOff.length === invoices.length)
      return {
        color: Color.green,
        invoiceExist: true,
        invoiceText: 'Quitada',
      };

    if (wait.length > 0)
      return {
        color: Color.yellow,
        invoiceExist: true,
        invoiceText: 'Processando',
        invoiceTextOverDue: `${wait.length} fatura${
          wait.length > 1 ? 's' : ''
        } Processando!`,
      };

    if (overDue && overDue.length > 0)
      return {
        color: Color.redAscendet,
        invoiceExist: true,
        invoiceText: 'Vencida',
        invoiceTextOverDue: `${overDue.length} fatura${
          overDue.length > 1 ? 's' : ''
        } em aberto!`,
      };

    return {
      color: Color.yellow,
      invoiceExist: true,
      invoiceText: 'Pendente',
    };
  };

  const skeletonCount = [0, 1, 2, 3, 4];

  return (
    <Main>
      <CustomCardForm>
        <Margin height={isMobile ? 30 : 77} />
        <ContainerCardInvoice>
          {loading
            ? skeletonCount.map((registration, index) => (
                <SkeletonRegistrationsList key={index && index.toString()} />
              ))
            : registrations &&
              registrations.map((registration: any) => (
                <div key={registration?.code?.toString()}>
                  <CardInvoice
                    onClick={() => {
                      dispatch(
                        setEnrollmentsAuthRegistratioCheckRequest(registration),
                      );
                    }}
                  >
                    <Border
                      color={
                        invoiceStatus(registration.invoices_pendants || []).color
                      }
                    />
                    <Margin width={isMobile ? 7 : 9} />
                    <ContainerInfo>
                      <RowInfo>
                        <p>Mat. &nbsp;</p>
                        <label
                          style={{
                            color: Color.blueSeconde,
                            fontWeight: '500',
                          }}
                        >
                          {registration.enrollment}
                        </label>
                      </RowInfo>
                      <Margin height={5} />
                      <RowInfo>
                        <label>End.:&nbsp;</label>
                        <label>{registration.address_connection}</label>
                      </RowInfo>
                      <Margin height={isMobile ? 3 : 4} />
                      <RowInfo>
                        <label
                          style={{
                            color: invoiceStatus(
                              registration.invoices_pendants || [],
                            ).color,
                            fontWeight: '500',
                          }}
                        >
                          {
                            invoiceStatus(registration.invoices_pendants || [])
                              .invoiceText
                          }
                        </label>
                      </RowInfo>
                    </ContainerInfo>
                    <ContainerSvg>
                      <ArrowRightSVG color={Color.blueSeconde} />
                    </ContainerSvg>
                  </CardInvoice>
                  <Margin
                    height={isMobile ? 5 : 7}
                    key={registration?.code?.toString()}
                  />
                </div>
              ))}
        </ContainerCardInvoice>
        <Margin height={isMobile ? 21 : 70} />
      </CustomCardForm>
    </Main>
  );
};

export default RegistrationsList;
