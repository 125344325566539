import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { ButtonText } from '~/components/ButtonText';
import { Margin } from '~/components/Margin';
import Modal from '~/components/Modal';
import { postSocialTariffAttachmentsRequest } from '~/store/modules/socialTariff/actions';
import { IState } from '~/store/modules/types';
import { Breakpoints, Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import ModalError from './ModalError';
import SocialTariffStepConcluded from './SocialTariffStepConcluded';
import SocialTariffStepFiles from './SocialTariffStepFiles';
import SocialTariffStepType from './SocialTariffStepType';
import { Stepper } from './Stepper';

import {
  BoxContent,
  Container,
  ContainerBreadCrumb,
  ContainerButtons,
  Header,
  Main,
  Separator,
} from './styles';
import { BannerApp } from '~/components/BannerApp';
import { BreadCrumb } from '~/components/BreadCrumb';
import { RouteName } from '~/routes/Routes.name';
import { AppDispatch } from '~/store';

const steps = [
  {
    id: 0,
    label: 'Tipo de tarifa social',
  },
  {
    id: 1,
    label: 'Documentação',
  },
  {
    id: 2,
    label: 'Solicitação realizada',
  },
];

export interface Types {
  id: number;
  label: string;
  description: string;
  files: Array<{
    label: string;
    note: string;
    file: File | null;
    name: string;
    base64: string;
  }>;
}

const SocialTariffSolicitationRio: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch<AppDispatch>();

  const enrollment = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.enrollment,
  );
  const loadingAttachments = useSelector(
    (item: IState) => item.socialTariff.loadingAttachments,
  );
  const successCreate = useSelector(
    (item: IState) => item.socialTariff.successCreate,
  );
  const error = useSelector((item: IState) => item.socialTariff.errorAttachments);

  const [open, setOpen] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [type, setType] = useState<Types | null>(null);
  const [disabledFile, setDisabledFile] = useState<boolean>(true);

  useEffect(() => {
    setType(null);
  }, []);

  useEffect(() => {
    if (successCreate) {
      setActiveStep(activeStep + 1);
    }
  }, [successCreate]);

  useEffect(() => {
    if (error && error.state) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [error]);

  const checkFiles = async () => {
    if (type) {
      const disabled = type.files.find((item) => {
        return !item.file;
      });
      setDisabledFile(!!disabled);
    } else {
      setDisabledFile(true);
    }
  };

  useEffect(() => {
    checkFiles();
  }, [type]);

  const getStep = () => {
    switch (activeStep) {
      case 0:
        return {
          content: <SocialTariffStepType type={type} setType={setType} />,
          backAction: () => Navigation.goBack(),
          disabled: !type,
        };
      case 1:
        return {
          content: <SocialTariffStepFiles type={type} setType={setType} />,
          backAction: () => setActiveStep(0),
          disabled: disabledFile,
        };
      case 2:
        return {
          content: <SocialTariffStepConcluded />,
          backAction: () => setActiveStep(0),
          disabled: false,
        };
      default:
        return null;
    }
  };

  return (
    <Main>
      <Modal type="error" title="Ops" open={open}>
        <ModalError setOpen={setOpen} />
      </Modal>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Tarifa Social',
              onClick: () => Navigation.navigate(RouteName.SOCIALTARIFF),
              active: false,
            },
            {
              label: 'Solicitar tarifa Social',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Container>
        <Header />
        <BoxContent>
          <h1>Solicitação de tarifa social</h1>
          <Stepper steps={steps} activeStep={activeStep} />
          <Separator />
          {getStep()?.content}
        </BoxContent>
        <Margin height={32} />
        {activeStep !== 2 && (
          <ContainerButtons>
            {isMobile ? (
              <>
                <ButtonText
                  text={type?.id === 1 ? 'Aceitar e continuar' : 'Continuar'}
                  typeBtn="content"
                  Color={Color.white}
                  backgroundColor={
                    getStep()?.disabled ? Color.grayLightSeconde : Color.green
                  }
                  loading={loadingAttachments}
                  nextIcon
                  onClick={async () => {
                    if (getStep()?.disabled || loadingAttachments) return;

                    if (activeStep === 0) {
                      setActiveStep(activeStep + 1);
                      return;
                    }

                    if (type && enrollment) {
                      let files: any = {};

                      await type?.files.map((file) => {
                        if (file.file) {
                          files[file.name] = {
                            filename: file.file.name,
                            filedata: file.base64,
                          };
                        }
                      });

                      dispatch(
                        postSocialTariffAttachmentsRequest({
                          enrollment,
                          description: type?.label,
                          files,
                        }),
                      );
                    }
                  }}
                />
                <Margin height={24} />
                <ButtonText
                  text="Voltar"
                  typeBtn="content"
                  Color={Color.white}
                  backgroundColor={Color.blueSeconde}
                  backIcon
                  onClick={() => {
                    if (loadingAttachments) return;
                    getStep()?.backAction();
                    setType(null);
                  }}
                />
              </>
            ) : (
              <>
                <ButtonText
                  text="Voltar"
                  typeBtn="content"
                  Color={Color.white}
                  backgroundColor={Color.blueSeconde}
                  backIcon
                  onClick={() => {
                    if (loadingAttachments) return;
                    getStep()?.backAction();
                    setType(null);
                  }}
                />
                <ButtonText
                  text={type?.id === 1 ? 'Aceitar e continuar' : 'Continuar'}
                  typeBtn="content"
                  Color={Color.white}
                  backgroundColor={
                    getStep()?.disabled ? Color.grayLightSeconde : Color.green
                  }
                  loading={loadingAttachments}
                  nextIcon
                  onClick={async () => {
                    if (getStep()?.disabled || loadingAttachments) return;

                    if (activeStep === 0) {
                      setActiveStep(activeStep + 1);
                      return;
                    }

                    if (type && enrollment) {
                      let files: any = {};

                      await type?.files.map((file) => {
                        if (file.file) {
                          files[file.name] = {
                            filename: file.file.name,
                            filedata: file.base64,
                          };
                        }
                      });

                      dispatch(
                        postSocialTariffAttachmentsRequest({
                          enrollment,
                          description: type?.label,
                          files,
                        }),
                      );
                    }
                  }}
                />
              </>
            )}
          </ContainerButtons>
        )}
        <Margin height={64} />
        <BannerApp />
      </Container>
    </Main>
  );
};

export default SocialTariffSolicitationRio;
