import React, { FC, useEffect, useState } from 'react';
import { Input } from '~/components/Input';
import { Margin } from '~/components/Margin';
import {
  ContainerTitle,
  Label,
  Main,
  Row,
  SeparatorTitle,
  Title,
  Subtitle,
  TextFooter,
  Column,
  DescriptionTitle,
  SubtitleBold,
  Content,
} from './styles';
import BoxInput from '../../../components/BoxInput';
import { InputTextArea } from '~/components/InputTextArea';
import DropZone from '../../../components/DropZone';
import { Color } from '~/styles';
import ButtonBar from '../../../components/ButtonBar';
import formSchema from './form.json';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { validateEmail, validateForm, validateIsEmpty } from '~/utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import {
  patchInformationPropertyRegistrationRequest,
  postUploadFilePropertyRegistrationRequest,
} from '~/store/modules/propertyRegistration/actions';
import convertFileToBase64 from '~/utils/convertBase64';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';
import StatusInfo from '~/views/SupplyAndDepletionPossibilityDeclaration/components/StatusInfo';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import { AppDispatch } from '~/store';

interface IPropsForm {
  value: string;
  isRequired: boolean;
  error: string;
}

type typeForm = {
  [key: string]: IPropsForm;
};

const Water: FC = () => {
  const [form, setForm] = useState<typeForm>(formSchema as typeForm);
  const [licensingFile, setLicensingFile] = useState<File | null>(null);
  const [licensingFileError, setLicensingFileError] = useState<boolean>(false);
  const [shouldShowAlert, setShouldShowAlert] = useState<boolean>(false);
  const loadingUploadFile = useSelector(
    (item: IState) => item.propertyRegistration.loadingUploadFile,
  );
  const loadingPatch = useSelector(
    (item: IState) => item.propertyRegistration.loadingPatch,
  );

  const [formBoolean, setFormBoolean] = useState<{
    lowerReservoir: null | boolean;
    superiorReservoir: null | boolean;
    waterCastle: null | boolean;
    enterpriseWaterTotalFlow: null | boolean;
    waterDistribution: null | boolean;
    accordingly: boolean;
  }>({
    lowerReservoir: null,
    superiorReservoir: null,
    waterCastle: null,
    enterpriseWaterTotalFlow: null,
    waterDistribution: null,
    accordingly: false,
  });

  const [formBooleanError, setFormBooleanError] = useState<{
    [key: string]: string;
  }>({
    lowerReservoir: '',
    superiorReservoir: '',
    waterCastle: '',
    enterpriseWaterTotalFlow: '',
    waterDistribution: '',
    accordingly: '',
  });

  const selected = {
    null: null,
    true: true,
    false: false,
  };

  const dispatch = useDispatch<AppDispatch>();
  const caseId = useSelector(
    (item: IState) => item.propertyRegistration.dataItem?.id,
  );
  const caseFCP = useSelector((item: IState) => item.propertyRegistration.dataItem);
  const fcpDetails = useSelector(
    (item: IState) => item.propertyRegistration.dataItem?.details?.fcp,
  );

  const [isRevision, setIsRevision] = useState<boolean>(false);
  const [shouldAttachDocument, setShouldAttachDocument] = useState<boolean>(true);
  const [revisionDescription, setRevisionDescription] = useState<string>('');

  const handleInput = (valueInput: string, input: string) => {
    let error = '';
    let value = valueInput;

    if (form[input].isRequired && value.length === 0) {
      error = 'Campo obrigatório';
    }

    if (input.includes('Number') || ['extensionEntrySupply'].includes(input)) {
      value = valueInput.replace(/[^0-9]/g, '');
    }

    if (
      ['waterCastleFlow', 'lowerReservoirFlow', 'superiorReservoirFlow'].includes(
        input,
      )
    ) {
      value = valueInput.replace(/[^0-9,]/g, '');

      const separateValues = value.split(',');
      if (separateValues.length > 1) {
        separateValues[1] = separateValues[1].slice(0, 2);
        value = separateValues.join(',');
      }
    }

    if (input.includes('Name')) {
      value = valueInput.replace(/[^A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]/g, '');
    }

    if (input === 'dateDeliveryImovel') {
      error = !value.length
        ? 'Campo obrigatório'
        : value.length !== 10
          ? 'Campo inválido'
          : '';
    }

    if (input === 'propertyTechnicalResponsiblePhone') {
      value =
        formatter.formatarPhone(valueInput.replace(/[^0-9]/g, '')) ||
        valueInput.replace(/[^0-9]/g, '');
    }

    if (
      input === 'propertyTechnicalResponsiblePhone' &&
      valueInput.replace(/[^0-9]/g, '').length < 11
    ) {
      error = !valueInput.replace(/[^0-9]/g, '').length
        ? 'Campo obrigatório'
        : valueInput.replace(/[^0-9]/g, '').length < 11
          ? 'Campo inválido'
          : '';
    }

    if (input === 'licensingJustification') {
      setLicensingFileError(false);
      return setForm((prevState) => ({
        ...prevState,
        licensingNumber: {
          ...prevState.licensingNumber,
          error: '',
        },
        processNumber: {
          ...prevState.processNumber,
          error: '',
        },
        [input]: {
          value,
          error,
          isRequired: prevState[input].isRequired,
        },
      }));
    }

    if (input === 'licensingNumber')
      return setForm((prevState) => ({
        ...prevState,
        licensingNumber: {
          value,
          error,
          isRequired:
            value.length > 0 ||
            form.processNumber.value.length > 0 ||
            !!licensingFile,
        },
        licensingJustification: {
          value: '',
          error: '',
          isRequired:
            (shouldAttachDocument && !licensingFile) ||
            value.length < 1 ||
            form.processNumber.value.length < 1,
        },
      }));

    if (input === 'processNumber') {
      return setForm((prevState) => ({
        ...prevState,
        processNumber: {
          value,
          error,
          isRequired:
            value.length > 0 ||
            form.licensingNumber.value.length > 0 ||
            !!licensingFile,
        },
        licensingJustification: {
          value: '',
          error: '',
          isRequired:
            (shouldAttachDocument && !licensingFile) ||
            value.length < 1 ||
            form.licensingNumber.value.length < 1,
        },
      }));
    }

    return setForm((prevState) => ({
      ...prevState,
      [input]: {
        value,
        error,
        isRequired: prevState[input].isRequired,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(form);
    let hasError = false;

    Object.entries(formBoolean).forEach((allInput) => {
      if (allInput[1] !== true && allInput[1] !== false) {
        hasError = true;
        setFormBooleanError((prevState) => ({
          ...prevState,
          [allInput[0]]: 'Campo obrigatório',
        }));
      }
    });

    Object.keys(form).forEach((inputName) => {
      if (form[inputName].error && form[inputName].isRequired) {
        hasError = true;
      }
    });

    Object.entries(form).forEach((allInput) => {
      if (allInput[0] === 'licensingNumber' || allInput[0] === 'processNumber') {
        if (
          (licensingFile ||
            form.licensingNumber.value.length > 0 ||
            form.processNumber.value.length > 0) &&
          allInput[1].value.length === 0
        ) {
          setForm((prevState) => ({
            ...prevState,
            [allInput[0]]: {
              ...prevState[allInput[0]],
              error: 'Campo obrigatório',
            },
          }));
        }
      }

      if (shouldAttachDocument) {
        const hasFileError =
          (form.licensingNumber.value.length > 0 ||
            form.processNumber.value.length > 0) &&
          !licensingFile;

        if (hasFileError) hasError = true;
        setLicensingFileError(hasFileError);
      }
    });

    setShouldShowAlert(hasError);
    return validateIsEmpty(inputsWithError) && !hasError;
  };

  const handleSubmit = () => {
    Object.entries(form).forEach((allInput) => {
      handleInput(allInput[1].value, allInput[0]);
    });

    if (isFormValid()) {
      dispatch(
        patchInformationPropertyRegistrationRequest(
          caseId!,
          {
            fcpId: fcpDetails?.id,
            isRevision,
            licensing: {
              processNumber: form.processNumber.value,
              licenseNumber: form.licensingNumber.value,
              justification: !licensingFile
                ? form.licensingJustification.value
                : undefined,
            },
            involvedData: {
              technicalManagerFCP: {
                name: form.technicalManagerFCPName.value,
                email: form.technicalManagerFCPEmail.value,
              },
              workPermitApplicant: {
                name: form.workPermitApplicantName.value,
                email: form.workPermitApplicantEmail.value,
              },
              propertyTechnicalResponsible: {
                name: form.propertyTechnicalResponsibleName.value,
                phone: form.propertyTechnicalResponsiblePhone.value,
                address: form.propertyTechnicalResponsibleAddress.value,
                email: form.propertyTechnicalResponsibleEmail.value,
              },
              installationsExecutionTechnicalResponsible: {
                name: form.installationsExecutionTechnicalResponsibleName.value,
                email: form.installationsExecutionTechnicalResponsibleEmail.value,
              },
            },
            projectInformation: {
              dateDeliveryImovel: form.dateDeliveryImovel.value,
              lowerReservoir: {
                has: formBoolean.lowerReservoir,
                flow: form.lowerReservoirFlow.value,
                justification: form.lowerReservoirJustification.value,
              },
              superiorReservoir: {
                has: formBoolean.superiorReservoir,
                flow: form.superiorReservoirFlow.value,
                justification: form.superiorReservoirJustification.value,
              },
              waterCastle: {
                has: formBoolean.waterCastle,
                flow: form.waterCastleFlow.value,
                justification: form.waterCastleJustification.value,
              },
              enterpriseWaterTotalFlow: {
                servesThreeDays: formBoolean.enterpriseWaterTotalFlow,
                justification: form.enterpriseWaterTotalFlowJustification.value,
              },
              waterDistribution: {
                madeByGravity: formBoolean.waterDistribution,
                justification: form.waterDistributionJustification.value,
              },
              extensionEntrySupply: {
                DN: form.extensionEntrySupply.value,
                justification:
                  parseFloat(form.extensionEntrySupply.value.replace(',', '.')) > 100
                    ? form.extensionEntrySupplyJustification.value
                    : undefined,
              },
            },
            accordingly: formBoolean.accordingly,
            type: 'FCPA',
          },
          !!licensingFile,
        ),
      );
    }
  };

  useEffect(() => {
    if (!isRevision) return setShouldAttachDocument(true);

    const workLicenseFile = caseFCP?.attachments?.find(
      (attachment) => attachment.type === 'WORKS_LICENSE',
    );

    if (!workLicenseFile || (workLicenseFile && workLicenseFile.review)) {
      setRevisionDescription(workLicenseFile?.review_description || '');
      return setShouldAttachDocument(true);
    }

    return setShouldAttachDocument(false);
  }, [isRevision, caseFCP]);

  useEffect(() => {
    setIsRevision(caseFCP?.step?.status === stepStatusEnum.REVIEW);
  }, [caseFCP]);

  useEffect(() => {
    setForm((prevState) => ({
      ...prevState,
      lowerReservoirJustification: {
        value: prevState.lowerReservoirJustification.value,
        error: prevState.lowerReservoirJustification.error,
        isRequired: !formBoolean.lowerReservoir,
      },
      superiorReservoirJustification: {
        value: prevState.superiorReservoirJustification.value,
        error: prevState.superiorReservoirJustification.error,
        isRequired: !formBoolean.superiorReservoir,
      },
      waterCastleJustification: {
        value: prevState.waterCastleJustification.value,
        error: prevState.waterCastleJustification.error,
        isRequired: !formBoolean.waterCastle,
      },
      enterpriseWaterTotalFlowJustification: {
        value: prevState.enterpriseWaterTotalFlowJustification.value,
        error: prevState.enterpriseWaterTotalFlowJustification.error,
        isRequired: !formBoolean.enterpriseWaterTotalFlow,
      },
      waterDistributionJustification: {
        value: prevState.waterDistributionJustification.value,
        error: prevState.waterDistributionJustification.error,
        isRequired: !formBoolean.waterDistribution,
      },
      lowerReservoirFlow: {
        value: prevState.lowerReservoirFlow.value,
        error: prevState.lowerReservoirFlow.error,
        isRequired: !!formBoolean.lowerReservoir,
      },
      superiorReservoirFlow: {
        value: prevState.superiorReservoirFlow.value,
        error: prevState.superiorReservoirFlow.error,
        isRequired: !!formBoolean.superiorReservoir,
      },
      waterCastleFlow: {
        value: prevState.waterCastleFlow.value,
        error: prevState.waterCastleFlow.error,
        isRequired: !!formBoolean.waterCastle,
      },
      extensionEntrySupplyJustification: {
        value: prevState.extensionEntrySupplyJustification.value,
        error: prevState.extensionEntrySupplyJustification.error,
        isRequired:
          parseFloat(form.extensionEntrySupply.value.replace(',', '.')) > 100,
      },
      licensingJustification: {
        value: prevState.licensingJustification.value,
        error: prevState.licensingJustification.error,
        isRequired: !licensingFile,
      },
    }));
  }, [formBoolean, form.extensionEntrySupply.value, licensingFile]);

  useEffect(() => {
    if (isRevision) {
      setFormBoolean({
        lowerReservoir: fcpDetails.lowerReservoir.has,
        superiorReservoir: fcpDetails.superiorReservoir.has,
        waterCastle: fcpDetails.waterCastle.has,
        enterpriseWaterTotalFlow: fcpDetails.totalWaterFlow.servesThreeDays,
        waterDistribution: fcpDetails.waterDistribution.madeByGravity,
        accordingly: true,
      });
      setForm((prevState) => ({
        ...prevState,
        dateDeliveryImovel: {
          ...prevState.dateDeliveryImovel,
          value: fcpDetails?.deliveryDate || '',
        },
        lowerReservoirFlow: {
          ...prevState.lowerReservoirFlow,
          value: fcpDetails?.lowerReservoir.flow || '',
        },
        lowerReservoirJustification: {
          ...prevState.lowerReservoirJustification,
          value: fcpDetails?.lowerReservoir.justification || '',
        },
        superiorReservoirFlow: {
          ...prevState.lowerReservoirFlow,
          value: fcpDetails?.superiorReservoir.flow || '',
        },
        superiorReservoirJustification: {
          ...prevState.lowerReservoirJustification,
          value: fcpDetails?.superiorReservoir.justification || '',
        },
        waterCastleFlow: {
          ...prevState.waterCastleFlow,
          value: fcpDetails?.waterCastle.flow || '',
        },
        waterCastleJustification: {
          ...prevState.waterCastleJustification,
          value: fcpDetails?.waterCastle.justification || '',
        },
        enterpriseWaterTotalFlowJustification: {
          ...prevState.enterpriseWaterTotalFlowJustification,
          value: fcpDetails.totalWaterFlow.justification || '',
        },
        waterDistributionJustification: {
          ...prevState.waterDistributionJustification,
          value: fcpDetails.waterDistribution.justification || '',
        },

        extensionEntrySupply: {
          ...prevState.extensionEntrySupply,
          value: fcpDetails.extensionEntrySupply.DN || '' || '',
        },
        extensionEntrySupplyJustification: {
          ...prevState.extensionEntrySupplyJustification,
          value: fcpDetails.extensionEntrySupply.justification || '',
        },

        propertyTechnicalResponsibleName: {
          ...prevState.propertyTechnicalResponsibleName,
          value: fcpDetails.propertyTechnicalResponsible.name || '',
        },
        propertyTechnicalResponsiblePhone: {
          ...prevState.propertyTechnicalResponsiblePhone,
          value: fcpDetails.propertyTechnicalResponsible.phone || '',
        },
        propertyTechnicalResponsibleAddress: {
          ...prevState.propertyTechnicalResponsibleAddress,
          value: fcpDetails.propertyTechnicalResponsible.address || '',
        },
        propertyTechnicalResponsibleEmail: {
          ...prevState.propertyTechnicalResponsibleEmail,
          value: fcpDetails.propertyTechnicalResponsible.email || '',
        },
        workPermitApplicantEmail: {
          ...prevState.workPermitApplicantEmail,
          value: fcpDetails.workPermitApplicant.email || '',
        },
        workPermitApplicantName: {
          ...prevState.workPermitApplicantName,
          value: fcpDetails.workPermitApplicant.name || '',
        },
        technicalManagerFCPName: {
          ...prevState.technicalManagerFCPName,
          value: fcpDetails.technicalManagerFCP.name || '',
        },
        technicalManagerFCPEmail: {
          ...prevState.technicalManagerFCPEmail,
          value: fcpDetails.technicalManagerFCP.email || '',
        },
        installationsExecutionTechnicalResponsibleName: {
          ...prevState.installationsExecutionTechnicalResponsibleName,
          value: fcpDetails.installationsExecutionTechnicalResponsible.name || '',
        },
        installationsExecutionTechnicalResponsibleEmail: {
          ...prevState.installationsExecutionTechnicalResponsibleEmail,
          value: fcpDetails.installationsExecutionTechnicalResponsible.email || '',
        },
        processNumber: {
          ...prevState.processNumber,
          value: fcpDetails.licensing.processNumber || '',
        },
        licensingNumber: {
          ...prevState.licensingNumber,
          value: fcpDetails.licensing.licenseNumber || '',
        },
        licensingJustification: {
          ...prevState.licensingJustification,
          value: fcpDetails.licensing.justification || '',
        },
      }));
    }
  }, [isRevision]);

  return (
    <>
      <Main>
        {isRevision && (
          <>
            <StatusInfo style={{ width: 'calc(100% - 50px)' }} status="warning">
              As informações precisam ser revisadas. Por favor, preencha novamente.
            </StatusInfo>
            <Margin height={40} />

            {caseFCP?.reviewDescription && (
              <>
                <Label
                  style={{
                    width: '100%',
                    color: Color.red,
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  {caseFCP?.reviewDescription}
                </Label>
                <Margin height={40} />
              </>
            )}
          </>
        )}
        <DescriptionTitle>
          Nesta etapa é necessário o anexo de todos os documentos e informações
          solicitados a seguir para seguir com o processo.
        </DescriptionTitle>
      </Main>
      <Margin height={64} />

      <ContainerTitle>
        <SeparatorTitle />
        <Title>Informações do projeto</Title>
      </ContainerTitle>
      <Margin height={50} />
      <Main>
        <Row divider="40">
          <Input
            label="Data de entrega do imóvel:*"
            type="date"
            value={form.dateDeliveryImovel.value}
            onChange={(e) => handleInput(e.target.value, 'dateDeliveryImovel')}
            error={!!form.dateDeliveryImovel.error}
            errorText={form.dateDeliveryImovel.error}
            errorPosition="bottom"
            maxLength={10}
          />
          <Label>
            Neste campo você deve preencher a data em que a chave do imóvel será
            entregue aos moradores e sua ligação de água e esgoto se tornará
            definitiva.
          </Label>
        </Row>
        <Margin height={35} />
        <Subtitle>Os elementos abaixo são existentes no imóvel?*</Subtitle>
        <Margin height={35} />
        <Row>
          <Margin width={80} />
          <div style={{ width: '100%' }}>
            <BoxInput
              setSelectValue={(e: any) => {
                setFormBoolean({
                  ...formBoolean,
                  lowerReservoir: selected[e as 'true' | 'false' | 'null'],
                });
                setFormBooleanError((prevState) => ({
                  ...prevState,
                  lowerReservoir: '',
                }));
              }}
              selectValue={formBoolean.lowerReservoir}
              labelInput="Indique a vazão do elemento acima*"
              labelSelect="Reservatório inferior*"
              textInsideInput="Litros"
              inputFlow={{
                value: form.lowerReservoirFlow.value,
                onChange: (e) => handleInput(e.target.value, 'lowerReservoirFlow'),
                error: !!form.lowerReservoirFlow.error,
                errorText: form.lowerReservoirFlow.error,
                errorPosition: 'bottom',
              }}
              inputJustification={{
                value: form.lowerReservoirJustification.value,
                onChange: (e) =>
                  handleInput(e.target.value, 'lowerReservoirJustification'),
                error: !!form.lowerReservoirJustification.error,
                errorText: form.lowerReservoirJustification.error,
              }}
              inputSelect={{
                error: !!formBooleanError.lowerReservoir,
                errorText: formBooleanError.lowerReservoir,
              }}
            />
            <BoxInput
              setSelectValue={(e: any) => {
                setFormBoolean({
                  ...formBoolean,
                  superiorReservoir: selected[e as 'true' | 'false' | 'null'],
                });
                setFormBooleanError((prevState) => ({
                  ...prevState,
                  superiorReservoir: '',
                }));
              }}
              selectValue={formBoolean.superiorReservoir}
              labelInput="Indique a vazão do elemento acima*"
              labelSelect="Reservatório superior*"
              textInsideInput="Litros"
              inputFlow={{
                value: form.superiorReservoirFlow.value,
                onChange: (e) =>
                  handleInput(e.target.value, 'superiorReservoirFlow'),
                error: !!form.superiorReservoirFlow.error,
                errorText: form.superiorReservoirFlow.error,
                errorPosition: 'bottom',
              }}
              inputJustification={{
                value: form.superiorReservoirJustification.value,
                onChange: (e) =>
                  handleInput(e.target.value, 'superiorReservoirJustification'),
                error: !!form.superiorReservoirJustification.error,
                errorText: form.superiorReservoirJustification.error,
              }}
              inputSelect={{
                error: !!formBooleanError.superiorReservoir,
                errorText: formBooleanError.superiorReservoir,
              }}
            />
            <BoxInput
              setSelectValue={(e: any) => {
                setFormBoolean({
                  ...formBoolean,
                  waterCastle: selected[e as 'true' | 'false' | 'null'],
                });
                setFormBooleanError((prevState) => ({
                  ...prevState,
                  waterCastle: '',
                }));
              }}
              selectValue={formBoolean.waterCastle}
              labelInput="Indique a vazão do elemento acima*"
              labelSelect="Castelo d’água*"
              textInsideInput="Litros"
              inputFlow={{
                value: form.waterCastleFlow.value,
                onChange: (e) => handleInput(e.target.value, 'waterCastleFlow'),
                error: !!form.waterCastleFlow.error,
                errorText: form.waterCastleFlow.error,
                errorPosition: 'bottom',
              }}
              inputJustification={{
                value: form.waterCastleJustification.value,
                onChange: (e) =>
                  handleInput(e.target.value, 'waterCastleJustification'),
                error: !!form.waterCastleJustification.error,
                errorText: form.waterCastleJustification.error,
              }}
              inputSelect={{
                error: !!formBooleanError.waterCastle,
                errorText: formBooleanError.waterCastle,
              }}
            />
          </div>
        </Row>
        <BoxInput
          setSelectValue={(e: any) => {
            setFormBoolean({
              ...formBoolean,
              enterpriseWaterTotalFlow: selected[e as 'true' | 'false' | 'null'],
            });
            setFormBooleanError((prevState) => ({
              ...prevState,
              enterpriseWaterTotalFlow: '',
            }));
          }}
          selectValue={formBoolean.enterpriseWaterTotalFlow}
          labelInput=""
          labelSelect="A vazão total da reservação do empreendimento atende a 3 dias?*"
          spaceTextArea
          onlyJustifcation
          inputJustification={{
            value: form.enterpriseWaterTotalFlowJustification.value,
            onChange: (e) =>
              handleInput(e.target.value, 'enterpriseWaterTotalFlowJustification'),
            error: !!form.enterpriseWaterTotalFlowJustification.error,
            errorText: form.enterpriseWaterTotalFlowJustification.error,
          }}
          inputSelect={{
            error: !!formBooleanError.enterpriseWaterTotalFlow,
            errorText: formBooleanError.enterpriseWaterTotalFlow,
          }}
        />
        <BoxInput
          setSelectValue={(e: any) => {
            setFormBoolean({
              ...formBoolean,
              waterDistribution: selected[e as 'true' | 'false' | 'null'],
            });
            setFormBooleanError((prevState) => ({
              ...prevState,
              waterDistribution: '',
            }));
          }}
          selectValue={formBoolean.waterDistribution}
          labelInput=""
          labelSelect="A distribuição de água para as unidades consumidoras está sendo feita por gravidade?*"
          spaceTextArea
          onlyJustifcation
          inputJustification={{
            value: form.waterDistributionJustification.value,
            onChange: (e) =>
              handleInput(e.target.value, 'waterDistributionJustification'),
            error: !!form.waterDistributionJustification.error,
            errorText: form.waterDistributionJustification.error,
          }}
          inputSelect={{
            error: !!formBooleanError.waterDistribution,
            errorText: formBooleanError.waterDistribution && 'Campo obrigatório',
          }}
        />
        <Input
          label="DN do ramal de entrada de abastecimento:*"
          style={{ width: 'calc(40% - 95px)', paddingRight: '95px' }}
          styleLabelError={{ width: '40%' }}
          textInsideInput="Milímetros"
          rigthTextInsideInput="60%"
          colorTextInsideInput={Color.grayLightSeconde}
          value={form.extensionEntrySupply.value}
          onChange={(e) => handleInput(e.target.value, 'extensionEntrySupply')}
          error={!!form.extensionEntrySupply.error}
          errorText={form.extensionEntrySupply.error}
          errorPosition="bottom"
        />
        {parseFloat(form.extensionEntrySupply.value.replace(',', '.')) > 100 && (
          <>
            <Margin height={40} />
            <InputTextArea
              rows={5}
              label="Justifique.*"
              value={form.extensionEntrySupplyJustification.value}
              onChange={(e) =>
                handleInput(e.target.value, 'extensionEntrySupplyJustification')
              }
              error={!!form.extensionEntrySupplyJustification.error}
              errorText={form.extensionEntrySupplyJustification.error}
              maxLength={500}
              errorPosition="bottom"
              style={{ resize: 'none' }}
            />
          </>
        )}
      </Main>

      <Margin height={50} />
      <ContainerTitle>
        <SeparatorTitle />
        <Title>Dados dos envolvidos</Title>
      </ContainerTitle>
      <Margin height={35} />
      <Main>
        <SubtitleBold>Responsável técnico pelo imóvel</SubtitleBold>
        <Margin height={35} />
        <Row divider="60">
          <Column>
            <Input
              label="Nome*"
              value={form.propertyTechnicalResponsibleName.value}
              onChange={(e) =>
                handleInput(e.target.value, 'propertyTechnicalResponsibleName')
              }
              error={!!form.propertyTechnicalResponsibleName.error}
              errorText={form.propertyTechnicalResponsibleName.error}
              errorPosition="bottom"
              maxLength={50}
            />
            <Margin height={form.propertyTechnicalResponsibleName.error ? 25 : 40} />
            <Input
              label="Endereço*"
              value={form.propertyTechnicalResponsibleAddress.value}
              onChange={(e) =>
                handleInput(e.target.value, 'propertyTechnicalResponsibleAddress')
              }
              error={!!form.propertyTechnicalResponsibleAddress.error}
              errorText={form.propertyTechnicalResponsibleAddress.error}
              errorPosition="bottom"
            />
          </Column>
          <Margin width={40} />
          <Column style={{ width: '40%' }}>
            <Input
              styleInput={{ width: '100%' }}
              label="Telefone*"
              value={form.propertyTechnicalResponsiblePhone.value}
              onChange={(e) =>
                handleInput(e.target.value, 'propertyTechnicalResponsiblePhone')
              }
              error={!!form.propertyTechnicalResponsiblePhone.error}
              errorText={form.propertyTechnicalResponsiblePhone.error}
              errorPosition="bottom"
            />
            <Margin
              height={form.propertyTechnicalResponsiblePhone.error ? 25 : 40}
            />
            <Input
              styleInput={{ width: '100%' }}
              label="Email*"
              value={form.propertyTechnicalResponsibleEmail.value}
              onChange={(e) =>
                handleInput(e.target.value, 'propertyTechnicalResponsibleEmail')
              }
              error={!!form.propertyTechnicalResponsibleEmail.error}
              errorText={form.propertyTechnicalResponsibleEmail.error}
              errorPosition="bottom"
              onBlur={(e) =>
                setForm((prevState) => ({
                  ...prevState,
                  propertyTechnicalResponsibleEmail: {
                    value: e.target.value,
                    error: !validateEmail(e.target.value) ? 'Email inválido' : '',
                    isRequired:
                      prevState.propertyTechnicalResponsibleEmail.isRequired,
                  },
                }))
              }
            />
          </Column>
          <Margin height={40} />
        </Row>
        <Margin height={35} />
        <SeparatorTitle />
        <Margin height={35} />
        <SubtitleBold>Requerente da licença de obra</SubtitleBold>
        <Margin height={35} />
        <Row divider="60">
          <Input
            label="Nome*"
            value={form.workPermitApplicantName.value}
            onChange={(e) => handleInput(e.target.value, 'workPermitApplicantName')}
            error={!!form.workPermitApplicantName.error}
            errorText={form.workPermitApplicantName.error}
            errorPosition="bottom"
            maxLength={50}
          />
          <Margin width={40} />
          <Input
            styleInput={{ width: '100%' }}
            label="Email*"
            value={form.workPermitApplicantEmail.value}
            onChange={(e) => handleInput(e.target.value, 'workPermitApplicantEmail')}
            error={!!form.workPermitApplicantEmail.error}
            errorText={form.workPermitApplicantEmail.error}
            errorPosition="bottom"
            onBlur={(e) =>
              setForm((prevState) => ({
                ...prevState,
                workPermitApplicantEmail: {
                  value: e.target.value,
                  error: !validateEmail(e.target.value) ? 'Email inválido' : '',
                  isRequired: prevState.workPermitApplicantEmail.isRequired,
                },
              }))
            }
          />
        </Row>
        <Margin height={40} />
        <SeparatorTitle />
        <Margin height={35} />
        <SubtitleBold>Responsável técnico FCP</SubtitleBold>
        <Margin height={35} />
        <Row divider="60">
          <Input
            label="Nome*"
            value={form.technicalManagerFCPName.value}
            onChange={(e) => handleInput(e.target.value, 'technicalManagerFCPName')}
            error={!!form.technicalManagerFCPName.error}
            errorText={form.technicalManagerFCPName.error}
            errorPosition="bottom"
            maxLength={50}
          />
          <Margin width={40} />
          <Input
            styleInput={{ width: '100%' }}
            label="Email*"
            value={form.technicalManagerFCPEmail.value}
            onChange={(e) => handleInput(e.target.value, 'technicalManagerFCPEmail')}
            error={!!form.technicalManagerFCPEmail.error}
            errorText={form.technicalManagerFCPEmail.error}
            errorPosition="bottom"
            onBlur={(e) =>
              setForm((prevState) => ({
                ...prevState,
                technicalManagerFCPEmail: {
                  value: e.target.value,
                  error: !validateEmail(e.target.value) ? 'Email inválido' : '',
                  isRequired: prevState.technicalManagerFCPEmail.isRequired,
                },
              }))
            }
          />
        </Row>
        <Margin height={40} />
        <SeparatorTitle />
        <Margin height={35} />
        <SubtitleBold>
          Responsável técnico pela execução das instalações
        </SubtitleBold>
        <Margin height={35} />
        <Row divider="60">
          <Input
            label="Nome*"
            maxLength={50}
            value={form.installationsExecutionTechnicalResponsibleName.value}
            onChange={(e) =>
              handleInput(
                e.target.value,
                'installationsExecutionTechnicalResponsibleName',
              )
            }
            error={!!form.installationsExecutionTechnicalResponsibleName.error}
            errorText={form.installationsExecutionTechnicalResponsibleName.error}
            errorPosition="bottom"
          />
          <Margin width={40} />
          <Input
            styleInput={{ width: '100%' }}
            label="Email*"
            value={form.installationsExecutionTechnicalResponsibleEmail.value}
            onChange={(e) =>
              handleInput(
                e.target.value,
                'installationsExecutionTechnicalResponsibleEmail',
              )
            }
            error={!!form.installationsExecutionTechnicalResponsibleEmail.error}
            errorText={form.installationsExecutionTechnicalResponsibleEmail.error}
            errorPosition="bottom"
            onBlur={(e) =>
              setForm((prevState) => ({
                ...prevState,
                installationsExecutionTechnicalResponsibleEmail: {
                  value: e.target.value,
                  error: !validateEmail(e.target.value) ? 'Email inválido' : '',
                  isRequired:
                    prevState.installationsExecutionTechnicalResponsibleEmail
                      .isRequired,
                },
              }))
            }
          />
        </Row>
      </Main>

      <Margin height={50} />
      <ContainerTitle>
        <SeparatorTitle />
        <Title>Licenciamento</Title>
      </ContainerTitle>
      <Margin height={45} />
      <Main>
        <Row divider="45">
          <Input
            label="Número do processo"
            value={form.processNumber.value}
            onChange={(e) => handleInput(e.target.value, 'processNumber')}
            error={!!form.processNumber.error}
            errorText={form.processNumber.error}
            errorPosition="bottom"
          />
          <Margin width={30} />
          <Input
            label="Número da licença"
            styleInput={{ width: '40%' }}
            value={form.licensingNumber.value}
            onChange={(e) => handleInput(e.target.value, 'licensingNumber')}
            error={!!form.licensingNumber.error}
            errorText={form.licensingNumber.error}
            errorPosition="bottom"
          />
        </Row>
        <Margin height={45} />
        {shouldAttachDocument && (
          <>
            <Subtitle>Anexe o seguinte documento:</Subtitle>
            <Margin height={40} />
            <Row divider="45">
              <DropZone
                disabled={false}
                disabledDeleteFile={false}
                infoFileName="Licença de obras"
                name="Licença de obras"
                error={licensingFileError}
                content={licensingFile}
                onFileUploaded={(file: File | null) => {
                  if (file) {
                    convertFileToBase64(file, (base64: string) => {
                      dispatch(
                        postUploadFilePropertyRegistrationRequest({
                          caseIdSales: caseId!,
                          type: 'WORKS_LICENSE',
                          step: '2',
                          filename: file?.name || '',
                          filedata: base64,
                        }),
                      );
                    });
                  }

                  setLicensingFile(file);
                }}
              />
              <Margin width={15} />
              <Label>
                {isRevision
                  ? revisionDescription
                  : 'Documentos devem ser anexados em formato PDF.'}
              </Label>
            </Row>
            <Margin height={40} />
          </>
        )}

        {!licensingFile &&
          !form.processNumber.value &&
          !form.licensingNumber.value && (
            <>
              <InputTextArea
                label="Caso não possua a licença de obras, justifique.*"
                value={form.licensingJustification.value}
                onChange={(e) =>
                  handleInput(e.target.value, 'licensingJustification')
                }
                error={!!form.licensingJustification.error}
                errorText={form.licensingJustification.error}
                errorPosition="bottom"
                maxLength={500}
                style={{ resize: 'none' }}
              />
              <Margin height={80} />
            </>
          )}

        <SeparatorTitle />
        <Margin height={50} />
        <TextFooter>
          A Iguá se reserva o direito de a qualquer momento promover vistoria de
          inspeção das instalações prediais do imóvel.
        </TextFooter>
        <Margin height={70} />

        {shouldShowAlert && (
          <Content>
            <StatusInfo title="Preencha os campos obrigatórios" status="warning">
              Todos os campos obrigatórios precisam estar preenchidos para dar
              continuidade ao processo.
            </StatusInfo>

            <Margin height={30} />
          </Content>
        )}

        <ButtonBar
          nextBackground={Color.blueSeconde}
          nextDisabled={false}
          nextLoading={!!loadingUploadFile || !!loadingPatch}
          backOnClick={() => Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)}
          nextOnClick={() => handleSubmit()}
          nextLabel={isRevision ? 'Continuar' : 'Aceitar e continuar'}
        />
        <Margin height={50} />
      </Main>
    </>
  );
};

export default Water;
