export enum ErrorTypes {
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  USER_NOT_EXISTS_IN_PARTNERS = 'USER_NOT_EXISTS_IN_PARTNERS',
}

export type IEnumErrorResponse = {
  type: string;
  message?: string;
  status?: number;
};
