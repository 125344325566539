import React, { FC, useState } from 'react';

import { Main } from './styles';
import StatusInfo from '../../components/StatusInfo';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import LoadingComponent from '~/components/Loading';
import { Margin } from '~/components/Margin';
import { Color } from '~/styles';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import ButtonBar from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/PropertyRegistration/components/ButtonBar';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import {
  getDataDownloadFilePropertyRegistrationRequest,
  patchStepLocalPropertyRegistratioRequest,
} from '~/store/modules/propertyRegistration/actions';
import { AppDispatch } from '~/store';

const SettleIn: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const caseFCP = useSelector(
    (item: IState) => item.propertyRegistration.dataItem,
  ) as any;

  const loadingDownloadFile = useSelector(
    (item: IState) => item.propertyRegistration.loadingDownloadFile,
  );

  const [disabled, setDisabled] = useState(true);

  const nextLabel =
    caseFCP?.step.status === stepStatusEnum.SUCCESS ? 'Home' : 'Continuar';

  return (
    <>
      {loadingDownloadFile && <LoadingComponent labelWait="baixando..." />}
      <Main>
        {caseFCP?.step.status === stepStatusEnum.SUCCESS ? (
          <>
            <StatusInfo
              style={{ width: '100%' }}
              status="success"
              onClick={() => {
                if (loadingDownloadFile) return;
                dispatch(
                  getDataDownloadFilePropertyRegistrationRequest({
                    idFile: caseFCP.approvedDocuments.SETTLE_IN || '',
                  }),
                );
                setDisabled(false);
              }}
              labelButton="Baixar habite-se"
            >
              Os documentos foram gerados. Faça o download da documentação antes de
              seguir com sua solicitação.
            </StatusInfo>
            <Margin height={15} />
            <StatusInfo style={{ width: '100%' }} status="warning">
              Sua FCP de
              {caseFCP.type === 'FCPA' ? ' abastecimento ' : ' esgotamento '}
              foi concluída!
            </StatusInfo>
          </>
        ) : (
          <StatusInfo status="wait">
            Os documentos foram enviados para análise. Você pode acompanhar o
            andamento na tela inicial da <b>Viabilidade de Empreendimento</b>.
          </StatusInfo>
        )}
        <Margin height={40} />
        <ButtonBar
          nextBackground={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          nextDisabled={disabled}
          nextLoading={!!loadingDownloadFile}
          backOnClick={() =>
            dispatch(
              patchStepLocalPropertyRegistratioRequest({
                name: '',
                number: 4,
                substep: '',
                status: stepStatusEnum.SUCCESS,
              }),
            )
          }
          nextLabel={nextLabel}
          nextOnClick={() => Navigation.navigate(RouteName.HOME)}
        />
        <Margin height={50} />
      </Main>
    </>
  );
};

export default SettleIn;
