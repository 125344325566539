export enum IEnumCacheConfig {
  ENROLLMENTS_INVOICES_INVOICES_LIST = 'enrollments_invoices_invoices_list',
  ENROLLMENTS_INVOICES_INVOICE_DETAIL = 'enrollments_invoices_invoice_detail',
  ENROLLMENTS_INVOICES_TYPES_INVOICES = 'enrollments_invoices_types_invoices',
  ENROLLMENTS_INVOICES_MATURITY_DAYS = 'enrollments_invoices_maturity_days',
  ENROLLMENTS_INVOICES_MATURITY_DAYS_ALTERNATE = 'enrollments_invoices_maturity_days_alternate',
  ENROLLMENTS_CONSUMPTION_HISTORY_DATA = 'enrollments_consumption_history_data',
  METRIC_REGISTER_AUTH = 'metric_register_auth',
  METRIC_REGISTER_OUTSIDES = 'metric_register_outsides',
  OUTSIDES_ENROLLMENTS_INVOICES_LIST = 'outsides_enrollments_invoices_list',
  OUTSIDES_DEBTS_CHARGES_LIST = 'outsides_debts_charges_list',
  ENROLLMENTS_NEGATIVE_CERTIFICATES_LIST = 'enrollments_negative_certificates_list',
  ENROLLMENTS_NEGATIVE_CERTIFICATES_DOWNLOAD = 'enrollments_negative_certificates_download',
  NEGOTATION_DEBTS_LIST = 'negotation_debts_list',
  ORDER_SERVICES_LIST = 'order_services_list',
  ORDER_SERVICES_INPUTS_LACKWATER_LIST = 'order_services_inputs_lackwater_list',
  SOCIAL_TARIFF_CHECK = 'social_tariff_check',
  SOCIAL_TARIFF_RENOVATION_CHECK = 'social_tariff_renovation_check',
  DPA_DPE_STATUSES = 'dpa_dpe_statuses',
  DPA_DPE_LIST_CASES = 'dpa_dpe_list_cases',
  DPA_DPE_LIST_OPERATIONS = 'dpa_dpe_list_operations',
  DPA_DPE_LIST_SLIPS = 'dpa_dpe_list_slips',
  NOTIFICATIONS_LIST = 'notifications_list',
}
