import React, { FC, useState } from 'react';

import { Main } from './styles';
import StatusInfo from '../../../components/StatusInfo';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import LoadingComponent from '~/components/Loading';
import { Margin } from '~/components/Margin';
import { Color } from '~/styles';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import ButtonBar from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/PropertyRegistration/components/ButtonBar';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import {
  getDataDownloadFilePropertyRegistrationRequest,
  patchStepLocalPropertyRegistratioRequest,
  patchStepPropertyRegistratioRequest,
} from '~/store/modules/propertyRegistration/actions';
import { AppDispatch } from '~/store';

const Status: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const caseFCP = useSelector((item: IState) => item.propertyRegistration.dataItem);

  const loadingDownloadFile = useSelector(
    (item: IState) => item.propertyRegistration.loadingDownloadFile,
  );

  const loadingPatch = useSelector(
    (item: IState) => item.propertyRegistration.loadingPatch,
  );

  const [disabled, setDisabled] = useState(true);

  const handleClick = () => {
    if (caseFCP?.realStep?.number === 3) {
      return dispatch(
        patchStepPropertyRegistratioRequest({
          case: caseFCP?.id || '',
          step: 'FOUR',
        }),
      );
    }

    return dispatch(
      patchStepLocalPropertyRegistratioRequest({
        name: '',
        number: 4,
        substep: '',
        status:
          caseFCP?.realStep?.number === 4
            ? caseFCP?.realStep.status
            : stepStatusEnum.SUCCESS,
      }),
    );
  };

  return (
    <>
      {loadingDownloadFile && <LoadingComponent labelWait="baixando..." />}
      <Main>
        {caseFCP?.step?.status === stepStatusEnum.SUCCESS && (
          <StatusInfo
            status="success"
            onClick={() => {
              if (loadingDownloadFile) return;

              dispatch(
                getDataDownloadFilePropertyRegistrationRequest({
                  idFile:
                    (caseFCP.type === 'FCPA'
                      ? caseFCP.approvedDocuments?.PROJECT_APPROVED_SUPPLY
                      : caseFCP.approvedDocuments?.PROJECT_APPROVED_EXHAUSTION) ||
                    '',
                }),
              );
              setDisabled(false);
            }}
            labelButton={
              caseFCP.type === 'FCPA'
                ? 'Baixar projeto de abastecimento aprovado'
                : 'Baixar projeto de esgotamento aprovado'
            }
            title={
              caseFCP.type === 'FCPA'
                ? 'Projeto de abastecimento aprovado'
                : 'Projeto de esgotamento aprovado'
            }
          >
            Os documentos foram gerados. Faça o download da documentação antes de
            seguir com sua solicitação.
          </StatusInfo>
        )}
        {caseFCP?.step?.status === stepStatusEnum.WAIT && (
          <StatusInfo status="wait">
            Os documentos foram enviados para análise. Você pode acompanhar o
            andamento na tela inicial da <b>Viabilidade de Empreendimento</b>.
          </StatusInfo>
        )}
        <Margin height={40} />
        <ButtonBar
          nextBackground={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          nextDisabled={disabled}
          nextLoading={!!loadingDownloadFile || !!loadingPatch}
          backOnClick={() => Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)}
          nextOnClick={handleClick}
        />
        <Margin height={50} />
      </Main>
    </>
  );
};

export default Status;
