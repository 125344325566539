import styled from 'styled-components';
import { colors } from '~/design_system/styles/colors';
import { Breakpoints } from '~/styles';

interface ICardStyledProps {
  maxWidth?: number;
  doubleGap?: boolean;
}

export const CardStyled = styled.div<ICardStyledProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 24px;
  gap: ${({ doubleGap }) => (doubleGap ? '32px' : '16px')};
  background: ${colors.white};
  box-shadow:
    0px 0px 8px 2px rgba(44, 144, 160, 0.1),
    0px 0px 24px 8px rgba(39, 126, 140, 0.06);
  border-radius: 16px;
  max-width: ${({ maxWidth }) =>
    maxWidth ? `calc(${maxWidth}px - 24px)` : 'calc(100% - 24px)'};

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 40px;
    max-width: ${({ maxWidth }) =>
      maxWidth ? `calc(${maxWidth}px - 80px)` : undefined};
  }
`;

export const TitleText = styled.h1`
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.grey_100};
`;

export const DescriptionText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: ${colors.grey_50};
  text-align: left;
`;
