import { EndpointBuilder, BaseQueryFn } from '@reduxjs/toolkit/query';
import { IEnumCacheConfig } from '../enum';
import { IResponseListNotifications } from '~/store/modules/notifications/types';

export const notificationsEndpoints = (
  builder: EndpointBuilder<BaseQueryFn, IEnumCacheConfig, 'cache'>,
) => ({
  fetchNotificationsList: builder.query<
    IResponseListNotifications,
    { enrollment: string }
  >({
    query: ({ enrollment }) => ({
      url: `v3/notifications`,
      method: 'GET',
      params: { enrollment },
    }),
    providesTags: [IEnumCacheConfig.NOTIFICATIONS_LIST],
  }),
});
