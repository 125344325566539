import React, { useEffect } from 'react';
import {
  IFieldAgrgregatort,
  IFieldObject,
} from '~/store/modules/dpadpeDataMenager/types';

import CharactersInput from '../CharactersInput';
import { IPropsForm } from '../typesForm';

interface IProps extends IPropsForm {
  fields: IFieldAgrgregatort[] | IFieldObject[];
  inputs?: IFieldObject[];
  setInputs: (e: IFieldObject[]) => void;
  onValidate: () => void;
}

const ObjectInputs: React.FC<IProps> = ({
  fields,
  inputs,
  setInputs,
  errors,
  touched,
  onValidate,
}) => {
  useEffect(() => {
    setInputs(
      fields.filter(
        (field): field is IFieldObject =>
          'key' in field && 'label' in field && 'value' in field,
      ),
    );
  }, [fields, setInputs]);

  return (
    <CharactersInput
      inputs={inputs}
      setInputs={setInputs}
      background="rgba(170, 215, 255, 0.5)"
      fields={fields}
      errors={errors}
      touched={touched}
      onValidate={() => onValidate()}
    />
  );
};

export default ObjectInputs;
