import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Main } from './styles';
import { Margin } from '~/components/Margin';
import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import { postErrorCloseSocialTariffAttachmentsRequest } from '~/store/modules/socialTariff/actions';
import { AppDispatch } from '~/store';

interface IProps {
  setOpen: (status: boolean) => void;
}

const ModalError: FC<IProps> = ({ setOpen }) => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Main>
      <Margin height={39} />
      <p>
        Não foi possivel processar sua solicitação. <br />
        Por favor tente mais tarde!
      </p>
      <Margin height={70} />
      <ButtonText
        text="Entendi"
        typeBtn="content"
        backgroundColor={Color.blueSeconde}
        Color={Color.white}
        onClick={() => {
          dispatch(postErrorCloseSocialTariffAttachmentsRequest());
          setOpen(false);
        }}
        style={{ maxWidth: '340px', height: '68px' }}
      />
    </Main>
  );
};

export default ModalError;
