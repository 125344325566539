import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { IState } from '~/store/modules/types';
import PaginationDefault from '~/components/Pagination';

interface IPaginationProps {
  handleGetData: (page: number) => void;
}

const Pagination: FC<IPaginationProps> = ({ handleGetData }) => {
  const pagination = useSelector(
    (item: IState) => item.outsides.easyInvoices?.pagination,
  ) || { totalItens: 0, maxPages: 0, page: 0 };
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    handleGetData(page);
  }, [page]);

  return <PaginationDefault setPage={setPage} pagination={pagination} />;
};

export default Pagination;
