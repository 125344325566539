import { Dispatch } from 'redux';
import {
  getSocialTariffService,
  postSocialTariffCreateService,
} from '~/services/socialTariff';
import { IState } from '../types';
import {
  RepositoriesTypesSocialTariff,
  IRequestPostCreate,
  IResponsePostCreate,
  IResponseGetSocialTariff,
} from './types';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import {
  fetchSocialTariffCheck,
  fetchSocialTariffRenovationCheck,
} from '~/store/cache/apiCache';
import { AppDispatch } from '~/store';

const getSocialTariffRequest =
  () => async (dispatch: AppDispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_METHOD_REQUEST,
      });

      const { enrollmentsAuth } = getState() as IState;

      const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

      const response = await dispatch(
        fetchSocialTariffCheck.initiate({ registration }),
      );

      if (response.error) throw response.error;

      return dispatch({
        type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_METHOD_SUCCESS,
        payload: {
          data: response?.data,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.status,
            type: error?.data?.type,
          },
        },
      });
    }
  };

const postSocialTariffAttachmentsRequest =
  (data: IRequestPostCreate, isCuiaba?: boolean) =>
  async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_REQUEST,
      });

      const responseCreate = (await postSocialTariffCreateService(
        data,
      )) as IResponsePostCreate;

      if (isCuiaba) {
        const { enrollmentsAuth } = getState() as IState;

        const registration = enrollmentsAuth?.registrationCheck?.enrollment || '';

        const response = (await getSocialTariffService(
          registration,
        )) as IResponseGetSocialTariff;

        dispatch({
          type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_METHOD_SUCCESS,
          payload: {
            data: response,
          },
        });

        Navigation.navigate(RouteName.SOCIALTARIFFREQUEST, {
          ...response,
        });

        return dispatch({
          type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_SUCCESS,
        });
      }

      return dispatch({
        type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_SUCCESS,
        payload: {
          data: responseCreate,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const postErrorCloseSocialTariffAttachmentsRequest =
  () => async (dispatch: Dispatch) => {
    dispatch({
      type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_CLOSE,
    });
  };

const getRenovationSocialTariffRequest =
  (enrollment: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_RENOVATION_METHOD_REQUEST,
      });

      const response = await dispatch(
        fetchSocialTariffRenovationCheck.initiate({ registration: enrollment }),
      );

      if (response.error) throw response.error;

      return dispatch({
        type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_RENOVATION_METHOD_SUCCESS,
        payload: {
          needRenovation: response?.data?.needRenovation,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_GET_RENOVATION_METHOD_SUCCESS,
        payload: {
          needRenovation: false,
        },
      });
    }
  };

const viewedBannerSocialTariffRequest = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesSocialTariff.SOCIAL_TARIFF_VIEWED_BANNER_METHOD_SUCCESS,
  });
};

export {
  getSocialTariffRequest,
  postSocialTariffAttachmentsRequest,
  postErrorCloseSocialTariffAttachmentsRequest,
  getRenovationSocialTariffRequest,
  viewedBannerSocialTariffRequest,
};
