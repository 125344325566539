import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const ContainerInvoicePixCode = styled.div<{ hasAlert?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: ${({ hasAlert }) => (hasAlert ? '16px' : '40px')};
`;

export const TextInvoicePixCode = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${Color.grayDark};
  line-height: 24px;
  margin-right: auto;
`;

export const LabelBox = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${Color.realBlack};
  margin-top: 20px;
  margin-bottom: 4px;
`;

export const ContainerBox = styled.div`
  min-height: 36px;
  display: flex;
  align-items: center;
  border: 1px solid #999999;
  border-radius: 10px;
  padding: 0 20px 0 10px;
`;

export const TextBox = styled.p`
  height: 15px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
`;

export const AlertFraudBox = styled.div`
  margin: 0 auto;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    margin: 0;
  }
`;
