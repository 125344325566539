import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, BoxDescription, BoxTitle, Wrapper } from './styles';
import { AppIcon, DetailedHistoryIcon, SocialTariffIcon } from '../Icons';
import { getStoreUrlByOS } from '~/utils/outside';
import AutomaticDebitSVG from '~/assets/Icons/AutomaticDebit';
import { Color } from '~/styles';
import { useDispatch } from 'react-redux';
import { RouteName } from '~/routes/Routes.name';
import { setRouteAuthRedirect } from '~/store/modules/enrollmentsAuth/actions';
import { AppDispatch } from '~/store';

const UsefulLinks: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const links = [
    {
      title: 'Débito automático',
      description: 'Solicite o cadastro do benefício',
      icon: <AutomaticDebitSVG color={Color.blueIntense} />,
      active: false,
      url: RouteName.AUTOMATICDEBIT,
    },
    {
      title: 'Histórico detalhado',
      description: 'Tenha o controle do seu consumo',
      icon: <DetailedHistoryIcon />,
      active: false,
      url: RouteName.DETAILEDHISTORY,
    },
    {
      title: 'Consulta de faturas',
      description: 'Fatura detalhada e parcelamento no cartão de crédito',
      icon: <SocialTariffIcon />,
      active: false,
      last: true,
      url: RouteName.INVOICES,
    },
    {
      title: 'Baixe o APP',
      description: 'Nossos serviços na palma da sua mão!',
      icon: <AppIcon />,
      active: true,
      url: getStoreUrlByOS(),
    },
  ];

  return (
    <Wrapper>
      {links.map((item) =>
        item.active ? (
          <Box
            key={item.title}
            href={item.url}
            active={+item.active}
            target="_blank"
          >
            {item.icon}
            <BoxTitle>{item.title}</BoxTitle>
            <BoxDescription active={item.active}>{item.description}</BoxDescription>
          </Box>
        ) : (
          <Box
            as={Link}
            key={item.title}
            to="/login"
            active={+item.active}
            style={{ marginRight: item.last ? 0 : undefined }}
            onClick={() => {
              dispatch(setRouteAuthRedirect(item?.url));
            }}
          >
            {item.icon}
            <BoxTitle>{item.title}</BoxTitle>
            <BoxDescription>{item.description}</BoxDescription>
          </Box>
        ),
      )}
    </Wrapper>
  );
};

export { UsefulLinks };
