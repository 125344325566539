import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import configsAppJsonImport from '~/configs/app.config';
import { ContainerButtons, ContainerRecaptcha, Wrapper } from './styles';
import { Button } from '../Button';
import { Dialog } from '../Dialog';
import { RecaptchaSVG } from '~/assets/Icons/Recaptcha';

const configsAppJson = configsAppJsonImport as any;

interface IRecaptchaProps {
  onClose: () => void;
  setRecaptchaTokenV3: Dispatch<SetStateAction<string | null>>;
  setRecaptchaTokenV2: Dispatch<SetStateAction<string | null>>;
  showRecaptchaV2: boolean;
}

declare global {
  interface Window {
    grecaptcha: {
      execute: (siteKey: string, options: { action: string }) => Promise<string>;
      render: (container: string | HTMLElement, parameters: any) => number;
      reset: (widgetId?: number) => void;
      getResponse: (widgetId?: number) => string;
      ready: (func?: any) => void;
    };
    onloadCallback: () => void;
  }
}

export const Recaptcha: FC<IRecaptchaProps> = ({
  onClose,
  setRecaptchaTokenV3,
  setRecaptchaTokenV2,
  showRecaptchaV2,
}) => {
  useEffect(() => {
    window.onloadCallback = () => {
      window?.grecaptcha?.ready(() => {
        handleV3Verification();
      });
    };

    const handleV3Verification = async () => {
      if (window.grecaptcha) {
        const token = await window?.grecaptcha?.execute(
          '6LfiaxcqAAAAAN75Jnj_VlKnLgfljeY50QXrl9jc',
          { action: 'submit' },
        );

        setRecaptchaTokenV3(token);
      }
    };

    if (window?.grecaptcha) {
      window?.onloadCallback();
    } else {
      window?.addEventListener('load', window?.onloadCallback);
    }

    return () => {
      window?.removeEventListener('load', window?.onloadCallback);
    };
  }, []);

  const handleV2Verification = (token: string | null) => {
    setRecaptchaTokenV2(token);
    onClose();
  };

  return showRecaptchaV2 ? (
    <Dialog open onClose={onClose}>
      <Wrapper>
        <div>
          <RecaptchaSVG />
        </div>
        <h1>Não sou um robô</h1>
        <p>Para seguir, preencha uma breve validação de segurança.</p>
        <ContainerButtons>
          <ContainerRecaptcha>
            <ReCAPTCHA
              sitekey={configsAppJson?.GOOGLE_RECAPTCHA[configsAppJson?.NODE_ENV]}
              onErrored={() => setRecaptchaTokenV2('')}
              onExpired={() => setRecaptchaTokenV2('')}
              onChange={handleV2Verification}
              size="normal"
            />
          </ContainerRecaptcha>
          <Button text="Entendi" type="button" onClick={onClose} />
        </ContainerButtons>
      </Wrapper>
    </Dialog>
  ) : null;
};
