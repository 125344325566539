import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { ContainerButtons, Wrapper } from './styles';

import { Button } from '~/components/Button';
import { InfoSVG } from '~/assets/Icons';
import { clearModalNotClient } from '~/store/modules/enrollmentsUsersNotClient/actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '~/store';

const ModalNotClient: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    ReactGA.event({
      category: 'Recadastramento',
      action: `[Cliente Não Saneamento] – Vincular Matricula – Troca de Titularidade`,
    });
  }, []);

  return (
    <Wrapper>
      <InfoSVG />
      <h1>Atenção</h1>
      <p>
        Essa matrícula possui outro CPF/CNPJ como titular. Apenas o titular poderá
        acessar os dados dessa conta.
        <br /> <br />
        Para alterar a titularidade, acesse o Fale conosco.
      </p>
      <ContainerButtons>
        <Button
          modalType="error"
          text="Cancelar"
          type="button"
          onClick={() => dispatch(clearModalNotClient())}
        />
      </ContainerButtons>
    </Wrapper>
  );
};

export default ModalNotClient;
