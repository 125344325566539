import styled from 'styled-components';
import { Color, Breakpoints } from '~/styles';

interface Props {
  isActive?: boolean;
  stepPosition?: number;
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  padding: 24px 0;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    padding: 24px 0 16px 0;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    padding: 24px 0;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const StepNumber = styled.div<Props>`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  background: ${(p) =>
    p.isActive && p.stepPosition === 3
      ? Color.green
      : p.isActive && p.stepPosition !== 3
        ? Color.blueIntense
        : Color.gray};
  border-radius: 36px;
`;

export const StepLabel = styled.div<Props>`
  position: absolute;
  top: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${(p) =>
    p.isActive && p.stepPosition === 3
      ? Color.green
      : p.isActive && p.stepPosition !== 3
        ? Color.blueIntense
        : Color.gray};
  font-size: 16px;
  font-weight: ${(p) => (p.isActive ? 700 : 500)};
  line-height: 150%;
`;

export const StepDivider = styled.div<Props>`
  margin-top: 16px;
  width: 100%;
  height: 3px;
  background: ${(p) => (p.isActive ? Color.blueIntense : Color.gray)};
`;
