import React, { FC, useEffect, useState } from 'react';
import { Input } from '~/components/Input';
import { Margin } from '~/components/Margin';
import {
  ContainerTitle,
  Label,
  Main,
  Row,
  SeparatorTitle,
  Title,
  Subtitle,
  TextFooter,
  Column,
  DescriptionTitle,
  CheckboxListContaner,
  CheckboxList,
  SubtitleBold,
  Content,
} from './styles';
import { InputTextArea } from '~/components/InputTextArea';
import DropZone from '../../../components/DropZone';
import { Color } from '~/styles';
import ButtonBar from '../../../components/ButtonBar';
import Table from './Table';
import formSchema from './form.json';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { validateEmail, validateForm, validateIsEmpty } from '~/utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import {
  patchInformationPropertyRegistrationRequest,
  postUploadFilePropertyRegistrationRequest,
} from '~/store/modules/propertyRegistration/actions';
import convertFileToBase64 from '~/utils/convertBase64';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';
import StatusInfo from '~/views/SupplyAndDepletionPossibilityDeclaration/components/StatusInfo';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import { AppDispatch } from '~/store';

interface IPropsForm {
  value: string;
  isRequired: boolean;
  error: string;
}

type typeForm = {
  [key: string]: IPropsForm;
};

const Sewage: FC = () => {
  const [form, setForm] = useState<typeForm>(formSchema as typeForm);
  const [licensingFile, setLicensingFile] = useState<File | null>(null);
  const [licensingFileError, setLicensingFileError] = useState<boolean>(false);
  const [shouldShowAlert, setShouldShowAlert] = useState<boolean>(false);
  const caseId = useSelector(
    (item: IState) => item.propertyRegistration.dataItem?.id,
  );
  const caseFCP = useSelector((item: IState) => item.propertyRegistration.dataItem);
  const fcpDetails = useSelector(
    (item: IState) => item.propertyRegistration.dataItem?.details?.fcp,
  );

  const [isRevision, setIsRevision] = useState<boolean>(false);
  const [shouldAttachDocument, setShouldAttachDocument] = useState<boolean>(true);
  const [revisionDescription, setRevisionDescription] = useState<string>('');

  const loadingUploadFile = useSelector(
    (item: IState) => item.propertyRegistration.loadingUploadFile,
  );
  const loadingPatch = useSelector(
    (item: IState) => item.propertyRegistration.loadingPatch,
  );

  const [formBoolean, setFormBoolean] = useState<{
    hasWashingMachine: boolean;
    hasWashingTank: boolean;
    hasKitchen: boolean;
    accordingly: boolean;
  }>({
    hasWashingMachine: false,
    hasWashingTank: false,
    hasKitchen: false,
    accordingly: false,
  });

  const dispatch = useDispatch<AppDispatch>();

  const handleInput = (valueInput: string, input: string) => {
    let error = '';
    let value = valueInput;

    if (form[input].isRequired && value.length === 0) {
      error = 'Campo obrigatório';
    }

    if (
      input.includes('Number') ||
      ['toiletsNumber', 'internalHouseholdExtensionDiameter'].includes(input)
    ) {
      value = valueInput.replace(/[^0-9]/g, '');
    }

    if (input.includes('Name')) {
      value = valueInput.replace(/[^A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]/g, '');
    }

    if (input === 'dateDeliveryImovel') {
      error = !value.length
        ? 'Campo obrigatório'
        : value.length !== 10
          ? 'Campo inválido'
          : '';
    }

    if (
      input === 'propertyTechnicalResponsiblePhone' &&
      valueInput.replace(/[^0-9]/g, '').length < 11
    ) {
      error = !valueInput.replace(/[^0-9]/g, '').length
        ? 'Campo obrigatório'
        : valueInput.replace(/[^0-9]/g, '').length < 11
          ? 'Campo inválido'
          : '';
    }

    if (input === 'propertyTechnicalResponsiblePhone') {
      value =
        formatter.formatarPhone(valueInput.replace(/[^0-9]/g, '')) ||
        valueInput.replace(/[^0-9]/g, '');
    }

    if (input === 'licensingJustification') {
      setLicensingFileError(false);

      return setForm((prevState) => ({
        ...prevState,
        licensingNumber: {
          ...prevState.licensingNumber,
          error: '',
        },
        processNumber: {
          ...prevState.processNumber,
          error: '',
        },

        [input]: {
          value,
          error,
          isRequired: prevState[input].isRequired,
        },
      }));
    }

    if (input === 'licensingNumber')
      return setForm((prevState) => ({
        ...prevState,
        licensingNumber: {
          value,
          error,
          isRequired:
            value.length > 0 ||
            form.processNumber.value.length > 0 ||
            !!licensingFile,
        },
        licensingJustification: {
          value: '',
          error: '',
          isRequired:
            (shouldAttachDocument && !licensingFile) ||
            value.length < 1 ||
            form.processNumber.value.length < 1,
        },
      }));

    if (input === 'processNumber') {
      return setForm((prevState) => ({
        ...prevState,
        processNumber: {
          value,
          error,
          isRequired:
            value.length > 0 ||
            form.licensingNumber.value.length > 0 ||
            !!licensingFile,
        },
        licensingJustification: {
          value: '',
          error: '',
          isRequired:
            (shouldAttachDocument && !licensingFile) ||
            value.length < 1 ||
            form.licensingNumber.value.length < 1,
        },
      }));
    }

    return setForm((prevState) => ({
      ...prevState,
      [input]: {
        value,
        error,
        isRequired: prevState[input].isRequired,
      },
    }));
  };

  const isFormValid = (): boolean => {
    const inputsWithError = validateForm(form);
    let hasError = false;

    Object.entries(formBoolean).forEach((allInput) => {
      if (allInput[1] !== true && allInput[1] !== false) {
        hasError = true;
        setShouldShowAlert(true);
      }
    });
    Object.keys(form).forEach((inputName) => {
      if (form[inputName].error && form[inputName].isRequired) {
        hasError = true;
        setShouldShowAlert(true);
      }
    });
    Object.entries(form).forEach((allInput) => {
      if (allInput[0] === 'licensingNumber' || allInput[0] === 'processNumber') {
        if (
          (licensingFile ||
            form.licensingNumber.value.length > 0 ||
            form.processNumber.value.length > 0) &&
          allInput[1].value.length === 0
        ) {
          setForm((prevState) => ({
            ...prevState,
            [allInput[0]]: {
              ...prevState[allInput[0]],
              error: 'Campo obrigatório',
            },
          }));
        }
        if (shouldAttachDocument) {
          const hasFileError =
            (form.licensingNumber.value.length > 0 ||
              form.processNumber.value.length > 0) &&
            !licensingFile;

          if (hasFileError) hasError = true;
          setLicensingFileError(hasFileError);
        }
      }
    });

    setShouldShowAlert(hasError);

    return validateIsEmpty(inputsWithError) && !hasError;
  };

  const handleSubmit = () => {
    Object.entries(form).forEach((allInput) => {
      handleInput(allInput[1].value, allInput[0]);
    });

    setShouldShowAlert(!isFormValid());

    if (isFormValid()) {
      setShouldShowAlert(false);
      dispatch(
        patchInformationPropertyRegistrationRequest(
          caseId!,
          {
            fcpId: fcpDetails?.id,
            isRevision,
            licensing: {
              processNumber: form.processNumber.value,
              licenseNumber: form.licensingNumber.value,
              justification: !licensingFile
                ? form.licensingJustification.value
                : undefined,
            },
            involvedData: {
              technicalManagerFCP: {
                name: form.technicalManagerFCPName.value,
                email: form.technicalManagerFCPEmail.value,
              },
              workPermitApplicant: {
                name: form.workPermitApplicantName.value,
                email: form.workPermitApplicantEmail.value,
              },
              propertyTechnicalResponsible: {
                name: form.propertyTechnicalResponsibleName.value,
                phone: form.propertyTechnicalResponsiblePhone.value,
                address: form.propertyTechnicalResponsibleAddress.value,
                email: form.propertyTechnicalResponsibleEmail.value,
              },
              installationsExecutionTechnicalResponsible: {
                name: form.installationsExecutionTechnicalResponsibleName.value,
                email: form.installationsExecutionTechnicalResponsibleEmail.value,
              },
            },
            projectInformation: {
              dateDeliveryImovel: form.dateDeliveryImovel.value,
              toiletsNumber: form.toiletsNumber.value,
              hasKitchen: formBoolean.hasKitchen,
              hasWashingTank: formBoolean.hasWashingTank,
              hasWashingMachine: formBoolean.hasWashingMachine,
              internalHouseholdExtension: {
                diameter: form.internalHouseholdExtensionDiameter.value,
                justification:
                  parseFloat(
                    form.internalHouseholdExtensionDiameter.value.replace(',', '.'),
                  ) > 100
                    ? form.internalHouseholdExtensionJustification.value
                    : undefined,
              },
              greaseTrap: {
                length: form.greaseTrapLength.value,
                width: form.greaseTrapWidth.value,
                depth: form.greaseTrapDepth.value,
              },
              soapBox: {
                length: form.soapBoxLength.value,
                width: form.soapBoxWidth.value,
                depth: form.soapBoxDepth.value,
              },
              internalInspectionBox: {
                length: form.internalInspectionBoxLength.value,
                width: form.internalInspectionBoxWidth.value,
                depth: form.internalInspectionBoxDepth.value,
              },
              rainWaterTank: {
                length: form.irainWaterTankLength.value,
                width: form.irainWaterTankWidth.value,
                depth: form.irainWaterTankDepth.value,
              },
            },
            accordingly: formBoolean.accordingly,
            type: 'FCPE',
          },
          !!licensingFile,
        ),
      );
    }
  };

  useEffect(() => {
    if (!isRevision) return setShouldAttachDocument(true);

    const workLicenseFile = caseFCP?.attachments?.find(
      (attachment) => attachment.type === 'WORKS_LICENSE',
    );

    if (!workLicenseFile || (workLicenseFile && workLicenseFile.review)) {
      setRevisionDescription(workLicenseFile?.review_description || '');
      return setShouldAttachDocument(true);
    }

    return setShouldAttachDocument(false);
  }, [isRevision, caseFCP]);

  useEffect(() => {
    setIsRevision(caseFCP?.step?.status === stepStatusEnum.REVIEW);
  }, [caseFCP]);

  useEffect(() => {
    if (isRevision) {
      setFormBoolean({
        hasWashingMachine:
          fcpDetails?.existingElements?.includes('Máquina de lavar'),
        hasWashingTank: fcpDetails?.existingElements?.includes('Tanque'),
        hasKitchen: fcpDetails?.existingElements?.includes('Cozinha'),
        accordingly: true,
      });
      setForm((prevState) => ({
        ...prevState,
        dateDeliveryImovel: {
          ...prevState.dateDeliveryImovel,
          value: fcpDetails?.deliveryDate || '',
        },
        toiletsNumber: {
          ...prevState.toiletsNumber,
          value: fcpDetails?.numberOfToilets || '',
        },
        internalHouseholdExtensionDiameter: {
          ...prevState.internalHouseholdExtensionDiameter,
          value: fcpDetails?.internalHouseholdExtensionDiameter || '',
        },
        internalHouseholdExtensionJustification: {
          ...prevState.internalHouseholdExtensionJustification,
          value: fcpDetails?.justificationForDNOver100mm || '',
        },
        greaseTrapLength: {
          ...prevState.greaseTrapLength,
          value: fcpDetails.greaseTrap.length || '',
        },
        greaseTrapWidth: {
          ...prevState.greaseTrapWidth,
          value: fcpDetails.greaseTrap.width || '',
        },
        greaseTrapDepth: {
          ...prevState.greaseTrapDepth,
          value: fcpDetails.greaseTrap.depth || '',
        },
        soapBoxLength: {
          ...prevState.soapBoxLength,
          value: fcpDetails.soapBox.length || '',
        },
        soapBoxWidth: {
          ...prevState.soapBoxWidth,
          value: fcpDetails.soapBox.width || '',
        },
        soapBoxDepth: {
          ...prevState.soapBoxDepth,
          value: fcpDetails.soapBox.depth || '',
        },
        internalInspectionBoxLength: {
          ...prevState.internalInspectionBoxLength,
          value: fcpDetails.internalInspectionBox.length || '',
        },
        internalInspectionBoxWidth: {
          ...prevState.internalInspectionBoxWidth,
          value: fcpDetails.internalInspectionBox.width || '',
        },
        internalInspectionBoxDepth: {
          ...prevState.internalInspectionBoxDepth,
          value: fcpDetails.internalInspectionBox.depth || '',
        },
        irainWaterTankLength: {
          ...prevState.irainWaterTankLength,
          value: fcpDetails.rainWaterTank.length || '',
        },
        irainWaterTankWidth: {
          ...prevState.irainWaterTankWidth,
          value: fcpDetails.rainWaterTank.width || '',
        },
        irainWaterTankDepth: {
          ...prevState.irainWaterTankDepth,
          value: fcpDetails.rainWaterTank.depth || '',
        },
        propertyTechnicalResponsibleName: {
          ...prevState.propertyTechnicalResponsibleName,
          value: fcpDetails.propertyTechnicalResponsible.name || '',
        },
        propertyTechnicalResponsiblePhone: {
          ...prevState.propertyTechnicalResponsiblePhone,
          value: fcpDetails.propertyTechnicalResponsible.phone || '',
        },
        propertyTechnicalResponsibleAddress: {
          ...prevState.propertyTechnicalResponsibleAddress,
          value: fcpDetails.propertyTechnicalResponsible.address || '',
        },
        propertyTechnicalResponsibleEmail: {
          ...prevState.propertyTechnicalResponsibleEmail,
          value: fcpDetails.propertyTechnicalResponsible.email || '',
        },
        workPermitApplicantEmail: {
          ...prevState.workPermitApplicantEmail,
          value: fcpDetails.workPermitApplicant.email || '',
        },
        workPermitApplicantName: {
          ...prevState.workPermitApplicantName,
          value: fcpDetails.workPermitApplicant.name || '',
        },
        technicalManagerFCPName: {
          ...prevState.technicalManagerFCPName,
          value: fcpDetails.technicalManagerFCP.name || '',
        },
        technicalManagerFCPEmail: {
          ...prevState.technicalManagerFCPEmail,
          value: fcpDetails.technicalManagerFCP.email || '',
        },
        installationsExecutionTechnicalResponsibleName: {
          ...prevState.installationsExecutionTechnicalResponsibleName,
          value: fcpDetails.installationsExecutionTechnicalResponsible.name || '',
        },
        installationsExecutionTechnicalResponsibleEmail: {
          ...prevState.installationsExecutionTechnicalResponsibleEmail,
          value: fcpDetails.installationsExecutionTechnicalResponsible.email || '',
        },
        processNumber: {
          ...prevState.processNumber,
          value: fcpDetails.licensing.processNumber || '',
        },
        licensingNumber: {
          ...prevState.licensingNumber,
          value: fcpDetails.licensing.licenseNumber || '',
        },
        licensingJustification: {
          ...prevState.licensingJustification,
          value: fcpDetails.licensing.justification || '',
        },
      }));
    }
  }, [isRevision]);

  useEffect(() => {
    setForm((prevState) => ({
      ...prevState,
      internalHouseholdExtensionJustification: {
        value: prevState.internalHouseholdExtensionJustification.value,
        error: prevState.internalHouseholdExtensionJustification.error,
        isRequired:
          parseFloat(
            form.internalHouseholdExtensionDiameter.value.replace(',', '.'),
          ) > 100,
      },
      licensingJustification: {
        value: prevState.licensingJustification.value,
        error: prevState.licensingJustification.error,
        isRequired: !licensingFile,
      },
    }));
  }, [form.internalHouseholdExtensionDiameter.value, licensingFile]);

  return (
    <>
      <Main>
        {isRevision && (
          <>
            <StatusInfo style={{ width: 'calc(100% - 50px)' }} status="warning">
              As informações precisam ser revisadas. Por favor, preencha novamente.
            </StatusInfo>
            <Margin height={40} />

            {caseFCP?.reviewDescription && (
              <>
                <Label
                  style={{
                    width: '100%',
                    color: Color.red,
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  {caseFCP?.reviewDescription}
                </Label>
                <Margin height={40} />
              </>
            )}
          </>
        )}
        <DescriptionTitle>
          Nesta etapa é necessário o anexo de todos os documentos e informações
          solicitados a seguir para seguir com o processo.
        </DescriptionTitle>
      </Main>
      <Margin height={64} />

      <ContainerTitle>
        <SeparatorTitle />
        <Title>Informações do projeto</Title>
      </ContainerTitle>
      <Margin height={50} />
      <Main>
        <Row divider="40">
          <Input
            label="Data de entrega do imóvel:*"
            type="date"
            value={form.dateDeliveryImovel.value}
            onChange={(e) => handleInput(e.target.value, 'dateDeliveryImovel')}
            error={!!form.dateDeliveryImovel.error}
            errorText={form.dateDeliveryImovel.error}
            errorPosition="bottom"
            maxLength={10}
          />
          <Label>
            Neste campo você deve preencher a data em que a chave do imóvel será
            entregue aos moradores e sua ligação de água e esgoto se tornará
            definitiva.
          </Label>
        </Row>
        <Margin height={35} />
        <Row divider="40">
          <Input
            label="Número de vasos sanitários do imóvel:*"
            onChange={(e) => handleInput(e.target.value, 'toiletsNumber')}
            value={form.toiletsNumber.value}
            error={!!form.toiletsNumber.error}
            errorText={form.toiletsNumber.error}
            errorPosition="bottom"
          />
        </Row>
        <Margin height={40} />

        <Subtitle>Os elementos abaixo são existentes no imóvel?</Subtitle>
        <Margin height={40} />
        <CheckboxListContaner>
          <CheckboxList
            type="checkbox"
            checked={formBoolean.hasKitchen}
            onClick={() => {
              setFormBoolean((prevState) => ({
                ...prevState,
                hasKitchen: !prevState.hasKitchen,
              }));
              setForm((prevState) => ({
                ...prevState,
                greaseTrapLength: {
                  ...prevState.greaseTrapLength,
                  isRequired: true,
                },
                greaseTrapWidth: {
                  ...prevState.greaseTrapWidth,
                  isRequired: true,
                },
                greaseTrapDepth: {
                  ...prevState.greaseTrapDepth,
                  isRequired: true,
                },
              }));
            }}
          />
          <p>Cozinha</p>
        </CheckboxListContaner>
        <CheckboxListContaner>
          <CheckboxList
            type="checkbox"
            checked={formBoolean.hasWashingTank}
            onClick={() => {
              setFormBoolean((prevState) => ({
                ...prevState,
                hasWashingTank: !prevState.hasWashingTank,
              }));
              setForm((prevState) => ({
                ...prevState,
                soapBoxLength: {
                  ...prevState.soapBoxLength,
                  isRequired: true,
                },
                soapBoxWidth: {
                  ...prevState.soapBoxWidth,
                  isRequired: true,
                },
                soapBoxDepth: {
                  ...prevState.soapBoxDepth,
                  isRequired: true,
                },
              }));
            }}
          />
          <p>Tanque</p>
        </CheckboxListContaner>
        <CheckboxListContaner>
          <CheckboxList
            type="checkbox"
            checked={formBoolean.hasWashingMachine}
            onClick={() => {
              setFormBoolean((prevState) => ({
                ...prevState,
                hasWashingMachine: !prevState.hasWashingMachine,
              }));
              setForm((prevState) => ({
                ...prevState,
                internalInspectionBoxLength: {
                  ...prevState.internalInspectionBoxLength,
                  isRequired: true,
                },
                internalInspectionBoxWidth: {
                  ...prevState.internalInspectionBoxWidth,
                  isRequired: true,
                },
                internalInspectionBoxDepth: {
                  ...prevState.internalInspectionBoxDepth,
                  isRequired: true,
                },
              }));
            }}
          />
          <p>Máquina de lavar</p>
        </CheckboxListContaner>
        <Input
          style={{ width: 'calc(40% - 95px)', paddingRight: '95px' }}
          styleLabelError={{ width: '40%' }}
          label="Diâmetro do ramal domiciliar interno a ser ligado à caixa de inspeção externa (mm)*"
          textInsideInput="Milímetros"
          rigthTextInsideInput="60%"
          colorTextInsideInput={Color.grayLightSeconde}
          onChange={(e) =>
            handleInput(e.target.value, 'internalHouseholdExtensionDiameter')
          }
          value={form.internalHouseholdExtensionDiameter.value}
          error={!!form.internalHouseholdExtensionDiameter.error}
          errorText={form.internalHouseholdExtensionDiameter.error}
          errorPosition="bottom"
        />
        {parseFloat(
          form.internalHouseholdExtensionDiameter.value.replace(',', '.'),
        ) > 100 && (
          <>
            <Margin height={40} />
            <InputTextArea
              rows={5}
              label="Justifique.*"
              value={form.internalHouseholdExtensionJustification.value}
              onChange={(e) =>
                handleInput(
                  e.target.value,
                  'internalHouseholdExtensionJustification',
                )
              }
              errorPosition="bottom"
              error={!!form.internalHouseholdExtensionJustification.error}
              errorText={form.internalHouseholdExtensionJustification.error}
              maxLength={500}
              style={{ resize: 'none' }}
            />
          </>
        )}
        <Margin height={40} />
        <Table
          form={form}
          setForm={setForm}
          formBoolean={formBoolean}
          onChange={(value, type) => handleInput(value, type)}
        />
        <Margin height={40} />
      </Main>

      <Margin height={50} />
      <ContainerTitle>
        <SeparatorTitle />
        <Title>Dados dos envolvidos</Title>
      </ContainerTitle>
      <Margin height={35} />
      <Main>
        <SubtitleBold>Responsável técnico pelo imóvel</SubtitleBold>
        <Margin height={35} />
        <Row divider="60">
          <Column>
            <Input
              label="Nome*"
              value={form.propertyTechnicalResponsibleName.value}
              onChange={(e) =>
                handleInput(e.target.value, 'propertyTechnicalResponsibleName')
              }
              maxLength={50}
              error={!!form.propertyTechnicalResponsibleName.error}
              errorText={form.propertyTechnicalResponsibleName.error}
              errorPosition="bottom"
            />
            <Margin height={form.propertyTechnicalResponsibleName.error ? 25 : 40} />
            <Input
              label="Endereço*"
              value={form.propertyTechnicalResponsibleAddress.value}
              onChange={(e) =>
                handleInput(e.target.value, 'propertyTechnicalResponsibleAddress')
              }
              error={!!form.propertyTechnicalResponsibleAddress.error}
              errorText={form.propertyTechnicalResponsibleAddress.error}
              errorPosition="bottom"
            />
          </Column>
          <Margin width={40} />
          <Column style={{ width: '40%' }}>
            <Input
              styleInput={{ width: '100%' }}
              label="Telefone*"
              value={form.propertyTechnicalResponsiblePhone.value}
              onChange={(e) =>
                handleInput(e.target.value, 'propertyTechnicalResponsiblePhone')
              }
              error={!!form.propertyTechnicalResponsiblePhone.error}
              errorText={form.propertyTechnicalResponsiblePhone.error}
              errorPosition="bottom"
            />
            <Margin
              height={form.propertyTechnicalResponsiblePhone.error ? 25 : 40}
            />
            <Input
              styleInput={{ width: '100%' }}
              label="Email*"
              value={form.propertyTechnicalResponsibleEmail.value}
              onChange={(e) =>
                handleInput(e.target.value, 'propertyTechnicalResponsibleEmail')
              }
              error={!!form.propertyTechnicalResponsibleEmail.error}
              errorText={form.propertyTechnicalResponsibleEmail.error}
              errorPosition="bottom"
              onBlur={(e) =>
                setForm((prevState) => ({
                  ...prevState,
                  propertyTechnicalResponsibleEmail: {
                    value: e.target.value,
                    error: !validateEmail(e.target.value) ? 'Email inválido' : '',
                    isRequired:
                      prevState.propertyTechnicalResponsibleEmail.isRequired,
                  },
                }))
              }
            />
          </Column>
          <Margin height={40} />
        </Row>
        <Margin height={35} />
        <SeparatorTitle />
        <Margin height={35} />
        <SubtitleBold>Requerente da licença de obra</SubtitleBold>
        <Margin height={35} />
        <Row divider="60">
          <Input
            label="Nome*"
            value={form.workPermitApplicantName.value}
            onChange={(e) => handleInput(e.target.value, 'workPermitApplicantName')}
            error={!!form.workPermitApplicantName.error}
            errorText={form.workPermitApplicantName.error}
            errorPosition="bottom"
            maxLength={50}
          />
          <Margin width={40} />
          <Input
            styleInput={{ width: '100%' }}
            label="Email*"
            value={form.workPermitApplicantEmail.value}
            onChange={(e) => handleInput(e.target.value, 'workPermitApplicantEmail')}
            error={!!form.workPermitApplicantEmail.error}
            errorText={form.workPermitApplicantEmail.error}
            errorPosition="bottom"
            onBlur={(e) =>
              setForm((prevState) => ({
                ...prevState,
                workPermitApplicantEmail: {
                  value: e.target.value,
                  error: !validateEmail(e.target.value) ? 'Email inválido' : '',
                  isRequired: prevState.workPermitApplicantEmail.isRequired,
                },
              }))
            }
          />
        </Row>
        <Margin height={40} />
        <SeparatorTitle />
        <Margin height={35} />
        <SubtitleBold>Responsável técnico FCP</SubtitleBold>
        <Margin height={35} />
        <Row divider="60">
          <Input
            label="Nome*"
            value={form.technicalManagerFCPName.value}
            onChange={(e) => handleInput(e.target.value, 'technicalManagerFCPName')}
            error={!!form.technicalManagerFCPName.error}
            errorText={form.technicalManagerFCPName.error}
            errorPosition="bottom"
            maxLength={50}
          />
          <Margin width={40} />
          <Input
            styleInput={{ width: '100%' }}
            label="Email*"
            value={form.technicalManagerFCPEmail.value}
            onChange={(e) => handleInput(e.target.value, 'technicalManagerFCPEmail')}
            error={!!form.technicalManagerFCPEmail.error}
            errorText={form.technicalManagerFCPEmail.error}
            errorPosition="bottom"
            onBlur={(e) =>
              setForm((prevState) => ({
                ...prevState,
                technicalManagerFCPEmail: {
                  value: e.target.value,
                  error: !validateEmail(e.target.value) ? 'Email inválido' : '',
                  isRequired: prevState.technicalManagerFCPEmail.isRequired,
                },
              }))
            }
          />
        </Row>
        <Margin height={40} />
        <SeparatorTitle />
        <Margin height={35} />
        <SubtitleBold>
          Responsável técnico pela execução das instalações
        </SubtitleBold>
        <Margin height={35} />
        <Row divider="60">
          <Input
            label="Nome*"
            value={form.installationsExecutionTechnicalResponsibleName.value}
            onChange={(e) =>
              handleInput(
                e.target.value,
                'installationsExecutionTechnicalResponsibleName',
              )
            }
            error={!!form.installationsExecutionTechnicalResponsibleName.error}
            errorText={form.installationsExecutionTechnicalResponsibleName.error}
            errorPosition="bottom"
          />
          <Margin width={40} />
          <Input
            styleInput={{ width: '100%' }}
            label="Email*"
            value={form.installationsExecutionTechnicalResponsibleEmail.value}
            onChange={(e) =>
              handleInput(
                e.target.value,
                'installationsExecutionTechnicalResponsibleEmail',
              )
            }
            error={!!form.installationsExecutionTechnicalResponsibleEmail.error}
            errorText={form.installationsExecutionTechnicalResponsibleEmail.error}
            errorPosition="bottom"
            onBlur={(e) =>
              setForm((prevState) => ({
                ...prevState,
                installationsExecutionTechnicalResponsibleEmail: {
                  value: e.target.value,
                  error: !validateEmail(e.target.value) ? 'Email inválido' : '',
                  isRequired:
                    prevState.installationsExecutionTechnicalResponsibleEmail
                      .isRequired,
                },
              }))
            }
          />
        </Row>
      </Main>

      <Margin height={50} />
      <ContainerTitle>
        <SeparatorTitle />
        <Title>Licenciamento</Title>
      </ContainerTitle>
      <Margin height={45} />
      <Main>
        <Row divider="45">
          <Input
            label="Número do processo"
            value={form.processNumber.value}
            onChange={(e) => handleInput(e.target.value, 'processNumber')}
            error={!!form.processNumber.error}
            errorText={form.processNumber.error}
            errorPosition="bottom"
          />
          <Margin width={30} />
          <Input
            label="Número da licença"
            styleInput={{ width: '40%' }}
            value={form.licensingNumber.value}
            onChange={(e) => handleInput(e.target.value, 'licensingNumber')}
            error={!!form.licensingNumber.error}
            errorText={form.licensingNumber.error}
            errorPosition="bottom"
          />
        </Row>
        <Margin height={45} />
        {shouldAttachDocument && (
          <>
            <Subtitle>Anexe o seguinte documento:</Subtitle>
            <Margin height={40} />
            <Row divider="45">
              <DropZone
                disabled={false}
                disabledDeleteFile={false}
                infoFileName="Licença de obras"
                name="Licença de obras"
                content={licensingFile}
                error={licensingFileError}
                onFileUploaded={(file: File | null) => {
                  if (file) {
                    convertFileToBase64(file, (base64: string) => {
                      dispatch(
                        postUploadFilePropertyRegistrationRequest({
                          caseIdSales: caseId!,
                          type: 'WORKS_LICENSE',
                          step: '2',
                          filename: file?.name || '',
                          filedata: base64,
                        }),
                      );
                    });
                  }

                  setLicensingFile(file);

                  setForm((prevState) => ({
                    ...prevState,
                    licensingNumber: {
                      value: prevState.licensingNumber.value,
                      error: prevState.licensingNumber.error,
                      isRequired:
                        !!file ||
                        !!form.licensingNumber.value ||
                        !!form.processNumber.value,
                    },
                    processNumber: {
                      value: prevState.processNumber.value,
                      error: prevState.processNumber.error,
                      isRequired:
                        !!file ||
                        !!form.licensingNumber.value ||
                        !!form.processNumber.value,
                    },
                    licensingJustification: {
                      value: '',
                      error: '',
                      isRequired:
                        !file ||
                        !form.licensingNumber.value ||
                        !form.processNumber.value,
                    },
                  }));
                }}
              />
              <Margin width={15} />
              <Label>
                {isRevision
                  ? revisionDescription
                  : 'Documentos devem ser anexados em formato PDF.'}
              </Label>
            </Row>
            <Margin height={40} />
          </>
        )}
        {!licensingFile &&
          !form.processNumber.value &&
          !form.licensingNumber.value && (
            <InputTextArea
              label="Caso não possua a licença de obras, justifique.*"
              onChange={(e) => handleInput(e.target.value, 'licensingJustification')}
              error={!!form.licensingJustification.error}
              errorText={form.licensingJustification.error}
              value={form.licensingJustification.value}
              errorPosition="bottom"
              style={{ resize: 'none' }}
              maxLength={500}
            />
          )}
        <Margin height={80} />
        <SeparatorTitle />
        <Margin height={50} />
        <TextFooter>
          A Iguá se reserva o direito de a qualquer momento promover vistoria de
          inspeção das instalações prediais do imóvel.
        </TextFooter>
        <Margin height={70} />

        {shouldShowAlert && (
          <Content>
            <StatusInfo title="Preencha os campos obrigatórios" status="warning">
              Todos os campos obrigatórios precisam estar preenchidos para dar
              continuidade ao processo.
            </StatusInfo>

            <Margin height={30} />
          </Content>
        )}

        <ButtonBar
          nextDisabled={false}
          nextBackground={Color.blueSeconde}
          nextLoading={!!loadingUploadFile || !!loadingPatch}
          backOnClick={() => Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)}
          nextOnClick={() => handleSubmit()}
          nextLabel={isRevision ? 'Continuar' : 'Aceitar e continuar'}
        />
        <Margin height={50} />
      </Main>
    </>
  );
};

export default Sewage;
