import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import {
  Main,
  Card,
  ContainerBreadCrumb,
  ContainerButtons,
  Border,
  ContainerBorder,
  ContainerAmount,
  ContainerHeader,
  ContainerInvoice,
} from './styles';
import { ButtonText } from '~/components/ButtonText';
import { BreadCrumb } from '~/components/BreadCrumb';
import { Breakpoints, Color } from '~/styles';
import { RouteName } from '~/routes/Routes.name';
import { history } from '~/store';
import { IDadosDebts } from '~/store/modules/outsides/types';
import formatter from '~/utils/formatter';
import Navigation from '~/utils/Navigation';
import { statusColor } from '~/utils/invoiceStatus';
import { useDispatch, useSelector } from 'react-redux';
import { setDataPaymentDebtsNegotiation } from '~/store/modules/negotiation/actions';
import { INegotationProps } from '../types';
import { formatPaymentIds } from '~/components/Payment/utils';
import { IPaymentCharges, IPaymentInvoices } from '~/store/modules/payment/types';
import { IState } from '~/store/modules/types';
import LoadingComponent from '~/components/Loading';
import { AppDispatch } from '~/store';

interface IDadosDebtsCustom extends IDadosDebts {
  active: boolean;
}

interface IInvoiceGroupDebts {
  address: string;
  debts: IDadosDebtsCustom[];
  activeAll: boolean;
}

const NegotiationSinglePayment: FC<INegotationProps> = ({ links }) => {
  const dataHistory = history.location.state as any;

  const dispatch = useDispatch<AppDispatch>();

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.laptop.min });

  const dataDados = history.location.state as any;

  const token = useSelector((item: IState) => item.enrollmentsAuth.token);
  const enrollmentsUsersCnp = useSelector(
    (item: IState) => item.enrollmentsUsers.data?.cnp,
  );
  const loading = useSelector(
    (item: IState) => item.negotiation.loadingDebtsNegotiationCalculateInstallments,
  );
  const cnp = token ? enrollmentsUsersCnp : dataHistory?.cnp;
  const routeInvoiceReceipt = token
    ? RouteName.SINGLEPAYMENTDATAPAYMENTRECEIPT
    : RouteName.DISCHARGEDEBTSSINGLEPAYMENTDATAPAYMENTRECEIPT;

  const [invoicesGroupDebts, setInvoicesGroupDebts] = useState<
    Array<IInvoiceGroupDebts>
  >([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);

  useEffect(() => {
    setInvoicesGroupDebts(dataDados?.dados);
  }, []);

  const getActiveDebts = () => {
    let activeDebts: Array<any> = [];
    let activePaymentsGroup: Array<IInvoiceGroupDebts> = [];

    invoicesGroupDebts?.map((item) => {
      item?.debts.map((invoice) => {
        if (invoice?.active) {
          const hasGroup = activePaymentsGroup.find(
            (paymentsGroup) => paymentsGroup?.address === item?.address,
          );

          if (!hasGroup) {
            activePaymentsGroup.push({
              ...item,
              debts: [invoice],
            });
          } else {
            const indexGroup = activePaymentsGroup.findIndex(
              (paymentsGroup) => paymentsGroup?.address === item?.address,
            );

            activePaymentsGroup[indexGroup]?.debts.push({
              ...invoice,
            });
          }

          activeDebts.push({
            ...invoice,
          });
        }

        return invoice;
      });
    });

    return { activeDebts, activePaymentsGroup };
  };

  const addInvoices = () => {
    const amountInvoices = getActiveDebts()?.activeDebts.reduce(
      (total: any, number: any) => total + number.amount,
      0,
    );

    setTotalAmount(amountInvoices);
  };

  const handleCheck = ({ checked, index }: { checked: boolean; index: number }) => {
    let data: Array<IInvoiceGroupDebts> = [...invoicesGroupDebts];

    data[index].activeAll = !checked;

    data[index].debts = data[index]?.debts?.map((debt) => {
      return {
        ...debt,
        active: !checked,
      };
    });

    setInvoicesGroupDebts(data);
  };

  const handleAllCheck = ({ debts }: { debts: IDadosDebtsCustom[] }) => {
    const debtInactive = debts?.find((debt) => !debt.active);
    return !debtInactive;
  };

  const getDescriptionDebt = (type: string) => {
    return type?.toUpperCase() === 'INVOICE' ? (
      'Fatura'
    ) : (
      <>
        {' '}
        Outros <br />
        serviços{' '}
      </>
    );
  };

  const getDataNegotiation = () => {
    if (getActiveDebts()?.activeDebts.length < 1) return;

    ReactGA.event({
      category: 'Negociação de dívidas',
      action: '[Negociação de dívidas][Pagamento Único] Ir para o pagamento',
    });

    if (getActiveDebts()?.activeDebts?.length === 1) {
      const debt = getActiveDebts()?.activeDebts[0];

      const enrollmentInfo =
        debt?.type === 'INVOICE' ? debt?.enrollment_id : debt?.operation;
      const type = debt?.type === 'INVOICE' ? 'invoices' : 'charges';

      const dataDebt = formatPaymentIds({
        type: debt?.type,
        id: debt?.id,
        enrollmentInfo,
      });

      dispatch(
        setDataPaymentDebtsNegotiation(
          {
            [type]: {
              [type]: [dataDebt],
            },
            amount: totalAmount,
            possible_quantity_installments: debt.possible_quantity_installments || 1,
            qr_code_key: debt.pix_code,
            bar_code_number: debt.bar_code_number,
            isNegotiation: false,
            routeInvoiceReceipt,
          },
          cnp,
        ),
      );
    } else {
      let invoices: Array<IPaymentInvoices> = [];
      let charges: Array<IPaymentCharges> = [];
      let totalInvoicesAmount = 0;
      let totalChargesAmount = 0;

      getActiveDebts()?.activePaymentsGroup.map((paymentsGroup) => {
        paymentsGroup?.debts?.map((item) => {
          if (item?.type === 'INVOICE') {
            totalInvoicesAmount += item.amount;

            invoices?.push(
              formatPaymentIds({
                type: 'INVOICE',
                id: String(item?.id),
                enrollmentInfo: item.enrollment_id,
              }),
            );
          } else {
            totalChargesAmount += item.amount;

            charges?.push(
              formatPaymentIds({
                type: 'CHARGE',
                id: String(item?.id),
                enrollmentInfo: item?.operation?.toString() || '',
              }),
            );
          }
        });

        return paymentsGroup;
      });

      const showDuplicateModal =
        invoices?.length > 0 && charges?.length > 0
          ? {
              totalInvoicesAmount,
              totalChargesAmount,
            }
          : null;

      dispatch(
        setDataPaymentDebtsNegotiation(
          {
            invoices: invoices ? { invoices } : undefined,
            charges: charges ? { charges } : undefined,
            amount: totalAmount,
            showDuplicateModal,
            isNegotiation: false,
            routeInvoiceReceipt,
          },
          cnp,
        ),
      );
    }
  };

  useEffect(() => {
    if (invoicesGroupDebts) addInvoices();
  }, [invoicesGroupDebts]);

  return (
    <Main>
      {loading && <LoadingComponent />}
      <ContainerBreadCrumb>
        <BreadCrumb links={links} />
      </ContainerBreadCrumb>
      <Card>
        <h1>Pagamento único</h1>
        {invoicesGroupDebts &&
          invoicesGroupDebts?.map((item, index) => (
            <React.Fragment key={item?.address}>
              <ContainerBorder>
                <Border />
              </ContainerBorder>
              {invoicesGroupDebts?.length > 1 && <h3>Endereço: {item?.address}</h3>}
              <ContainerHeader>
                <div>
                  <label className="container">
                    <input
                      type="checkbox"
                      name="checkAll"
                      onChange={() =>
                        handleCheck({ checked: item?.activeAll, index })
                      }
                      checked={item?.activeAll}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <p>Status/tipo</p>
                {!isMobile && <p>Vencimento</p>}
                <p>Valor</p>
                {!isMobile && <p>Referência</p>}
              </ContainerHeader>
              {item?.debts?.map((invoice: IDadosDebtsCustom, indexItem: number) => (
                <ContainerInvoice
                  key={invoice?.id}
                  color={statusColor(invoice?.status?.label, true)}
                >
                  <div>
                    <span />
                    <label className="container">
                      <input
                        type="checkbox"
                        name={`check${invoice?.id}`}
                        onChange={() => {
                          let data: Array<IInvoiceGroupDebts> = [
                            ...invoicesGroupDebts,
                          ];

                          data[index].debts[indexItem].active = !invoice?.active;
                          data[index].activeAll = handleAllCheck({
                            debts: data[index].debts,
                          });

                          setInvoicesGroupDebts(data);
                        }}
                        checked={invoice.active}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <p className="status">
                    {invoice?.status?.label} <br />{' '}
                    <span>{getDescriptionDebt(invoice?.type)}</span>
                  </p>
                  {!isMobile && (
                    <p>{moment(invoice?.maturity_date).format('DD/MM/YYYY')}</p>
                  )}
                  <p>{formatter.formatCurrency(invoice?.amount)}</p>
                  {!isMobile && <p>{invoice?.reference}</p>}
                </ContainerInvoice>
              ))}
            </React.Fragment>
          ))}
        <ContainerAmount>
          <p>Valor total: {formatter.formatCurrency(totalAmount)}</p>
        </ContainerAmount>
        <ContainerButtons>
          <ButtonText
            text="Voltar"
            typeBtn="content"
            onClick={() => {
              ReactGA.event({
                category: 'Negociação de dívidas',
                action: '[Negociação de dívidas][Pagamento Único] Voltar',
              });

              Navigation.goBack();
            }}
          />
          <ButtonText
            typeBtn="content"
            backgroundColor={
              getActiveDebts()?.activeDebts.length > 0
                ? Color.blueSeconde
                : Color.gray
            }
            onClick={getDataNegotiation}
            nextIcon
          >
            Ir para pagamento
          </ButtonText>
        </ContainerButtons>
      </Card>
    </Main>
  );
};

export default NegotiationSinglePayment;
