import React, { FC } from 'react';
import ReactGA from 'react-ga4';

import {
  AmountTotalDiscountText,
  ButtonNegotiationPayment,
  ContainerDiscount,
  ContainerDiscountIcon,
  Description,
  DiscountNumberText,
  DiscountText,
  Main,
  TextButtonPayment,
  Title,
} from './styles';
import { Border } from '../styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { ArrowIcon, DiscountIcon } from '../Icons';
import { Color } from '~/styles';
import formatter from '~/utils/formatter';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { postDebtsNegotiationRequest } from '~/store/modules/negotiation/actions';
import { IResponseDataDebtsNegotiationPaymentCondition } from '~/store/modules/negotiation/types';
import { AppDispatch } from '~/store';

interface ITypePaymentProps {
  hasDiscount?: boolean;
  title: string;
  description: React.ReactNode;
  data: IResponseDataDebtsNegotiationPaymentCondition;
  typeButton: 'simulation' | 'payment';
  maximumDownPayment?: number;
  campaignPaymentConditionId?: number;
}

const TypePayment: FC<ITypePaymentProps> = ({
  hasDiscount,
  title,
  description,
  data,
  typeButton,
  maximumDownPayment,
  campaignPaymentConditionId,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const dataDebtsNegotiation = useSelector(
    (item: IState) => item.negotiation.dataDebtsNegotiation,
  );
  const enrollmentCheck = useSelector(
    (item: IState) => item.negotiation.enrollmentCheck,
  );

  const getButton = () => {
    switch (typeButton) {
      case 'simulation':
        return {
          label: 'Simule uma negociação',
          onClick: () => {
            ReactGA.event({
              category: 'Negociação de dívidas',
              action:
                '[Negociação de dívidas][Escolha o tipo de pagamento] Simule uma negociação',
            });

            Navigation.navigate(RouteName.DEBTNEGOTIATIONSIMULATION, {
              unitRegistrationNumber: dataDebtsNegotiation?.unitRegistrationNumber,
              campaignPaymentConditions: data,
              maximumDownPayment,
              campaignPaymentConditionId,
            });
          },
          background: '#e9ecef',
        };

      default:
        return {
          label: 'Pagar',
          onClick: () => {
            ReactGA.event({
              category: 'Negociação de dívidas',
              action:
                '[Negociação de dívidas][Escolha o tipo de pagamento] Pagar à vista”',
            });

            dispatch(
              postDebtsNegotiationRequest({
                enrollment: enrollmentCheck?.enrollment || '',
                operationId: enrollmentCheck?.operationId || 0,
                type: 'AT_SIGHT',
                campaignBillingId: data?.campaignPaymentConditionId || 0,
                downPaymentAmount: data?.minimumDownPayment,
                installmentsId: '0|0,00|0,00',
              }),
            );
          },
          background: Color?.blueSeconde,
        };
    }
  };

  return (
    <Main>
      {hasDiscount && (
        <ContainerDiscountIcon>
          <DiscountIcon />
        </ContainerDiscountIcon>
      )}
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Border />
      <ContainerDiscount>
        <DiscountText>Desconto de</DiscountText>
        <DiscountNumberText>
          {data?.maximumDiscountAmountPercent}%
        </DiscountNumberText>
        <AmountTotalDiscountText>
          Valor total do desconto <br />{' '}
          <b>{formatter.formatCurrency(data?.maximumDiscountAmount)}</b>
        </AmountTotalDiscountText>
      </ContainerDiscount>
      <ButtonNegotiationPayment
        background={getButton()?.background}
        type="button"
        onClick={getButton()?.onClick}
      >
        <TextButtonPayment>{getButton()?.label}</TextButtonPayment>
        <div>
          <ArrowIcon />
        </div>
      </ButtonNegotiationPayment>
    </Main>
  );
};

export default TypePayment;
