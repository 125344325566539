import React, { FC, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { Margin } from '~/components/Margin';

import {
  Main,
  Card,
  ContainerBreadCrumb,
  ContainerButtons,
  Border,
  ContainerBorder,
  ContainerInputs,
} from './styles';
import { ButtonText } from '~/components/ButtonText';
import { Input } from '~/common/InputV3';
import { Color } from '~/styles';
import formatter from '~/utils/formatter';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { BreadCrumb } from '~/components/BreadCrumb';
import { checkCnp } from '~/utils/validation';
import Autocomplete from '~/common/Autocomplete';
import {
  getErrorCloseOutsidesSocialTariffEnrollments,
  getErrorCloseOutsidesSocialTariffValidEnrollment,
  getOutsidesSocialTariffEnrollmentsRequest,
  getOutsidesSocialTariffValidEnrollmentRequest,
} from '~/store/modules/outsides/actions';
import LoadingComponent from '~/components/Loading';
import { Dialog } from '~/components/Dialog';
import ModalWarning from './ModalWarning';
import StatusInfo from './StatusInfo';
import { Link } from 'react-router-dom';
import { AppDispatch } from '~/store';

export interface IDocuments {
  key: string;
  label: string;
  file: null | File;
}

interface IEnrollments {
  label: string;
  value: string;
  code: string;
}

const SocialTariffOutside: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const loading = useSelector(
    (item: IState) => item.outsides.loadingSocialTariffEnrollments,
  );
  const loadingSocialTariffValidEnrollment = useSelector(
    (item: IState) => item.outsides.loadingSocialTariffValidEnrollment,
  );
  const dataSocialTariffEnrollments = useSelector(
    (item: IState) => item.outsides.dataSocialTariffEnrollments,
  );
  const warningSocialTariffValidEnrollment = useSelector(
    (item: IState) => item.outsides.warningSocialTariffValidEnrollment,
  );
  const error = useSelector(
    (item: IState) => item.outsides.errorSocialTariffValidEnrollment,
  );

  const cnpInputRef = useRef<HTMLInputElement>(null);
  const [cnp, setCnp] = useState<string>('');
  const [enrollment, setEnrollment] = useState<string>('');
  const [enrollmentsOption, setEnrollmentsOption] = useState<Array<IEnrollments>>(
    [],
  );
  const [cnpError, setCnpError] = useState<string>('');
  const [registrationError, setRegistrationError] = useState<string>('');
  const [warning, setWarning] = useState<boolean>(false);
  const [openWarning, setOpenWarning] = useState<boolean>(false);
  const typeSolicitation = useSelector(
    (item: IState) =>
      item.outsides.dataSocialTariffValidEnrollment?.typeSolicitation,
  );

  const resetErrors = () => {
    dispatch(getErrorCloseOutsidesSocialTariffEnrollments());
    dispatch(getErrorCloseOutsidesSocialTariffValidEnrollment());
    setCnp('');
    setEnrollment('');
    setEnrollmentsOption([]);
    setRegistrationError('');
  };

  useEffect(() => {
    resetErrors();
  }, []);

  useEffect(() => {
    setWarning(false);
  }, [loading]);

  useEffect(() => {
    setOpenWarning(!!typeSolicitation);
  }, [typeSolicitation]);

  useEffect(() => {
    if (warningSocialTariffValidEnrollment || warning)
      ReactGA.event({
        category: 'Tarifa Social',
        action: `[Ñ Logado][Tarifa Social][Documento e Matricula] – Mensagem de Outra Operação`,
      });
  }, [warningSocialTariffValidEnrollment, warning]);

  useEffect(() => {
    if (dataSocialTariffEnrollments) {
      if (error || loadingSocialTariffValidEnrollment) return;

      let array: any = [];

      dataSocialTariffEnrollments?.result?.map((enrollment) => {
        array?.push({
          label: `${enrollment?.code} (${enrollment?.address_connection})`,
          value: enrollment?.enrollment,
          code: enrollment?.code,
        });

        setEnrollmentsOption(array);

        if (dataSocialTariffEnrollments?.result?.length === 1) {
          const operation =
            dataSocialTariffEnrollments?.result[0]?.operation?.name.toLocaleUpperCase();
          setEnrollment(dataSocialTariffEnrollments?.result[0]?.code || '');
          setWarning(operation !== 'RIO DE JANEIRO' && operation !== 'CUIABA');
        }
      });
    } else {
      setWarning(false);
      setEnrollment('');
    }
  }, [error, loadingSocialTariffValidEnrollment, dataSocialTariffEnrollments]);

  const validateCnp = () => {
    setEnrollment('');
    setRegistrationError('');
    dispatch(getErrorCloseOutsidesSocialTariffValidEnrollment());

    if (!cnp) return setCnpError('');

    const cnpIsvalid = checkCnp(cnp);

    if (!cnpIsvalid) {
      return setCnpError('CPF/CNPJ inválido');
    }

    return setCnpError('');
  };

  const validateRegistration = () => {
    dispatch(getErrorCloseOutsidesSocialTariffValidEnrollment());
    if (!enrollment) return setRegistrationError('');
  };

  const handleGetEnrollments = () => {
    if (cnp) {
      setEnrollmentsOption([]);
      dispatch(getErrorCloseOutsidesSocialTariffValidEnrollment());
      dispatch(getOutsidesSocialTariffEnrollmentsRequest(cnp));
    }
  };

  const handleWarning = (value: string) => {
    const enrollmentsOptionFind = dataSocialTariffEnrollments?.result?.find(
      (item) => item?.code === formatter?.enrollment(value),
    );

    const operation =
      enrollmentsOptionFind &&
      enrollmentsOptionFind?.operation?.name?.toLocaleUpperCase();

    setWarning(
      operation ? operation !== 'RIO DE JANEIRO' && operation !== 'CUIABA' : false,
    );
  };

  const handleValidEnrollment = () => {
    if (loadingSocialTariffValidEnrollment || cnpError || registrationError) return;

    ReactGA.event({
      category: 'Tarifa Social',
      action: `[Ñ Logado][Tarifa Social][Documento e Matricula] - Continuar`,
    });

    if (!cnp || !enrollment) {
      setCnpError(!cnp ? 'CPF/CNPJ inválido' : '');
      setRegistrationError(!enrollment ? 'Matrícula não encontrada' : '');
      return;
    }

    const dataEnrollment = dataSocialTariffEnrollments
      ? dataSocialTariffEnrollments.result?.find((item) => item.code === enrollment)
      : null;

    dispatch(
      getOutsidesSocialTariffValidEnrollmentRequest(
        {
          cnp,
          enrollment,
        },
        { ...dataEnrollment, cnp, code: enrollment },
      ),
    );
  };

  return (
    <Main>
      {loading && <LoadingComponent />}
      <Dialog open={openWarning} onClose={() => setOpenWarning(false)} fullScreen>
        <ModalWarning onClose={() => setOpenWarning(false)} />
      </Dialog>
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.LANDING),
            },
            {
              label: 'Tarifa social',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <Card>
        <h1>Tarifa social</h1>
        <Margin height={34} />
        <ContainerBorder>
          <Border />
        </ContainerBorder>
        <p className="description">
          A tarifa social nos serviços públicos de abastecimento de água e
          esgotamento sanitário é um benefício concedido à clientes consumidores das
          concessionárias do Grupo Iguá, onde há normativa oficial, publicada pelo
          poder concedente, para tal aplicação.
          <br /> <br />
          Para as matrículas localizadas no <b>Rio de Janeiro e Cuiabá</b>, o cliente
          titular poderá fazer a solicitação do benefício clicando em "Solicitar
          tarifa social”. Para demais regiões, deve ser consultado a operação através
          dos nossos canais de atendimento. <br /> <br />
          <button
            type="button"
            onClick={() => {
              Navigation.navigate(RouteName.SOCIALTARIFFOUTSIDEDETAIL);
              ReactGA.event({
                category: 'Tarifa Social',
                action: `[Ñ Logado][Tarifa Social] – Detalhes de cada região`,
              });
            }}
          >
            Confira mais detalhes de cada região{' '}
          </button>
        </p>
        <ContainerBorder>
          <Border />
          <h2>Solicitação</h2>
          <Border />
        </ContainerBorder>
        <span className="label">
          Para começar, preencha os dados referentes ao titular da matrícula:
        </span>
        <Margin height={24} />
        <ContainerInputs>
          <Input
            value={cnp}
            onChange={(ev) => setCnp(formatter.cpfCnpjMask(ev.target.value))}
            onBlur={validateCnp}
            label="CPF/CNPJ*"
            name="cnp"
            refEl={cnpInputRef}
            error={!!cnpError}
            errorText={cnpError}
            onBlurCapture={handleGetEnrollments}
          />
          <Autocomplete
            label="Matrícula*"
            options={enrollmentsOption || []}
            name="registration"
            placeholder="Digite matrícula"
            value={enrollment}
            onChange={(ev) => {
              setEnrollment(formatter.enrollment(ev.target.value));
              setRegistrationError('');
              handleWarning(ev.target.value);
              if (error)
                dispatch(getErrorCloseOutsidesSocialTariffValidEnrollment());
            }}
            onBlur={validateRegistration}
            onSelectOption={(ev: any) => {
              setRegistrationError('');
              const enrollmentsOptionFind = enrollmentsOption.find(
                (item) => item.value === ev.value,
              );
              handleWarning(enrollmentsOptionFind?.code || '');
              setEnrollment(enrollmentsOptionFind?.code || '');
            }}
            error={!!registrationError || !!error}
            errorText={error ? 'Matrícula não encontrada' : registrationError}
            disabled={false}
          />
        </ContainerInputs>
        {warningSocialTariffValidEnrollment && (
          <>
            <Margin height={24} />
            <StatusInfo status="Atenção">
              Para solicitar tarifa social, entre em contato com a sua operação
              através dos nossos{' '}
              <Link
                to={RouteName.SOCIALTARIFFOUTSIDEDETAIL}
                style={{ fontWeight: 500 }}
              >
                canais de atendimento
              </Link>
              .
            </StatusInfo>
          </>
        )}
        <Margin height={56} />
        <ContainerButtons>
          <ButtonText
            text="Voltar"
            typeBtn="content"
            Color={Color.white}
            backgroundColor={Color.green}
            onClick={() => {
              Navigation.goBack();
              ReactGA.event({
                category: 'Tarifa Social',
                action: `[Ñ Logado][Tarifa Social][Documento e Matricula] - Voltar`,
              });
            }}
            id="voltar-tela-documento-e-matricula"
            backIcon
          />
          <ButtonText
            typeBtn="content"
            backgroundColor={Color.blueSeconde}
            onClick={handleValidEnrollment}
            nextIcon
            loading={loadingSocialTariffValidEnrollment}
            id="continuar-tela-documento-e-matricula"
          >
            Continuar
          </ButtonText>
        </ContainerButtons>
      </Card>
    </Main>
  );
};

export default SocialTariffOutside;
