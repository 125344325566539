import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  padding: 37px 10px 68px 10px;
  margin: 0 auto;
  position: relative;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 40px 24px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: 100%;
    max-width: 1170px;
  }
`;

export const Container = styled.div`
  width: calc(100% - 300px);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.19);
  border-radius: 16px;
  margin-right: 296px;

  @media screen and (max-width: 1340px) {
    width: 100%;
    margin-right: 0px;
  }
`;

export const Header = styled.div`
  height: 40px;
  background: ${Color.blueIntense};
  border-radius: 23px 23px 0 0;

  /* @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    padding: 40px 0;
  }

  @media screen and (min-width: ${Breakpoints.desktopLarge.min}px) {
    padding: 52px 0;
  } */
`;

export const BoxContent = styled.div`
  width: 100%;
  max-width: 870px;
  margin: 0 auto;

  h1 {
    max-width: 670px;
    margin: 32px auto;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    color: ${Color.blueIntense};
  }
`;

export const Separator = styled.div`
  max-width: 870px;
  border: 1px solid #e9e9e9;
  margin: 0 auto;
`;

export const TypeText = styled.p`
  max-width: 700px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: ${Color.blueIntense};
`;

export const ContainerButtons = styled.section`
  width: calc(100% - 48px);
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px 0 24px;

  button {
    height: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;

    :last-of-type {
      svg {
        right: 39px;
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    max-width: 700px;
    flex-direction: row;
    padding: 0;

    button {
      max-width: 250px;
    }
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1180px;
  margin: 0 auto 22px auto;
  display: none;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    display: inherit;
  }
`;
