import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  padding: 37px 10px 68px 10px;
  margin: 0 auto;
  position: relative;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: calc(100% - 48px);
    padding: 24px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    padding-bottom: 104px;
  }

  @media (min-width: ${Breakpoints.laptop.max}px) {
    max-width: 1170px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 870px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 24px;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    margin: 0 auto;
  }
`;

export const Header = styled.div`
  height: 24px;
  background: ${Color.blueIntense};
  border-radius: 23px 23px 0 0;
`;

export const BoxContent = styled.div`
  width: 100%;
  margin: 0 auto;

  h1 {
    padding: 14px 0 10px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: ${Color.blueIntense};
  }
`;

export const ContainerBorder = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    width: 100%;
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background: ${Color.grayLightSeconde};
`;

export const ContainerButtons = styled.section`
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;

  button {
    height: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border-radius: 8px;
    box-shadow: none;

    svg {
      left: 16px;
    }

    :first-of-type {
      border: 2px solid ${Color.green};
      background: transparent;
    }

    :last-of-type {
      svg {
        left: auto;
        right: 16px;
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    flex-direction: row;

    button {
      max-width: 252px;
    }
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1180px;
  margin: 0 auto 22px auto;
  display: none;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    display: inherit;
  }
`;

export const Content = styled.div`
  padding: 0 24px;

  @media (min-width: ${Breakpoints.mobile.max}px) {
    padding: 0 40px;
  }

  @media (min-width: ${Breakpoints.laptop.min}px) {
    padding: 0 80px;
  }
`;
