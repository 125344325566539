import React, { FC } from 'react';

import { BoxTerm, CloseIconButton, Main } from './styles';

import { Margin } from '~/components/Margin';
import { ButtonText } from '~/components/ButtonText';
import CloseModalSVG from '~/assets/Icons/CloseModal';

interface IProps {
  onClose: () => void;
}

const ModalTerm: FC<IProps> = ({ onClose }) => {
  return (
    <Main>
      <CloseIconButton onClick={onClose} type="button">
        <CloseModalSVG width={14} height={14} isBold />
      </CloseIconButton>
      <h3>Termo de medição digital</h3>
      <p>Para finalizar a solicitação, leia com atenção os termos a seguir:</p>
      <Margin height={16} />
      <BoxTerm>
        <p>
          Por meio deste Termo de Adesão, o CONTRATANTE manifesta sua concordância e
          ciência às Condições Gerais de Prestação de Serviço (“Condições Gerais”) da
          CONTRATADA e com as condições específicas do abaixo descritas.
          <br />
          <br />
          1. CONDIÇÕES ESPECÍFICAS
          <br />
          <br />
          1. Plano Contratado
          <br />
          1.1. Plano: Anual
          <br />
          1.2. Preço: R$ 118,80 por ano.
          <br />
          1.3. Prazo: Vigência mínima de 12 (doze) meses, a partir da instalação do
          equipamento;
          <br />
          1.4. Forma de pagamento:
          <br />
          Cartão de Crédito
          <br />
          1.5. Correção:
          <br />
          IPC/FIPE, a cada 12 (doze) meses de vigência.
          <br />
          <br />
          2. RESUMO DO CONTRATO
          <br />
          <br />
          1. Os serviços prestados pela CONTRATADA estão disciplinados pelas regras
          contidas nas Condições Gerais de Prestação de Serviço (“Condições Gerais”).
          <br />
          2. Mediante o emprego de equipamento especializado, a CONTRATADA presta
          serviços de medição, telemetria, acompanhamento e análise de dados do
          consumo de água da residência ou estabelecimento do(a) CONTRATANTE.
          <br />
          3. O(A) CONTRATANTE é pessoa física ou jurídica que deseja adquirir os
          serviços da CONTRATADA, mediante pagamento de remuneração, nos termos
          previstos neste Termo de Adesão e nas Condições Gerais.
          <br />
          4. O(A) CONTRATANTE expressamente autoriza a CONTRATADA a realizar a
          medição e leitura de seu consumo de água, nos termos das Condições Gerais,
          permitindo, inclusive, o compartilhamento dessa informação com a respectiva
          CONCESSIONÁRIA para aperfeiçoamento dos serviços de saneamento prestados ou
          para eventual cofaturamento.
          <br />
          5. Este contrato envolve a coleta e tratamento de dados pessoais do(a)
          CONTRATANTE, conforme os termos previstos nas Condições Gerais, na Lei
          Geral de Proteção de Dados Pessoais e na Política de Privacidade da
          CONTRATADA, que se encontra disponível no website
          “igua.com.br/politica-de-privacidade".
          <br />
          5.1. Para quaisquer dúvidas relacionadas ao tratamento de dados pessoais
          realizado no âmbito da prestação dos serviços pela CONTRATADA, o(a)
          CONTRATANTE poderá acessar o canal do encarregado de proteção de dados
          pessoais da CONTRATADA pelo endereço “dpo@fluxx.com.br”.
          <br />
          6. Os principais direitos do(a) CONTRATANTE são:
          <br />
          i) Receber os serviços contratados;
          <br />
          ii) Receber em cessão ou empréstimo o equipamento usado para a medição;
          <br />
          7. As principais obrigações do(a) CONTRATANTE são:
          <br />
          i) Pagar o preço contratado, conforme este Termo de Adesão e as Condições
          Gerais;
          <br />
          ii) Conservar o equipamento de medição instalado pela CONTRATADA;
          <br />
          8. A prestação dos SERVIÇOS pela CONTRATADA poderá ser solicitada nas
          seguintes modalidades:
          <br />
          (i) Plano Mensal: Sujeito ao prazo mínimo de 1 (um) mês, a partir da
          instalação do Equipamento, sendo renovado automática e sucessivamente, ao
          final de cada período.
          <br />
          (ii) Plano Anual: Sujeito ao prazo mínimo de 12 (dose) meses, a partir da
          instalação do Equipamento, sendo renovado automática e sucessivamente, ao
          final de cada período. Cancelamento antecipado, depende do pagamento do
          valor residual do contrato.
          <br />
          9. O atraso no pagamento das parcelas, sujeitará o(a) CONTRATANTE ao
          pagamento de multa e juros, nos termos das Condições Gerais.
          <br />
          10. As hipóteses de extinção e cancelamento do contrato estão previstas nas
          Condições Gerais.
          <br />
          <br />
          3. ADESÃO
          <br />
          <br />
          2. Por meio deste Termo de Adesão, o(a) CONTRATANTE declara ter recebido,
          lido e compreendido as Condições Gerais, concordando com todas as suas
          disposições.
          <br />
          3. O(A) CONTRATANTE declara estar ciente de que a assinatura de termo de
          adesão impresso ou com aceite enviado por e-mail; o preenchimento e aceite
          on-line do termo de adesão eletrônico; ou o pagamento parcial ou total do
          PREÇO, implicará a adesão e aceitação automática do inteiro teor destas
          CONDIÇÕES GERAIS e início de sua vigência.
          <br />
          4. Este Termo, celebrado de forma irretratável e irrevogável, constitui o
          entendimento integral entre as Partes e revoga expressamente todas e
          quaisquer tratativas ou discussões entre elas em relação a seu objeto,
          obrigando as Partes e seus sucessores, a qualquer título.
        </p>
      </BoxTerm>
      <Margin height={30} />
      <ButtonText
        text="Ok"
        typeBtn="content"
        onClick={onClose}
        className="button-green"
      />
    </Main>
  );
};

export default ModalTerm;
