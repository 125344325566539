import React, { TextareaHTMLAttributes, CSSProperties, FC } from 'react';

import {
  ContainerInputStyled,
  InputStyled,
  LabelStyled,
  ContainerIconStyled,
  LabelRow,
  LabelError,
} from './styles';

import InvisiblePassword from '~/assets/Icons/InvisiblePassword';
import VisiblePasswordSVG from '~/assets/Icons/VisiblePassword';
import EditSVG from '~/assets/Icons/editIcon';
import { Margin } from '../Margin';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name?: string;
  value?: string;
  label?: string;
  onChange?: (ev: any) => void;
  iconPassword?: boolean;
  error?: boolean;
  errorText?: string;
  passwordVisible?: boolean;
  setPasswordVisible?: (statusVisible: boolean) => void;
  editIcon?: boolean;
  maxLength?: number;
  payment?: boolean;
  placeholder?: string;
  style?: CSSProperties;
  styleInput?: any;
  styleLabelError?: CSSProperties;
  onBlur?: (ev: any) => void;
  refEl?: React.MutableRefObject<any>;
  disabled?: boolean;
  isNewHeigthLabelInput?: boolean;
  errorPosition?: 'top' | 'bottom';
  autoFocus?: boolean;
  labelErroWeigth?: number;
  className?: string;
  onFocusCapture?: any;
}

export const InputTextArea: FC<Props> = ({
  value,
  label,
  onChange,
  iconPassword,
  error,
  errorText,
  passwordVisible,
  setPasswordVisible,
  editIcon,
  maxLength,
  payment,
  placeholder,
  style,
  styleInput,
  name,
  onBlur,
  refEl,
  disabled,
  isNewHeigthLabelInput,
  errorPosition = 'top',
  autoFocus,
  className,
  styleLabelError,
  onFocusCapture,
  ...props
}) => {
  return (
    <ContainerInputStyled style={styleInput}>
      <LabelRow>
        {label && <LabelStyled payment={payment}>{label}</LabelStyled>}
      </LabelRow>
      {isNewHeigthLabelInput && <Margin height={4} />}

      {error && errorPosition === 'top' && (
        <>
          <LabelError>{errorText}</LabelError>
          <Margin height={8} />
        </>
      )}
      <InputStyled
        name={name}
        value={value}
        error={error}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={maxLength}
        payment={payment}
        autoComplete="off"
        ref={refEl}
        disabled={disabled}
        style={style}
        inputMode={props.inputMode}
        autoFocus={autoFocus}
        className={className}
        onFocusCapture={onFocusCapture}
      />
      {error && errorPosition === 'bottom' && (
        <>
          <Margin height={8} />
          <LabelError style={{ ...styleLabelError }}>{errorText}</LabelError>
        </>
      )}
      {iconPassword && (
        <ContainerIconStyled
          error={!!(error && errorText)}
          errorPosition={errorPosition}
          onClick={() => {
            if (setPasswordVisible) {
              setPasswordVisible(!passwordVisible);
            }
          }}
        >
          {passwordVisible ? <VisiblePasswordSVG /> : <InvisiblePassword />}
        </ContainerIconStyled>
      )}
      {editIcon && (
        <ContainerIconStyled
          onClick={() => {
            if (setPasswordVisible) {
              setPasswordVisible(!passwordVisible);
            }
          }}
        >
          <EditSVG />
        </ContainerIconStyled>
      )}
    </ContainerInputStyled>
  );
};
