import { HTMLInputTypeAttribute, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';
import { COLORS } from '~/utils/constants';

export interface IPropStyled extends InputHTMLAttributes<HTMLInputTypeAttribute> {
  error?: boolean;
  borderColor?: string;
}

export const ContainerInputStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 4px;
`;

export const LabelRow = styled.div`
  margin-block-end: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* height: 21px; */
`;

export const LabelError = styled.div`
  margin-block-start: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* height: 18px; */
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.3;
  color: ${Color.red};
`;

export const InputStyled = styled.input<IPropStyled>`
  font-size: 16px;
  height: 36px;
  font-weight: 400;
  color: ${({ error }) => (error ? Color.red : Color.black)};
  border: 1px solid
    ${({ error, borderColor }) =>
      error ? Color.red : borderColor || Color.grayLightSeconde};
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;

  :focus-visible {
    outline: none;
  }

  ::-webkit-input-placeholder {
    font-weight: 300;
    font-size: 16px;
  }

  :-ms-input-placeholder {
    font-weight: 300;
    font-size: 16px;
  }

  :disabled {
    background: ${Color.white};
  }

  ::placeholder {
    font-weight: 300;
    font-size: 16px;
  }

  :read-only {
    background: ${COLORS.gray200};
  }
  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    font-size: 16px;
    height: 36px;
  }
`;

export const LabelStyled = styled.label`
  color: ${Color.blueIntense};
  font-weight: 500;
  font-size: 14px;
`;

export interface IPropsContainerIconStyled {
  error?: boolean;
}

export const ContainerIconStyled = styled.div<IPropsContainerIconStyled>`
  position: absolute;
  top: 36px;
  right: 20px;
  cursor: pointer;
`;
