import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Margin } from '~/components/Margin';
import { IState } from '~/store/modules/types';
import {
  ContainerButton,
  Subtitle,
} from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/styles';
import { Main } from './styles';
import StatusInfo from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/components/StatusInfo';
import { Label, Row, SenFiles } from '../styles';
import DropZone from '../DropZone';
import convertFileToBase64 from '~/utils/convertBase64';
import {
  patchAttachFilesCustomerObligationRequest,
  patchStatusStepCustomerObligationRequest,
  postUploadFileCustomerObligationRequest,
} from '~/store/modules/customerObligation/actions';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import { AppDispatch } from '~/store';

export interface IDocuments {
  key:
    | 'executive_project'
    | 'signaling_plan'
    | 'scheduled_works_or_repairs_request_form'
    | 'timeline'
    | 'commitment_term'
    | 'responsibility_and_waiver_term'
    | 'design_elements_declaration'
    | 'technical_justification_letter';
  label: string;
  file: null | File;
  type: 'default' | 'letter';
  id?: string;
  description?: string;
  error?: string;
}

const Form: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const idCreate = useSelector((item: IState) => item.customerObligation.idCreate);
  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);
  const isRevision = caseOC?.attachments.find(
    (attachment) => attachment.review === true,
  );
  const loadingUploadFile = useSelector(
    (item: IState) => item.customerObligation.loadingUploadFile,
  );
  const loadingAttachFiles = useSelector(
    (item: IState) => item.customerObligation.loadingAttachFiles,
  );

  const [disabled, setDisabled] = useState(true);
  const [documents, setDocuments] = useState<IDocuments[]>([
    {
      key: 'executive_project',
      label: 'Projeto Executivo*',
      file: null,
      error: '',
      type: 'default',
    },
    {
      key: 'signaling_plan',
      label: 'Planta de Sinalização*',
      file: null,
      error: '',
      type: 'default',
    },
    {
      key: 'scheduled_works_or_repairs_request_form',
      label:
        'Formulário de Requerimento para obras ou reparos programados (ANEXO I)*',
      file: null,
      error: '',
      type: 'default',
    },
    {
      key: 'timeline',
      label: 'Cronograma (ANEXO IV)*',
      file: null,
      error: '',
      type: 'default',
    },
    {
      key: 'commitment_term',
      label: 'Termo de Compromisso (ANEXO V)*',
      file: null,
      error: '',
      type: 'default',
    },
    {
      key: 'responsibility_and_waiver_term',
      label: 'Termo de Responsabilidade e Renúncia (ANEXO VII)*',
      file: null,
      error: '',
      type: 'default',
    },
    {
      key: 'design_elements_declaration',
      label: 'Declaração dos elementos de projeto (ANEXO XVIII)*',
      file: null,
      error: '',
      type: 'default',
    },
    {
      key: 'technical_justification_letter',
      label: 'Carta justificativa técnica*',
      file: null,
      error: '',
      type: 'letter',
    },
  ]);

  const handleClick = () => {
    if (caseOC?.realStep.number === 3) {
      return dispatch(
        patchAttachFilesCustomerObligationRequest({
          case: idCreate || caseOC?.id || '',
          step: '3',
        }),
      );
    }
    return dispatch(
      patchStatusStepCustomerObligationRequest({
        name: '',
        number: 3,
        substep: '',
        status: stepStatusEnum.SUCCESS,
      }),
    );
  };

  const verifyDocuments = async () => {
    if (
      (await documents.filter((document) => !document.file)).length > 0 ||
      loadingUploadFile
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  useEffect(() => {
    verifyDocuments();
  }, [documents, loadingUploadFile]);

  return (
    <>
      {!isRevision && (
        <>
          <Subtitle>
            Preencha e confira as informações abaixo para dar início ao processo.
          </Subtitle>
          <Margin height={64} />
        </>
      )}
      <Main>
        <StatusInfo status="info">
          Os arquivos devem ser anexados em <b>formato PDF</b> e com{' '}
          <a
            href="https://conservacao.prefeitura.rio/sc-cor-vias/licenciamento-documentacao/obra-programada/"
            target="_blank"
            rel="noreferrer"
          >
            Carimbo / Modelo Padrão da Prefeitura.
          </a>{' '}
          (clique para o download).
        </StatusInfo>
        <Margin height={40} />
        {documents.map((document, index: number) => (
          <Row key={document.key}>
            <SenFiles>
              <DropZone
                disabled={!!document.file}
                infoFileName={document.label}
                name={document.label}
                content={document.file || null}
                onFileUploaded={(file: File | null) => {
                  const array = [...documents];
                  if (file && file.size < 100) {
                    array[index].error = 'Por favor, verifique o seu arquivo.';
                    return setDocuments(array);
                  }
                  array[index].file = file;
                  if (file === null) {
                    array[index].error = '';
                    return setDocuments(array);
                  }
                  if (file) {
                    convertFileToBase64(file, (base64: string) => {
                      dispatch(
                        postUploadFileCustomerObligationRequest({
                          caseIdSales: idCreate || caseOC?.id || '',
                          type: document.key,
                          step: '3',
                          filename: file?.name || '',
                          filedata: base64,
                        }),
                      );
                    });
                  }

                  return setDocuments(array);
                }}
                type={document.type}
                error={!!document.error}
                errorText={document.error}
              />
            </SenFiles>
            {document.type === 'default' && (
              <Label>Arquivos devem ser anexados em formato PDF.</Label>
            )}
            {document.type === 'letter' && (
              <Label>
                Carta com Justificativas Técnicas da Empresa executora da <br /> Obra
                sobre Impossibilidade Técnica de Execução pelo M.N.D.
              </Label>
            )}
          </Row>
        ))}
      </Main>
      <Margin height={40} />
      <ContainerButton isRevision={!!isRevision}>
        <Button
          backIcon
          backgroundColor={Color.green}
          onClick={() => {
            dispatch(
              patchStatusStepCustomerObligationRequest({
                name: '',
                number: 2,
                substep: 'B',
                status: stepStatusEnum.SUCCESS,
              }),
            );
          }}
        >
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          style={{ opacity: 1 }}
          onClick={handleClick}
          loading={loadingUploadFile || loadingAttachFiles}
          disabled={disabled}
        >
          Continuar
        </Button>
      </ContainerButton>
    </>
  );
};

export default Form;
