import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '~/services/api/axiosBaseQuery';
import { IEnumCacheConfig } from './enum';
import { enrollmentsInvoicesEndpoints } from './endpoints/enrollmentsInvoicesEndpoints';
import { enrollmentsConsumptionHistoryEndpoints } from './endpoints/enrollmentsConsumptionHistoryEndpoints';
import { metricEndpoints } from './endpoints/metricEndpoints';
import { outsidesEndpoints } from './endpoints/outsidesEndpoints';
import { enrollmentsNegativeCertificatesEndpoints } from './endpoints/enrollmentsNegativeCertificatesEndpoints';
import { negotiationEndpoints } from './endpoints/negotiationEndpoints';
import { orderServicesEndpoints } from './endpoints/orderServicesEndpoints';
import { socialTariffEndpoints } from './endpoints/socialTariffEndpoints';
import { notificationsEndpoints } from './endpoints/notificationsEndpoints';
import { dpadpeEndpoints } from './endpoints/dpadpeEndpoints';

export const apiCache = createApi({
  reducerPath: 'cache',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    IEnumCacheConfig.ENROLLMENTS_INVOICES_INVOICES_LIST,
    IEnumCacheConfig.ENROLLMENTS_INVOICES_INVOICE_DETAIL,
    IEnumCacheConfig.ENROLLMENTS_INVOICES_TYPES_INVOICES,
    IEnumCacheConfig.ENROLLMENTS_INVOICES_MATURITY_DAYS,
    IEnumCacheConfig.ENROLLMENTS_INVOICES_MATURITY_DAYS_ALTERNATE,
    IEnumCacheConfig.ENROLLMENTS_CONSUMPTION_HISTORY_DATA,
    IEnumCacheConfig.METRIC_REGISTER_AUTH,
    IEnumCacheConfig.METRIC_REGISTER_OUTSIDES,
    IEnumCacheConfig.OUTSIDES_ENROLLMENTS_INVOICES_LIST,
    IEnumCacheConfig.OUTSIDES_DEBTS_CHARGES_LIST,
    IEnumCacheConfig.ENROLLMENTS_NEGATIVE_CERTIFICATES_LIST,
    IEnumCacheConfig.ENROLLMENTS_NEGATIVE_CERTIFICATES_DOWNLOAD,
    IEnumCacheConfig.NEGOTATION_DEBTS_LIST,
    IEnumCacheConfig.ORDER_SERVICES_LIST,
    IEnumCacheConfig.ORDER_SERVICES_INPUTS_LACKWATER_LIST,
    IEnumCacheConfig.SOCIAL_TARIFF_CHECK,
    IEnumCacheConfig.SOCIAL_TARIFF_RENOVATION_CHECK,
    IEnumCacheConfig.DPA_DPE_STATUSES,
    IEnumCacheConfig.DPA_DPE_LIST_CASES,
    IEnumCacheConfig.DPA_DPE_LIST_OPERATIONS,
    IEnumCacheConfig.DPA_DPE_LIST_SLIPS,
    IEnumCacheConfig.NOTIFICATIONS_LIST,
  ],
  endpoints: (builder) => ({
    ...enrollmentsInvoicesEndpoints(builder),
    ...enrollmentsConsumptionHistoryEndpoints(builder),
    ...metricEndpoints(builder),
    ...outsidesEndpoints(builder),
    ...enrollmentsNegativeCertificatesEndpoints(builder),
    ...negotiationEndpoints(builder),
    ...orderServicesEndpoints(builder),
    ...socialTariffEndpoints(builder),
    ...dpadpeEndpoints(builder),
    ...notificationsEndpoints(builder),
  }),
});

export const {
  fetchEnrollmentsInvoices,
  fetchEnrollmentsInvoiceDetails,
  fetchEnrollmentsTypesInvoices,
  fetchEnrollmentsMaturityDays,
  fetchEnrollmentsMaturityDaysAlternate,
  fetchEnrollmentsConsumptionHistory,
  fetchMetricRegisterAuth,
  fetchMetricRegisterOutsides,
  fetchOutsidesInvoicesList,
  fetchOutsidesDebtsChargesList,
  fetchNegativeCertificatesList,
  fetchNegativeCertificateDownload,
  fetchNegotiationDebtsList,
  fetchOrderServicesList,
  fetchOrderServicesInputsLackWaterList,
  fetchSocialTariffCheck,
  fetchSocialTariffRenovationCheck,
  fetchDpadpeListStatuses,
  fetchDpadpeListCases,
  fetchDpadpeListOperations,
  fetchDpadpeListSlips,
  fetchNotificationsList,
} = apiCache.endpoints;
