import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import Form from './Form';
import Status from './Status';
import Revision from './Revision';

const StartWorkDates: FC = () => {
  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);

  return (
    <>
      {caseOC?.step.status === stepStatusEnum.FORM && <Form />}
      {(caseOC?.step.status === stepStatusEnum.WAIT ||
        caseOC?.step.status === stepStatusEnum.SUCCESS) && <Status />}
      {caseOC?.step.status === stepStatusEnum.REVIEW && <Revision />}
    </>
  );
};

export default StartWorkDates;
