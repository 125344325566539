import { EndpointBuilder, BaseQueryFn } from '@reduxjs/toolkit/query';
import { IEnumCacheConfig } from '../enum';
import {
  IResponseInvoices,
  IResponseInvoicesDetail,
  IResponseInvoicesMaturityDays,
  IResponseTypesInvoices,
} from '../../modules/enrollmentsInvoices/types';
import {
  IFetchEnrollmentsInvoices,
  IFetchEnrollmentsInvoiceDetail,
  IFetchEnrollmentsInvoicesChangeData,
} from '../types';

export const enrollmentsInvoicesEndpoints = (
  builder: EndpointBuilder<BaseQueryFn, IEnumCacheConfig, 'cache'>,
) => ({
  fetchEnrollmentsInvoices: builder.query<
    IResponseInvoices,
    IFetchEnrollmentsInvoices | void
  >({
    query: (params) => ({
      url: `v3/enrollments/${params?.enrollmentId}/invoices`,
      method: 'GET',
      params: params?.params || {},
    }),
    providesTags: [IEnumCacheConfig.ENROLLMENTS_INVOICES_INVOICES_LIST],
  }),
  fetchEnrollmentsInvoiceDetails: builder.query<
    IResponseInvoicesDetail,
    IFetchEnrollmentsInvoiceDetail
  >({
    query: ({ enrollmentId, invoiceId }) => ({
      url: `v3/enrollments/${enrollmentId}/invoices/${invoiceId}`,
      method: 'GET',
    }),
    providesTags: [IEnumCacheConfig.ENROLLMENTS_INVOICES_INVOICE_DETAIL],
  }),
  fetchEnrollmentsTypesInvoices: builder.query<
    IResponseTypesInvoices,
    IFetchEnrollmentsInvoicesChangeData
  >({
    query: ({ enrollmentId }) => ({
      url: `v3/enrollments/${enrollmentId}/types-invoices`,
      method: 'GET',
    }),
    providesTags: [IEnumCacheConfig.ENROLLMENTS_INVOICES_TYPES_INVOICES],
  }),
  fetchEnrollmentsMaturityDays: builder.query<
    IResponseInvoicesMaturityDays,
    IFetchEnrollmentsInvoicesChangeData
  >({
    query: ({ enrollmentId }) => ({
      url: `v3/enrollments/${enrollmentId}/maturity-days`,
      method: 'GET',
    }),
    providesTags: [IEnumCacheConfig.ENROLLMENTS_INVOICES_MATURITY_DAYS],
  }),
  fetchEnrollmentsMaturityDaysAlternate: builder.query<
    Array<number>,
    IFetchEnrollmentsInvoicesChangeData
  >({
    query: ({ enrollmentId }) => ({
      url: `v3/enrollments/${enrollmentId}/maturity-days/alternate`,
      method: 'GET',
    }),
    providesTags: [IEnumCacheConfig.ENROLLMENTS_INVOICES_MATURITY_DAYS_ALTERNATE],
  }),
});
