import React, { FC } from 'react';
import ReactGA from 'react-ga4';
import { Main } from './styles';

import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import { useDispatch, useSelector } from 'react-redux';
import StatusWarningIconSVG from '~/assets/Icons/StatusWarning';
import { IState } from '~/store/modules/types';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { getErrorCloseOutsidesSocialTariffValidEnrollment } from '~/store/modules/outsides/actions';
import { AppDispatch } from '~/store';

interface IProps {
  onClose: () => void;
}

const ModalWarning: FC<IProps> = ({ onClose }) => {
  const dispatch = useDispatch<AppDispatch>();

  const dataSocialTariffValidEnrollment = useSelector(
    (item: IState) => item.outsides.dataSocialTariffValidEnrollment,
  );

  return (
    <Main>
      <StatusWarningIconSVG />
      {dataSocialTariffValidEnrollment?.typeSolicitation === 'changeOwnership' && (
        <>
          <h1>Titularidade incorreta</h1>
          <p>
            Essa matrícula possui outro <b>CPF/CNPJ</b> como titular.
            <br />
            <br />
            Deseja seguir e <b>solicitar a troca de titularidade</b> junto à
            solicitação de tarifa social?
          </p>
        </>
      )}
      {dataSocialTariffValidEnrollment?.typeSolicitation === 're-registration' && (
        <>
          <h1>Necessário recadastramento</h1>
          <p>
            Essa matrícula possui outro <b>CPF/CNPJ</b> como titular.
            <br />
            <br />
            Deseja seguir e <b>solicitar o recadastramento</b> junto à solicitação de
            tarifa social?
          </p>
        </>
      )}
      <ButtonText
        id="sim-modal-aviso"
        backgroundColor={Color.blueSeconde}
        typeBtn="content"
        text={
          dataSocialTariffValidEnrollment?.typeSolicitation === 'changeOwnership'
            ? 'Sim, solicitar a troca de titularidade'
            : 'Sim, solicitar recadastramento'
        }
        onClick={() => {
          ReactGA.event({
            category: 'Tarifa Social',
            action: `[Ñ Logado][Tarifa Social][Modal de Sucesso] – Botão OK`,
          });
          Navigation.navigate(RouteName.SOCIALTARIFFOUTSIDESOLICITATION, {
            ...dataSocialTariffValidEnrollment?.dataEnrollment,
            renovation: dataSocialTariffValidEnrollment?.renovation,
            typeSolicitation: dataSocialTariffValidEnrollment?.typeSolicitation,
          });
          onClose();
        }}
      />
      <ButtonText
        id="nao-modal-aviso"
        backgroundColor={Color.white}
        color="#000"
        typeBtn="content"
        text="Não, voltar"
        onClick={() => {
          ReactGA.event({
            category: 'Tarifa Social',
            action: `[Ñ Logado][Tarifa Social][Modal de Sucesso] – Botão OK`,
          });
          dispatch(getErrorCloseOutsidesSocialTariffValidEnrollment());
          onClose();
        }}
      />
    </Main>
  );
};

export default ModalWarning;
