import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContainerButtons, Main } from './styles';

import { Margin } from '~/components/Margin';
import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import { getEnrollmentsInvoicesDetailRequest } from '~/store/modules/enrollmentsInvoices/actions';
import { IState } from '~/store/modules/types';
import { clearModalAutomaticDebits } from '~/store/modules/automaticDebits/actions';
import { AppDispatch } from '~/store';

const ModalAccountDebt: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const checkModal = useSelector((item: IState) => item.automaticDebits.checkModal);

  return (
    <Main>
      <Margin height={39} />
      <p>
        Suas faturas estão com débito em conta cadastrado.
        <br /> Deseja continuar mesmo assim?
      </p>
      <Margin height={70} />
      <ContainerButtons>
        <ButtonText
          text="Cancelar"
          typeBtn="content"
          backgroundColor={Color.red}
          Color={Color.white}
          onClick={() => dispatch(clearModalAutomaticDebits())}
          style={{ height: '68px' }}
        />
        <Margin width={34} />
        <ButtonText
          text="Continuar"
          typeBtn="content"
          backgroundColor={Color.blueSeconde}
          Color={Color.white}
          onClick={() => {
            dispatch(clearModalAutomaticDebits());
            dispatch(
              getEnrollmentsInvoicesDetailRequest(checkModal?.invoice_id || 0),
            );
          }}
          style={{ height: '68px' }}
        />
      </ContainerButtons>
    </Main>
  );
};

export default ModalAccountDebt;
