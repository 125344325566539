import formatter from '~/utils/formatter';
import { api } from './api';
import {
  IRegistrationUpdate,
  IRequesDebtsPayment,
  IRequestInvoicePayment,
  IRequestQueryInvoices,
  IRequestQueryaSocialTariff,
  IRequestRegistrationUpdate,
  IRequestSocialTariff,
  IRequestSocialTariffValidEnrollment,
} from '~/store/modules/outsides/types';

function getEasyDuplicateInvoicesService(
  cnp: string,
  enrollmentId: string,
  params?: IRequestQueryInvoices,
) {
  return new Promise((resolve, reject) => {
    try {
      const getEasyDuplicateInvoices: any = async () => {
        const response = await api.get(
          `v3/outsides/v2/enrollments/${formatter.formatarNumber(enrollmentId)}/invoices`,
          {
            headers: {
              'x-cnp': formatter.formatarNumber(cnp),
            },
            params: {
              ...params,
            },
          },
        );

        return response.data;
      };

      resolve(getEasyDuplicateInvoices());
    } catch (error) {
      reject(error);
    }
  });
}

function getCredentialsByTokenService(token: string) {
  return new Promise((resolve, reject) => {
    try {
      const getCredentialsByToken: any = async () => {
        const response = await api.get(`/v3/outsides/tokens/${token}`);

        return response.data;
      };

      resolve(getCredentialsByToken());
    } catch (error) {
      reject(error);
    }
  });
}

function outsideTogglesServices() {
  return new Promise((resolve, reject) => {
    try {
      const getToggles: any = async () => {
        // realizando a busca dos toggles disponíveis
        const response = await api.get('v3/outsides/toggles');
        return response.data;
      };
      resolve(getToggles());
    } catch (error) {
      reject(error);
    }
  });
}

function getOutsidesInvoicesInstallmentsService(cnp: string, amount: number) {
  return new Promise((resolve, reject) => {
    try {
      const getOutsidesInvoicesInstallmentsService: any = async () => {
        const response = await api.get(`v3/outsides/installments`, {
          headers: {
            'x-cnp': formatter.formatarNumber(cnp),
          },
          params: {
            amount,
          },
        });

        return response.data;
      };

      resolve(getOutsidesInvoicesInstallmentsService());
    } catch (error) {
      reject(error);
    }
  });
}

function postOutsidesInvoicePaymentService(
  data: IRequestInvoicePayment,
  enrollmentId: string,
  cnp: string,
) {
  return new Promise((resolve, reject) => {
    try {
      const postEnrollmentsInvoiceGroupPayment: any = async () => {
        const response = await api.post(
          `/v3/outsides/enrollments/${formatter.removeDigitEnrollment(
            enrollmentId,
          )}/invoices/payment`,
          {
            ...data,
          },
          {
            headers: {
              'x-cnp': formatter.formatarNumber(cnp),
            },
          },
        );

        return response.data;
      };

      resolve(postEnrollmentsInvoiceGroupPayment());
    } catch (error) {
      reject(error);
    }
  });
}

function getOutsidesInvoicePaymentVoucherService(
  enrollmentId: string,
  invoice_group_id: number,
  cnp: string,
  isGroup?: boolean,
) {
  return new Promise((resolve, reject) => {
    try {
      const getEnrollmentsInvoiceGroupPaymentVoucher: any = async () => {
        const response = await api.get(
          `v3/outsides/enrollments/${formatter.removeDigitEnrollment(enrollmentId)}/invoices${
            isGroup ? '/group/' : '/'
          }${invoice_group_id}/payment/voucher`,
          {
            headers: {
              'x-cnp': formatter.formatarNumber(cnp),
            },
          },
        );

        return response.data;
      };

      resolve(getEnrollmentsInvoiceGroupPaymentVoucher());
    } catch (error) {
      reject(error);
    }
  });
}

function getDischargeDebtsService(cnp: string, params?: IRequestQueryInvoices) {
  return new Promise((resolve, reject) => {
    try {
      const getDischargeDebtsS: any = async () => {
        const response = await api.get(`v3/outsides/v2/debts`, {
          headers: {
            'x-cnp': formatter.formatarNumber(cnp),
          },
          params: {
            ...params,
          },
        });

        return response.data;
      };

      resolve(getDischargeDebtsS());
    } catch (error) {
      reject(error);
    }
  });
}

function postDischargeDebtsService(data: IRequesDebtsPayment, cnp: string) {
  return new Promise((resolve, reject) => {
    try {
      const postDischargeDebtsPayment: any = async () => {
        const response = await api.post(
          'v3/outsides/debts/payment',
          {
            ...data,
            enrollment_id: formatter.removeDigitEnrollment(data.enrollment_id),
          },
          {
            headers: {
              'x-cnp': formatter.formatarNumber(cnp),
            },
          },
        );

        return response.data;
      };

      resolve(postDischargeDebtsPayment());
    } catch (error) {
      reject(error);
    }
  });
}

function getEasyDischargeDebtsService(cnp: string, params?: IRequestQueryInvoices) {
  return new Promise((resolve, reject) => {
    try {
      const getDischargeDebtsS: any = async () => {
        const response = await api.get(`v3/outsides/debts/charges`, {
          headers: {
            'x-cnp': formatter.formatarNumber(cnp),
          },
          params: {
            ...params,
          },
        });

        return response.data;
      };

      resolve(getDischargeDebtsS());
    } catch (error) {
      reject(error);
    }
  });
}

function getOutsidesRegistrationUpdateService(cnp: string) {
  return new Promise((resolve, reject) => {
    try {
      const getOutsidesRegistrationUpdateService: any = async () => {
        const response = await api.get(`v3/outsides/registration-update`, {
          headers: {
            'x-cnp': formatter.formatarNumber(cnp),
          },
        });

        return response.data;
      };

      resolve(getOutsidesRegistrationUpdateService());
    } catch (error) {
      reject(error);
    }
  });
}

function postOutsidesRegistrationUpdateService(
  cnp: string,
  data: IRequestRegistrationUpdate,
) {
  return new Promise((resolve, reject) => {
    try {
      const postOutsidesRegistrationUpdateService: any = async () => {
        const response = await api.post(
          `v3/outsides/registration-update`,
          { ...data },
          {
            headers: {
              'x-cnp': formatter.formatarNumber(cnp),
            },
          },
        );

        return response.data;
      };

      resolve(postOutsidesRegistrationUpdateService());
    } catch (error) {
      reject(error);
    }
  });
}

function getOutsidesSocialTariffEnrollmentsService(cnp: string) {
  return new Promise((resolve, reject) => {
    try {
      const getOutsidesSocialTariffEnrollments: any = async () => {
        const response = await api.get(`v3/outsides/social-tariff/enrollments`, {
          headers: {
            'x-cnp': formatter.formatarNumber(cnp),
          },
        });

        return response.data;
      };

      resolve(getOutsidesSocialTariffEnrollments());
    } catch (error) {
      reject(error);
    }
  });
}

function getOutsidesSocialTariffValidEnrollmentService(
  data: IRequestSocialTariffValidEnrollment,
) {
  return new Promise((resolve, reject) => {
    try {
      const getOutsidesSocialTariffValidEnrollment: any = async () => {
        const response = await api.get(
          `v3/outsides/social-tariff/enrollment/${formatter.removeDigitEnrollment(
            data.enrollment,
          )}`,
          {
            headers: {
              'x-cnp': formatter.formatarNumber(data.cnp),
            },
          },
        );

        return response.data;
      };

      resolve(getOutsidesSocialTariffValidEnrollment());
    } catch (error) {
      reject(error);
    }
  });
}

function postOutsidesSocialTariffService(data: IRequestSocialTariff) {
  return new Promise((resolve, reject) => {
    try {
      const postOutsidesSocialTariff: any = async () => {
        const response = await api.post('v3/outsides/social-tariff', {
          ...data,
          cnp: formatter.formatarNumber(data.cnp),
          holder: {
            ...data.holder,
            cellphone: `55${formatter.formatarNumber(data.holder.cellphone)}`,
          },
          residence: {
            ...data.residence,
            zipCode: formatter.formatarNumber(data.residence.zipCode),
          },
        });

        return response.data;
      };

      resolve(postOutsidesSocialTariff());
    } catch (error) {
      reject(error);
    }
  });
}

function getOutsidesDowloadInvoiceService(
  invoiceId: string | number,
  cnp: string,
  enrollmentId: string,
) {
  return new Promise((resolve, reject) => {
    try {
      const getOutsidesDowloadInvoiceService: any = async () => {
        const response = await api.get(
          `v3/outsides/enrollments/${enrollmentId}/invoices/${invoiceId}/type/invoice/download`,
          {
            headers: {
              'x-cnp': formatter.formatarNumber(cnp),
            },
            responseType: 'blob',
          },
        );

        return response.data;
      };

      resolve(getOutsidesDowloadInvoiceService());
    } catch (error) {
      reject(error);
    }
  });
}

function getOutsidesDowloadDebtsService(invoiceId: string | number, cnp: string) {
  return new Promise((resolve, reject) => {
    try {
      const getOutsidesDowloadInvoiceService: any = async () => {
        const response = await api.get(
          `v3/outsides/invoices/${invoiceId}/type/debts/download`,
          {
            headers: {
              'x-cnp': formatter.formatarNumber(cnp),
            },
            responseType: 'blob',
          },
        );

        return response.data;
      };

      resolve(getOutsidesDowloadInvoiceService());
    } catch (error) {
      reject(error);
    }
  });
}

function getStatesAddressService(cnp: string, params?: IRequestQueryaSocialTariff) {
  return new Promise((resolve, reject) => {
    try {
      const getStatesAddress: any = async () => {
        const response = await api.get(`v3/utils/states`, {
          headers: {
            'x-cnp': formatter.formatarNumber(cnp),
          },
          params: {
            ...params,
          },
        });

        return response.data;
      };

      resolve(getStatesAddress());
    } catch (error) {
      reject(error);
    }
  });
}

function getCitiesAddressService(cnp: string, params?: IRequestQueryaSocialTariff) {
  return new Promise((resolve, reject) => {
    try {
      const getCitiesAddress: any = async () => {
        const response = await api.get(`v3/utils/cities`, {
          headers: {
            'x-cnp': formatter.formatarNumber(cnp),
          },
          params: {
            ...params,
          },
        });

        return response.data;
      };

      resolve(getCitiesAddress());
    } catch (error) {
      reject(error);
    }
  });
}

function postUnloggedSession(
  cnp: string,
  tokenType:
    | 'forgotPasswordAndRegistration'
    | 'easyInvoiceAndDebts'
    | 'easyInvoiceAndDebtsSansys',
): Promise<{ token: string }> {
  return new Promise((resolve, reject) => {
    try {
      const postUnloggedSession: any = async () => {
        const response = await api.post(`v3/outsides/sessions/${tokenType}`, {
          headers: {
            'x-cnp': formatter.formatarNumber(cnp),
          },
        });

        return response.data;
      };

      resolve(postUnloggedSession());
    } catch (error) {
      reject(error);
    }
  });
}

function getPartnersService(cnp: string) {
  return new Promise((resolve, reject) => {
    try {
      const getPartners: any = async () => {
        const response = await api.get(`v3/outsides/partners`, {
          headers: {
            'x-cnp': formatter.formatarNumber(cnp),
          },
        });

        return response.data;
      };

      resolve(getPartners());
    } catch (error) {
      reject(error);
    }
  });
}

export {
  getEasyDuplicateInvoicesService,
  outsideTogglesServices,
  getOutsidesInvoicesInstallmentsService,
  postOutsidesInvoicePaymentService,
  getOutsidesInvoicePaymentVoucherService,
  getDischargeDebtsService,
  postDischargeDebtsService,
  getEasyDischargeDebtsService,
  getOutsidesRegistrationUpdateService,
  postOutsidesRegistrationUpdateService,
  getCredentialsByTokenService,
  getOutsidesSocialTariffEnrollmentsService,
  getOutsidesSocialTariffValidEnrollmentService,
  postOutsidesSocialTariffService,
  getOutsidesDowloadInvoiceService,
  getOutsidesDowloadDebtsService,
  getStatesAddressService,
  getCitiesAddressService,
  postUnloggedSession,
  getPartnersService,
};
