import React, { FC } from 'react';
import { Checkbox } from '~/components/CheckboxV3';
import { Data } from '..';
import { Container, ContainerAlert, ContainerCheckBox } from './styles';
import { DescriptionText } from '../styles';

import InfoBox from '~/components/InfoBox';

interface IProps {
  data: Data | null;
  setData: (type: any) => void;
}

export const typesReportLackWaterStepTwo = [
  {
    id: 1,
    label: 'Desobstrução de esgoto',
  },
  {
    id: 2,
    label: 'Falta d’água',
  },
  {
    id: 3,
    label: 'Vazamento de água na via',
  },
  {
    id: 4,
    label: 'Vazamento de cavalete',
  },
  {
    id: 5,
    label: 'Vazamento de esgoto na via',
  },
];

const ReportLackWaterStepTwo: FC<IProps> = ({ data, setData }) => {
  return (
    <Container>
      <ContainerAlert>
        <InfoBox label="Para demais reparos, entre em contato pelo">
          {' '}
          fale conosco.
        </InfoBox>
      </ContainerAlert>
      <DescriptionText>Identifique o problema:</DescriptionText>
      <ContainerCheckBox>
        {typesReportLackWaterStepTwo?.map((item) => (
          <Checkbox
            key={item?.id}
            checked={data ? data?.screening === item.id : false}
            // onClick={() => setData({ ...data, screening: item?.id })}
          >
            {item.label}
          </Checkbox>
        ))}
      </ContainerCheckBox>
    </Container>
  );
};

export default ReportLackWaterStepTwo;
