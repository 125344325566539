import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';

import { Routes, Route, useLocation } from 'react-router-dom';

import { RouteName } from './Routes.name';

import ForgotPassword from '~/views/ForgotPassword';
import ForgotPasswordMethod from '~/views/ForgotPasswordMethod';
import ForgotPasswordCheckCode from '~/views/ForgotPasswordCheckCode';
import ForgotPasswordNewPassword from '~/views/ForgotPasswordNewPassword';
import Landing from '~/views/Landing';
import Login from '~/views/Login';
import Register from '~/views/Register';
import RegisterVerificationMethod from '~/views/RegisterVerificationMethod';
import RegisterCheckCode from '../views/RegisterCheckCode';
import RegisterClientPassword from '~/views/RegisterClientPassword';
import RegisterNumberPhone from '~/views/RegisterNumberPhone';
import RegisterEntrepreneur from '~/views/RegisterEntrepreneur';
import RegisterNotClient from '~/views/RegisterNotClient';
import RegisterManager from '~/views/RegisterManager';
import RegisterProfile from '~/views/RegisterProfile';
import GlobalLayout from '~/components/GlobalLayout';
import EasyDuplicate from '~/views/EasyDuplicate';
import EasyDuplicateSinglePayment from '~/views/EasyDuplicateSinglePayment';
import EasyDuplicateSinglePaymentDataPayment from '~/views/EasyDuplicateSinglePaymentDataPayment';
import EasyDuplicateSinglePaymentDataPaymentReceipt from '~/views/EasyDuplicateSinglePaymentDataPaymentReceipt';
import { Dialog } from '~/components/Dialog';

import { IState } from '~/store/modules/types';
import { getTogglesRequest } from '~/store/modules/toggles/actions';
import {
  clearModalDataUpdate,
  clearModalSalesforceSatisfaction,
  postErrorCloseOutsidesRegistrationUpdate,
} from '~/store/modules/outsides/actions';
import PasswordExpirationNewPassword from '~/views/PasswordExpirationNewPassword';
import PasswordExpiration from '~/views/PasswordExpiration';
import PasswordExpirationCheckCode from '~/views/PasswordExpirationCheckCode';
import ModalSalesforceSatisfaction from '~/components/ModalSalesforceSatisfaction';
import { checkToggle } from '~/utils/toggles';
import DischargeDebts from '~/views/DischargeDebts';
import DischargeDebtsSinglePaymentReceipt from '~/views/DischargeDebtsSinglePaymentReceipt';
import SupplyAndDepletionPossibilityDeclarationLanding from '~/views/SupplyAndDepletionPossibilityDeclaration/outside/landing';
import ForgotPasswordSuccess from '~/views/ForgotPasswordSuccess';
import ModalCopyDataUpdate from '~/components/ModalCopyDataUpdate';
import Modal from '~/components/Modal';
import ModalError from '~/components/ModalError';
import Versions from '~/views/Versions';
import SocialTariffOutside from '~/views/SocialTariffOutside';
import SocialTariffOutsideDetail from '~/views/SocialTariffOutsideDetail';
import SocialTariffOutsideSolicitation from '~/views/SocialTariffOutsideSolicitation';
import { apiHeaderSetSession } from '~/utils/apiSession';
import DebtNegotiation from '~/views/DebtNegotiation';
import DebtNegotiationSinglePayment from '~/views/DebtNegotiationSinglePayment';
import DebtNegotiationSinglePaymentDataPayment from '~/views/DebtNegotiationSinglePaymentDataPayment';
import DebtNegotiationTypePayment from '~/views/DebtNegotiationTypePayment';
import DebtNegotiationSimulation from '~/views/DebtNegotiationSimulation';
import DebtNegotiationDetail from '~/views/DebtNegotiationDetail';
import { apiCache } from '~/store/cache/apiCache';
import { handleRedirectIfNotAuthenticated } from './routeUtils';
import { AppDispatch } from '~/store';

function RouteAppWithLayout({ Component, fullHeader }: any) {
  const dispatch = useDispatch<AppDispatch>();

  const modalSalesforceSatisfaction = useSelector(
    (item: IState) => item.outsides.modalSalesforceSatisfaction,
  );
  const modalDataUpdate = useSelector(
    (item: IState) => item.outsides.modalDataUpdate,
  );
  const fromTypeRegistrationUpdate = useSelector(
    (item: IState) => item.outsides.fromTypeRegistrationUpdate,
  );
  const errorRegistrationUpdate = useSelector(
    (item: IState) => item.outsides.errorRegistrationUpdate,
  );
  const toggles = useSelector((item: IState) => item.toggles.data);
  const [satisfactionSurvey, setSatisfactionSurvey] = useState<boolean>(false);
  const [dataUpdate] = useState<boolean>(true);

  const verifyToggles = async () => {
    setSatisfactionSurvey(
      await checkToggle(toggles, 'SATISFACTION_SURVEY_NOT_LOGGED'),
    );
  };

  useEffect(() => {
    if (toggles && toggles.length > 0) verifyToggles();
  }, [toggles]);

  useEffect(() => {
    if (errorRegistrationUpdate && errorRegistrationUpdate?.state) {
      if (fromTypeRegistrationUpdate) {
        if (fromTypeRegistrationUpdate === 'pixCode') {
          ReactGA.event({
            category: 'Atualização cadastral',
            action: '[WEB][AC][Copiar][Pix] Erro na Atualização Cadastral',
          });
        } else {
          ReactGA.event({
            category: 'Atualização cadastral',
            action: '[WEB][AC][Copiar][Boleto] Erro na Atualização Cadastral',
          });
        }
      } else {
        ReactGA.event({
          category: 'Atualização cadastral',
          action: '[WEB][AC][CP] Erro na Atualização Cadastral',
        });
      }
    }
  }, [errorRegistrationUpdate]);

  return (
    <GlobalLayout fullHeader={fullHeader}>
      {satisfactionSurvey && modalSalesforceSatisfaction && (
        <Dialog
          open={modalSalesforceSatisfaction}
          onClose={async () => dispatch(clearModalSalesforceSatisfaction())}
        >
          <ModalSalesforceSatisfaction />
        </Dialog>
      )}
      {dataUpdate && modalDataUpdate && (
        <Dialog
          open={modalDataUpdate}
          onClose={async () => dispatch(clearModalDataUpdate())}
        >
          <ModalCopyDataUpdate />
        </Dialog>
      )}
      {errorRegistrationUpdate && (
        <Modal
          open={errorRegistrationUpdate && errorRegistrationUpdate?.state}
          type="error"
          title="Ops!"
          v3
        >
          <ModalError
            onClose={() => dispatch(postErrorCloseOutsidesRegistrationUpdate())}
          />
        </Modal>
      )}
      <Component />
    </GlobalLayout>
  );
}

const AuthRoutes: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { pathname } = useLocation();

  useEffect(() => {
    handleRedirectIfNotAuthenticated(pathname);
    apiHeaderSetSession();
    dispatch(apiCache.util.resetApiState());
  }, []);

  useEffect(() => {
    dispatch(getTogglesRequest());
  }, [dispatch]);

  return (
    <Routes>
      <Route
        element={<RouteAppWithLayout Component={Versions} />}
        path={RouteName.VERSIONS}
      />
      <Route
        element={<RouteAppWithLayout Component={Landing} />}
        path={RouteName.LANDING}
      />
      <Route
        element={<RouteAppWithLayout Component={Login} />}
        path={RouteName.LOGIN}
      />
      <Route
        element={<RouteAppWithLayout Component={RegisterProfile} />}
        path={RouteName.REGISTERPROFILE}
      />
      <Route
        element={<RouteAppWithLayout Component={Register} />}
        path={RouteName.REGISTER}
      />
      <Route
        element={<RouteAppWithLayout Component={ForgotPassword} />}
        path={RouteName.FORGOTPASSWORD}
      />
      <Route
        element={<RouteAppWithLayout Component={ForgotPasswordMethod} />}
        path={RouteName.FORGOTPASSWORDPHONE}
      />
      <Route
        element={<RouteAppWithLayout Component={ForgotPasswordCheckCode} />}
        path={RouteName.FORGOTPASSWORDCODE}
      />
      <Route
        element={<RouteAppWithLayout Component={ForgotPasswordNewPassword} />}
        path={RouteName.FORGOTPASSWORDUPDATE}
      />
      <Route
        element={<RouteAppWithLayout Component={ForgotPasswordSuccess} />}
        path={RouteName.FORGOTPASSWORDUPDATESUCCESS}
      />
      <Route
        element={<RouteAppWithLayout Component={RegisterVerificationMethod} />}
        path={RouteName.REGISTERVERIFICATIONMETHOD}
      />
      <Route
        element={<RouteAppWithLayout Component={RegisterNumberPhone} />}
        path={RouteName.REGISTERUMBERPHONE}
      />
      <Route
        element={<RouteAppWithLayout Component={RegisterNumberPhone} />}
        path={RouteName.REGISTEREMAIL}
      />
      <Route
        element={<RouteAppWithLayout Component={RegisterCheckCode} />}
        path={RouteName.REGISTERTWOVERSIOVERIFYCODE}
      />
      <Route
        element={<RouteAppWithLayout Component={RegisterClientPassword} />}
        path={RouteName.REGISTERPASSWORDCLIENT}
      />
      <Route
        element={<RouteAppWithLayout Component={RegisterEntrepreneur} />}
        path={RouteName.REGISTERENTREPRENEUR}
      />
      <Route
        element={<RouteAppWithLayout Component={RegisterNotClient} />}
        path={RouteName.REGISTERNOTCLIENT}
      />
      <Route element={<RegisterManager />} path={RouteName.REGISTERMANAGER} />
      <Route
        element={<RouteAppWithLayout Component={EasyDuplicate} />}
        path={RouteName.EASYDUPLICATE}
      />
      <Route
        element={<RouteAppWithLayout Component={EasyDuplicateSinglePayment} />}
        path={RouteName.EASYDUPLICATESINGLEPAYMENT}
      />
      <Route
        element={
          <RouteAppWithLayout Component={EasyDuplicateSinglePaymentDataPayment} />
        }
        path={RouteName.EASYDUPLICATESINGLEPAYMENTDATAPAYMENT}
      />

      <Route
        element={
          <RouteAppWithLayout
            Component={EasyDuplicateSinglePaymentDataPaymentReceipt}
          />
        }
        path={RouteName.EASYDUPLICATESINGLEPAYMENTDATAPAYMENTRECEIPT}
      />
      <Route
        element={<RouteAppWithLayout Component={EasyDuplicate} />}
        path={RouteName.EASYDUPLICATETOKEN}
      />

      <Route
        element={<RouteAppWithLayout Component={PasswordExpiration} />}
        path={RouteName.PASSWORDEXPIRATION}
      />
      <Route
        element={<RouteAppWithLayout Component={PasswordExpirationCheckCode} />}
        path={RouteName.PASSWORDEXPIRATIONVERIFYCODE}
      />
      <Route
        element={<RouteAppWithLayout Component={PasswordExpirationNewPassword} />}
        path={RouteName.PASSWORDEXPIRATIONNEWPASSWORD}
      />
      <Route
        element={<RouteAppWithLayout Component={DischargeDebts} />}
        path={RouteName.DISCHARGEDEBTS}
      />
      <Route
        element={
          <RouteAppWithLayout Component={DischargeDebtsSinglePaymentReceipt} />
        }
        path={RouteName.DISCHARGEDEBTSSINGLEPAYMENTDATAPAYMENTRECEIPT}
      />
      <Route
        element={<RouteAppWithLayout Component={DebtNegotiation} />}
        path={RouteName.DEBTNEGOTIATION}
      />
      <Route
        element={<RouteAppWithLayout Component={DebtNegotiationSinglePayment} />}
        path={RouteName.DEBTNEGOTIATIONSINGLEPAYMENT}
      />
      <Route
        element={
          <RouteAppWithLayout Component={DebtNegotiationSinglePaymentDataPayment} />
        }
        path={RouteName.DEBTNEGOTIATIONSINGLEPAYMENTDATAPAYMENT}
      />
      <Route
        element={<RouteAppWithLayout Component={DebtNegotiationTypePayment} />}
        path={RouteName.DEBTNEGOTIATIONTYPEPAYMENT}
      />
      <Route
        element={<RouteAppWithLayout Component={DebtNegotiationSimulation} />}
        path={RouteName.DEBTNEGOTIATIONSIMULATION}
      />
      <Route
        element={<RouteAppWithLayout Component={DebtNegotiationDetail} />}
        path={RouteName.DEBTNEGOTIATIONDETAIL}
      />
      <Route
        element={
          <RouteAppWithLayout
            Component={SupplyAndDepletionPossibilityDeclarationLanding}
          />
        }
        path={RouteName.SUPPLYDEPLETIONPOSSIBILITYDECLARATIONLANDING}
      />
      <Route
        element={<RouteAppWithLayout Component={SocialTariffOutside} />}
        path={RouteName.SOCIALTARIFFOUTSIDE}
      />
      <Route
        element={<RouteAppWithLayout Component={SocialTariffOutsideDetail} />}
        path={RouteName.SOCIALTARIFFOUTSIDEDETAIL}
      />
      <Route
        element={<RouteAppWithLayout Component={SocialTariffOutsideSolicitation} />}
        path={RouteName.SOCIALTARIFFOUTSIDESOLICITATION}
      />
    </Routes>
  );
};

export default AuthRoutes;
