import React, { Dispatch, FC, SetStateAction } from 'react';
import ReactGA from 'react-ga4';

import { Color } from '~/styles';
import ContinueButton from '../ContinueButton';
import BackButton from '../BackButton';
import {
  ButtonPromotionalDiscounts,
  Main,
  RowTextSummaryNegociation,
  SummaryNegociation,
  TextButtonDiscounts,
  TextSummaryNegociation,
} from './styles';
import { DiscountIcon } from '../Icons';
import { history } from '~/store';
import { IDebtNegotiationSimulationHistory } from '..';
import { useDispatch, useSelector } from 'react-redux';
import { getDebtsNegotiationTermsRequest } from '~/store/modules/negotiation/actions';
import formatter from '~/utils/formatter';
import { IState } from '~/store/modules/types';
import { AppDispatch } from '~/store';

interface IStepThreeProps {
  setStep: Dispatch<SetStateAction<number>>;
  setOpenTerm: Dispatch<SetStateAction<boolean>>;
  entryValue: number;
  installment: string;
}

const StepThree: FC<IStepThreeProps> = ({
  setStep,
  setOpenTerm,
  entryValue,
  installment,
}) => {
  const data = history?.location?.state as IDebtNegotiationSimulationHistory;

  const dispatch = useDispatch<AppDispatch>();

  const enrollmentCheck = useSelector(
    (item: IState) => item.negotiation.enrollmentCheck,
  );

  const handleBackOnClick = () => {
    ReactGA.event({
      category: 'Negociação de dívidas',
      action:
        '[Negociação de dívidas][Simule uma negociação] Etapa 03 – Simular outra negociação',
    });

    setStep(0);
  };

  const handleContinue = () => setOpenTerm(true);

  const handleOnClick = () => {
    ReactGA.event({
      category: 'Negociação de dívidas',
      action:
        '[Negociação de dívidas][Simule uma negociação] Etapa 03 – Quero negociar.',
    });

    dispatch(
      getDebtsNegotiationTermsRequest({
        enrollment: enrollmentCheck?.enrollment || '',
        operationId: enrollmentCheck?.operationId || 0,
        handleContinue,
      }),
    );
  };

  return (
    <>
      <Main>
        <ButtonPromotionalDiscounts type="button">
          <div>
            <DiscountIcon />
          </div>
          <TextButtonDiscounts>
            Você ganhou{' '}
            {data?.campaignPaymentConditions?.maximumDiscountAmountPercent}% de
            desconto
          </TextButtonDiscounts>
        </ButtonPromotionalDiscounts>
        <SummaryNegociation>
          <RowTextSummaryNegociation>
            <TextSummaryNegociation>Entrada:</TextSummaryNegociation>
            <TextSummaryNegociation>
              {formatter.formatCurrency(entryValue)}
            </TextSummaryNegociation>
          </RowTextSummaryNegociation>
          <RowTextSummaryNegociation>
            <TextSummaryNegociation>Parcelamento:</TextSummaryNegociation>
            <TextSummaryNegociation>{installment}</TextSummaryNegociation>
          </RowTextSummaryNegociation>
        </SummaryNegociation>
      </Main>
      <ContinueButton
        label="Quero negociar"
        backgroundColor={Color?.blueSeconde}
        onClick={handleOnClick}
      />
      <BackButton label="Simular outra negociação" onClick={handleBackOnClick} />
    </>
  );
};

export default StepThree;
