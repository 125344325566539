import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Margin } from '~/components/Margin';

import {
  Main,
  Card,
  ContainerBreadCrumb,
  // AddressText,
  // EnrollmentText,
} from './styles';
import { Banner } from './Banner';
import CardTitle from './CardTitle';
import MyPendingIssues from './MyPendingIssues';
// import PendingIssuesAvailableNegotiation from './PendingIssuesAvailableNegotiation';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import { IState } from '~/store/modules/types';
import LoadingComponent from '~/components/Loading';
import { INegotationProps } from '../types';
import {
  getDebtsNegotiationAuthRequest,
  getErrorCloseDebtsNegotiation,
  postErrorCloseDebtsNegotiation,
} from '~/store/modules/negotiation/actions';
import { getErrorCloseDischargeDebts } from '~/store/modules/outsides/actions';
import { AppDispatch } from '~/store';

const NegotiationHome: FC<INegotationProps> = ({ links }) => {
  const dispatch = useDispatch<AppDispatch>();

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });
  const isTablet = useMediaQuery({ maxWidth: Breakpoints.laptop.max - 1 });

  const token = useSelector((item: IState) => item.enrollmentsAuth.token);
  const loading = useSelector(
    (item: IState) => item.negotiation.loadingDebtsNegotiation,
  );
  // const dataDebtsEnrollments = useSelector(
  //   (item: IState) => item.negotiation.dataDebtsEnrollments,
  // );

  // const showSelect = dataDebtsEnrollments?.length > 1;

  // const getEnrollments = () => {
  //   const enrollments =
  //     dataDebtsEnrollments?.map((enrollment) => {
  //       return {
  //         value: enrollment?.enrollment,
  //         label: (
  //           <>
  //             <EnrollmentText>{enrollment?.enrollment}</EnrollmentText>
  //             <AddressText>{enrollment.address}.</AddressText>
  //           </>
  //         ),
  //       };
  //     }) || [];

  //   return enrollments;
  // };

  useEffect(() => {
    if (token) {
      dispatch(getDebtsNegotiationAuthRequest());
      dispatch(getErrorCloseDischargeDebts());
    }

    dispatch(getErrorCloseDebtsNegotiation());
    dispatch(postErrorCloseDebtsNegotiation());
  }, []);

  return (
    <Main>
      {loading && <LoadingComponent />}
      {!isMobile && !isTablet && (
        <ContainerBreadCrumb>
          <BreadCrumb links={links} />
        </ContainerBreadCrumb>
      )}
      <Banner />
      <Card isBorder>
        <CardTitle label="Minhas pendências" showDescription />
        <MyPendingIssues />
      </Card>
      <Margin height={16} />
      {/* <Card>
        <CardTitle
          label="Pendências disponíveis para negociação"
          showSelect={showSelect}
          enrollments={getEnrollments()}
        />
        <PendingIssuesAvailableNegotiation />
      </Card>
      <Margin height={16} /> */}
    </Main>
  );
};

export default NegotiationHome;
