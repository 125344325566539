import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import {
  getDataDownloadFileCustomerObligationRequest,
  patchStatusStepCustomerObligationRequest,
} from '~/store/modules/customerObligation/actions';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import StatusInfo from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/components/StatusInfo';
import { Margin } from '~/components/Margin';
import { ContainerButton } from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/styles';
import LoadingComponent from '~/components/Loading';
import { Main } from './styles';
import { AppDispatch } from '~/store';

const Status: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);
  const loadingDownloadFile = useSelector(
    (item: IState) => item.customerObligation.loadingDownloadFile,
  );

  const [disabled, setDisabled] = useState(true);

  const handleClick = () => {
    if (caseOC?.realStep.number === 5 && caseOC?.realStep.substep === 'B') {
      return dispatch(
        patchStatusStepCustomerObligationRequest({
          name: '',
          number: 5,
          substep: 'B',
          status: caseOC?.realStep.status,
        }),
      );
    }
    return dispatch(
      patchStatusStepCustomerObligationRequest({
        name: '',
        number: 5,
        substep: 'B',
        status: stepStatusEnum.FORM,
      }),
    );
  };

  const successStatus = (
    <StatusInfo
      status="success"
      onClick={() => {
        setDisabled(false);
        dispatch(
          getDataDownloadFileCustomerObligationRequest({
            idFile: caseOC?.approvedDocuments.LETTER_SIGNED || '',
          }),
        );
      }}
      labelButton="Baixar Anexo 13 Assinado"
      maxWidthButton={272}
    >
      Os documentos foram gerados. Faça o download da
      <br />
      documentação antes de seguir com sua solicitação.
    </StatusInfo>
  );

  const acceptedDateStatus = (
    <StatusInfo title="Data Aceita!" status="success">
      Aguarde até a data escolhida e o técnico da iguá Rio irá até o local para
      vistoriar a conclusão da obra. Após a aprovação da conclusão será liberado o
      anexo 13 assinado para download.
    </StatusInfo>
  );

  const waitStatus = (
    <StatusInfo status="wait">
      Os documentos foram enviados para análise. Você pode acompanhar o andamento na
      tela inicial da <b>Viabilidade de Empreendimento.</b>
    </StatusInfo>
  );

  const statusesMap: Map<stepStatusEnum | undefined, React.JSX.Element> = new Map([
    [stepStatusEnum.SUCCESS, successStatus],
    [stepStatusEnum.ACCEPTED_DATE, acceptedDateStatus],
    [stepStatusEnum.WAIT, waitStatus],
  ]);

  const statusElement = statusesMap.get(caseOC?.step.status);

  return (
    <Main>
      {loadingDownloadFile && <LoadingComponent labelWait="baixando..." />}
      {statusElement}
      <Margin height={40} />
      <ContainerButton>
        <Button
          backIcon
          backgroundColor={Color.green}
          onClick={() => {
            dispatch(
              patchStatusStepCustomerObligationRequest({
                name: '',
                number: 4,
                substep: '',
                status: stepStatusEnum.SUCCESS,
              }),
            );
          }}
        >
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          style={{ opacity: 1 }}
          onClick={handleClick}
          disabled={disabled}
        >
          Continuar
        </Button>
      </ContainerButton>
    </Main>
  );
};

export default Status;
