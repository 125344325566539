import styled from 'styled-components';
import { Color } from '~/styles';

export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StepContainer = styled.div<{ connect?: boolean; toBottom?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ connect }) => connect && `margin-left: -14px;`};
`;

export const StepTitle = styled.div<{ active?: boolean }>`
  height: 40px;
  width: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0px;

  font-size: 10px;
  font-weight: 500;
  color: ${({ active }) => (active ? Color.blueIntense : Color.grayLightSeconde)};
  text-align: center;
`;

export const CircleContainer = styled.div<{ connect?: boolean; position: string }>`
  position: relative;
  height: 105px;
  display: flex;

  ${({ position }) => position === 'bottom' && `align-items: end;`};
`;

export const Circle = styled.div<{ active?: boolean }>`
  z-index: 10;
  position: absolute;
  left: 14px;
  top: 14px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial;
  font-weight: 600;
  font-size: 14pt;
  border: 1px solid ${Color.greenLight};
  background-color: ${({ active }) => (active ? Color.greenLight : Color.white)};
  color: ${({ active }) => (active ? Color.white : Color.greenLight)};
`;

export const HalfCircle = styled.div<{
  position: string;
  active?: boolean;
  index: number;
}>`
  z-index: ${({ index }) => `${index};`}
  width: 105px;
  height: 53px;

  background: ${({ active }) => (active ? Color.green : Color.grayLightThird)};
  ${({ position }) =>
    position === `top`
      ? `border-top-left-radius: 150px;border-top-right-radius: 150px; border-bottom-left-radius: 15px;border-bottom-right-radius: 15px;`
      : `border-bottom-left-radius: 150px;border-bottom-right-radius: 150px; border-top-left-radius: 15px;border-top-right-radius: 15px;`};
`;
