import styled, { css } from 'styled-components';
import { Breakpoints, Color } from '~/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerInvoiceDetails = styled.div`
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  padding: 32px 24px;
  gap: 4px;
  border-radius: 0 0 10px 10px;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #004b55;
  }
`;

export const ContainerInfoTitle = styled.div`
  min-height: 32px;
  font-size: 16px;
  color: ${Color.blueIntense};
  font-weight: 600;
  background: ${Color.BlueLight};
  padding: 0 24px;
  display: flex;
  align-items: center;
  border-radius: 10px 10px 0 0;
  line-height: 24px;
`;

export const ContainerInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${Breakpoints.tablet.max}px) {
    flex-direction: row;
  }
`;

export const ContainerBox = styled.div<{ isDetail?: boolean }>`
  min-height: 36px;
  display: flex;
  align-items: center;
  border: 1px solid #999999;
  border-radius: 10px;
  padding: 0 10px;

  ${({ isDetail }) =>
    isDetail &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}
`;

export const TextBox = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #004b55;
  white-space: nowrap;
  overflow: hidden;
`;
