import React, { FC, useState } from 'react';
import { Dialog } from '~/components/Dialog';
import { Margin } from '~/components/Margin';
import { Types } from '..';
import { TypeText } from '../styles';
import ModalFile from './ModalFile';

import { Container, ContainerFile } from './styles';
import { UploadField } from './UploadField';

interface IProps {
  type: Types | null;
  setType: (type: any) => void;
}

const SocialTariffStepFiles: FC<IProps> = ({ type, setType }) => {
  const [openModalFile, setOpenModalFile] = useState<boolean>(false);
  const [filePreview, setFilePreview] = useState<File | null>(null);

  return (
    <>
      <Dialog open={openModalFile} onClose={setOpenModalFile}>
        <ModalFile onClose={setOpenModalFile} file={filePreview} />
      </Dialog>
      <Container>
        <Margin height={32} />
        <TypeText>Favor anexar os seguintes documentos:</TypeText>
        <Margin height={8} />
        <ContainerFile>
          {type &&
            type.files.map((file, index) => (
              <>
                <UploadField
                  key={index && index.toString()}
                  className="large"
                  name={file.name}
                  content={type.files[index].file}
                  note={file.note}
                  handleUpload={(image: File | null) => {
                    if (image) {
                      const reader = new FileReader();
                      reader.readAsDataURL(image);

                      reader.onloadend = () => {
                        const base64String = String(reader.result)
                          .replace('data:', '')
                          .replace(/^.+,/, '');
                        newType.files[index].base64 = base64String;
                      };
                    }

                    let newType = {
                      ...type,
                    };
                    newType.files[index].file = image;

                    setType(newType);
                  }}
                  handlePreview={(image: File) => {
                    setFilePreview(image);
                    setOpenModalFile(true);
                  }}
                >
                  {file.label}
                </UploadField>
                <Margin width={31} />
              </>
            ))}
        </ContainerFile>
      </Container>
    </>
  );
};

export default SocialTariffStepFiles;
