import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import {
  getDataDownloadFileCustomerObligationRequest,
  patchStatusStepCustomerObligationRequest,
} from '~/store/modules/customerObligation/actions';
import { stepStatusEnum } from '~/enums/customerObligationEnum';
import StatusInfo from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/components/StatusInfo';
import { Margin } from '~/components/Margin';
import { ContainerButton } from '~/views/SupplyAndDepletionPossibilityDeclaration/inside/CustomerObligation/styles';
import LoadingComponent from '~/components/Loading';
import { Main } from './styles';
import { AppDispatch } from '~/store';

const Status: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);
  const loadingDownloadFile = useSelector(
    (item: IState) => item.customerObligation.loadingDownloadFile,
  );

  const [disabled] = useState(true);

  return (
    <Main>
      {loadingDownloadFile && <LoadingComponent labelWait="baixando..." />}
      {caseOC?.step.status === stepStatusEnum.SUCCESS && (
        <StatusInfo
          status="success"
          onClick={() => {
            // setDisabled(false);
            dispatch(
              getDataDownloadFileCustomerObligationRequest({
                idFile: caseOC?.approvedDocuments.AIO || '',
              }),
            );
          }}
          labelButton="Baixar AIO Aprovada"
          maxWidthButton={272}
        >
          Os documentos foram gerados. Faça o download da
          <br />
          documentação antes de seguir com sua solicitação.
        </StatusInfo>
      )}
      {caseOC?.step.status === stepStatusEnum.WAIT && (
        <StatusInfo status="wait">
          Os documentos foram enviados para análise. Você pode acompanhar o andamento
          na tela inicial da <b>Viabilidade de Empreendimento.</b>
        </StatusInfo>
      )}
      {caseOC?.step.status === stepStatusEnum.APPROVED_LICENSE && (
        <StatusInfo status="warning">
          Sua licença se encontra aprovada pela PMRJ e está disponível para retirada
          na loja Iguá Rio Tanque.
          <br />
          Endereço:
          <br />
          Rua Henrique, 107, Tanque, Jacarepaguá, Rio de Janeiro/RJ.
          <br />O atendimento para retirada funciona toda quarta-feira das 09:00 Hrs
          até as 16:00 Hrs.
        </StatusInfo>
      )}
      <Margin height={40} />
      <ContainerButton>
        <Button
          backIcon
          backgroundColor={Color.green}
          onClick={() => {
            dispatch(
              patchStatusStepCustomerObligationRequest({
                name: '',
                number: 5,
                substep: 'A',
                status: stepStatusEnum.SUCCESS,
              }),
            );
          }}
        >
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={disabled ? Color.grayLightSeconde : Color.blueSeconde}
          style={{ opacity: 1 }}
          onClick={() => {
            console.log('passe a etapa');
          }}
          disabled={disabled}
        >
          Continuar
        </Button>
      </ContainerButton>
    </Main>
  );
};

export default Status;
